import { animated } from 'react-spring';

import styled, { css } from 'styled-components';
import sizes from 'utils/screenSizes';

const MediaWrapperModifier = {
  mobile: () => css`
    justify-content: center;

    div {
      max-width: 400px;
    }

    h3 {
      font-size: 20px;
      line-height: 24px;
    }

    p {
      max-width: 360px;
      font-size: 14px;
      line-height: 17px;
    }

    textarea {
      padding: 8px !important;
      height: 80px;
      max-width: 400px;
    }

    @media (min-width: 1000px) and (min-height: 1200px) {
      padding-top: 20px;
      > img:first-child {
        max-width: 100%;
        max-height: 100%;
      }

      textarea {
        height: 120px;
      }

      p {
        font-size: 16px;
        line-height: 19px;
      }

      h3 {
        font-size: 22px;
        line-height: 26px;
      }
    }

    @media (max-width: ${sizes.smallMobile}) and (max-height: 670px) {
      > img:first-child {
        max-width: 70%;
        max-height: 73%;

        @media (max-height: 580px) {
          max-width: 40%;
          max-height: 43%;
        }
      }

      textarea {
        height: 60px;
      }

      p {
        font-size: 12px;
        line-height: 15px;
      }

      h3 {
        font-size: 18px;
        line-height: 20px;
      }
    }
  `,
  desktop: () => css`
    padding: 40px;
    padding-top: 10px;
    max-width: 485px;
    h3 {
      font-size: 28px;
      line-height: 32px;
    }

    p {
      font-size: 20px;
      line-height: 24px;
    }

    button {
      font-size: 22px !important;
    }

    textarea {
      padding: 8px;
      height: 120px;
      font-size: 20px;
    }

    @media (max-height: 768px) {
      padding-top: 0;
      h3 {
        font-size: 24px;
        line-height: 30px;
      }

      p {
        font-size: 16px;
        line-height: 20px;
      }

      textarea {
        padding: 8px;
        height: 100px;
        font-size: 13px;
      }
    }
  `,
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  > img {
    max-width: 80%;
    max-height: 83%;
    margin: 10px;
    &.finish {
      transform: scale(1.2);
    }
  }

  h3,
  p {
    text-align: center;
    font-style: normal;
  }

  h3 {
    font-family: 'ChickenSansBold';
    font-weight: bold;
  }

  p {
    font-family: 'ChickenSansRegular';
    font-weight: normal;

    margin-top: 15px;
    margin-bottom: 15px;
    padding-right: 5px;
    padding-left: 5px;

    opacity: 50%;
  }

  textarea {
    align-self: center;
    width: 100%;
    margin-bottom: 20px;
  }

  @media (min-width: ${sizes.ipadPro}) {
    ${MediaWrapperModifier.desktop()}
  }
  @media (max-width: ${sizes.ipadPro}) {
    ${MediaWrapperModifier.mobile()}
  }
`;

const MediaRatingContainerModifier = {
  mobile: () => css`
    @media (max-width: ${sizes.smallMobile}) {
      img {
        margin: 3px;
      }
    }

    @media (max-width: 280px) {
      img {
        margin: 1px;
        width: 40px !important;
        height: 40px !important;
      }
    }
    @media (min-width: 1000px) and (min-height: 1200px) {
      img {
        width: 65px !important;
        height: 65px !important;
      }
    }
    div {
      button {
        padding: 0;
      }
      padding: 0;
    }
  `,
  desktop: () => css`
    @media (max-width: ${sizes.notebook}) {
    }
    @media (min-width: ${sizes.notebook}) {
    }
    @media (min-width: ${sizes.desktop}) {
    }
  `,
};

export const RatingContainer = styled.div`
  margin-bottom: 40px;
  flex-direction: row !important;
  div {
    flex-direction: row !important;
  }

  @media (max-width: ${sizes.ipadPro}) {
    ${MediaRatingContainerModifier.mobile()}
  }
`;

const MediaButtonContainerModifier = {
  mobile: () => css`
    width: 80vw;
  `,
  desktop: () => css`
    > button:nth-child(2) {
      display: none;
    }
  `,
};

export const ButtonContainer = styled.div`
  padding: 0 10px;

  @media (max-width: ${sizes.ipadPro}) {
    ${MediaButtonContainerModifier.mobile()}
  }
  @media (min-width: ${sizes.ipadPro}) {
    ${MediaButtonContainerModifier.desktop()}
  }
`;

const SpaceModifier = {
  small: () =>
    css`
      height: 22px;
    `,
  medium: () =>
    css`
      height: 33px;
    `,
  big: () =>
    css`
      height: 40px;
    `,
};

export const Spacing = styled.div`
  ${({ size }) =>
    css`
      ${SpaceModifier[size || 'medium']()}
    `}
`;

const ModalOverlayModifier = {
  isOpen: () => css`
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  `,
  isClose: () => css`
    opacity: 0;
    visibility: hidden;
  `,
};

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  transition: all 0.5s 0.5s ease-in-out;
  background-color: rgba(0, 0, 0, 0.6);

  z-index: 15;

  ${({ open }) =>
    css`
      ${open && ModalOverlayModifier.isOpen()}
      ${!open && ModalOverlayModifier.isClose()}
    `};
`;

const MediaModalModifier = {
  mobile: () => css`
    padding: 20px;
    width: 90%;
  `,
  desktop: () => css`
    padding: 10px;
    max-height: 650px;
  `,
};

export const Modal = styled(animated.div)`
  max-width: 460px;
  border-radius: 8px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  //transition: all 0.5s ease-in-out;
  @media (min-width: ${sizes.ipadPro}) {
    ${MediaModalModifier.desktop()}
  }
  @media (max-width: ${sizes.ipadPro}) {
    ${MediaModalModifier.mobile()}
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 3px 5px;
  > button {
    width: 32px;
    img {
      max-height: 30px;
      max-width: 30px;
    }
  }
  @media (max-width: ${sizes.ipadPro}) {
    display: none;
  }
`;
