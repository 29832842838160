export default function IconYoutube() {
  return (
    <svg
      width="41"
      height="30"
      viewBox="0 0 41 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M35.887 1.74592C37.6206 2.21225 38.9857 3.58632 39.4491 5.33108C40.2911 8.4937 40.2911 15.0919 40.2911 15.0919C40.2911 15.0919 40.2911 21.69 39.4491 24.853C38.9857 26.5974 37.6206 27.9715 35.887 28.438C32.745 29.2853 20.1455 29.2853 20.1455 29.2853C20.1455 29.2853 7.54616 29.2853 4.40415 28.438C2.67054 27.9715 1.30522 26.5976 0.841992 24.8528C0 21.6902 0 15.0919 0 15.0919C0 15.0919 0 8.4937 0.841992 5.33108C1.30522 3.58632 2.67054 2.21225 4.40415 1.74592C7.54616 0.898438 20.1455 0.898438 20.1455 0.898438C20.1455 0.898438 32.745 0.898438 35.887 1.74592ZM26.5555 15.092L16.0248 21.0825V9.10107L26.5555 15.092Z"
        fill="white"
      />
    </svg>
  );
}
