import { useHistory } from 'react-router-dom';

import FramePop from 'assets/images/sucess/FramePop.png';
import TitleSection from 'components/TitleSection';

import { Container, Content, Button } from './styles';

const RecoveredNumber = () => {
  const { push } = useHistory();
  return (
    <Container>
      <TitleSection
        title="Sucesso!"
        subtitle="Novo número cadastrado com sucesso"
      />
      <Content>
        <img src={FramePop} alt="Pop with trombet" />
      </Content>

      <Button
        onClick={() => {
          push('/');
        }}
      >
        <p>Entrar</p>
      </Button>
    </Container>
  );
};

export default RecoveredNumber;
