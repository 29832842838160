import React, { useEffect, useRef, useState } from 'react';

import BackButton from 'components/BackButton';
import FooterComponent from 'components/FooterComponent';
import HeaderComponent from 'components/HeaderComponent';
import { SendEmailContainer } from 'containers';
import { useHeader } from 'hooks/HeaderContext';

import { Container } from './styles';

function SignInNumber() {
  const { handleRef } = useHeader();
  const [urlBack, setUrlBack] = useState('/perfil');
  const [title, setTitle] = useState('Direitos e solicitações');
  const containerRef = useRef();

  useEffect(() => {
    handleRef(containerRef);
  }, []);

  useEffect(() => {
    const params = new URL(window.location).searchParams;

    const href = params.get('href');
    if (href) setUrlBack(href);

    const titleParams = params.get('title');
    if (titleParams) setTitle(titleParams);
  }, []);

  return (
    <Container ref={containerRef}>
      <HeaderComponent title={title} href={urlBack} />
      <BackButton />

      <SendEmailContainer />

      <FooterComponent />
    </Container>
  );
}

export default SignInNumber;
