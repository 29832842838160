import SideProductsFilter from './SideProductsFilter';

export function Container({
  categories,
  selectedCategory,
  setSelectedCategory,
  selectedFilter,
  setSelectedFilter,
  filters,
}: any) {
  return (
    <SideProductsFilter
      categories={categories}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
      selectedFilter={selectedFilter}
      setSelectedFilter={setSelectedFilter}
      filters={filters}
    />
  );
}
