import React from 'react';

import popBurn from 'assets/images/Pop/popBurn.svg';
import popTalk from 'assets/images/Pop/popTalk.svg';
import Button from 'components/Button';
import TextArea from 'components/TextArea';
import PropTypes from 'prop-types';

import { ModalContainer } from '../ModalContainer';
import * as S from '../styles';

const FeedbackOptions = {
  good: {
    pop: popTalk,
    title: 'Conta pra gente o\nmotivo da nota',
    description: (
      <p>
        Você ama frango tanto como a gente.
        <br />
        Que tal contar tudinho?
      </p>
    ),
  },
  bad: {
    pop: popBurn,
    title: 'Conta por que fritou a cabeça',
    description: (
      <p>
        Queremos saber quais são os problemas
        <br />
        que você passou e trabalhar para
        <br />
        melhorar sua experiência
      </p>
    ),
  },
};

const Feedback = ({ ratingType, onNext, onClose }) => (
  <ModalContainer typeButton="submit" onClose={onClose}>
    <img src={FeedbackOptions[ratingType].pop} alt="Mascote Pop falando" />
    <h3>{FeedbackOptions[ratingType].title}</h3>
    {FeedbackOptions[ratingType].description}
    <TextArea
      placeholder="Coloque aqui seu comentário (opcional)"
      name="comments"
    />
    <S.ButtonContainer>
      <Button variant="primary" type="submit" onClick={onNext}>
        Enviar
      </Button>
      <Button variant="tertiary" type="submit" onClick={onClose}>
        Fechar
      </Button>
    </S.ButtonContainer>
  </ModalContainer>
);

Feedback.propTypes = {
  ratingType: PropTypes.oneOf(['good', 'bad']).isRequired,
  onNext: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { Feedback };
