import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  position: relative;

  header {
    @media all and (max-width: ${sizes.notebook}) {
      display: none;
    }
  }
  @media all and (max-width: ${sizes.notebook}) {
    div:nth-of-type(2) {
      form {
        padding: 0;
      }
    }
  }
`;
