import MenuItem from 'components/MenuItem';
import * as S from './styles';

interface IContainerProps {
  menuContentRef: React.RefObject<HTMLDivElement>;
  elementsRef: any;
  categories: any;
  products: any;
  selectedCategory: any;
}

export function Container({
  categories,
  selectedCategory,
  products,
  menuContentRef,
  elementsRef,
}: IContainerProps) {
  const listCategories = selectedCategory
    ? categories.filter((category: any) => category.name === selectedCategory)
    : categories;

  return (
    <S.ContainerProductsMenu id="teste">
      {listCategories?.map((category: any) => {
        const hasProductWithCategory = products?.some(
          (product: any) => product.categoriesIds[0]?.id === category.id,
        );
        return (
          <div
            key={category.name}
            id={category.name}
            ref={elementsRef[category.name]}
          >
            {hasProductWithCategory && (
              <>
                <S.BorderLine />
                <S.CategoryTitle>{category.name}</S.CategoryTitle>
              </>
            )}
            <S.ProductsRow>
              {products.map((product: any) => {
                if (category.id === product.categoriesIds[0]?.id) {
                  return <MenuItem product={product} key={product.id} />;
                }
                return null;
              })}
            </S.ProductsRow>
          </div>
        );
      })}
    </S.ContainerProductsMenu>
  );
}
