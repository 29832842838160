import styled, { css } from 'styled-components';
import { popeyesSecondaryColors, spacings } from 'styles';
import sizes from 'utils/screenSizes';

import * as T from './interfaces';

export const Container = styled.div<T.IStyleDropdown>`
  display: none;
  position: absolute;
  z-index: 2;
  width: 232px;
  ${props =>
    props.isActive &&
    'display: flex; flex-direction: column; align-items: center;'};

  @media (min-width: ${sizes.desktop}) {
    width: 264px;
  }
`;

export const ContentDropDown = styled.div`
  top: 65px;
  position: relative;

  padding: 10px;
  width: 300px;

  border-radius: 4px;

  border: solid 0.7px #e5e5e5;
  background: ${popeyesSecondaryColors.light};

  button {
    width: 80%;
    margin: ${spacings.space07} auto ${spacings.space04};
  }
  @media (min-width: ${sizes.desktop}) {
    top: 80px;
  }
`;

export const ArrowUp = styled.div<T.IStyleDropdown>`
  position: relative;
  top: 65px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #e5e5e5;
  @media (min-width: ${sizes.desktop}) {
    top: 80px;
  }

  ${props =>
    !props.showIcon &&
    css`
      display: none;
    `};
`;
