import CtaAppStore from '../../assets/images/home/cta-app-store.png';
import CtaGooglePay from '../../assets/images/home/cta-google-play.png';
import ImgMobileAppPlk from '../../assets/images/home/img-celular-app-plk.png';
import * as S from './styles';

function AboutAppComponent() {
  return (
    <S.Container>
      <S.BoxContent>
        <S.Content>
          <S.MainText>
            <div>
              <p>Baixe o App</p>
              <h1 id="AboutAppComponent">Popeyes</h1>
            </div>
            <p>e veja todas a ofertas</p>
          </S.MainText>

          <S.Info>
            <div>
              <a href="https://play.google.com/store/apps/details?id=com.app.plk&pcampaignid=web_share">
                <img src={CtaGooglePay} alt="Logo GooglePLay" />
              </a>
              <a href="https://apps.apple.com/br/app/popeyes-brasil/id6449981844">
                <img src={CtaAppStore} alt="Logo App Store" />
              </a>
            </div>
            <span>
              Apple e o logotipo Apple são marcas comerciais da Apple Inc.,
              registadas nos EUA e em outros países. App Store é uma marca de
              serviço da Apple Inc. Google Play é uma marca registada da Google
              Inc. Aplicam-se os termos vigentes.
            </span>
          </S.Info>
        </S.Content>
        <S.ImageAppMockup
          src={ImgMobileAppPlk}
          alt="Imagem de celular com app do plk."
        />
      </S.BoxContent>
    </S.Container>
  );
}

export default AboutAppComponent;
