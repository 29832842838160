import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  height: 100%;
  width: 100%;
  margin: 0 auto;
  max-width: 850px;

  background-color: var(--color-popeyes-orange);

  padding-bottom: 10%;
  padding-top: var(--size-header-height);

  label {
    color: var(--color-salt-white);
  }

  h2 {
    color: var(--color-salt-white);
  }

  p {
    line-height: 19px;
    color: var(--color-salt-white);
  }

  > a {
    padding: 0px 24px;
  }

  button {
    width: 90%;
  }

  @media (min-width: ${sizes.notebook}) {
    max-width: 100vw;

    padding-bottom: 0;
    padding-top: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: auto;
    > div:first-of-type {
      margin-top: 20px;
    }

    button {
      width: 305px;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  align-items: left;

  margin-top: auto;
  right: 7%;

  height: 50%;
  max-width: 850px;

  background-color: var(--color-popeyes-orange);

  img {
    width: 96%;
    opacity: 0.5;
  }

  @media (min-width: ${sizes.notebook}) {
    position: static;
    margin: 0;
    max-width: 100vw;
    justify-content: center;
    img {
      width: 30%;
    }
  }
`;
