import React, { createContext, useContext, useEffect, useState } from 'react';

import ModalResponsive from 'components/ModalResponsive';
import { Button } from 'components';
import { ModalContent } from './styles';

// Criando o contexto para armazenar as informações de localização
const LocationContext = createContext<{
  userLocation: {
    latitude: number;
    longitude: number;
  };
  handleGeoLocationRequest: (setLoading: (value: boolean) => void) => void;
  calculateDistance: (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
  ) => number;
} | null>(null);

// Componente provedor do contexto que irá fornecer as informações de localização
export const LocationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userLocation, setUserLocation] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);

  const [openModal, onToggleModal] = useState(false);

  function calculateDistance(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
  ): number {
    const R = 6371; // Raio da Terra em km
    const dLat = deg2rad(lat2 - lat1); // Diferença de latitude em radianos
    const dLon = deg2rad(lon2 - lon1); // Diferença de longitude em radianos
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distância em km
    return d;
  }

  // Função para converter graus em radianos
  const deg2rad = (deg: number) => {
    return deg * (Math.PI / 180);
  };

  useEffect(() => {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported by your browser');
      return;
    }

    const successCallback = (position: GeolocationPosition) => {
      setUserLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    };

    navigator.permissions.query({ name: 'geolocation' }).then(result => {
      if (result.state === 'granted')
        navigator.geolocation.getCurrentPosition(successCallback);
    });
  }, []);

  function handleGeoLocationRequest(setLoading: (value: boolean) => void) {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported by your browser');
      return;
    }

    const successCallback = (position: GeolocationPosition) => {
      setUserLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    };

    const errorCallback = (error: GeolocationPositionError) => {
      setLoading(false);
      onToggleModal(true);
    };

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  }

  return (
    <LocationContext.Provider
      value={{
        userLocation,
        handleGeoLocationRequest,
        calculateDistance,
      }}
    >
      {children}
      <ModalResponsive
        open={openModal}
        onClose={() => onToggleModal(false)}
        maxModalHeight={380}
        sizeModal="middle"
      >
        <ModalContent>
          <h3 className="title">Não foi possível acessar sua localização</h3>
          <p className="subtitle">
            Tente habilitar sua localização pelas configurações de seu navegador
            e tente novamente.
          </p>

          <Button
            className="rotate"
            type="button"
            variant="primary"
            font="regular"
            loading={false}
            margin={undefined}
            size={undefined}
            onClick={() => onToggleModal(false)}
          >
            <span className="votar-btn">Tentar novamente</span>
          </Button>
        </ModalContent>
      </ModalResponsive>
    </LocationContext.Provider>
  );
};

// Hook para acessar as informações de localização do usuário
export const useLocation = () => {
  const location = useContext(LocationContext);
  if (!location) {
    throw new Error('useLocation must be used within a LocationProvider');
  }
  return location;
};
