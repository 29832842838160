import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 100%;
  width: 100%;
  max-width: var(--size-max-width-container);

  margin: 0 auto;
  padding-top: var(--size-header-height);

  background-color: #e5e5e5;
  position: relative;

  font-family: 'ChickenSansRegular';

  .my-cupons {
    display: flex;
    flex-direction: column;
    margin: 26px 0px;

    row-gap: 8px;
  }

  > .contact {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
    margin-top: 40px;

    h3 {
      font-family: 'ChickenSansRegular' !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 24px !important;
      line-height: 21px !important;

      color: #303030;
    }

    p {
      margin: 32px 0 0 0;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #929292;
    }

    .contact-button {
      width: 100%;
      margin-top: 48px;
      padding: 0px 24px;
    }
  }

  @media all and (min-width: ${sizes.notebook}) {
    padding-top: 10px;
    border-radius: 16px;
    overflow: hidden;

    > .contact {
      margin: 30px 0px;
      padding: 0px 30px;

      button {
        display: flex;
        width: 80%;
        margin: 0px auto;
      }
    }
  }
`;

export const HelpContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: auto;

  p {
    padding: 24px 41.44px;

    text-align: start;
    font-family: 'ChickenSansBold' !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 18px !important;
    line-height: 21px !important;

    color: #303030;
    background-color: var(--color-salt-white);

    @media all and (min-width: ${sizes.notebook}) {
      padding: 20px 30px;
    }
  }

  button:last-child {
    margin-top: 2px;
  }
`;

export const ItemButton = styled.button`
  position: relative;

  outline: none;
  border: none;
  border-radius: 0px;

  width: 100%;
  min-height: 74px;
  background-color: var(--color-salt-white);

  font-family: 'ChickenSansRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;

  padding: 24px 41.44px;
  text-align: left;

  span {
    display: block;

    font-size: 14px;
    line-height: 16px;
  }

  p {
    font-size: 24px;
    line-height: 28px;

    margin-bottom: 7px;
  }

  @media all and (min-width: ${sizes.notebook}) {
    padding: 40px 30px;
    font-size: 1.2rem;

    line-height: auto;
  }

  :hover {
    opacity: 0.8;
  }
  cursor: pointer;
`;

export const UserContent = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: 2px;
`;

export const ArrowSpace = styled.div`
  position: absolute;

  right: 32px;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
`;
