import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 100%;
  width: 100%;
  max-width: var(--size-max-width-container);

  margin: 0 auto;
  padding-top: var(--size-header-height);
  padding-bottom: ${({ footerHeight }) =>
    footerHeight ? `${footerHeight}px` : '110px'};

  @media all and (min-width: ${sizes.notebook}) {
    padding-bottom: 20px;
    min-height: auto;
  }
`;

export const SubjectContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: 40px;

  h1 {
    padding: 0px 24px;
    padding-bottom: 32px;

    font-family: 'ChickenSansBold';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
  }

  @media all and (min-width: ${sizes.notebook}) {
    padding-bottom: 0px;
  }
`;

export const Footer = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;

  flex-direction: column;

  font-family: 'ChickenSansRegular';
  font-style: normal;
  font-weight: normal;

  color: var(--color-text-switch);
  background-color: var(--color-salt-white);

  text-align: center;
  margin-top: auto;

  > p {
    margin: 20px 0px;

    font-size: 12px;
    line-height: 14px;
  }

  form {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 100%;
    padding: 0 24px;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;
      max-width: 552px;

      margin-bottom: 8px;

      > span {
        font-size: 12px;
        line-height: 14px;
      }
    }

    button {
      margin-top: 20px;
      margin-bottom: 40px;

      max-width: 552px;
    }
  }

  @media all and (min-width: ${sizes.notebook}) {
    position: unset;

    > button {
      margin-bottom: 14px;
    }
  }
`;

export const Item = styled.button`
  position: relative;

  outline: none;
  border: none;
  border-radius: 0px;

  width: 100%;
  min-height: 74px;
  padding: 0 24px;
  margin-bottom: 32px;

  font-family: 'ChickenSansRegular';
  font-style: normal;
  font-weight: normal;

  text-align: left;
  background: transparent;

  span {
    display: -webkit-box;

    font-size: 14px;
    line-height: 16px;
    color: #929292;

    padding-right: 35px;

    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  p {
    font-size: 18px;
    line-height: 21px;

    margin-bottom: 7px;
    padding-right: 35px;
  }

  @media all and (min-width: ${sizes.notebook}) {
    padding: 0px 30px;
    font-size: 1.2rem;

    line-height: auto;
  }

  :hover {
    opacity: 0.8;
  }
  cursor: pointer;

  :last-child {
    margin-bottom: 10px;
  }
`;

export const ArrowSpace = styled.div`
  position: absolute;

  right: 24px;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
`;

export const Question = styled.div`
  width: 100%;
  padding: 0 24px;
  margin-bottom: 32px;

  font-family: 'ChickenSansRegular';
  font-style: normal;
  font-weight: normal;

  text-align: left;
  background: transparent;

  span {
    margin-top: 10px;
    display: block;

    font-size: 14px;
    line-height: 17px;
    color: #929292;
  }

  p {
    font-size: 24px;
    line-height: 29px;

    margin-top: 32px;
    margin-bottom: 25px;
  }

  cursor: pointer;

  @media all and (min-width: ${sizes.notebook}) {
    padding: 40px 30px;
    font-size: 1.2rem;

    line-height: auto;
    cursor: default;
  }
`;

export const OptionButtons = styled.div`
  display: flex;
  column-gap: 16px;

  width: 100%;
  padding: 0 24px;
  margin-bottom: 48px;

  max-width: 600px;
`;

export const Sucessful = styled.div`
  width: 100%;
  padding: 0 24px;
  margin-bottom: 48px;

  max-width: 600px;

  > div {
    margin-bottom: 40px;

    p {
      margin-top: 12px;

      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #303030;
    }
  }
`;
