import styled, { css } from 'styled-components';
import { font as fontGlobal, popeyesPrimaryColors } from 'styles';

interface IRestaurantListWrapperProps {
  open: boolean;
}

export const RestaurantListInternalWrapper = styled.div<IRestaurantListWrapperProps>`
  width: 100%;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: ${props => (props.open ? 'none' : '0')};
  transition: all 0.4s ease-in-out;
  overflow: hidden;
`;

export const ArrowExpanderButton = styled.button`
  border: none;
  background: transparent;
`;

export const RestaurantStateName = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  a {
    color: ${popeyesPrimaryColors.primary};
    text-decoration: underline !important;
  }

  h1 {
    width: fit-content;
    line-height: 29px;
    font-family: 'ChickenSansBold';
  }

  span {
    font-size: 24px;
    color: #757575;
  }
`;

export const RestaurantStateWrapper = styled.div`
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid #bdbdbd;
  font-family: 'ChickenSansRegular';
  font-weight: 700;
  cursor: pointer;
`;

export const RestaurantStateHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
