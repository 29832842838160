import defaultRemoteConfig from 'defaultRemoteConfig';
// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app';

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';
import 'firebase/remote-config';
// import 'firebase/messaging';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = process.env.STORYBOOK_ENV_STORE
  ? {
      apiKey: process.env.STORYBOOK_APP_FIREBASE_API_KEY,
      authDomain: `${process.env.STORYBOOK_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
      databaseURL: `https://${process.env.STORYBOOK_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
      projectId: process.env.STORYBOOK_APP_FIREBASE_PROJECT_ID,
      storageBucket: `${process.env.STORYBOOK_APP_FIREBASE_PROJECT_ID}.appspot.com`,
      messagingSenderId: process.env.STORYBOOK_APP_FIREBASE_SENDER_ID,
      appId: process.env.STORYBOOK_APP_FIREBASE_APP_ID,
      measurementId: process.env.STORYBOOK_APP_FIREBASE_MEASUREMENT_ID,
    }
  : {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
      databaseURL: `https://${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
      messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// Initialize Remote Config
const remoteConfig = firebase.remoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = Number(
  process.env.REACT_APP_FIREBASE_INTERVAL_MILLIS,
);
remoteConfig.defaultConfig = defaultRemoteConfig;
remoteConfig.fetchAndActivate();

// Initialize Cloud Messaging
// const messaging = firebase.messaging();
// messaging
//   .getToken({ vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_KEY })
//   .then(currentToken => {
//     if (currentToken) {
//       // Send the token to your server and update the UI if necessary
//       console.log('Firebase messaging token', currentToken);
//       localStorage.setItem('@Popeyes.firebase_token', currentToken);

//       messaging
//         .subscribeToTopic([currentToken], 'marketing')
//         .then(response => {
//           // See the MessagingTopicManagementResponse reference documentation
//           // for the contents of response.
//           console.log('Successfully subscribed to topic:', response);
//         })
//         .catch(error => {
//           console.log('Error subscribing to topic:', error);
//         });
//     }
//   })
//   .catch(err => {
//     console.log('An error occurred while retrieving token. ', err);
//   });

// messaging.onMessage(function (payload) {
//   const notificationOption = {
//     body: payload.notification.body,
//     icon: payload.notification.icon,
//   };

//   if (Notification.permission === 'granted') {
//     const notification = new Notification(
//       payload.notification.title,
//       notificationOption,
//     );

//     notification.onclick = ev => {
//       ev.preventDefault();
//       if (payload.data.click_action)
//         window.open(payload.data.click_action, '_self');
//       notification.close();
//     };
//   }
// });

export default firebase;

export const fireLogEvent = (nameLog, params) =>
  firebase.analytics().logEvent(nameLog, params);
