import React, { useEffect, useRef } from 'react';

import BackButton from 'components/BackButton';
import FooterComponent from 'components/FooterComponent';
import HeaderComponent from 'components/HeaderComponent';
import { SetNumberContainer } from 'containers';
import { useHeader } from 'hooks/HeaderContext';
import { AuthLayout } from 'layouts';

import { Container } from './styles';

export default function SetNumber() {
  const { handleRef } = useHeader();
  const containerRef = useRef();

  useEffect(() => {
    handleRef(containerRef);
  }, []);

  return (
    <Container ref={containerRef}>
      <HeaderComponent title="Celular" href="/validado" />
      <BackButton />
      <AuthLayout ref={containerRef}>
        <SetNumberContainer />
      </AuthLayout>
      <FooterComponent />
    </Container>
  );
}
