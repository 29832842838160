import React, { useEffect, useRef, useState } from 'react';

import { HeaderMenu } from 'components/Organisms';

import { SideProductsFilter } from 'components/Molecules';
import { MenuFilterIconBar } from 'components/Molecules/MenuFilterIconBar';
import { MenuProducts } from 'components/Molecules/MenuProducts';
import { menuApi } from 'services';
import { breakpoints } from 'styles';
import * as T from './interfaces';
import * as S from './styles';

const clamp = (value: number) => Math.max(0, value);
const screenHeight = window.screen.height;
const offset = screenHeight / 2;

export const Cardapio: React.FC<T.IMenuRestaurant> = ({
  containerRef,
  hideBanner,
  hideFilters,
}) => {
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const menuBarRef = useRef<HTMLDivElement | null>(null);
  const menuContentRef = useRef<HTMLDivElement | null>(null);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('');
  const [isFilterAllCategoriesActive, setIsFilterAllCategoriesActive] =
    useState(true);
  const [productsWithFilters, setProductsWithFilters] = useState([]);
  const [filters, setFilters] = useState([]);

  const [ids, setIds] = useState(['Carregando...']);
  const [activeId, setActiveId] = useState('');

  const elementsRef: any = {};

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (menuBarRef.current) {
      const scrollContainer = menuBarRef.current;

      const handleWheel = (evt: WheelEvent) => {
        evt.preventDefault();
        scrollContainer.scrollLeft += evt.deltaY;
      };

      scrollContainer.addEventListener('wheel', handleWheel);

      return () => {
        scrollContainer.removeEventListener('wheel', handleWheel);
      };
    }
  }, []);

  useEffect(() => {
    if (menuContentRef?.current && menuBarRef?.current) {
      menuContentRef.current.addEventListener('scroll', handleVerticalScroll);
    }

    return () => {
      if (menuContentRef?.current) {
        menuContentRef.current.removeEventListener(
          'scroll',
          handleVerticalScroll,
        );
      }
    };
  }, []);

  const handleVerticalScroll = () => {
    if (menuContentRef?.current && menuBarRef?.current) {
      menuBarRef.current.scrollLeft = menuContentRef.current.scrollTop;
    }
  };

  const handleClickOnFilter = () => {
    setShowMobileFilters(!showMobileFilters);
  };

  useEffect(() => {
    if (hideBanner && containerRef.current) {
      const { innerWidth: width } = window;
      if (width >= parseInt(breakpoints.large, 10)) {
        containerRef.current.scrollTop = 100;
      }
    }
  }, [hideBanner, containerRef]);

  function listener() {
    const verticalScroll = window.scrollY;

    if (menuBarRef.current) {
      if (verticalScroll > 530) {
        menuBarRef.current.scrollLeft = 250;
      } else if (verticalScroll > 800) {
        menuBarRef.current.scrollLeft = 600;
      } else {
        menuBarRef.current.scrollLeft = 0;
      }
    }

    const position = ids
      .map(id => {
        // const element = elementsRef[id]?.current;
        const element = document.getElementById(id);

        if (!element) {
          return { id, top: -1, bottom: -1 };
        }

        const rect = element.getBoundingClientRect();
        const top = clamp(rect.top + verticalScroll - offset);
        const bottom = clamp(rect.bottom + verticalScroll - offset);

        return { id, top, bottom };
      })
      .find(
        ({ top, bottom }: any) =>
          verticalScroll >= top && verticalScroll <= bottom,
      );

    setActiveId(position?.id || '');
  }

  useEffect(() => {
    listener();

    window.addEventListener('resize', listener);
    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('resize', listener);
      window.removeEventListener('scroll', listener);
    };
  }, [elementsRef, ids, offset]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await menuApi.get('/listar-categorias');

        const responseData = response.data;
        const categoriesData = responseData.results;
        const onlyCategoriesWithProducts = categoriesData.filter(
          (category: any) => category.produtos.length > 0,
        );

        const newCategories = onlyCategoriesWithProducts.map(
          (category: any) => ({
            id: category.id,
            order: category.ordem,
            name: category.nome,
            image: category.imagem?.url || null,
            pedacos: category.pedacos,
            suave: category.suave,
            vibrante: category.vibrante,
            products: category.produtos,
          }),
        );

        const sortedByOrder = [...newCategories].sort(
          (a: any, b: any) => a.order - b.order,
        );

        const getCategoriesName: any = sortedByOrder.map(
          (category: any) => category.name,
        );

        setIds(getCategoriesName);

        setCategories(sortedByOrder);
      } catch (error) {
        console.error('Erro ao obter as categorias:', error);
      }
    };

    fetchCategories();
  }, [selectedCategory]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        let response: any;

        if (!selectedCategory && !selectedFilter) {
          response = await menuApi.get(`/listar-produtos`);
        }

        if (selectedCategory && !selectedFilter) {
          response = await menuApi.get(
            `/listar-produtos?categorias=${selectedCategory}`,
          );
        }

        if (selectedCategory && selectedFilter) {
          response = await menuApi.get(
            `/listar-produtos?categorias=${selectedCategory}&filtros=${selectedFilter}`,
          );
        }

        if (!selectedCategory && selectedFilter) {
          response = await menuApi.get(
            `/listar-produtos?categorias=&filtros=${selectedFilter}`,
          );
        }

        // const response = !selectedCategory
        //   ? await menuApi.get(`/listar-produtos`)
        //   : await menuApi.get(
        //       `/listar-produtos/filtro?categorias=${selectedCategory}`,
        //     );

        const productsData = response.data;

        const { results } = productsData;

        const getProductWithFilters = results.filter((product: any) => {
          return product.filtros.length > 0;
        });

        setProductsWithFilters(getProductWithFilters);

        const newProducts = results.map((product: any) => ({
          id: product.id,
          image: product.imagens[0]?.url,
          categoriesIds: product.categorias, // mapeia todos os ids de categorias
          filtersIds: product.filtros, // mapeia todos os ids de filtros
          name: product.nome,
          description: product.descricao,
          labels: product.labels,
          order: product.ordem,
        }));

        const sortedByOrder = [...newProducts].sort(
          (a: any, b: any) => a.order - b.order,
        );

        setProducts(sortedByOrder);
      } catch (error) {
        console.error('Erro ao obter os produtos:', error);
      }
    };

    fetchProducts();
  }, [selectedCategory, selectedFilter]);

  useEffect(() => {
    if (selectedCategory === '' && !selectedFilter) {
      setIsFilterAllCategoriesActive(true);
    }

    return setIsFilterAllCategoriesActive(false);
  }, [selectedCategory, selectedFilter]);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await menuApi.get('listar-filtros');

        const filtersData = response.data;

        const { results } = filtersData;

        setFilters(results);
      } catch (error) {
        console.error('Erro ao obter os produtos:', error);
      }
    };

    fetchFilters();
  }, []);

  return (
    <S.Container>
      <HeaderMenu
        id="headerMenuRestaurant"
        hideBanner={hideBanner}
        hideFilters={hideFilters}
        handleClickOnFilter={handleClickOnFilter}
      />
      <MenuFilterIconBar
        categories={categories}
        activeId={activeId}
        isFilterAllCategoriesActive={isFilterAllCategoriesActive}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        menuBarRef={menuBarRef}
        menuContentRef={menuContentRef}
        elementsRef={elementsRef}
      />
      <S.BoxProductsList>
        <SideProductsFilter
          categories={categories}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          filters={filters}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
        <MenuProducts
          categories={categories}
          selectedCategory={selectedCategory}
          products={products}
          menuContentRef={menuContentRef}
          elementsRef={elementsRef}
        />
      </S.BoxProductsList>
    </S.Container>
  );
};
