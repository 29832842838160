import styled, { css } from 'styled-components';
import { font } from 'styles';

import * as T from './interfaces';

const headingModifier = {
  level: {
    1: () => css`
      font-size: ${font.sizes.heading01};
      line-height: ${font.lineHeight.large};
    `,
    2: () => css`
      font-size: ${font.sizes.heading02};
      line-height: ${font.lineHeight.medium};
    `,
    3: () => css`
      font-size: ${font.sizes.heading03};
      line-height: ${font.lineHeight.small};
    `,
    4: () => css`
      font-size: ${font.sizes.heading04};
      line-height: ${font.lineHeight.small};
    `,
    5: () => css`
      font-size: ${font.sizes.heading05};
      line-height: ${font.lineHeight.small};
    `,
    6: () => css`
      font-size: ${font.sizes.heading06};
      line-height: ${font.lineHeight.small};
    `,
  },
  bold: () => css`
    font-weight: ${font.weight.bold};
  `,
  italic: () => css`
    font-style: italic;
  `,
  fontSizeTitle: () => css`
    font-size: ${font.sizes.heading00};
  `,
};

export const Heading = styled('h1').attrs<T.IHeading>(({ level }) => ({
  as: `h${level}`,
}))<T.IHeading>`
  ${({ level, italic, bold, fontSizeTitle }) => css`
    font-weight: ${font.weight.normal};
    font-style: normal;
    font-family: ${font.family.ChickenHeadline};

    ${!!level && headingModifier.level[level]()}
    ${!!bold && headingModifier.bold()}
    ${!!italic && headingModifier.italic()}
    ${!!fontSizeTitle && headingModifier.fontSizeTitle()}

    strong {
      ${headingModifier.bold()}
    }
  `}
`;
