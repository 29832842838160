import { BottomSheet } from 'react-spring-bottom-sheet';

import styled, { css } from 'styled-components';
import {
  popeyesPrimaryColors,
  popeyesSecondaryColors,
  shadows,
  ZIndex,
  popeyesNeutral,
  spacings,
} from 'styles';

import * as T from './interfaces';

const WrapperModifier = {
  border: {
    ease: () => css`
      [data-rsbs-overlay] {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }
    `,
    prominent: () => css`
      [data-rsbs-overlay] {
        border-top-left-radius: 35px;
        border-top-right-radius: 35px;
      }
    `,
  },
};

export const Wrapper = styled(BottomSheet)<T.IModalMobileStyles>`
  div.banner_modal {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    width: 100%;
    height: 30px;
    transform: translateY(-30px);
    background: ${popeyesPrimaryColors.secondary};

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    &::before {
      content: '';
      position: absolute;

      background-color: transparent;
      bottom: -60px;
      left: 0;
      height: 60px;
      width: 100%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      box-shadow: 0px -30px 0 0 #f25600;
    }
  }
  ${({ mustShow }) => css`
    display: ${mustShow ? 'initial' : 'none'} !important;
  `}
  [data-rsbs-overlay] {
    display: flex;
    background: var(--rsbs-bg, ${popeyesSecondaryColors.light});
    flex-direction: column;
    height: var(--rsbs-overlay-h, 0px);
    transform: translate3d(0, var(--rsbs-overlay-translate-y, 0px), 0);
    will-change: height;
  }

  [data-rsbs-overlay]:focus {
    outline: none;
  }

  [data-rsbs-is-blocking='false'] [data-rsbs-overlay] {
    box-shadow: ${shadows.shadow03};
  }

  [data-rsbs-overlay],
  [data-rsbs-root]:after {
    max-width: var(--rsbs-max-w, auto);
    margin-left: var(--rsbs-ml, env(safe-area-inset-left));
    margin-right: var(--rsbs-mr, env(safe-area-inset-right));
  }

  [data-rsbs-overlay],
  [data-rsbs-backdrop],
  [data-rsbs-root]:after {
    z-index: ${ZIndex.menu};
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
    touch-action: none;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }

  [data-rsbs-backdrop] {
    top: -60px;
    bottom: -60px;
    background-color: ${popeyesNeutral.neutral09};
    will-change: opacity;
    cursor: pointer;
    opacity: 0.2;
  }

  [data-rsbs-is-dismissable='false'] [data-rsbs-backdrop] {
    cursor: ns-resize;
  }

  [data-rsbs-root]:after {
    content: '';
    pointer-events: none;
    background: var(--rsbs-bg, ${popeyesSecondaryColors.light});
    height: 1px;
    transform-origin: bottom;
    transform: scale3d(1, var(--rsbs-antigap-scale-y, 0), 1);
    will-change: transform;
  }

  [data-rsbs-footer],
  [data-rsbs-header] {
    flex-shrink: 0;
    cursor: ns-resize;
    /* padding: 16px; */
  }

  [data-rsbs-header] {
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* box-shadow: 0 1px 0
      rgba(46, 59, 66, calc(var(--rsbs-content-opacity,1) * 0.125)); */
    z-index: ${ZIndex.base};
    padding-top: calc(${spacings.space05} + env(safe-area-inset-top));
    padding-bottom: ${spacings.space04};
  }

  [data-rsbs-header]:before {
    position: absolute;
    content: '';
    display: block;
    width: 64px;
    height: 5px;
    top: calc(${spacings.space04} + env(safe-area-inset-top));
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
    background-color: ${popeyesNeutral.neutral04};
    background-color: #d9d9d9;
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    [data-rsbs-header]:before {
      transform: translateX(-50%) scaleY(0.75);
    }
  }

  [data-rsbs-has-header='false'] [data-rsbs-header] {
    box-shadow: none;
    padding-top: calc(${spacings.space06} + env(safe-area-inset-top));
  }

  [data-rsbs-has-header='true'] [data-rsbs-header]:before {
    height: 0px;
  }

  [data-rsbs-scroll] {
    flex-shrink: 1;
    flex-grow: 1;
    -webkit-tap-highlight-color: revert;
    -webkit-touch-callout: revert;
    -webkit-user-select: auto;
    -ms-user-select: auto;
    -moz-user-select: auto;
    user-select: auto;
    overflow: auto;
    -ms-scroll-chaining: none;
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
  }

  .scroll {
    overflow: hidden;
  }

  [data-rsbs-scroll]:focus {
    outline: none;
  }

  [data-rsbs-has-footer='false'] [data-rsbs-content] {
    padding-bottom: env(safe-area-inset-bottom);
  }

  [data-rsbs-content] {
    /* The overflow hidden is to ensure any margin on child nodes are included when the resize observer is measuring the height */
    overflow: hidden;
  }

  [data-rsbs-footer] {
    /* box-shadow: 0 -1px 0 rgba(46, 59, 66, calc(var(--rsbs-content-opacity,1) * 0.125)),
      0 2px 0 var(--rsbs-bg,#fff); */
    overflow: hidden;
    z-index: ${ZIndex.base};
  }

  [data-rsbs-is-dismissable='true'] [data-rsbs-header] > *,
  [data-rsbs-is-dismissable='true'] [data-rsbs-scroll] > *,
  [data-rsbs-is-dismissable='true'] [data-rsbs-footer] > *,
  [data-rsbs-is-dismissable='false'][data-rsbs-state='opening']
    [data-rsbs-header]
    > *,
  [data-rsbs-is-dismissable='false'][data-rsbs-state='closing']
    [data-rsbs-header]
    > *,
  [data-rsbs-is-dismissable='false'][data-rsbs-state='opening']
    [data-rsbs-scroll]
    > *,
  [data-rsbs-is-dismissable='false'][data-rsbs-state='closing']
    [data-rsbs-scroll]
    > *,
  [data-rsbs-is-dismissable='false'][data-rsbs-state='opening']
    [data-rsbs-footer]
    > *,
  [data-rsbs-is-dismissable='false'][data-rsbs-state='closing']
    [data-rsbs-footer]
    > * {
    opacity: var(--rsbs-content-opacity, 1);
  }

  [data-rsbs-is-dismissable='true'] [data-rsbs-backdrop],
  [data-rsbs-is-dismissable='false'][data-rsbs-state='opening']
    [data-rsbs-backdrop],
  [data-rsbs-is-dismissable='false'][data-rsbs-state='closing']
    [data-rsbs-backdrop] {
    opacity: 0.2;
  }

  [data-rsbs-state='closed'],
  [data-rsbs-state='closing'] {
    /* Allows interactions on the rest of the page before the close transition is finished */
    pointer-events: none;
  }

  ${({ border }) => css`
    ${WrapperModifier.border[border]()}
  `}
`;
