export const handleClickOutside = (ref, frame) => {
  const handleClick = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      frame(false);
    }
  };

  document.addEventListener('mousedown', handleClick);
  return () => {
    document.removeEventListener('mousedown', handleClick);
  };
};
