import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Button from 'components/Button';

import { Container, ContentPolicies } from './styles';

const ZampPrivacyPoliciesContainer = () => {
  const [urlBack, setUrlBack] = useState('');
  const { goBack, push } = useHistory();

  useEffect(() => {
    const params = new URL(window.location).searchParams;

    const href = params.get('href');
    if (href) setUrlBack(href);
  }, []);

  return (
    <>
      <Container>
        <ContentPolicies>
          <h2>DIRETRIZES DE PRIVACIDADE DE DADOS </h2>

          <h2>SOBRE A ZAMP E SUAS DIRETRIZES DE PRIVACIDADE DE DADOS</h2>

          <p>
            A <strong>ZAMP S/A (“ZAMP”)</strong> é máster franqueada e
            operadora, no Brasil, de restaurantes <strong>BURGER KING®</strong>{' '}
            e <strong>POPEYES®</strong> . A <strong>ZAMP</strong> atualmente
            adota valores baseados em Meritocracia, Foco no Cliente, Visão de
            Dono, Alegria, Ética e Simplicidade.
          </p>

          <p>
            Visando o foco no cliente e a preservação de valores éticos, a{' '}
            <strong>ZAMP</strong> acredita também que a proteção de dados
            pessoais e a privacidade das pessoas são imprescindíveis para
            construção de um bom relacionamento com seus consumidores, uma vez
            que a <strong>ZAMP</strong> acaba por gerenciar informações pessoais
            para, principalmente, aprimorar produtos e serviços com a finalidade
            de melhorar cada vez mais a experiência dos titulares que interagem
            com o <strong>ZAMP</strong>.
          </p>

          <p>
            As diretrizes fixadas neste documento aplicam-se a todo tratamento
            conduzido pela <strong>ZAMP</strong> em território nacional com
            relação a dados pessoais que esta venha a coletar de clientes,
            investidores, potenciais franqueados, candidatos em processos
            seletivos para vagas de trabalho e demais terceiros com quem a{' '}
            <strong>ZAMP</strong> venha a se relacionar (“
            <strong>Titular(es)</strong>”).
          </p>
          <p>
            A leitura do presente documento é indispensável para os Titulares
            que possuam interesse em interagir com sites ou aplicações digitais
            da <strong>ZAMP</strong> (“<strong>Plataformas de Interação</strong>
            ”). O Titular que não estiver integralmente de acordo com os termos
            aqui previstos deve não acessar as Plataformas de Interação e
            compartilhar dados pessoais com as mesmas.
          </p>
          <p>
            Como condição para acesso e uso das Plataformas de Interação da{' '}
            <strong>ZAMP</strong>, o Titular{' '}
            <span style={{ textDecoration: 'underline' }}>
              declara ser maior de 18 (dezoito) anos e que fez leitura completa
              e atenta das regras deste documento.
            </span>
          </p>
          {/* <p style={{ marginTop: '40px' }}>
            <hr />
          </p>
          <p style={{ textAlign: 'center' }}>
            <strong>
              NOTA ESPECIAL PARA ADOLESCENTES/CRIANÇAS COM IDADE INFERIOR A 18
              ANOS:
            </strong>
          </p>
          <p style={{ textAlign: 'center' }}>
            POR FAVOR, NÃO SE REGISTRE NAS PLATAFORMAS DE INTERAÇÃO ZAMP CASO
            TENHA MENOS DE 18 ANOS.
          </p>
          <p style={{ textAlign: 'center' }}>
            <strong>
              NOTA ESPECIAL AOS PAIS OU REPRESENTANTES LEGAIS DE PESSOAS COM
              MENOS DE 18 ANOS:
            </strong>
          </p>
          <p style={{ textAlign: 'center' }}>
            Apesar de solicitarmos o não registro de menores de idade em nossas
            Plataformas de Interação, os pais devem supervisionar as atividades
            digitais dos seus filhos menores de idade nas Plataformas de
            Interação. As atividades de menores de 18 anos devem ser assistidas
            pelos pais ou representantes legais.
          </p>
          <p>
            <hr />
          </p> */}
          <h4 style={{ textAlign: 'center', marginTop: 30 }}>
            Caso não esteja de acordo com as condições previstas nesta Política,
            o Titular deve descontinuar, imediatamente, o acesso ou uso das
            Plataformas de Interação da ZAMP.
          </h4>
          <p>
            <strong>DEFINIÇÕES</strong>
          </p>
          <p>
            A leitura deste documento deve levar em consideração as seguintes
            definições e significados:
          </p>
          <table>
            <tbody>
              <tr>
                <td style={{ textAlign: 'center' }}>
                  <strong>Termo</strong>
                </td>
                <td style={{ textAlign: 'center' }}>
                  <strong>Definição</strong>
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: 'center' }}>Cookies</td>
                <td>
                  Pequenos arquivos enviados pelos sistemas das Plataformas de
                  Interação da <strong>ZAMP</strong> salvos nos dispositivos dos
                  Titulares, que armazenam as preferências e outras informações
                  dos mesmos, com a finalidade de personalizar a navegação dos
                  Titulares, de acordo com os seus respectivos perfis.
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: 'center' }}>Dado Pessoal</td>
                <td>
                  Quaisquer dados pessoais de qualquer pessoa física que possua
                  relacionamento com a <strong>ZAMP</strong>. Exemplo: Nome,
                  Endereço, RG, CPF, filiação, data de nascimento, filiação
                  sindical de, exemplificativamente, colaboradores,
                  ex-colaboradores, clientes, terceiros contratados, etc.
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: 'center' }}>IP</td>
                <td>
                  Abreviatura de Internet Protocol. Conjunto de números que
                  identifica o computadores, smartphones e demais dispositivos
                  aptos a acessarem a internet na Internet.
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: 'center' }}>Tratamento</td>
                <td>
                  Qualquer interface que se tenha por parte da{' '}
                  <strong>ZAMP</strong> com DadosPessoais, incluindo, mas não se
                  limitando a arquivamento, compartilhamento, coleta,
                  classificação, utilização, processamento, eliminação,
                  modificação e transferência de Dados Pessoais.
                </td>
              </tr>
            </tbody>
          </table>
          <h3>
            DADOS E INFORMAÇÕES COLETADOS PELA ZAMP E SUAS RESPECTIVAS
            FINALIDADES
          </h3>
          <p>
            Dados coletados pela <strong>ZAMP</strong> por meio das Plataformas
            de Interação:
          </p>
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              <strong>Informações cadastrais</strong>: Nome completo, RG, CPF,
              data de nascimento, endereço, CEP, telefone, e-mail, Logs de
              acesso (registro de atividades), token ao utilizar aplicativos da
              ZAMP, informações sobre cliques em ambientes virtuais (inclusive
              coletadas por meio de cookies), endereço de IP, dados
              transacionais para compras (Ex. informações de cartões de crédito
              e débito), geolocalização, páginas visitadas, anúncios
              visualizados, preferências de compras, tipo de navegador, data e
              horários de acesso.
            </li>
            <li>
              <strong>Informações públicas</strong>: Informações sobre Titulares
              que estejam disponíveis publicamente na internet, informações
              sobre menções ou interações feitas com as marcas{' '}
              <strong>BURGER KING®</strong> e <strong>POPEYES®</strong> .
            </li>
          </ol>
          <p>
            Além dos dados mencionados nesse item, fica permitido às Plataformas
            de Interação também, a opção de coletar outros tipos de dados,
            resguardada a devida informação e trasparência ao Titular de dados
            ao realizar o tratamento extraordinário.
          </p>
          <p>
            <strong>Finalidade da Coleta dos Dados:</strong>
          </p>
          <ul>
            <li>
              <strong>Para identificar ou autenticar um Titular:</strong>
              Verificação de credenciais, incluindo senhas, dicas de senha,
              informações e perguntas sobre segurança, ID, etc.
            </li>
            <li>
              <strong>
                Desenvolver e aprimorar produtos, serviços ou campanhas:
              </strong>
              Identificar tendências de uso e desenvolver novos conteúdos,
              entender como o Titular e seu dispositivo interagem com a{' '}
              <strong>ZAMP</strong>, etc.
            </li>
            <li>
              <strong>
                Personalizar a experiência dos Titulares ao navegar nas
                Plataformas de Interação:
              </strong>
              Garantir a pertinência da disponibilização de um conteúdo a cada
              Titular, entender os interesses de consumo de cada Titular,
              personalizar, na medida do possível, produtos, serviços ou
              conteúdo às preferências de cada titular, estruturação de índices
              estatísticos no geral, etc.
            </li>
            <li>
              <strong>Vendas:</strong> Realização de vendas, entrega e retirada
              de produtos<strong> BURGER KING®</strong> ou{' '}
              <strong>POPEYES®</strong> em Plataformas de Interação.
            </li>
            <li>
              <strong>Promoções:</strong> Permitir que Titulares participem de
              promoções como sorteios, concursos e campanhas similares.
            </li>
            <li>
              <strong>Contatos com Titulares:</strong> Estabelecimento de
              contato com Titulares para compartilhamento de informações,
              tendências e/ou lançamentos da <strong>BURGER KING®</strong> ou da{' '}
              <strong>POPEYES®</strong> .
            </li>
            <li>
              <strong>Redes de conectividade:</strong> Viabilizar a
              disponibilização de redes Wi-Fi aos Titulares nos restaurantes{' '}
              <strong>BURGER KING®</strong> ou <strong>POPEYES®.</strong>
            </li>
            <li>
              <strong>Marketing e Comunicação:</strong> Responder aos
              questionamentos de Titulares, fornecer informações a destinatários
              específicos como notícias, realização de eventos ou lançamentos de
              produtos, impactar clientes de um nicho específico com campanhas
              específicas, etc.
            </li>
            <li>
              <strong>Relacionamento com investidores:</strong> Registrar
              investimentos, manter contato e outros tratamentos típicos do
              relacionamento “Companhia – Investidor” legal e regulamentarmente
              previstos.
            </li>
            <li>
              <strong>Clube BK:</strong> Ao realizar o cadastro nos aplicativos
              da Companhia, seus dados poderão ser automaticamente direcionados
              também aos programas de fidelidade da <strong>ZAMP</strong>, que
              têm por finalidade a definição de perfis de compra para viabilizar
              experiências personalizadas e conceder, automaticamente mediante
              identificação via CPF e compras em canais como restaurantes
              físicos, plataformas de delivery próprias e terceiras, totens de
              autoatendimento, etc, pontuações que poderão ser convertidas em
              recompensas conforme o regulamento de cada programa.
            </li>
            <li>
              <strong>Recrutamento e seleção para vagas de trabalho</strong>:
              Nos casos de navegação em Plataformas de Interação cuja finalidade
              seja a busca por oportunidades de trabalho/emprego, a{' '}
              <strong>ZAMP</strong> fará a retenção dos dados cedidos pelo
              respectivo interessado para avaliações do seu perfil e eventuais
              chances de sua contratação. Tais dados serão retidos por tempo
              indeterminado pela <strong>ZAMP</strong> sob o intuito de
              preservar informações suficientes destes Titulares para futuras e
              eventuais novas oportunidades de contratação.
            </li>
            <li>
              <strong>
                Apuração de eventuais fraudes em compras pagas por meios
                eletrônicos:
              </strong>
              É possível que a <strong>ZAMP</strong> colete dados pessoais dos
              Titulares para verificação de eventuais fraudes em pagamentos com
              intuito de mitigar prejuízos relacionados a esse tipo de prática
              (chargebacks).
            </li>
          </ul>
          <p>
            Além das finalidades mencionadas nesse item, fica permitido às
            Plataformas de Interação também, a opção de coletar dados pessoais
            com outros intuitos desde que previamente solicitado e assim
            autorizado pelo Titular na(s) referida(s) oportunidade(s).
          </p>
          <h3>COMPARTILHAMENTO DE DADOS PESSOAIS COM TERCEIROS</h3>
          <p>
            <strong>Parceiros da ZAMP</strong>: A <strong>ZAMP</strong>{' '}
            compartilhará os dados pessoais dos Titulares apenas com os
            parceiros comerciais estritamente necessários para que seus serviços
            e plataformas atinjam nível de desempenho satisfatório, incluindo,
            mas sem se limitar, à servidores externos(eventualmente alocados no
            exterior), agências responsáveis por conduzir campanhas
            publicitárias pontuais, empresas especializadas em armazenar e
            administrar dados pessoais, etc.
          </p>
          <p>
            Toda e qualquer empresa terceira com quem a <strong>ZAMP</strong>{' '}
            compartilhar dados pessoais estará comprometida com práticas
            satisfatórias de ética, sigilo e privacidade garantindo assim um bom
            padrão de segurança aos dados pessoais dos Titulares cedidos à{' '}
            <strong>ZAMP</strong>.
          </p>
          <p>
            <strong>Empresas do mesmo grupo</strong>: A <strong>ZAMP</strong>{' '}
            pode compartilhar dados pessoais de Titulares com empresas que
            eventualmente venham a fazer parte de sua estrutura econômica ou com
            empresas que eventualmente absorvam a estrutura econômica da{' '}
            <strong>ZAMP</strong> em casos de fusões e aquisições, desde que com
            objetivo de atender as finalidades mencionadas neste documento.
          </p>
          <p>
            <strong>Autoridades competentes:</strong> A <strong>ZAMP</strong>{' '}
            pode compartilhar dados pessoais de Titulares com autoridades
            competentes que eventualmente exijam acesso a tais informações.
          </p>
          <h3>PADRÕES DE SEGURANÇA NO TRATAMENTO DE DADOS PESSOAIS</h3>
          <p>
            Com o objetivo de preservar a privacidade dos Titulares, a{' '}
            <strong>ZAMP</strong> tem como principais mecanismos de garantia da
            confidencialidade e ética no tratamento dos dados pessoais que
            coleta, as seguintes premissas em sua estrutura e seus procedimentos
            internos:
          </p>
          <ul>
            <li>
              Armazenamento de dados pessoais dos Titulares apenas em ambientes
              de acesso restrito e controlado.
            </li>
            <li>
              Conscientização do pessoal que possui acesso a dados pessoais dos
              Titulares.
            </li>
            <li>
              Adoção de práticas e ferramentas de segurança para prevenção e
              combate a ataques hackers.
            </li>
            <li>
              Medidas administrativas voltadas à governança para gestão de temas
              relacionados a dados pessoais tratados pela <strong>ZAMP</strong>.
            </li>
          </ul>
          <h3>
            POR QUANTO TEMPO A ZAMP RETÉM DADOS PESSOAIS CEDIDOS POR CLIENTES
            BK?
          </h3>
          <p>
            Com exceção da hipótese prevista no item a seguir, dados pessoais
            cedidos por Titulares ficam armazenados para tratamento por tempo
            indeterminado, devendo o Titular que não tiver mais interesse em
            permanecer com seus dados armazenados junto à <strong>ZAMP</strong>,
            solicitar a eliminação dos mesmos por meio das próprias Plataformas
            de Interação ou por meio da Privally, disponível para acesso na
            barra de preferências no rodapé dos sites{' '}
            <a
              href="https://www.burgerking.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.burgerking.com.br
            </a>{' '}
            e{' '}
            <a
              href="https://www.popeyesbrasil.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.popeyesbrasil.com.br
            </a>
            .
          </p>
          <p>
            O armazenamento de dados pessoais de Titulares será realizado por
            tempo determinado quando (i) A respectiva coleta tiver como objetivo
            um tratamento específico e pontual; e (ii) Houver indicação expressa
            ao Titular no momento deste tipo de coleta no sentido de que
            efetivamente trata-se de algo com intuito pontual e específico.
            Dados pessoais de Titulares coletados neste tipo de situação serão
            tratados pela <strong>ZAMP</strong> pelo tempo mínimo suficiente
            para que a respectiva finalidade seja esgotada.
          </p>
          <h3>COOKIES E AFINS</h3>
          <p>
            Ao visitar sites da <strong>ZAMP</strong>, é possível que haja a
            coleta cookies. A <strong>ZAMP</strong> utiliza cookies para
            diversas finalidades, tais como reconhecer visitantes em sites,
            determinar o número de visitantes únicos em sites, e verificar quais
            as páginas de sites que os usuários visitam. É possível associar
            informações coletadas através de cookies com dados pessoais
            concedidos pelos Titulares à <strong>ZAMP</strong>. Os Titulares
            podem sempre recusar os cookies nos sites da <strong>ZAMP</strong>,
            mas ao fazer isso, os Titulares podem restringir o conteúdo ao qual
            possuem acesso nos sites em questão. O segmento “ajuda” da barra de
            ferramentas na maioria dos navegadores pode auxiliar em como
            configurar o navegador para não aceitar novos cookies, como fazer
            com que navegador reporte quando o usuáriorecebe um novo cookie e
            como apagar cookies de sua unidade de hardware.
          </p>
          <p>
            A <strong>ZAMP</strong> pode utilizar também arquivos “GIF”, para
            auxiliar a gerenciar publicidades da ZAMP em sites de terceiros.
            Estes arquivos possibilitam que a <strong>ZAMP</strong> reconheça um
            cookie único no navegador de um Titular, que por sua vez permite que
            a <strong>ZAMP</strong> saiba quais de seus anúncios de fato trazem
            usuários ao site da <strong>ZAMP</strong>.
          </p>
          <h3>SOLICITAÇÕES DE TITULARES NO ÂMBITO DA PRIVACIDADE DE DADOS</h3>
          <p>
            O compartilhamento de dados pessoais por parte de Titulares com a{' '}
            <strong>ZAMP</strong> submete-se às disposições da Lei 13.709/2018
            (Lei Geral de Proteção de Dados - LGPD) e, neste sentido, o pleito
            por qualquer direito previsto na referida Lei deverá ser realizado
            preferencialmente nas próprias Plataformas de Interação com as quais
            o Titular tiver contato ou, na impossibilidade da solicitação ser
            feita por esse tipo de canal, o Titular deverá formalizar seu pleito
            por meio do Portal Privally, localizado no site{' '}
            <a
              href="https://www.burgerking.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.burgerking.com.br
            </a>
            .
          </p>
          <p>Faz parte dos direitos de um Titular:</p>
          <ul>
            <li>
              Confirmar a existência de tratamento por parte da{' '}
              <strong>ZAMP</strong>;
            </li>
            <li>
              Ter acesso aos dados pessoais que compartilhou com a{' '}
              <strong>ZAMP</strong>;
            </li>
            <li>
              Solicitar a correção de dados incompletos, inexatos ou
              desatualizados armazenados e utilizados pela <strong>ZAMP</strong>
              ; e
            </li>
            <li>
              Solicitar a anonimização, bloqueio ou eliminação de dados
              desnecessários, excessivos.
            </li>
          </ul>
          <p>
            Ressaltamos que eventualmente sua requisição poderá ser rejeitada,
            seja por motivos formais (a exemplo de impossibilidade de
            comprovação da sua identidade) ou legais (a exemplo do pedido de
            exclusão de dados cuja manutenção é livre exercício de direito do{' '}
            <strong>ZAMP</strong>), sendo certo que, na hipótese de
            impossibilidade de atendimento destas requisições, serão
            apresentadas todas as justificativas cabíveis.
          </p>
          <h3 style={{ textAlign: 'center' }}>DISPOSIÇÕES GERAIS</h3>
          <p>
            A <strong>ZAMP</strong> possui o direito de alterar o teor desta{' '}
            <strong>Política</strong> a qualquer momento, conforme razoável
            juízo de conveniência e contexto temporal da Companhia. Cabe ao
            Titular checar o teor desta política com periodicidade recorrente
            sob o intuito de confirmar a manutenção de sua concordância com os
            presentes termos, que por sua vez é condição na uso das Plataformas
            de Interação.
          </p>
          <p>
            Quaisquer dúvidas ou esclarecimentos acerca da aplicação desta
            política deverão ser encaminhadas ao e-mail{' '}
            <a href="mailto:lgpd.bk@zamp.com.br">lgpd.bk@zamp.com.br</a>, este
            também é meio de comunicação com nosso DPO (ICTS Protiviti - CNPJ:
            08.226.125/0001-46).
          </p>

          <div className="button">
            <Button
              variant="primary"
              onClick={() => (urlBack ? push(urlBack) : goBack())}
            >
              Voltar
            </Button>
          </div>
        </ContentPolicies>
      </Container>
    </>
  );
};

export default ZampPrivacyPoliciesContainer;
