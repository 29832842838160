import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  .selected {
    background: #f25600;
    color: #ffffff;
  }
`;

export const Box = styled.div`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  max-width: 150px;
  margin: 0 10px 10px 0px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;

  p {
    padding: 8px 16px;
    font-size: 12px;
  }
`;
