import React, { useEffect, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import sucessfulIcon from 'assets/images/icons/sucessful.png';

// Components
import Button from 'components/Button';
import TextArea from 'components/TextArea';

// Hooks and Services
import { useAuth } from 'hooks/Auth';
import { api } from 'services';
import sizes from 'utils/screenSizes';
import { getWindowDimensions } from 'utils/windowDimensions';

// Styles
import {
  Container,
  Footer,
  Question,
  OptionButtons,
  Sucessful,
} from '../styles';

export default function FaqQuestion() {
  const { customerId, user } = useAuth();

  const history = useHistory();
  const footerRef = useRef();
  const { id } = useParams();

  const [footerHeight, setFooterHeight] = useState(150);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [helpfulComment, setHelpfulComment] = useState(true);
  const [sucessfulFaq, setSucessfulFaq] = useState(false);
  const [loading, setloaging] = useState(false);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  useEffect(() => {
    function handleResize() {
      const dimensions = getWindowDimensions();
      setWindowDimensions(dimensions);
    }
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      requestText: '',
    },
  });
  const { watch } = methods;

  useEffect(() => {
    async function getFaq() {
      try {
        const response = await api.get('/customer/v0.1/faq');
        try {
          const objectIndexes = id.split('-');
          const question =
            response.data[objectIndexes[0]].questions[objectIndexes[1]];
          setSelectedQuestion(question);
        } catch (error) {
          setSelectedQuestion(undefined);
        }
      } catch (error) {
        setSelectedQuestion(undefined);
      }
    }

    getFaq();
  }, []);

  const newComment = async () => {
    setloaging(true);

    const body = {
      customer_id: customerId,
      question: selectedQuestion.id,
      useful: helpfulComment ? '0' : '1',
      comment: helpfulComment ? '' : watch('requestText'),
    };

    try {
      await api.post('/customer/v0.1/faq', body);
      if (helpfulComment) {
        history.push({
          pathname: '/faq',
          search: `?${new URLSearchParams({
            href:
              windowDimensions.width <= parseInt(sizes.notebook, 10)
                ? '/perfil'
                : '/',
          }).toString()}`,
        });
      } else {
        setSucessfulFaq(true);
      }
    } catch (error) {
      setHelpfulComment(true);
    }

    setloaging(false);
  };

  const resizeObserver = new ResizeObserver(e => {
    const { height } = e[0].contentRect;
    setFooterHeight(height);
  });

  useEffect(() => {
    if (footerRef?.current) resizeObserver.observe(footerRef.current);
  }, [footerRef]);

  return (
    <>
      <Container footerHeight={footerHeight}>
        {selectedQuestion && (
          <Question>
            <p>{selectedQuestion.name}</p>
            {selectedQuestion.answer.split('\n').map(item => (
              <span key={item}>{item}</span>
            ))}
          </Question>
        )}
      </Container>

      {user?.signed && (
        <Footer ref={footerRef}>
          <hr style={{ position: 'absolute', width: '100%' }} />
          <p>Esse conteúdo foi útil?</p>

          <OptionButtons>
            <Button
              loading={loading && helpfulComment}
              type="button"
              onClick={newComment}
              disabled={!helpfulComment || sucessfulFaq || loading}
            >
              Sim
            </Button>
            <Button
              type="button"
              disabled={sucessfulFaq || loading}
              onClick={() => {
                setHelpfulComment(false);
                footerRef.current.scrollIntoView({
                  behavior: 'smooth',
                  inline: 'end',
                });
              }}
            >
              Não
            </Button>
          </OptionButtons>

          {!helpfulComment && !sucessfulFaq && (
            <>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(newComment)}>
                  <div>
                    <p>Deseja comentar? (opcional):</p>
                    <span>{watch('requestText').length}/300</span>
                  </div>
                  <TextArea name="requestText" rows="5" maxLength={300} />

                  <Button type="submit" loading={loading} disabled={loading}>
                    Finalizar
                  </Button>
                </form>
              </FormProvider>
            </>
          )}

          {sucessfulFaq && (
            <Sucessful>
              <div>
                <img
                  height="48px"
                  src={sucessfulIcon}
                  alt="Comentario enviado com sucesso"
                />
                <p>Popeyes Agradece!</p>
              </div>

              <Button
                type="button"
                onClick={() =>
                  history.push({
                    pathname: '/faq',
                    search: `?${new URLSearchParams({
                      href:
                        windowDimensions.width <= parseInt(sizes.notebook, 10)
                          ? '/perfil'
                          : '/',
                    }).toString()}`,
                  })
                }
              >
                Fechar
              </Button>
            </Sucessful>
          )}
        </Footer>
      )}
    </>
  );
}
