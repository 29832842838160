export default function IconFacebook() {
  return (
    <svg
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // eslint-disable-next-line max-len
        d="M39 19.1161C39 8.55857 30.2696 0 19.5 0C8.73044 0 0 8.55857 0 19.1161C0 28.6575 7.13086 36.5659 16.4531 38V24.6419H11.502V19.1161H16.4531V14.9046C16.4531 10.1136 19.3643 7.46724 23.8186 7.46724C25.952 7.46724 28.1836 7.84059 28.1836 7.84059V12.545H25.7247C23.3023 12.545 22.5469 14.0185 22.5469 15.5303V19.1161H27.9551L27.0905 24.6419H22.5469V38C31.8691 36.5659 39 28.6575 39 19.1161Z"
        fill="white"
      />
    </svg>
  );
}
