import React from 'react';

import styled, { css } from 'styled-components';

import { spacings, popeyesPrimaryColors, popeyesSecondaryColors } from 'styles';
import { font, breakpoints } from '../../../styles/themes';

import * as S from './interfaces';

export const Container = styled.div<S.IBackButtonHeaderStyles>`
  width: 100%;

  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  ${props =>
    props.isBackgroundColor === 'orange' &&
    css`
      background-color: ${popeyesPrimaryColors.primary};
    `}

  ${props =>
    props.isBackgroundColor === 'white' &&
    css`
      background-color: #fff;
    `}



  @media all and (min-width: ${breakpoints.intermediary}) {
    position: fixed;
    height: ${spacings.space10};
    width: 99%;
    z-index: 4;

    display: ${props =>
      props.hideDesktop || props.hideAllDevices ? 'none' : 'flex'};

    ${props =>
      props.hideBanner &&
      css`
        top: 0;
      `}
  }

  @media all and (max-width: ${breakpoints.intermediary}) {
    width: 100%;
    top: 0;
    position: fixed;
    height: ${spacings.space10};
    z-index: 4;

    ${props =>
      props.hideAllDevices &&
      css`
        display: none;
      `}

    ${props =>
      props.isBackgroundColor === 'orange' &&
      css`
        background-color: ${popeyesPrimaryColors.primary};
      `}

    ${props =>
      props.isBackgroundColor === 'white' &&
      css`
        background-color: #fff;
      `}
  }
`;

export const LabelRestaurant = styled.button<S.IBackButtonHeaderContainer>`
  display: ${props => (props.isTitle ? 'flex' : 'none')};
  align-items: flex-end;

  border: none;
  outline: none;
  background-color: transparent;

  :focus {
    outline: 0 !important;
  }

  span {
    color: ${props =>
      props.isBackgroundColor === 'orange'
        ? popeyesSecondaryColors.light
        : '#434040'};
  }
`;

export const Triangle = styled.div<S.IBackButtonHeaderStyles>`
  width: 6px;
  height: 6px;
  background: transparent;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: solid ${spacings.space02} #fff;
  border-radius: ${spacings.space00};

  border-top: ${props =>
    props.isBackgroundColor === 'orange'
      ? `solid ${spacings.space02} popeyesSecondaryColors.light`
      : `solid ${spacings.space02} #434040`};

  margin-left: 10px;
  margin-bottom: ${spacings.space00};
`;

export const ContainerSearch = styled.button`
  left: 90%;
  justify-content: center;
  position: absolute;

  border: none;
  outline: none;
  background-color: transparent;

  :focus {
    outline: 0 !important;
  }
`;
