import styled, { css } from 'styled-components';
import { spacings } from 'styles';
import sizes from 'utils/screenSizes';

import * as T from './interfaces';

export const Container = styled.div<T.ICarousel>`
  ul::-webkit-scrollbar {
    display: none;
  }
  overflow: hidden;
  height: 100%;
  width: 100%;

  @media only all and (min-width: ${sizes.notebook}) {
    width: 100%;
    margin-top: ${props => props.sizeCarousel === 'S' && '45px'};

    img {
      min-height: 200px;
    }
  }

  @media only all and (max-width: ${sizes.notebook}) {
    width: 100%;
    margin-top: ${props =>
      props.sizeCarousel === 'S' &&
      !props.carouselMobilePositionTop &&
      spacings.space10};

    img {
      min-height: 200px;
    }
  }
`;

export const ComponentContainer = styled.div`
  height: 95%;
  @media only all and (min-width: ${sizes.notebook}) {
    height: 100%;
  }
`;

export const List = styled.ul<T.ICarousel>`
  ::-webkit-scrollbar {
    display: none;
  }

  position: relative;
  flex-wrap: nowrap;
  list-style-type: none;
  display: flex;

  height: 100%;

  transition: 0.3s ease-in-out;
  /* transform: translateX(0%); */

  @media only all and (max-width: ${sizes.ipad}) {
    height: 80vh;
    ${props =>
      props.currentActive &&
      `transform: translateX(-${props.currentActive * 100}vw);`}
  }

  li {
    text-align: center;
    flex-flow: column;

    min-width: 100%;
    @media only all and (max-width: ${sizes.ipad}) {
      margin: ${props => (props.sizeCarousel !== 'S' ? '0px 20px' : '0')};
      :first-child {
        margin-left: 0px;
      }
    }
    /*  margin: 0px 20px;
    :first-child {
      margin-left: 0px;
    } */

    /* div {
      @media only all and (min-width: ${sizes.notebook}) {
        padding: 10px 0px;
      }
    } */
  }
  @media only all and (min-width: ${sizes.ipadPro}) {
    height: 100%;
    ${props =>
      props.currentActive &&
      `transform: translateX(-${props.currentActive * 100}%);`}
  }

  @media all and (min-width: ${sizes.notebook}) {
    max-height: none;
  }
`;

export const Wrap = styled.div<T.ICarousel>`
  width: 100%;
  height: 95vh;
  display: flex;
  flex-direction: column;

  @media only all and (min-width: ${sizes.notebook}) {
    height: 100%;
    padding: ${props =>
      props.sizeCarousel === 'S' ? '10px 0 0 0' : '10px 0px'};
    min-height: ${props => (props.sizeCarousel === 'S' ? '250px' : '500px')};
    display: block;
    justify-content: center;
  }

  @media only all and (max-width: ${sizes.notebook}) {
    height: 100%;
    padding: 10px 0px;
    min-height: ${props => (props.sizeCarousel === 'S' ? '250px' : '500px')};
    justify-content: center;
  }

  @media all and (min-width: ${sizes.notebook}) {
    height: 100%;

    min-height: ${props => (props.sizeCarousel === 'S' ? '250px' : '600px')};

    display: flex;
    justify-content: center;
  }
  @media all and (max-width: 1500px) {
    min-height: ${props => (props.sizeCarousel === 'S' ? '250px' : '700px')};
  }

  @media all and (min-width: ${sizes.desktop}) {
    min-height: ${props => (props.sizeCarousel === 'S' ? '250px' : '800px')};
  }

  @media all and (min-width: ${sizes.mobile}) {
    min-height: ${props => props.sizeCarousel === 'S' && '250px'};
  }
`;

export const ButtonContainer = styled.div<T.ICarousel>`
  width: 100%;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  justify-content: center;
  position: relative;
  bottom: 3%;
  pointer-events: none;
  button {
    pointer-events: all;
    background-color: #adadaf;
    border: none;

    cursor: pointer;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin: 0 8px;
  }
  button:focus,
  button:active {
    outline: none;
  }

  button:nth-child(${Props => Props.currentActive + 1}) {
    background-color: #00b2a9;
  }
  @media only all and (min-width: ${sizes.ipadPro}) and (max-width: ${sizes.notebook}) {
    button {
      height: ${props => (props.sizeCarousel === 'S' ? '12px' : '16px')};
      width: ${props => (props.sizeCarousel === 'S' ? '12px' : '16px')};
    }
  }
  @media only all and (min-width: ${sizes.notebook}) {
    flex-direction: row;
    button {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
  ${props => !props.showCarrouselButtons && 'display:none;'}
  ${props =>
    props.handleHomeButtons &&
    `bottom: 8%;
    @media only all and (min-width: ${sizes.desktop}) {
      bottom: 6%;
      button {
        height: 10px;
        width: 10px;
      }
    }`}

    ${props =>
    props.sizeCarousel === 'S' &&
    css`
      margin-top: 10px;
    `};
`;

export const ButtonAnimationContainer = styled.div<T.ICarousel>`
  @keyframes slideToTop {
    from {
      top: 30vh;
    }
    to {
      top: 0vh;
    }
  }
  text-align: center;
  position: relative;
  @media only all and (max-width: ${sizes.notebook}) {
    animation: slideToTop 0.5s linear;
  }

  @media only all and (min-width: ${sizes.notebook}) {
    margin-top: ${props =>
      props.sizeCarousel !== 'S' &&
      css`
        margin-top: 10px;
      `};

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .btn-facebook {
      margin: 0;
      height: 2rem;
      img {
        height: 70%;
      }
    }

    flex-direction: column;

    > div {
      -webkit-box-shadow: 2px 10px 35px -2px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 2px 10px 35px -2px rgba(0, 0, 0, 0.2);
      box-shadow: 2px 10px 35px -2px rgba(0, 0, 0, 0.2);
      min-width: 400px;
      height: auto;
      padding: 20px 0px;
      width: 50%;
      display: flex;
      border-radius: 10px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h1 {
        margin-top: 20px;
        width: 70%;
      }
      > div {
        display: block;
        width: 100%;
      }
    }
  }
  @media all and (min-width: ${sizes.ipadPro}) and (max-width: ${sizes.notebook}) {
    .btn-facebook {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      max-width: 400px;
      font-size: 28px;

      img {
        margin: 0px 20px;
        position: static;
      }
    }
  }
  @media all and (min-width: ${sizes.notebook}) {
    .btn-facebook {
      margin: 0;
      height: 3rem;
      img {
        height: auto;
      }
    }
  }
`;

export const ArrowsContainer = styled.div<T.ICarousel>`
  position: absolute;
  display: flex;
  @media only all and (max-width: ${sizes.notebook}) {
    display: ${props => (props.sizeCarousel === 'S' ? 'flex' : 'none')};
    ${props => !props.showArrows && 'display:none;'}
  }
  ${props => props.alreadyVisited && 'display: none;'}
  align-items: center;
  justify-content: space-between;
  z-index: 5;
  top: 48%;

  width: ${props => (props.sizeCarousel === 'S' ? '90%' : '100%')};

  pointer-events: none;
  button {
    margin: 0px 7%;
    min-width: 0px;
    background: none;
    border: none;
    :hover {
      cursor: pointer;
    }
    :focus {
      outline: none;
    }
    img {
      height: 30px;
    }
    pointer-events: all;
  }

  button:last-of-type {
    transform: rotate(180deg);
  }
  ${props => !props.showArrows && 'display:none;'}
`;
