import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding-bottom: 124px;

  .product-description {
    font-family: ChickenSansRegular;
    margin: 0px 24px 8px;
    color: rgb(146, 146, 146);
  }

  img {
    display: flex;
    max-width: 200px;
    max-height: 200px;
    margin: 20px 0 33px 0;
    margin-right: auto;
    margin-left: auto;

    align-items: center;
    justify-content: center;
  }

  b {
    color: #303030;
    font-size: 20px;
    font-family: 'ChickenSansRegular';
    font-weight: 700;
    margin: 0 0 32px 24px;
    margin-bottom: 24px;
  }

  span {
    margin: 0 24px 16px 24px;
    font-weight: 400;
    font-size: 14px;
    color: #303030;
    font-family: 'ChickenSansRegular';
    align-content: center;
    line-height: 17px;
    align-items: center;
  }

  p {
    font-family: 'ChickenSansRegular';
    margin: 0 24px 8px 24px;
    font-size: 16px;
    font-weight: 400;
    color: #929292;
  }

  hr {
    color: #929292;
    margin: 39px 24px 27px 24px;
    opacity: 0.3;
    border: 1px solid #929292;
    width: 80%;
  }
`;

export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px;
  padding-top: 0px;

  button {
    height: auto;
    width: auto;

    font-weight: normal;
    font-style: normal;
    font-size: 12px;
  }

  .rotate {
    transform: rotate(270deg);
  }

  h5 {
    font-family: 'ChickenSansRegular';
    font-size: 14px;
    font-weight: 400;
  }
  .votar-btn {
    display: none;
  }

  * {
    outline: none;
  }

  @media only all and (min-width: ${sizes.ipadPro}) {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;

    display: flex;
    .rotate {
      transform: rotate(0deg);
    }
    .iconShare {
      display: none;
    }
    h5 {
      display: none;
    }
    .votar-btn {
      display: inline;
      color: var(--color-popeyes-orange);
      align-content: center;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  height: 85px;
  bottom: 16px;
  left: 16px;
  right: 16px;
  max-width: 850px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  z-index: 2;
  background-color: var(--color-salt-white);
  justify-content: space-between;

  p {
    font-family: 'ChickenSansRegular';
  }

  .price {
    display: flex;
    text-align: center;
    margin: 0 auto;
  }

  .get-coupons-textValue {
    margin: 27px 2px 42px 30px;
    color: var(--color-popeyes-orange);

    font-family: 'ChickenSansRegular';
    font-weight: bold;
    font-size: 16px;
  }

  .get-coupons-valueFirst {
    margin: 22px 0 0 0;
    color: var(--color-popeyes-orange);

    font-family: 'ChickenSansRegular';
    font-size: 32px;
    font-weight: bold;
  }

  .get-coupons-valueSecond {
    margin: 28px 15px 42px 2px;
    color: var(--color-popeyes-orange);

    font-family: 'ChickenSansRegular';
    font-weight: bold;
    font-size: 14px;
  }

  .get-coupons-label {
    height: 50px;
    width: 60%;
    margin: 16px;
    padding: 0 20px;
    color: var(--color-salt-white);
    background-color: var(--color-burnt-orange);
    border: 0;
    border-radius: 4px;
    cursor: pointer;

    p {
      font-size: 18px;
      font-weight: 700;
      line-height: 50px;
    }
  }

  .get-coupons-label:hover {
    background-color: var(--color-popeyes-orange) !important;
  }

  .get-coupons-label-alreadyGot {
    height: 50px;
    width: 60%;
    margin: 16px;
    padding: 0 20px;
    color: var(--color-salt-white);
    background-color: var(--color-burnt-orange);
    opacity: 40%;
    border: 0;
    border-radius: 4px;

    p {
      font-size: 18px;
      font-weight: 700;
      line-height: 50px;
    }
  }

  @media (min-width: ${sizes.ipadPro}) {
    position: fixed;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
`;

export const ModalContent = styled.div`
  font-family: 'ChickenSansRegular';
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 24px 0px;
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
  }

  h3 {
    margin: 0px 24px;
  }

  > button {
    margin-top: 15px;

    > img {
      max-width: 25px;
      position: relative;
      right: 35px;
    }
  }

  @media (max-width: ${sizes.ipadPro}) {
    padding: 32px 24px 0px 24px;
    .MsgIcon {
      max-width: 25px;
      position: absolute;
      left: 40px;
    }
  }
`;

export const DesktopContent = styled.div`
  display: flex;
  font-family: 'ChickenSansRegular';
  color: #303030;
  gap: 2.5rem;

  .header-desktop {
    width: 100%;
    height: 15px;
    margin-bottom: 1rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .description-desktop {
    margin-top: 18px;
    color: #929292;
  }
  .descriptionSide {
    min-height: 470px;
    font-size: 16px;

    @media only all and (min-width: ${sizes.notebook}) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    button {
      height: auto;
      width: auto;
      display: initial;
      float: right;
    }
  }

  .descriptionSide b {
    font-size: 20px;
    font-weight: 700;
    display: block;
    margin-bottom: 0.5rem;
  }

  .descriptionSide span {
    font-weight: 400;
    font-size: 16px;

    line-height: 17px;
    align-items: center;
  }

  .description-desktop p:nth-child(4) {
    margin-bottom: 1.5rem;
    color: #929292;
  }

  .description-desktop p:nth-child(5) {
    color: #929292;
  }

  .sideImg {
    height: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sizeImg {
    padding: 5rem 6rem;
    background: #f3f3f3;

    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 592px;
    img {
      max-height: 240px;
    }
  }
`;

export const AlertContent = styled.div`
  width: 100%;
  text-align: center;
  background-color: var(--color-popeyes-orange);
  color: #ffffff;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0px;
  padding: 5px 0;
  display: ${({ visibility }) => (visibility ? 'block' : 'none')};

  animation-duration: 5s;
  animation-name: fade;

  @keyframes fade {
    95% {
      opacity: 0.1;
    }
    100% {
      opacity: 0;
    }
  }
`;
