import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  padding: 20px 0 80px 0;
  z-index: 20;
  margin-top: auto;
  margin-bottom: auto;

  background: #fff;

  svg {
    margin: auto;
  }
`;
