import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  height: 100%;
  width: 100%;

  header {
    display: none;
  }

  @media (min-width: ${sizes.notebook}) {
    overflow-x: hidden;

    header {
      display: flex;
    }

    position: relative;
  }
`;
