import styled from 'styled-components';
import { popeyesPrimaryColors, popeyesSecondaryColors, font } from 'styles';
import sizes from 'utils/screenSizes';

import { IBackButton } from './interfaces';

export const BackButtonContainer = styled.div<IBackButton>`
  display: none;

  @media all and (min-width: ${sizes.notebook}) {
    position: absolute;
    left: 0;

    width: 91px;
    margin: 2% 0 0 4%;

    z-index: 3;
    :hover {
      cursor: pointer;
    }

    display: flex;
    align-items: flex-end;
    img {
      height: 1rem;
      width: auto;
      margin: 0 14% 0 0;
    }

    p {
      font-size: ${font.sizes.textMedium};
      color: ${props =>
        props.colorBackButton === 'white'
          ? popeyesSecondaryColors.light
          : popeyesPrimaryColors.primary};
    }
  }
`;

export const BackButtonIcon = styled.div`
  display: none;
  @media all and (max-width: ${sizes.notebook}) {
    position: absolute;
    left: 24px;
    display: flex;
    align-items: center;

    cursor: pointer;

    img {
      width: 10px;
    }
  }
`;
