import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'components/Button';
import { useAuth } from 'hooks/Auth';
import { api } from 'services';

import Categories from './components/Categories';
import Dropdown from './components/Dropdown';
import Rate from './components/Rate';
import { Container } from './styles';

function TalkToUs() {
  const [matters, setMatters] = useState([]);
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState('');
  const [rating, setRating] = useState('');
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const { user, customerId } = useAuth();

  const history = useHistory();

  const loadMatters = async () => {
    try {
      const response = await api.get('/customer/v0.1/customer-support');
      if (response?.data) {
        setMatters(response.data);
      }
    } catch (error) {
      history.push({
        pathname: '/erro',
        search: `?${new URLSearchParams({
          href: '/fale-conosco',
        }).toString()}`,
      });
    }
  };

  useEffect(() => {
    loadMatters();
  }, []);

  useEffect(() => {
    if (subject?.subject === 'Outros' && message.length <= 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [subject, message]);

  const onChangeSubject = param => {
    setSubject(param);
    setSelectedCategories([]);
  };

  const onChangeCategories = param => {
    setSelectedCategories(param);
  };

  const onChangeRate = param => {
    setRating(param);
  };

  const onFinish = async () => {
    setLoading(true);
    setDisabled(true);
    const data = {
      subject: subject.id,
      categories: selectedCategories,
      rating: rating.toString(),
      message,
      customer_id: customerId,
    };

    try {
      const response = await api.post('/customer/v0.1/customer-support', data);
      if (response) {
        history.push({
          pathname: '/sucesso',
          search: `?${new URLSearchParams({
            href: '/fale-conosco',
            urlButton: '/',
            showButton: true,
            buttonTitle: 'Fechar',
            subTitle: `Retornaremos para o e-mail cadastrado: `,
            textBold: user.email,
            lastSubtitle:
              'Fique ligado na Caixa de spam! Prazo de retorno de até 7 dias úteis.',
          }).toString()}`,
        });
      }
    } catch (error) {
      history.push({
        pathname: '/erro',
        search: `?${new URLSearchParams({
          href: '/fale-conosco',
        }).toString()}`,
      });
    }
  };

  return (
    <Container>
      <div className="first-section">
        <p>Sobre o que quer falar?</p>
        <Dropdown matters={matters} onChange={onChangeSubject} />
      </div>
      {subject && subject?.subject !== 'Outros' && (
        <div className="second-section">
          <p>Selecione uma ou mais categorias: </p>
          <Categories
            onChange={onChangeCategories}
            categories={subject?.categories}
          />
        </div>
      )}
      {(selectedCategories.length >= 1 || subject?.subject === 'Outros') && (
        <div className="third-section">
          <h3>Escreva um comentário</h3>
          <p>
            Você receberá uma resposta no e-mail cadastrado&nbsp;
            <u>{user?.email}</u> em até 7 dias úteis.
          </p>
          <textarea
            name="message"
            onChange={e => {
              setMessage(e.target.value);
            }}
            maxLength="300"
            placeholder="Digite seu elogio, sugestão ou reclamação"
          />
          <p>{message.length}/300</p>
        </div>
      )}
      {selectedCategories.length >= 1 && (
        <div className="fourth-section">
          <h3>Gostaria de avaliar o Popeyes?</h3>
          <p>Escolha de 1 a 5 estrelas </p>
          <Rate onChange={onChangeRate} />
        </div>
      )}

      {(selectedCategories.length >= 1 || subject?.subject === 'Outros') && (
        <Button disabled={disabled} onClick={() => onFinish()}>
          {loading ? 'Carregando...' : 'Enviar'}
        </Button>
      )}

      {selectedCategories.length === 0 && subject?.subject !== 'Outros' && (
        <p>
          Mensagens enviadas anteriormente serão respondidas exclusivamente no
          e-mail cadastrado.
        </p>
      )}
    </Container>
  );
}

export default TalkToUs;
