import styled from 'styled-components';
import sizes from 'utils/screenSizes';
import { font } from '../../../styles/themes';

export const CategoryFilter = styled.div`
  border: 0.7px solid rgb(229, 229, 229);
  background: #ffffff;
  border-radius: 8px;
  margin-right: 10px;

  @media all and (max-width: ${sizes.notebook}) {
    display: none !important;
  }
`;

export const Icon = styled.img<{ isCategoriesOpen: boolean }>`
  transform: ${({ isCategoriesOpen }) =>
    isCategoriesOpen ? 'rotate(90deg)' : 'rotate(270deg)'};
  pointer-events: none;
`;

export const FiltersBar = styled.div`
  width: 240px;
  margin-top: 60px;

  @media all and (max-width: ${sizes.notebook}) {
    display: none !important;
  }
`;

export const HorizontalLine = styled.hr`
  border: none;
  border-top: 1px solid #e5e5e5;
`;

export const ToggleIconButton = styled.button`
  background: none;
  border: none !important;
  padding: 0;
  margin: 4px 0 0 0;
  cursor: pointer;
  outline: none !important;
`;

export const BoxCategoryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: normal;
  padding: 16px;
`;

export const CategoryItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  h2 {
    font-size: 16px;
  }
`;

export const FilterTitle = styled.h4`
  display: flex;
  margin-top: 12px;
  margin-bottom: 8px;
  font-family: ${font.family.ChickenHeadline};
  font-size: 16px;
  justify-content: space-between;
  padding: 0 16px;

  &:nth-child(2) {
    border-top: 1px solid #ededed;
    margin: 0;
    padding: 0;
    padding: 10px 16px;
  }
`;
