import { useCallback, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import { handleClickOutsideAndEsc } from 'utils/handleClickOutside';

import * as S from '../styles';
import { Feedback } from './Feedback';
import { Finish } from './Finish';
import { Punctuate } from './Punctuate';

const Modal = ({ children, style, onClose }) => {
  const wrapperRef = useRef(document.createElement('div'));

  const onClickOutsideNEsc = useCallback(() => {
    !!onClose && handleClickOutsideAndEsc(wrapperRef, onClose);
  }, [wrapperRef, onClose]);

  useEffect(() => {
    onClickOutsideNEsc();
  }, [onClickOutsideNEsc]);

  return (
    <S.Modal style={style} ref={wrapperRef}>
      {children}
    </S.Modal>
  );
};

const steps = [
  ({ styles, rating, onChange, onClose, onNext }) => (
    <Modal
      style={styles}
      onClose={() =>
        onClose({
          nao_avaliado: true,
        })
      }
    >
      <Punctuate
        rating={rating}
        onChange={onChange}
        onClose={() =>
          onClose({
            nao_avaliado: true,
          })
        }
        onNext={() =>
          onNext({
            nota: rating,
          })
        }
      />
    </Modal>
  ),
  ({ styles, ratingType, onClose, onNext }) => (
    <Modal style={{ ...styles, ZIndex: +1 }} onClose={onClose}>
      <Feedback
        ratingType={ratingType}
        onClose={onClose}
        onNext={() =>
          onNext({
            avaliar: ratingType === 'good' ? 'feedback' : 'critica',
          })
        }
      />
    </Modal>
  ),
  ({ styles, onClose }) => (
    <Modal
      style={{ ...styles, ZIndex: +2 }}
      onClose={() =>
        onClose({
          redirecionar_home: true,
        })
      }
    >
      <Finish
        onClose={() =>
          onClose({
            redirecionar_home: true,
          })
        }
      />
    </Modal>
  ),
];

steps.propTypes = {
  ratingType: PropTypes.oneOf(['good', 'bad']).isRequired,
  onNext: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  rating: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export { steps };
