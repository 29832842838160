import React from 'react';
import Lottie from 'react-lottie';

import * as animationData from 'assets/Loading/data.json';

import { Container } from './styles';

const Loading = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Container data-testid="loading-container">
      <Lottie
        options={defaultOptions}
        height={300}
        width={200}
        isStopped={false}
        isPaused={false}
      />
    </Container>
  );
};

export default Loading;
