import styled, { css } from 'styled-components';
import { breakpoints, font } from 'styles';

import { IMenuItemProps } from './interfaces';

export const Wrapper = styled.div<IMenuItemProps>`
  width: 30%;
  max-width: 320px;
  background: #eee;
  border-radius: 10px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  cursor: pointer;

  margin: ${props =>
    props.imgOutsideContainer ? '100px 2% 40px' : '10px 2% 40px'};

  h2 {
    font-size: ${font.sizes.heading03};
    margin: 0;
  }
  img {
    max-width: 100%;
    width: 100%;
    height: 247px;
    position: relative;
    margin: -105px auto 0px;
  }
  > div {
    padding: 0px 20px;
  }

  @media (max-width: ${breakpoints.medium}) {
    width: 46%;
    margin: 60px 10px 30px;
    min-height: 240px;
    max-height: 240px;

    img {
      height: 136px;
      margin: 0;
      margin: -62px auto 0px;
      width: 110%;
    }

    h2 {
      font-size: ${font.sizes.heading06};
      height: auto;
    }

    :nth-of-type(odd) {
      margin: 60px auto 0 0;
    }
    > div {
      padding: 0px 10px;
    }
  }

  @media (max-width: ${breakpoints.large}) and (min-width: ${breakpoints.medium}) {
    width: 35%;
    margin: 60px 10px 30px;
    min-height: 240px;
    max-height: 240px;

    img {
      height: auto;
      margin: 0;
      margin: -62px auto 0px;
    }

    h2 {
      font-size: ${font.sizes.heading06};
      height: auto;
    }

    :nth-of-type(odd) {
      margin: 60px auto 0 0;
    }
    > div {
      padding: 0px 10px;
    }
  }

  ${({ unavailable }) =>
    unavailable &&
    css`
      > div:nth-of-type(1) {
        opacity: 0.7;
        h2 {
          opacity: 0.4;
        }
      }
      > div:nth-of-type(2) {
        opacity: 0.4;
      }
    `}
`;

export const UnavailableContainer = styled.div`
  position: absolute;
  max-height: 260px;
  background-color: '#F5F5F5';
  opacity: 0.5;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  height: 76px;

  @media (max-width: ${breakpoints.intermediary}) {
    height: auto;
  }
  h2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const StatusContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0px 0px;
  span {
    margin: 0px 5px 5px 0px;
    @media (max-width: ${breakpoints.intermediary}) {
      font-size: ${font.sizes.textXSmall};
    }
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  text-align: center;
  margin: 15px auto 0 0;
  p {
    font-family: 'ChickenSansBold' !important;
    font-weight: bold;
    color: var(--color-burnt-orange);
    font-size: ${font.sizes.heading05};
  }
  p:first-of-type {
    margin: 0px 2px 29px 0px;
  }
  p:nth-of-type(2) {
    font-size: ${font.sizes.heading05};
  }
  p:nth-of-type(3) {
    margin: 0px 0px 0px 0px;
  }

  img {
    width: 35px;
    height: 35px;
    transform: rotate(180deg);
    margin: 0;
  }

  @media (max-width: ${breakpoints.intermediary}) {
    p {
      font-size: ${font.sizes.textMedium};
    }
    p:nth-of-type(2) {
      font-size: ${font.sizes.heading04};
    }

    img {
      width: 25px;
      height: 25px;
    }
  }
`;
