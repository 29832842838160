import React from 'react';

import * as T from './interfaces';
import { CheckboxName, Container, ContentInput, Input } from './styles';

export const Radio: React.FC<T.IRadio> = ({
  name,
  onClick,
  checked,
  margin,
  ...rest
}) => (
  <Container margin={margin} onClick={onClick}>
    {name && <CheckboxName>{name}</CheckboxName>}
    <ContentInput className="checkmark">
      <Input type="radio" checked={checked} readOnly hidden {...rest} />

      <label htmlFor={name} className="checkmark" />
    </ContentInput>
  </Container>
);
