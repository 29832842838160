import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import BackIcon from 'assets/images/icons/back.svg';
import Button from 'components/Button';

// Images and Icons

// Hooks
import { useAuth } from 'hooks/Auth';
import firebase from 'services/firebase';
import { getWindowDimensions } from 'utils/windowDimensions';

// Styles
import {
  ProfileContainer,
  ItemButton,
  UserContent,
  ArrowSpace,
  HelpContainer,
} from './styles';

export default function Profile() {
  const { user } = useAuth();
  const history = useHistory();

  const handleSelectMyCoupons = () => {
    history.push({
      pathname: '/cupom',
      search: `?${new URLSearchParams({
        cartModal: true,
      }).toString()}`,
    });
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  useEffect(() => {
    function handleResize() {
      const dimensions = getWindowDimensions();
      setWindowDimensions(dimensions);
    }
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  return (
    <>
      <ProfileContainer>
        {user?.signed && (
          <>
            <UserContent>
              <ItemButton
                onClick={() => {
                  // firebase.analytics().logEvent('permissoes_perfil');
                  history.push('/meu-perfil');
                }}
              >
                <p>{`Oi, ${user.name}`}</p>
                <span>Minhas informações</span>

                <ArrowSpace>
                  <img src={BackIcon} alt="Ir ao perfil do usuario" />
                </ArrowSpace>
              </ItemButton>
              <ItemButton
                onClick={() => {
                  firebase.analytics().logEvent('permissoes_perfil');
                  history.push('/permissoes-e-privacidade');
                }}
              >
                Permissões e privacidade
              </ItemButton>
            </UserContent>

            <div className="my-cupons">
              <ItemButton
                onClick={() => {
                  firebase
                    .analytics()
                    .logEvent('meus_cupons', { origem: 'perfil' });
                  handleSelectMyCoupons();
                }}
              >
                Meus cupons
              </ItemButton>
            </div>
          </>
        )}

        {!user?.signed && (
          <>
            <div className="contact">
              <h3>Bem-vindo ao Popeyes!</h3>
              <p>
                Cadastre-se para acessar cupons e descontos exclusivos para
                você.
              </p>

              <div className="contact-button">
                <Button
                  variant="primary"
                  onClick={() => history.push('/onboarding')}
                >
                  Entre e pegue cupons
                </Button>
              </div>
            </div>
          </>
        )}

        <HelpContainer>
          {/*
          <ItemButton
            onClick={() => {
              history.push('/restaurantes');
            }}
          >
            Restaurantes
          </ItemButton>
          */}

          <ItemButton
            onClick={() => {
              firebase
                .analytics()
                .logEvent(
                  user?.signed
                    ? 'tabelanutricional_logado'
                    : 'tabelanutricional_deslogado',
                );
              // download do arquivo
              window.open(
                'https://plk-app-media.s3.amazonaws.com/Tabela_Nutricional_A3_Novembro_2022.pdf',
                '_blank',
              );
            }}
          >
            Tabela nutricional
          </ItemButton>

          {/* <p>Precisa de ajuda?</p> */}
          {/* // Quando for habilitar o faq e o fale conosco descomentar proxima linha
          <ItemButton
            onClick={() => {
              history.push('/faq');
            }}
          >
            Dúvidas frequentes
          </ItemButton> */}

          {!user?.signed && (
            <ItemButton
              onClick={() => {
                firebase.analytics().logEvent('permissoes_perfil');

                history.push({
                  pathname: '/direitos-e-solicitacoes',
                  search: `?${new URLSearchParams({
                    href:
                      windowDimensions.width >= parseInt(1200, 10)
                        ? '/'
                        : `/perfil`,
                  }).toString()}`,
                });
              }}
            >
              Direitos e solicitações
            </ItemButton>
          )}
          <ItemButton
            onClick={() => {
              // Quando for habilitar o faq e o fale conosco descomentar proxima linha
              // history.push('/fale-conosco');
              firebase
                .analytics()
                .logEvent(
                  user?.signed ? 'faleconosco_logado' : 'faleconosco_deslogado',
                );
              history.push({
                pathname: '/enviar-email',
                search: `?${new URLSearchParams({
                  href:
                    windowDimensions.width >= parseInt(1200, 10)
                      ? '/'
                      : `/perfil`,
                  title: 'Fale com a gente',
                  subject: 'Fale com a gente',
                  isHelp: true,
                }).toString()}`,
              });
            }}
          >
            Fale conosco
          </ItemButton>
        </HelpContainer>
      </ProfileContainer>
    </>
  );
}
