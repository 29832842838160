import { animated } from '@react-spring/web';
import styled, { css } from 'styled-components';
import { spacings, transitions } from 'styles';

import * as T from './interfaces';

const ExpandIconModifier = () => css`
  transform: rotate(-90deg);
`;

export const Wrapper = styled.div<T.IExpandInfoStyle>`
  > button:last-child {
    display: flex;
    align-items: flex-end;
    padding: ${spacings.space00};

    img {
      transition: ${transitions.transform01};
      transform: rotate(90deg);
      margin-left: ${spacings.space02};
      margin-right: ${spacings.space00};

      ${({ isOpen }) => isOpen && ExpandIconModifier()}
    }
  }
`;
export const Content = styled(animated.div)`
  will-change: transform, opacity, height;
  overflow: hidden;

  + button {
    margin-top: ${spacings.space01};
    border: none;
    outline: none;
    border: 0;

    :focus {
      outline: 0;
    }
  }
`;
