import { FC } from 'react';

import { Radio } from './Radio';
import * as T from './interfaces';

export const Container: FC<T.IContainer> = ({
  children,
  onClick,
  logEvent,
  type = 'radio',
  name,
  hidden,
  checked,
  id,
  ...rest
}) => {
  return (
    <Radio
      {...rest}
      id={`radio@${id}`}
      data-testid={`radio@${id}`}
      type={type}
      name={name}
      onClick={onClick}
      checked={checked}
    />
  );
};
