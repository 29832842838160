import * as yup from 'yup';

const schema = yup.object().shape({
  name: yup
    .string()
    .min(2, 'Nome muito curto')
    .max(35, 'Nome muito longo')
    .required('Este campo é obrigatório!')
    .matches(/\w+\w/, 'Nome inválido'),
  email: yup
    .string()
    .email('Este e-mail não é válido')
    .matches(
      /^([.\]+)(_)?([a-z\d_]+)?@([a-z\d-]{3,10})\.([a-z]{2,8})(\.[a-z]{2,8})?$/,
      'Este e-mail não é válido',
    )
    .required('Este campo é obrigatório!'),
  requestText: yup.string(),
});

export default schema;
