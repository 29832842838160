import { IoIosArrowForward } from 'react-icons/io';

export function NextArrow({ onClick }) {
  return (
    <button
      type="button"
      id="btn-next"
      data-testid="btn-next"
      onClick={onClick}
    >
      <IoIosArrowForward size={48} color="#D7D7D7" />
    </button>
  );
}
