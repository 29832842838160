import { ApolloProvider } from '@apollo/client';
import GlobalStyles from 'assets/styles/Global';

import AppProvider from './hooks/AppProvider';
import { TagManagerProvider } from './hooks/Analytics';
import Routes from './routes';
import { client } from './services';

function App() {
  return (
    <AppProvider>
      <ApolloProvider client={client}>
        <TagManagerProvider>
          <GlobalStyles />
          <Routes />
        </TagManagerProvider>
      </ApolloProvider>
    </AppProvider>
  );
}

export default App;
