/* eslint-disable react/no-unescaped-entities */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Button from 'components/Button';

import { Container, ContentTerms } from './styles';

const UseTermsContainer = () => {
  const [urlBack, setUrlBack] = useState('');
  const { goBack, push } = useHistory();

  useEffect(() => {
    const params = new URL(window.location).searchParams;

    const href = params.get('href');
    if (href) setUrlBack(href);
  }, []);

  return (
    <>
      <Container>
        <ContentTerms>
          <h2>Termos de uso</h2>
          <p>
            Este Termos e Condições de Uso ("Acordo") estabelece um acordo
            vinculativo entre o usuário (“Usuário (s)”), Restaurants Brands
            Internacional e/ou quaisquer outros franqueados da marca POPEYES
            LOUISIANA KITCHEN®. ("PLK"). Por favor, leia este Acordo
            cuidadosamente antes de usar este aplicativo ("Aplicativo"). Ao
            fazer o download ou instalar este Aplicativo, você reconhece e
            concorda em vincular-se ao Acordo e em cumprir todas as leis,
            regulamentos e/ou regras aplicáveis no que diz respeito à sua
            utilização.
          </p>
          <p>
            O Usuário declara que é maior de idade e está apto a utilizar o
            Aplicativo. O Aplicativo e o Acordo poderão ser atualizados a
            qualquer tempo pela PLK.
          </p>
          <strong>PRODUTOS E SERVIÇOS - DISPONIBILIDADE</strong>
          <p>
            Todas as descrições, imagens, características, especificações,
            produtos e preços de produtos ou serviços estarão sujeitos a
            alterações a qualquer momento sem aviso prévio. da PLK. A PLK se
            reserva o direito a qualquer momento para e sem aviso prévio: (i)
            limitar a quantidade disponível de ou descontinuar qualquer produto
            ou serviço; (ii) impor condições sobre o respeito de qualquer cupom,
            código de cupom, código promocional ou outra promoção similar; (iii)
            impedi-lo de fazer ou completar qualquer ou todas as operações; e
            (iv) se recusar a fornecê-lo com qualquer produto ou serviço.
          </p>
          <strong>USO DO APLICATIVO</strong>
          <p>Os seguintes requisitos se aplicam ao uso do Aplicativo:</p>
          <p>
            O Usuário não poderá fazer upload, publicar, reproduzir ou
            distribuir qualquer informação, software ou outro material protegido
            por direitos autorais ou qualquer outro direito de propriedade
            intelectual (bem como os direitos de publicidade e privacidade) sem
            primeiro obter a permissão do proprietário de tais direitos.
          </p>
          <p>
            O Usuário não poderá utilizar o aplicativo para qualquer fim
            comercial sem a autorização expressa da PLK.
          </p>
          <p>
            O Usuário não poderá fazer upload, publicar, enviar e-mail, ou
            transmitir qualquer material que contenha vírus ou quaisquer outros
            arquivos ou programas que possam interromper, limitar ou interferir
            na funcionalidade do Aplicativo.
          </p>
          <strong>SERVIÇOS E TAXAS DE INTERNET</strong>
          <p>
            O uso do Aplicativo requer o uso de um dispositivo móvel e serviço
            móvel de dados sem fio, que deve ser obtido a partir de sua
            operadora sem fio, e podem requerer acesso à Internet, que deve ser
            obtido a partir de seu provedor de serviços; o Usuário é responsável
            por obter e pagar por esses serviços adicionais e obter um
            dispositivo adequado, incluindo, sem limitação, todos os encargos de
            utilização relacionados. Caso o Usuário não possua um plano de dados
            móveis wireless ilimitado, poderá incorrer em custos adicionais.
          </p>
          <span>Os Usuários do Aplicativo por meio da APPLE</span>
          <p>
            Se você baixar e/ou utilizar o nosso aplicativo para iPhone ou iPad:
            você, o usuário final deste Aplicativo, reconhece que o presente
            Acordo é celebrado entre PLK e o Usuário e não com a Apple, Inc., e
            Apple, Inc. não sendo responsável pelo Aplicativo e/ou seu conteúdo.
            Não obstante o acima exposto, você reconhece que a Apple, Inc. e
            suas controladas são terceiros beneficiários do presente Acordo e
            que a Apple, Inc. tem o direito (e é considerado como tendo aceitado
            o direito) de fazer cumprir este Acordo. Você reconhece que a Apple,
            Inc. não tem nenhuma obrigação de manter ou suportar o Aplicativo.
            Você reconhece que teve acesso aos Termos e Condições da App Store.
          </p>
          <strong>ATUALIZAÇÕES DO APLICATIVO</strong>
          <p>
            A PLK poderá disponibilizar para download certas atualizações de
            aplicativos ou atualizações para o Aplicativo, no sentido de
            aprimorar ou desenvolver o Aplicativo. A licença concedida permite
            que o Usuário baixe e use as atualizações do Aplicativo para
            atualizar o aplicativo em qualquer dispositivo. O Usuário não poderá
            fazer quaisquer cópias das atualizações do Aplicativo, a menos que
            essa cópia seja autorizada por escrito pela PLK. Exceto se e somente
            na extensão permitida pela lei aplicável, ou pelos termos de licença
            que regem o uso de componentes de código aberto incluídos com o
            Aplicativo, o Usuário não pode copiar, descompilar, fazer engenharia
            reversa, desmontar, tentar derivar o código fonte, modificar, ou
            criar trabalhos derivados do Aplicativo, atualizações do Aplicativo,
            ou qualquer parte dele. Qualquer tentativa de fazê-lo poderá ser
            considerada violação dos direitos da PLK e seus licenciadores. As
            violações de tais restrições estarão sujeitas as medidas cabíveis a
            serem tomadas pela PLK.
          </p>
          <strong>CONCESSÃO DE LICENÇA</strong>
          <p>
            O Aplicativo, incluindo o software (incluindo, sem limitação, o
            código fonte, arquivos, imagens, contidas ou geradas pelo software,
            que acompanha de dados e outros softwares incorporados),
            atualizações de aplicativos (conforme abaixo definido), e quaisquer
            elementos que compõem qualquer parte da App são licenciados para o
            Usuário pela PLK, sujeito aos termos do presente Acordo.
          </p>
          <p>
            O presente Acordo será rescindido automaticamente sem aviso prévio
            da PLK se você deixar de cumprir quaisquer termos do presente
            Acordo.
          </p>
          <strong>CONTEÚDO PLK</strong>
          <p>
            A PLK oferece o aplicativo para o Usuário. O Usuário declara e
            garante que irá usar o Aplicativo e o conteúdo apenas para os fins
            aqui autorizados, que todas as informações que vier a enviar serão
            precisas e estarão de acordo com o presente Acordo, e que notificará
            imediatamente a PLK em caso de modificação de informações, sendo
            elas do Usuário ou do Aplicativo.
          </p>
          <p>
            O Usuário concorda em não baixar, exibir ou usar qualquer conteúdo
            para uso em quaisquer publicações, apresentações públicas, em sites
            para qualquer outra finalidade comercial, em conexão com produtos ou
            serviços que não aqueles da PLK, de maneira que possa vir a causar
            confusão entre os consumidores.
          </p>
          <strong>PROPRIEDADE INTELECTUAL</strong>
          <p>
            Há uma série de marcas, logotipos, marcas de serviço, slogans, nomes
            de produtos e denominações, e outros indícios de propriedade
            (coletivamente "Marcas") utilizados no Aplicativo. Todas as marcas
            registradas exibidas no aplicativo são marcas comerciais registradas
            e registradas da PLK e / ou de terceiros que autorizaram a sua
            utilização.
          </p>
          <strong>USO DE DADOS DE GEOLOCALIZAÇÃO </strong>
          <p>
            O app Burger King utiliza a tecnologia de geolocalização que permite
            a coleta dos dados de visitas a estabelecimentos comerciais, sem
            identificar-lhe diretamente, para: i) enviar mensagens
            contextualizadas via notificação push; ii) fazer análises
            estatísticas de padrões comportamentais e de fluxos de visitas a
            estabelecimentos. Ao aceitar esta Política de Privacidade, você
            declara que está ciente e que concorda com a coleta e tratamento de
            seus dados, tal como descrito na &nbsp;
            <a
              href="https://www.burgerking.com.br/politicas-de-privacidade"
              target="_blank"
              rel="noreferrer"
            >
              Política de Privacidade da BK Brasil.
            </a>
          </p>
          <strong>
            REPRESENTAÇÕES, EXCLUSÃO DE GARANTIAS E LIMITAÇÕES DE
            RESPONSABILIDADE
          </strong>
          <p>
            A Internet pode estar sujeita a falhas de segurança. A PLK não é
            responsável por quaisquer danos resultantes aos dispositivos
            eletrônicos de quaisquer Usuários ou computador a partir de qualquer
            violação de segurança ou de qualquer vírus, erro, adulteração,
            intervenção não autorizada, fraude, erro, omissão, interrupção,
            defeito, atraso na operação ou transmissão, ou qualquer outra falha
            técnica. A PLK não garante a disponibilidade integral do Aplicativo.
            O Aplicativo poderá permanecer temporariamente indisponivel ou fora
            de operação.
          </p>
          <p>
            Aplicativo poderá estar temporariamente indisponível devido à
            manutenção ou falha de equipamentos de informática.
          </p>
          <p>
            O Aplicativo (incluindo todas suas atualizações) e o todo o conteúdo
            são disponibilizados "DA MANEIRA QUE SE ENCONTRAM", "CONFORME
            DISPONÍVEL", E "COM TODAS AS SUAS EVENTUAIS FALHAS". A PLK
            ESPECIFICAMENTE REJEITA TODAS AS GARANTIAS EXPRESSAS OU IMPLÍCITAS,
            INCLUINDO, SEM LIMITAÇÃO, AS GARANTIAS DE COMERCIALIZAÇÃO,
            QUALIDADE, ADEQUAÇÃO A UM DETERMINADO FIM E NÃO-VIOLAÇÃO, COM
            RELAÇÃO AO PEDIDO E DO SEU CONTEÚDO. A PLK NÃO GARANTE QUE AS
            FUNÇÕES CONTIDAS NO APLICATIVO ATENDERÃO ÀS SUAS NECESSIDADES.
          </p>
          <p>
            VOCÊ CONCORDA QUE A PLK NÃO É RESPONSÁVEL POR DANOS DE QUALQUER
            NATUREZA, SEJA COM BASE EM ATO ILÍCITO, CONTRATO, RESPONSABILIDADE
            OBJETIVA OU DE OUTRA FORMA, INCLUINDO, SEM LIMITAÇÃO, DANOS DIRETOS,
            INDIRETOS, INCIDENTAIS, OU PUNITIVOS, DANOS DECORRENTES DE QUALQUER
            FORMA OU EM CONEXÃO COM: (I) COM O APLICATIVO OU SEU CONTEÚDO; E
            (II) QUALQUER ERRO OU OMISSÃO NO FUNCIONAMENTO OU CONTEÚDO DO
            APLICATIVO.
          </p>
          <strong>CESSÃO</strong>
          <p>
            A PLK poderá ceder este Acordo, no todo ou em parte, a qualquer
            momento, com ou sem aviso prévio. O Usuário não poderá ceder este
            Acordo, ou qualquer parte dele, a qualquer outro Usuário. Qualquer
            tentativa de fazê-lo será nula. O Usuário não poderá transferir para
            qualquer outra pessoa, temporária ou permanentemente, quaisquer
            direitos para usar a totalidade ou parte. Na medida em que você
            permitir que um terceiro use seu dispositivo, você será o único
            responsável pela utilização da Aplicação por outros usando o
            dispositivo.
          </p>
          <strong>INFORMAÇÕES GERAIS</strong>
          <p>
            Este Acordo constitui a totalidade do acordo entre O Usuário e a PLK
            que rege o uso do Aplicativo, substituindo quaisquer contratos
            anteriores entre o Usuário e a PLK relativos ao seu uso destes
            materiais. O Usuário também pode estar sujeito a termos e condições
            adicionais (incluindo mas não limitado a, termos e condições de sua
            Operadora de Telefonia) que poderão ser aplicados para o seu uso do
            Aplicativo. Se qualquer disposição deste Acordo for considerada
            inválida por qualquer lei, regra, ordem ou regulamentação de
            qualquer governo ou por determinação final de qualquer tribunal
            estadual ou federal, tal invalidade não afetará a aplicabilidade de
            qualquer outra disposição deste Acordo. A falta da PLK em exercer ou
            executar qualquer direito ou disposição do presente Acordo não
            constituirá uma renúncia de tal direito ou disposição.
          </p>
          <p>
            O Usuário e reconhece e concorda que nenhuma sociedade é formada e
            nenhum tem o poder ou a autoridade para obrigar ou vincular a outra.
          </p>
          <p>
            Este acordo constitui um acordo vinculativo entre o Usuário e a PLK,
            e é aceito pelo Usuário na sua utilização do Aplicativo. Este acordo
            constitui a totalidade do acordo entre Usuário e a PLK sobre o uso
            do Aplicativo.
          </p>

          <div className="button">
            <Button
              variant="primary"
              onClick={() => (urlBack ? push(urlBack) : goBack())}
            >
              Voltar
            </Button>
          </div>
        </ContentTerms>
      </Container>
    </>
  );
};

export default UseTermsContainer;
