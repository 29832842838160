import React, { useEffect, useState } from 'react';

import { Container, Box } from './styles';

function Categories({ categories, onChange }) {
  const [categoriesList, setCategoriesList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);

  useEffect(() => {
    setCategoriesList(categories);
  }, [categories]);

  const handleSelect = (e, category) => {
    if (e.currentTarget.classList.contains('selected')) {
      const index = selectedList.findIndex(event => event === category.id);
      const newList = [...selectedList];
      newList.splice(index, 1);
      setSelectedList(newList);
      onChange(newList);
      e.currentTarget.classList.remove('selected');
    } else {
      const newList = [...selectedList, category.id];
      setSelectedList(newList);
      onChange(newList);
      e.currentTarget.classList.add('selected');
    }
  };

  return (
    <Container>
      {categoriesList.map(category => (
        <Box key={category.id} onClick={e => handleSelect(e, category)}>
          <p>{category?.description}</p>
        </Box>
      ))}
    </Container>
  );
}

export default Categories;
