import type { IRestaurantModel } from 'types/restaurant';

import { useEffect, useState } from 'react';

import RestaurantState from 'components/RestaurantState';
import Button from 'components/Button';
import { useLocation } from 'context/LocationContext';
import {
  RestaurantsStatesList,
  SearchInputWrapper,
  SearchRestaurantBox,
} from './styles';

import SearchIcon from './SearchIcon';

interface IRestaurantStateProps {
  restaurants: IRestaurantModel[];
  originalRestaurants: IRestaurantModel[];
  setRestaurants: (value: IRestaurantModel[]) => void;
  onToogleModalLogin: (value: boolean) => void;
}

export default function RestaurantList({
  restaurants,
  originalRestaurants,
  setRestaurants,
  onToogleModalLogin,
}: IRestaurantStateProps) {
  const [searchQuery, setSearchQuery] = useState('');

  const [loadingLocation, setLoadingLocation] = useState(false);

  const { handleGeoLocationRequest, calculateDistance, userLocation } =
    useLocation();

  const handleGeoLocation = () => {
    if (userLocation) return;
    setLoadingLocation(true);
    handleGeoLocationRequest(setLoadingLocation);
  };

  const filterRestaurants = () => {
    if (searchQuery === '') {
      if (userLocation) {
        const sortedRestaurants = originalRestaurants.slice().sort((a, b) => {
          const distanceA = calculateDistance(
            userLocation.latitude,
            userLocation.longitude,
            parseFloat(a.latitude),
            parseFloat(a.longitude),
          );
          const distanceB = calculateDistance(
            userLocation.latitude,
            userLocation.longitude,
            parseFloat(b.latitude),
            parseFloat(b.longitude),
          );
          return distanceA - distanceB;
        });

        setRestaurants(sortedRestaurants);
        return;
      }

      const sortedRestaurantsByName = originalRestaurants
        .slice()
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      console.log(sortedRestaurantsByName);
      setRestaurants(sortedRestaurantsByName);
      return;
    }

    const filteredRestaurants = restaurants.filter(restaurant => {
      const restaurantValues = Object.values(restaurant).map(value =>
        value ? value.toString().toLowerCase() : '',
      );
      return restaurantValues.some(value =>
        value.includes(searchQuery.toLowerCase()),
      );
    });

    if (userLocation) {
      const sortedRestaurants = filteredRestaurants.slice().sort((a, b) => {
        const distanceA = calculateDistance(
          userLocation.latitude,
          userLocation.longitude,
          parseFloat(a.latitude),
          parseFloat(a.longitude),
        );
        const distanceB = calculateDistance(
          userLocation.latitude,
          userLocation.longitude,
          parseFloat(b.latitude),
          parseFloat(b.longitude),
        );
        return distanceA - distanceB;
      });

      setRestaurants(sortedRestaurants);
      return;
    }
    setRestaurants(filteredRestaurants);
  };

  // Atualize o estado dos restaurantes com base na pesquisa
  useEffect(() => {
    filterRestaurants();
  }, [searchQuery]);

  useEffect(() => {
    setLoadingLocation(false);
    if (!userLocation) return;

    const sortedRestaurants = restaurants.slice().sort((a, b) => {
      const distanceA = calculateDistance(
        userLocation.latitude,
        userLocation.longitude,
        parseFloat(a.latitude),
        parseFloat(a.longitude),
      );
      const distanceB = calculateDistance(
        userLocation.latitude,
        userLocation.longitude,
        parseFloat(b.latitude),
        parseFloat(b.longitude),
      );
      return distanceA - distanceB;
    });

    setRestaurants(sortedRestaurants);
  }, [userLocation]);

  return (
    <>
      <SearchRestaurantBox>
        <div style={{ minWidth: '70%', flex: 1 }}>
          <SearchInputWrapper>
            <input
              type="text"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              placeholder="Pesquise aqui..."
            />
            <SearchIcon />
          </SearchInputWrapper>
          {!userLocation && (
            <p>
              Pesquise pelo endereço, nome, bairro ou cidade. Para resultados
              mais precisos, ative sua localização.
            </p>
          )}
        </div>
        {!userLocation && (
          <div style={{ width: '25%' }}>
            <Button
              className="rotate"
              type="button"
              variant="primary"
              font="regular"
              loading={loadingLocation}
              margin={undefined}
              size={undefined}
              onClick={handleGeoLocation}
            >
              <span className="votar-btn">Ativar Localização</span>
            </Button>
          </div>
        )}
      </SearchRestaurantBox>
      <RestaurantsStatesList>
        {restaurants.length > 0 &&
          Array.from(new Set(restaurants.map(restaurant => restaurant.estado)))
            .sort((a, b) => a.localeCompare(b))
            .map((estado, index) => (
              <RestaurantState
                key={index}
                onToogleModalLogin={onToogleModalLogin}
                state={{
                  id: index,
                  state: estado,
                }}
                restaurants={restaurants.filter(
                  restaurant => restaurant.estado === estado,
                )}
              />
            ))}
      </RestaurantsStatesList>
    </>
  );
}
