import styled, { css } from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  @media (min-width: ${sizes.notebook}) {
    margin-top: 60px;
    margin-bottom: 80px;
    width: 100%;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;

  input {
    width: 100%;

    padding: 24px 54px 12px 24px;

    font-size: 16px;
    background-color: #f2f2f2;
    border: none;
    border-radius: 4px;

    outline: none;

    transition: background-color 0.3s ease;
    transition: box-shadow 0s;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px #e5f7f6 inset !important;
    -webkit-box-shadow: 0 0 0px 1000px #e5f7f6 inset !important;

    transition: background-color 0.3s ease;
  }

  input:focus {
    background-color: #e5f7f6;
    transition: background-color 0.2s ease;
    transition: box-shadow 0s;
  }

  input:focus + .label-name .content-name,
  input:valid + .label-name .content-name {
    font-size: 12px;
    transform: translateY(-100%);
  }

  label {
    position: absolute;
    bottom: 14px;
    left: 25px;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .content-name {
    position: absolute;
    bottom: 5px;
    left: 0px;
    font-size: 16px;
    color: #919191;
    transition: all 0.2s ease;
  }
`;

export const DDD = styled.div`
  display: flex;
  min-width: 100px;
  max-width: 100px;
  margin-right: 20px;
  position: relative;
`;

export const Number = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

export const IconContainer = styled.div`
  position: absolute;
  bottom: 23%;
  right: 20px;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;

  img {
    width: 20px;
  }
  ${({ showIcon }) =>
    showIcon &&
    css`
      opacity: 1;
      transition: opacity 0.3s ease;
      pointer-events: none;
    `}
`;

export const ErrorMessage = styled.p`
  width: 100%;
  margin-top: 5px;
  margin-left: 9px;

  font-size: 15px;
  font-weight: 400;
  font-family: 'ChickenSansRegular';
  color: var(--color-spicy-red);
`;
