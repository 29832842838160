import React from 'react';

import { SafetyContainer } from './styles';

const SafetySection = () => {
  return (
    <SafetyContainer>
      <p>
        Este é um ambiente seguro e todas as suas informações estão protegidas.
      </p>
    </SafetyContainer>
  );
};

export default SafetySection;
