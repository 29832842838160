/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable prefer-const */
import { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';

import CenterUserIcon from 'assets/images/marker/center-user.png';
import { useModalMobileContext, useStore } from 'hooks';
import Leaflet from 'leaflet';

import { Container } from './styles';

interface IResetLocationButton {
  userPosition: Leaflet.LatLngTuple;
}

function ResetLocationButton({ userPosition }: IResetLocationButton) {
  const map = useMap();

  const [mobileTop, setMobileTop] = useState(0);
  const [initialPosition, setInitialPosition] = useState(null);
  const [modalElement, setModalElement] = useState<any>();
  const { mapMode, openModal } = useStore();
  const { isDragging, scrollAllTop } = useModalMobileContext();

  useEffect(() => {
    let interval: NodeJS.Timer;

    interval = setTimeout(() => {
      const modalMobile = document.getElementById('modalMobile@PlaceItem')
        ?.children[0];
      if (modalMobile) setModalElement(modalMobile);

      setMobileTop(modalMobile?.clientHeight);
      if (modalMobile || mobileTop !== 0) {
        document
          .getElementById('modalMobile@PlaceItem')
          ?.addEventListener('touchmove', (e: any) => {
            setMobileTop(modalMobile?.clientHeight);

            const modalStyle: any = e?.currentTarget
              ?.getAttribute('style')
              .split(';');

            const modalHPlusTranslateY = modalStyle
              ?.map((element: any, i: any) => {
                const attName = element?.split(':')[0].replace(' ', '');

                if (attName.includes('--rsbs-overlay-h')) {
                  return element?.split(':')[1];
                }
                if (attName.includes('--rsbs-overlay-translate-y')) {
                  return element?.split(':')[1];
                }
              })
              .filter((element: any) => element !== undefined);

            setMobileTop(
              -parseInt(modalHPlusTranslateY[0], 10) +
                parseInt(modalHPlusTranslateY[1], 10),
            );
          });
        document
          .getElementById('modalMobile@PlaceItem')
          ?.addEventListener('touchend', (e: any) => {
            setMobileTop(modalMobile?.clientHeight);

            const modalStyle: any = e?.currentTarget
              ?.getAttribute('style')
              .split(';');

            const modalHPlusTranslateY = modalStyle
              ?.map((element: any, i: any) => {
                const attName = element?.split(':')[0].replace(' ', '');

                if (attName.includes('--rsbs-overlay-h')) {
                  return element?.split(':')[1];
                }
                if (attName.includes('--rsbs-overlay-translate-y')) {
                  return element?.split(':')[1];
                }
              })
              .filter((element: any) => element !== undefined);
            if (!initialPosition) {
              setInitialPosition(parseInt(modalHPlusTranslateY[1], 10));
            }
          });
      }
    }, 1000);
  }, [mapMode, scrollAllTop, initialPosition]);

  return (
    <Container
      mobileTop={mobileTop}
      isDragging={isDragging}
      initialPosition={initialPosition}
      openModal={openModal}
    >
      <button
        onClick={() => {
          map.flyTo(userPosition, map.getZoom());
        }}
        type="button"
      >
        <img src={CenterUserIcon} alt="Resetar localização" />
      </button>
    </Container>
  );
}

export default ResetLocationButton;
