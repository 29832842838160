import styled, { css } from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  transition: all 0.5s 0.5s ease-in-out;
  background-color: rgba(0, 0, 0, 0.6);

  z-index: 15;

  ${({ open }) =>
    open
      ? css`
          opacity: 1;
          visibility: visible;
          -webkit-transition-delay: 0s;
          -ms-transition-delay: 0s;
          transition-delay: 0s;
        `
      : css`
          opacity: 0;
          visibility: hidden;
          -webkit-transition: all 0.5s 0.5s ease-in-out;
        `};
`;

export const ModalContent = styled.div`
  max-height: 85%;
  width: 100%;
  overflow: auto;
  position: absolute;
  top: 40px;
  left: 50%;
  padding: 10px;
  z-index: 15;
  border-radius: 4px;
  background: #fff;
  transition: all 0.5s ease-in-out;

  ${({ sizeModal }) =>
    sizeModal === 'normal' &&
    `
          max-width: 700px;
          min-width: 360px;
  `}

  ${({ sizeModal }) =>
    sizeModal === 'middle' &&
    `
          max-width: 480px;
          min-width: 280px;
          min-height: 200px;
          top: 20%;
          padding: 5rem !important;
          overflow: visible;
  `}

  ${({ sizeModal }) =>
    sizeModal === 'high' &&
    `
          max-width: 95%;
          min-height: 85%;
          padding: 10px 0;

      @media all and (min-width: ${sizes.desktop}) {
        width: 1150px;
      }

      ::-webkit-scrollbar {
        width: 0.6rem;

      }

      ::-webkit-scrollbar-track {
        background: #F3F3F3;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb {
        background: #BDC3C7;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: #95A5A6;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb:active {
        background: #7F8C8D;
        border-radius: 10px;
      }
  `}

  ${({ sizeModal }) =>
    sizeModal === 'full' &&
    `
          max-width: 95%;
          padding: 2.5rem;
          padding-bottom: 2.2rem;

      @media all and (min-width: ${sizes.desktop}) {
        width: 1150px;
      }


      ::-webkit-scrollbar {
        width: 0.6rem;

      }

      ::-webkit-scrollbar-track {
        background: #F3F3F3;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb {
        background: #BDC3C7;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: #95A5A6;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb:active {
        background: #7F8C8D;
        border-radius: 10px;
      }
  `}


  ${({ open }) =>
    open
      ? css`
          opacity: 1;
          -webkit-transform: translate(-50%, 0);
          -ms-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
          -webkit-transition-delay: 0.5s;
          -ms-transition-delay: 0.5s;
          transition-delay: 0.5s;
        `
      : css`
          opacity: 0;
          -webkit-transform: translate(-50%, 0);
          -ms-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
          -webkit-transition: all 0.5s ease-in-out;
        `};
`;

export const CloseModal = styled.div`
  img {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    cursor: pointer;
  }
`;
