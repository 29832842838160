import styled, { css } from 'styled-components';
import {
  font,
  popeyesPrimaryColors,
  popeyesSecondaryColors,
  spacings,
  transitions,
} from 'styles';

import * as T from './interfaces';

const ModifierFonts = {
  regular: () => css`
    font-style: normal;
    font-family: ${font.family.ChickenText};
    font-weight: ${font.weight.normal};
  `,
  bold: () => css`
    font-style: bold;
    font-family: ${font.family.ChickenHeadline};
    font-weight: ${font.weight.bold};
  `,
};

const ModifierButtonDefaultStyle = () => css`
  transition: ${transitions.filter01};

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;

  width: 100%;
  height: ${spacings.space09};

  font-size: ${font.sizes.textLarge};

  border-radius: 4px;
`;

const ModifierButton = {
  fonts: {
    regular: () => css`
      ${ModifierFonts.regular()}
    `,
    bold: () => css`
      ${ModifierFonts.bold()}
    `,
  },

  variants: {
    primary: () => css`
      ${ModifierFonts.bold()}
      ${ModifierButtonDefaultStyle()}
      background-color: ${popeyesPrimaryColors.secondary};
      color: ${popeyesSecondaryColors.light};
      border: none;

      :active {
        filter: brightness(0.8);
      }

      :hover {
        filter: brightness(0.4);
      }
    `,
    secondary: () => css`
      ${ModifierButtonDefaultStyle()}
      background: ${popeyesSecondaryColors.light};
      color: ${popeyesPrimaryColors.secondary};
      border: none;

      :active {
        filter: brightness(0.4);
      }

      :hover {
        filter: brightness(0.4);
      }
    `,
    tertiary: () => css`
      ${ModifierButtonDefaultStyle()}
      background: transparent;
      color: ${popeyesPrimaryColors.secondary};
      border: none;
    `,

    quaternary: () => css`
      ${ModifierButtonDefaultStyle()}
      background: ${popeyesSecondaryColors.light};
      color: ${popeyesPrimaryColors.secondary};
      border: 1px solid ${popeyesPrimaryColors.secondary};
      column-gap: 0px;
    `,
    ghost: (noHeritage: boolean) => css`
      border: none;
      padding: 0;
      background: transparent;
      ${!noHeritage &&
      css`
        color: ${popeyesPrimaryColors.primary};
      `}
    `,

    category: () => css`
      ${ModifierFonts.bold()}
      ${ModifierButtonDefaultStyle()}
      background-color: ${popeyesPrimaryColors.secondary};
      color: ${popeyesSecondaryColors.light};
      border: none;

      :active {
        filter: brightness(0.8);
      }

      :hover {
        filter: brightness(0.4);
      }
    `,

    menu: () => css`
      ${ModifierFonts.bold()}

      padding: auto;

      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 42px;

      background-color: ${popeyesPrimaryColors.secondary};
      color: ${popeyesSecondaryColors.light};
      border: none;
      border-radius: 4px;
      font-size: ${font.sizes.textMedium};

      transition: ${transitions.filter01};

      :active {
        filter: brightness(0.8);
      }

      :hover {
        filter: brightness(0.4);
      }
    `,
  },

  margin: {
    zeroMargin: () => css`
      margin: 0;
    `,
    highMargin: () => css`
      margin: ${spacings.space06} 0 ${spacings.space03} 0;
    `,
    litleMargin: () => css`
      margin: ${spacings.space01} 0 ${spacings.space00} 0;
    `,
  },

  sizes: {
    S: () => css`
      height: ${spacings.space07};
      font-size: ${font.sizes.textMedium};
    `,
    M: () => css`
      height: ${spacings.space09};
      font-size: ${font.sizes.textMedium};
    `,
    L: () => css`
      height: ${spacings.space11};
    `,
  },

  loading: () => css`
    @keyframes around {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    position: relative;

    .loader-icon {
      position: absolute;
      right: ${spacings.space05};
      top: 50%;
      transform: translateY(-50%);
      transition: ${transitions.opacity01};
    }

    .loader {
      position: relative;
      height: ${spacings.space05};
      width: ${spacings.space05};
      display: inline-block;
      animation: around 5.4s infinite;

      :after,
      :before {
        content: '';
        background: transparent;
        position: absolute;
        display: inline-block;
        width: 100%;
        height: 100%;
        border-width: 2px;
        border-color: ${popeyesSecondaryColors.light}
          ${popeyesSecondaryColors.light} transparent transparent;
        border-style: solid;
        border-radius: 20px;
        box-sizing: border-box;
        top: 0;
        left: 0;
        animation: around 0.7s ease-in-out infinite;
      }

      :after {
        animation: around 0.7s ease-in-out 0.1s infinite;
        background: transparent;
      }
    }
  `,
};

export const Button = styled.button<T.IStyleButton>`
  ${({ font, variant, margin, size, loading, noHeritage }) => css`
    ${!!variant && variant !== 'ghost' && ModifierButton.variants[variant]()};
    ${variant === 'ghost' && ModifierButton.variants.ghost(noHeritage)};
    ${!!font && !noHeritage && ModifierButton.fonts[font]()}};
    ${!!margin && ModifierButton.margin[margin]()};
    ${!!size && ModifierButton.sizes[size]()};
    ${loading ? ModifierButton.loading() : css``};
  `}
`;

export const Icon = styled.img`
  position: absolute;
  left: 0;
  margin-left: 5%;
  width: 25px;
  height: 25px;
`;
