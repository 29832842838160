import { useEffect, useMemo, useState } from 'react';

// eslint-disable-next-line
import { format } from 'date-fns';
// eslint-disable-next-line
import arrowRight from 'assets/images/icons/arrowRight.png';
import offerClock from 'assets/images/icons/offer-clock.svg';
import pop from 'assets/images/icons/pop.svg';
import * as Components from 'components';
import CouponItem from 'components/CouponItem';
import * as Organisms from 'components/Organisms';
import { ptBR } from 'date-fns/locale';

// Images and Icons

// Hooks and services
import { useCoupons } from 'hooks/Coupons';
import firebase from 'services/firebase';
import sizes from 'utils/screenSizes';
import { getWindowDimensions } from 'utils/windowDimensions';

import { useTagManager, ButtonEvents } from 'hooks/Analytics';

// Styles
import {
  Container,
  CouponsContainer,
  DayOffer,
  HeaderOffer,
  Highlights,
  SeeMore,
  TitleOffer,
} from './styles';

const CouponsComponent = ({
  onSelectCoupon,
  onGetCoupon: onGetCouponNow,
  onChangeCategories,
}: any) => {
  const {
    coupons,
    highlightsCoupons,
    categories,
    couponsRemoteConfig,
    onGetCoupon,
  } = useCoupons();

  const { sendButtonClickEvent } = useTagManager();

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      const dimensions = getWindowDimensions();
      setWindowDimensions(dimensions);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  const selectedCategoriesCount = useMemo(() => {
    const count = categories.filter(
      (category: any) => category.selected,
    ).length;
    return count;
  }, [categories]);

  const getFilteredCoupons = useMemo(() => {
    let auxCoupons = [];
    if (
      categories.length > 0 &&
      categories[0].id === -1 &&
      categories[0].selected
    ) {
      auxCoupons = highlightsCoupons;
    }

    if (categories.find((category: any) => category.selected)) {
      const couponsFound = coupons
        .filter((coupon: any) => coupon.categoria != null)
        .filter(
          (coupon: any) =>
            categories.find(
              (category: any) => category.id === coupon.categoria.id,
            ).selected,
        );

      const concatenadCoupons = [...auxCoupons, ...couponsFound];
      const filtered = concatenadCoupons.reduce((acc, current) => {
        const x = acc.find((item: any) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        }
        return acc;
      }, []);
      return filtered;
    }

    const couponsWithoutOffer = coupons.filter(
      (coupon: any) => !coupon.isDayCoupon,
    );
    return couponsWithoutOffer;
  }, [coupons, categories]);

  const getOfferCoupons = useMemo(() => {
    const couponsFound = coupons.filter((coupon: any) => coupon.isDayCoupon);
    return couponsFound;
  }, [coupons]);

  const handleClickCoupon = (coupon: any) => {
    const couponData = {
      cupomName: coupon.nome,
      isDayOffer: coupon.isDayCoupon,
      cupomCode: coupon.codigo,
      categoryOrder: 100,
      cupomValue: coupon.valor_descontado,
    };

    sendButtonClickEvent(ButtonEvents.VIEW_CUPOM, couponData);

    const abCoupon: any = couponsRemoteConfig.cupons.find(
      (abCouponItem: any) => abCouponItem.coupon_code === coupon.codigo,
    );

    if (abCoupon) {
      coupon.name = abCoupon.name ? abCoupon.name : coupon.name;
      coupon.imageUrl = abCoupon.image_url
        ? abCoupon.image_url
        : coupon.imageUrl;
    }

    onSelectCoupon(coupon);
  };

  const getLabelDayOffer = (date_text: any) => {
    const today = new Date();
    let dayLabel: any = format(today, 'eeee', { locale: ptBR });
    const day = today.getDay();
    dayLabel = dayLabel.split('-feira');

    const resultado = date_text.replace(
      '$dia_da_semana',
      `${day === 6 || day === 0 ? 'do' : 'da'} ${dayLabel[0]}`,
    );
    return resultado;
  };

  const sizeToSliceCoupons = useMemo(() => {
    if (highlightsCoupons.length > 0) return 0;
    if (windowDimensions.width >= 2426) return 5;
    if (windowDimensions.width >= 1976) return 4;
    if (windowDimensions.width >= parseInt(sizes.desktop, 10)) return 3;
    if (windowDimensions.width >= 1672) return 4;
    if (windowDimensions.width >= 1300) return 3;
    if (windowDimensions.width >= parseInt(sizes.notebook, 10)) return 2;
    if (windowDimensions.width >= parseInt(sizes.ipadPro, 10)) return 3;
    if (windowDimensions.width >= parseInt(sizes.ipad, 10)) return 2;
    return 2;
  }, [windowDimensions, highlightsCoupons]);

  const handleChangeCategories = (id: any) => {
    const newCategories = [...categories];
    newCategories[id].selected = !categories[id].selected;
    if (newCategories[id].selected)
      firebase.analytics().logEvent('categoria', {
        nome_categoria: newCategories[id].name,
      });

    onChangeCategories(newCategories);
  };

  return (
    <Container>
      {selectedCategoriesCount === 0 && highlightsCoupons.length > 0 && (
        <Highlights
          titleColor={couponsRemoteConfig.destaques_section.title_color}
        >
          <h1>{couponsRemoteConfig.destaques_section.title_text}</h1>
          <div>
            <Organisms.ScrollDrag showArrows>
              {highlightsCoupons.slice(0, 8).map((coupon: any) => (
                <Components.CouponHorizontallItem
                  key={coupon.id}
                  coupon={coupon}
                  abCouponConfig={couponsRemoteConfig.cupons.find(
                    (abCoupon: any) =>
                      abCoupon.coupon_code === coupon.couponCode,
                  )}
                  priceTextColor={
                    couponsRemoteConfig.cupons_price_text.text_color
                  }
                  onClick={() => handleClickCoupon(coupon)}
                  onGetNow={async () => {
                    coupon.replay_cupom = true;
                    await onGetCoupon(coupon);
                    onGetCouponNow();
                  }}
                  buttonOnGetRemoteConfig={
                    couponsRemoteConfig.destaques_section
                  }
                  showRegularValue={couponsRemoteConfig.show_preco_cheio}
                />
              ))}

              {highlightsCoupons.length > 8 && (
                <SeeMore onClick={() => handleChangeCategories(0)}>
                  <p>Ver Mais</p>
                  <img src={arrowRight} alt="Arrow para Esquerda" />
                </SeeMore>
              )}
            </Organisms.ScrollDrag>
          </div>
        </Highlights>
      )}

      <div>
        <CouponsContainer>
          {coupons &&
            getFilteredCoupons.map((coupon: any, i: any) => (
              <CouponItem
                key={i}
                coupon={coupon}
                abCouponConfig={couponsRemoteConfig.cupons.find(
                  (abCoupon: any) => abCoupon.coupon_code === coupon.codigo,
                )}
                priceTextColor={
                  couponsRemoteConfig.cupons_price_text.text_color
                }
                onClick={() => handleClickCoupon(coupon)}
                quantityTotal={getFilteredCoupons.length}
                actualCouponIndex={i + 1}
                showRegularValue={couponsRemoteConfig.show_preco_cheio}
              />
            ))}
        </CouponsContainer>

        {selectedCategoriesCount === 0 && getOfferCoupons.length > 0 && (
          <CouponsContainer
            isOfferOfTheDay
            bgColor={couponsRemoteConfig.oferta_dia_section.background_color}
          >
            <HeaderOffer>
              <TitleOffer
                titleColor={couponsRemoteConfig.oferta_dia_section.title_color}
                subTitleColor={
                  couponsRemoteConfig.oferta_dia_section.subtitle_color
                }
              >
                <img src={pop} alt="Crocância do dia" />
                <div>
                  <h1>{couponsRemoteConfig.oferta_dia_section.title_text}</h1>
                  <span>
                    {couponsRemoteConfig.oferta_dia_section.subtitle_text}
                  </span>
                </div>
              </TitleOffer>
              <DayOffer
                bgColor={
                  couponsRemoteConfig.oferta_dia_section.date_background_color
                }
                textColor={couponsRemoteConfig.oferta_dia_section.date_color}
              >
                <img src={offerClock} alt="Dia da oferta" />
                {getLabelDayOffer(
                  couponsRemoteConfig.oferta_dia_section.date_text,
                )}
              </DayOffer>
            </HeaderOffer>

            {getOfferCoupons.map((coupon: any, i: any) => (
              <CouponItem
                key={i}
                coupon={coupon}
                abCouponConfig={couponsRemoteConfig.cupons.find(
                  (abCoupon: any) => abCoupon.coupon_code === coupon.couponCode,
                )}
                priceTextColor={
                  couponsRemoteConfig.cupons_price_text.text_color
                }
                onClick={() => handleClickCoupon(coupon)}
                isOfferDayColor={
                  couponsRemoteConfig.oferta_dia_section.background_color
                }
                isOfferDay
                showRegularValue={couponsRemoteConfig.show_preco_cheio}
              />
            ))}
          </CouponsContainer>
        )}
      </div>
    </Container>
  );
};

export default CouponsComponent;
