import React from 'react';

import popStar from 'assets/images/Pop/popStar.svg';
import Button from 'components/Button';
import Rating from 'components/Rating';
import PropTypes from 'prop-types';

import { ModalContainer } from '../ModalContainer';
import * as S from '../styles';

const Punctuate = ({ rating, onNext, onClose, onChange }) => (
  <ModalContainer onClose={onClose}>
    <img src={popStar} alt="Mascote Pop falando" />
    <h3>O que achou do site?</h3>
    <S.RatingContainer>
      <Rating rating={rating} onChange={onChange} />
    </S.RatingContainer>
    <S.ButtonContainer>
      <Button
        variant="primary"
        type="button"
        onClick={onNext}
        disabled={rating === 0}
      >
        Avaliar
      </Button>
      <Button variant="tertiary" type="button" onClick={onClose}>
        Fechar
      </Button>
    </S.ButtonContainer>
  </ModalContainer>
);

Punctuate.propTypes = {
  rating: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export { Punctuate };
