import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: row;
  margin-top: 9px;
  margin-bottom: 9px;

  .checkbox-wrapper {
    margin-right: 5px;

    .checkmark {
      display: block;
      width: 24px;
      height: 24px;
      background-color: #fff;
      border-radius: 6px;
      border: 2px solid var(--color-burnt-orange);
      position: relative;
      transition: background-color 0.2s;
    }

    .checkmark::after {
      content: '';
      position: absolute;
      width: 5px;
      height: 10px;
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%) rotateZ(40deg);
      opacity: 0;
      transition: opacity 0.2s;
    }
  }
`;

export const Input = styled.input`
  :checked ~ .checkmark {
    background-color: var(--color-burnt-orange);
  }

  :checked ~ .checkmark::after {
    opacity: 1;
  }
`;
