import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import CloseIconGrey from 'assets/images/icons/close-grey.svg';
import CloseIcon from 'assets/images/icons/close-white.svg';
import MenuIconGrey from 'assets/images/icons/hamburguer-icon-grey.svg';
import MenuIcon from 'assets/images/icons/hamburguer-icon-white.svg';
import { useCoupons } from 'hooks/Coupons';

// Images

// Hooks, Services and Utils
import { api } from 'services';
import firebase from 'services/firebase';

// Styles
import { Container, Header, ShadowGradient, Title } from './styles';

const Hero = ({ hideBanner, handleChoseCoupon }) => {
  const history = useHistory();
  const { couponsRemoteConfig, coupons, categories, onUpdateCategories } =
    useCoupons();

  const [imgHeight, setImageHeight] = useState();
  const [bannerPromoInfos, setBannerPromoInfos] = useState({});

  const requestBannerPromo = async () => {
    try {
      const response = await api.get('/menu/v0.1/banner');

      if (response.data) {
        const banners = response.data.bannerList;
        let bannerCoupon;
        const { innerWidth: width } = window;
        if (width >= 1200) {
          bannerCoupon = banners.find(
            banner =>
              banner.local === 'CUPONS_SITE' && banner.status === 'ativo',
          );
        } else {
          bannerCoupon = banners.find(
            banner =>
              (banner.local === 'HOME_INFERIOR' || banner.local === 'CUPOM') &&
              banner.status === 'ativo',
          );
        }
        if (bannerCoupon) setBannerPromoInfos(bannerCoupon);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    requestBannerPromo();
  }, []);

  const onSelectCategories = useCallback(() => {
    const allCategories = [...categories];
    const categoryBannerSelected = bannerPromoInfos.category.category_id;

    for (let index = 0; index < categories.length; index += 1) {
      const element = categories[index];
      if (element.id === categoryBannerSelected) {
        allCategories[index].selected = true;
      } else {
        allCategories[index].selected = false;
      }
    }

    onUpdateCategories(allCategories);
  }, [categories, bannerPromoInfos, onUpdateCategories]);

  const openCouponBannerProduct = useCallback(() => {
    const productBannerSelected = String(bannerPromoInfos.product.sku_id);

    const couponFound = coupons.find(coupon =>
      coupon.products.find(
        product => product.salesItemId === productBannerSelected,
      ),
    );

    if (couponFound) {
      handleChoseCoupon(couponFound);
    }
  }, [handleChoseCoupon, bannerPromoInfos, coupons]);

  const onClickBanner = useCallback(() => {
    if (bannerPromoInfos.typeClick === 'CATEGORY') {
      firebase.analytics().logEvent('oferta_banner', {
        nome_categoria: bannerPromoInfos.category.name,
      });
      onSelectCategories();
    } else if (bannerPromoInfos.typeClick === 'PRODUCT') {
      firebase.analytics().logEvent('oferta_banner', {
        nome_cupom: bannerPromoInfos.product.name,
      });
      openCouponBannerProduct();
    }
  }, [bannerPromoInfos, onSelectCategories, openCouponBannerProduct]);

  return (
    <>
      <Container
        hideBanner={hideBanner}
        src={bannerPromoInfos?.imageUrl}
        onClick={onClickBanner}
        imgHeight={imgHeight}
      >
        {!hideBanner && <ShadowGradient />}
        <img
          id="Promo_banner"
          src={bannerPromoInfos?.imageUrl}
          alt="Promo banner"
          onLoad={e => setImageHeight(e.target.height)}
        />
        {/* <OfferButton
          titleColor={couponsRemoteConfig.ofertas_button?.title_color}
          backgroundColor={couponsRemoteConfig.ofertas_button?.background_color}
        >
          {couponsRemoteConfig.ofertas_button?.title}
        </OfferButton> */}

        <Header hideBanner={hideBanner}>
          <button
            type="button"
            onClick={() =>
              history.push({
                pathname: '/perfil',
                search: `?${new URLSearchParams({
                  href: '/cupom',
                }).toString()}`,
              })
            }
          >
            <img src={hideBanner ? MenuIconGrey : MenuIcon} alt="Menu" />
          </button>
          <p>Cupons</p>
          <button type="button" onClick={() => history.push('/')}>
            <img
              src={hideBanner ? CloseIconGrey : CloseIcon}
              alt="Voltar à home"
            />
          </button>
        </Header>
      </Container>
      <Title hideBanner={hideBanner}>Cupons</Title>
    </>
  );
};

Hero.propTypes = {
  hideBanner: PropTypes.bool.isRequired,
  handleChoseCoupon: PropTypes.func.isRequired,
};

export default Hero;
