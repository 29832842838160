import React, { useEffect, useMemo } from 'react';

import { addSeconds, format } from 'date-fns';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Countdown = ({ counter, onChange, onFinish }) => {
  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setInterval(() => onChange(counter - 1), 1000);
    } else {
      onFinish();
    }
    return () => clearInterval(timer);
  }, [counter]);

  const formattedTime = useMemo(() => {
    const helperDate = addSeconds(new Date(0), counter);
    return format(helperDate, 'mm:ss');
  }, [counter]);

  return <Container>{formattedTime}</Container>;
};

Countdown.propTypes = {
  counter: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default Countdown;
