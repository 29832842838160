import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${sizes.notebook}) {
    height: 100%;
  }
  width: 100%;
`;

export const ModalContent = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-color: #ffffff;
  margin: 0 24px 24px 24px;
  overflow: hidden;

  h3 {
    margin: 24px;
    font-family: 'ChickenSansBold';
    font-weight: bold;
  }

  p {
    margin: 12px 0 12px 0;
    color: rgba(0, 0, 0, 0.4);
    font-family: 'ChickenSansRegular';
  }

  button {
    margin: 25px 0px 0px;
  }

  button:last-of-type {
    margin: 10px 0px 15px;
  }

  @media (min-width: ${sizes.notebook}) {
    margin: 0;
    h3 {
      margin: 0 24px 24px;
    }
    button:last-of-type {
      margin: 10px 0px 0px;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex-direction: column;

  width: 100%;
  height: 100%;

  .countdown-code {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .safetyContainer {
    display: flex;
    flex-direction: column;

    margin-top: 30px;

    p {
      color: #000000;
      opacity: 80%;
      text-align: center;
      font-size: 12px;
    }
  }

  button {
    margin-top: 20px;
  }

  button:last-of-type {
    margin-top: 0px;
  }
  @media (min-width: ${sizes.notebook}) {
    width: 80%;
    justify-content: flex-start;

    .safetyContainer {
      margin-top: 20px;
    }
  }
`;
