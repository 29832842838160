import React, { useEffect, useRef } from 'react';

import BackButton from 'components/BackButton';
import FooterComponent from 'components/FooterComponent';
import HeaderComponent from 'components/HeaderComponent';
import { VerifyTokenContainer } from 'containers';
import { useHeader } from 'hooks/HeaderContext';
import { AuthLayout } from 'layouts';

import { Container } from './styles';

export default function SmsVerify() {
  const { handleRef } = useHeader();
  const containerRef = useRef();

  useEffect(() => {
    handleRef(containerRef);
  }, []);
  return (
    <Container>
      <HeaderComponent title="Celular" href="/definir-numero-recuperacao" />
      <BackButton />
      <AuthLayout ref={containerRef}>
        <VerifyTokenContainer />
      </AuthLayout>
      <FooterComponent />
    </Container>
  );
}
