import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  height: 100%;
  width: 100%;

  @media all and (min-width: ${sizes.notebook}) {
    /* overflow: auto; */
  }

  @media all and (max-width: ${sizes.notebook}) {
    header {
      display: none;
    }
  }
`;
