import styled from 'styled-components';

export const ContainerButton = styled.div`
  display: flex;

  justify-content: center;
  width: 100%;
`;

export const Button = styled.button`
  bottom: 0;
  position: relative;
  height: 48px;
  width: 100%;
  max-width: ${props => (props.getCoupon ? 'none' : '312px')};
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  background-color: var(--color-burnt-orange);
  margin-top: ${props => (props.getCoupon ? '20px' : '0px')};
  cursor: pointer;
  font-family: 'ChickenSansBold';
  font-weight: bold;
  font-size: 18px;

  @media (max-width: 340px) {
    font-size: ${props => (props.getCoupon ? '16px' : '18px')};
  }

  img {
    max-width: 25px;
    position: absolute;
    left: 15px;
  }
`;
