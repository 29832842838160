import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;

  @media (min-width: ${sizes.notebook}) {
    width: 80%;
    margin: 24px auto;
  }
  button {
    margin: 0 8px;
    border: none;
    background: none;
    :focus,
    :focus-visible {
      outline: none;
    }
    img {
      width: auto;
      height: 40px;
      opacity: 0.3;
    }
    img.selected {
      opacity: 1;
    }
  }
`;
