import { IconTrompete } from '../Icons/IconTrompete';
import { ButtonLink, ButtonLinkContainer } from './styles';

export function ButtonLinkCampaign({ link }: any) {
  return (
    <ButtonLinkContainer>
      <ButtonLink to={link}>
        <IconTrompete />
        <p>Frite essa Galinha!</p>
      </ButtonLink>
    </ButtonLinkContainer>
  );
}
