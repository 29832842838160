import React from 'react';

import PropTypes from 'prop-types';

import { SwitchInput, SwitchLabel, SwitchButton } from './styles';

const Switch = ({ name, toggled, onChange, ...rest }) => {
  return (
    <>
      <SwitchInput
        id={name}
        type="checkbox"
        checked={toggled}
        onChange={onChange}
        {...rest}
      />
      <SwitchLabel
        className={`switch-label ${toggled && 'active'}`}
        htmlFor={name}
      >
        <SwitchButton className="switch-button" />
      </SwitchLabel>
    </>
  );
};

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  toggled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Switch;
