import styled, { css } from 'styled-components';
import sizes from 'utils/screenSizes';
import {
  ICouponsContainerProps,
  IDayOfferProps,
  IHighlightsProps,
  ITitleOfferProps,
} from './types';

export const Container = styled.div`
  width: 100%;
`;

export const CouponsContainer = styled.div<ICouponsContainerProps>`
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;

  @media only all and (max-width: ${sizes.ipad}) {
    margin-top: 45px;
  }
`;

export const HeaderOffer = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 12px 25px 8px;
  width: 100%;

  font-family: 'ChickenSansRegular';
`;

export const TitleOffer = styled.div<ITitleOfferProps>`
  display: flex;

  div {
    padding-left: 12px;
  }

  h1 {
    color: ${({ titleColor }) => titleColor};
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  }

  span {
    color: ${({ subTitleColor }) => subTitleColor};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
  }
`;

export const DayOffer = styled.div<IDayOfferProps>`
  display: flex;

  height: fit-content;
  margin-top: 5px;
  padding: 2px 5px;

  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 11px;
  color: ${({ textColor }) => textColor};

  background-color: ${({ bgColor }) => bgColor};
  border-radius: 4px;

  img {
    margin-right: 5px;
  }
`;

export const Highlights = styled.div<IHighlightsProps>`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 24px 0px 24px 24px;

  > div {
    position: relative;

    display: flex;
    flex-direction: row;

    width: 100%;
    padding-right: 24px;

    overflow-x: scroll;
    overflow-y: hidden;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      display: none;
      width: 0px;
      height: 0px;
    }
  }

  h1 {
    font-family: 'ChickenSansRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    color: ${({ titleColor }) => titleColor};
  }

  @media only all and (min-width: ${sizes.ipad}) {
    padding: 24px 0px 24px 48px;
  }

  @media only all and (min-width: ${sizes.notebook}) {
    padding: 24px 0px 24px 0px;
    > div {
      padding-right: 0px;
    }
  }
`;

export const SeeMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 122px;
  width: 122px;
  height: 144px;
  margin: 12px 24px 0px 0px;

  font-family: 'ChickenSansRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: var(--color-burnt-orange);
  background-color: #f0f0f0;
  border-radius: 4px;

  img {
    height: 11px;
    margin-left: 4px;
    margin-top: 2px;
  }

  cursor: pointer;

  @media only all and (min-width: ${sizes.notebook}) {
    height: 200px;
    font-size: 14px;
  }
`;
