import styled from 'styled-components';
import sizes from 'utils/screenSizes';
import { font as fontGlobal, popeyesPrimaryColors } from 'styles';

interface IRestaurantFilterTypeProps {
  selected?: boolean;
}

export const ModalContent = styled.div`
  font-family: 'ChickenSansRegular';
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 24px 0px;
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
  }

  h3 {
    margin: 0px 24px;
  }

  > button {
    margin-top: 15px;

    > img {
      max-width: 25px;
      position: relative;
      right: 35px;
    }
  }

  @media (max-width: ${sizes.ipadPro}) {
    padding: 32px 24px 0px 24px;
    .MsgIcon {
      max-width: 25px;
      position: absolute;
      left: 40px;
    }
  }
`;

export const RestaurantFilterTypeList = styled.div`
  display: flex;
  width: fit-content;
  position: relative;
`;

export const RestaurantFilterTypeOption = styled.button<IRestaurantFilterTypeProps>`
  background: transparent;
  min-width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  border: none;
  gap: 4px;
  font-size: 16px;
  font-family: 'ChickenSansBold';
  transition: all 0.3s ease;
  color: ${props =>
    props.selected ? popeyesPrimaryColors.primary : '#BDBDBD'};
  ${props =>
    props.selected
      ? `border-bottom: 4px solid ${popeyesPrimaryColors.primary}`
      : ''};
  svg path {
    fill: ${props =>
      props.selected ? popeyesPrimaryColors.primary : '#BDBDBD'};
  }

  &:hover {
    color: #f25600;
    svg path {
      fill: #f25600;
    }
  }

  &:focus {
    outline: none;
  }
`;

export const RestaurantFilterLogo = styled.div`
  display: flex;
  flex: 1;

  img {
    margin-top: 15px;
  }
`;

export const RestaurantFilterType = styled.section`
  position: fixed;
  z-index: 2;
  width: 100%;
  margin: auto;
  padding-top: 30px;
  font-family: 'ChickenSansRegular';
  background: white;
`;

export const RestaurantFilterWrapper = styled.div`
  position: relative;
  display: flex;
  margin: auto;
  width: 1288px;

  h1 {
    display: block;
    width: 300px;
    font-family: 'ChickenSansBold';
    font-size: 32px;
  }

  span {
    display: block;
    position: absolute;
    left: -44px;
    top: 10px;
    color: ${popeyesPrimaryColors.primary};
    font-family: 'ChickenScript' !important;
    font-size: 4rem;
    font-weight: 400;
  }
`;

export const Container = styled.div`
  height: auto;
  width: 100%;
  background: #f5f1ef;
`;

export const Content = styled.div`
  width: 1070px;
  margin: auto;
  margin-top: 125px;
  min-height: 500px;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media all and (max-width: ${sizes.notebook}) {
    margin-top: 80px;
  }
`;

export const Title = styled.h3`
  color: rgb(255, 116, 0);
  font-style: bold;
  font-family: ${fontGlobal.family.ChickenHeadline};
  font-weight: ${fontGlobal.weight.bold};
  font-size: 24px;
  text-align: center;
`;

export const ContainerList = styled.div`
  width: 100%;
  max-width: 700px;
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  gap: 60px;
  flex-wrap: wrap;
`;

export const ItemList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 200px;

  h3 {
    font-style: bold;
    font-family: ${fontGlobal.family.ChickenHeadline};
    font-weight: ${fontGlobal.weight.bold};
    color: rgba(0, 0, 0, 0.7);
  }

  h5 {
    font-family: ${fontGlobal.family.ChickenText};
    font-size: ${fontGlobal.sizes.textMedium};
    color: rgba(0, 0, 0, 0.4);
    font-weight: 400;
  }
`;

export const Delivery = styled.div`
  width: 80px;
  margin-top: 5px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00b2a9;
  border-radius: 8px;

  h5 {
    font-family: ${fontGlobal.family.ChickenText};
    font-size: ${fontGlobal.sizes.textMedium};
    color: #fff;
    font-weight: 400;
  }
`;
