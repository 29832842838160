import { Container } from './styles';

function ScrollToTop({ onClick, isVisible }) {
  return (
    <Container onClick={onClick} isVisible={isVisible}>
      <span />
      <div>Voltar ao Topo</div>
    </Container>
  );
}

export default ScrollToTop;
