import { useEffect } from 'react';
import LoadingPop from 'pages/Loading';

export default function Galinhafritadinha() {
  useEffect(() => {
    setTimeout(() => {
      window.location.replace('https://galinhafritadinha.popeyesbrasil.com.br');
    }, 3000);
  }, []);

  return (
    <>
      <LoadingPop />
    </>
  );
}
