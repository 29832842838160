import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  @media (max-width: ${sizes.notebook}) {
    height: 100%;
  }

  .content {
    display: flex;
    justify-content: center;
  }
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  height: 100%;
  width: 100%;

  .countdown-code {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .container-buttons {
    margin-top: 30px;
  }

  button {
    margin-top: 15px;
  }

  @media (min-width: ${sizes.notebook}) {
    width: 60%;
    padding: 0px 0 36px;
    justify-content: flex-start;
    ${props =>
      props.isFacebookFlow &&
      `
    .container-buttons {
      margin-top: 5px;
    }
    button {
      margin-top: 10px;
    }`}
  }
`;

export const ModalContent = styled.div`
  padding: 24px 24px 0px 24px;
  font-family: 'ChickenSansRegular';
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 24px 0px;
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
  }

  h3 {
    margin: 0px 24px;
    text-align: center;
  }

  a {
    text-decoration: underline;
    color: var(--color-burnt-orange);
    margin-left: 5px;
  }

  button {
    margin-bottom: 10px;
  }
  @media (min-width: ${sizes.notebook}) {
    padding: 0;
  }
`;
