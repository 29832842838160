import styled from 'styled-components';
import { breakpoints, font } from 'styles/themes';
import sizes from 'utils/screenSizes';

export const ProductContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  width: 100%;
  width: 310px;
  font-family: 'ChickenSansBold' !important;
  height: auto;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 15px;

  @media (max-width: ${breakpoints.intermediary}) {
    width: auto;
  }

  @media (max-width: ${breakpoints.small}) {
    width: auto;
    /* width: 170px; */
  }
`;

export const Image = styled.img`
  width: 300px;
  height: 300px;
  align-self: center;
  margin: 0;
  border-radius: 8px 8px 0px 0px;
  object-fit: contain;

  @media (max-width: ${sizes.mobile}) {
    height: 160px;
    width: 160px;
    margin-bottom: 5px;
  }

  z-index: 2;
`;

export const ContainerTitle = styled.div`
  width: 100%;
  background: #ffffff;
  z-index: 2;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 15px;
`;

export const Title = styled.p`
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0.02em;
  text-align: left;
  padding-bottom: 7px;
  font-family: 'ChickenSansBold' !important;
  color: #212121 !important;

  @media (max-width: ${sizes.mobile}) {
    margin-bottom: 5px;
    font-size: 16px;
    font-family: 'ChickenSansBold' !important;
    line-height: 16px;
    letter-spacing: 0.01em;
  }

  @media only all and (min-width: ${sizes.notebook}) {
    font-size: 1.3rem;
    font-family: 'ChickenSansBold' !important;
    line-height: 1.5rem;
  }

  @media only all and (min-width: ${sizes.desktop}) {
    font-size: 1.6rem;
    font-family: 'ChickenSansBold' !important;
    line-height: 1.5rem;
  }
`;

export const ContainerLabels = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: ${breakpoints.small}) {
    display: none;
  }
`;

export const Labels = styled.span`
  background: #00b2a9;
  color: #ffffff;
  font-size: 12px;
  font-family: 'ChickenSans';
  font-weight: 400;
  border-radius: 4px;
  padding: 2px 7px;
  text-transform: uppercase;

  @media (max-width: ${breakpoints.small}) {
    font-size: 10px;
    text-transform: uppercase;
    width: max-content;
  }
`;

export const Description = styled.p`
  padding-top: 0.5rem;
  max-width: 400px;
  width: 100%;
  font-size: ${font.sizes.textMedium};
  line-height: 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
`;
