import React, { useEffect, useState, useCallback } from 'react';

import { TLocation } from 'components/Atoms/Map/interfaces';
import { useStore } from 'hooks';
import { LatLngTuple } from 'leaflet';
import LoadingPop from 'pages/Loading';
import { isMobile } from 'utils/isMobile';

import * as T from './interfaces';
import { Menu } from './Menu';
import { TLatLng } from './RequestLocation/interfaces';

export const Container = () => {
  const {
    places,
    placeSelected,
    mapMode,
    userPosition,
    listPlacesSuggestion,
    loading,
    changeUserPosition,
    loadListPlaces,
    changeCurrentSelected,
    openModal,
    handleModal,
    setMapMode,
  } = useStore();

  const [isMobileDevice, setIsMobileDevice] = useState(isMobile());
  const [mustRender, setMustRender] = useState<T.TRenderType>('loading');
  const handleRenderPlaces = useCallback(async () => {
    await loadListPlaces();
  }, [loadListPlaces]);

  useEffect(() => {
    function handleResize() {
      setIsMobileDevice(isMobile());
      console.log('resize');
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobileDevice]);

  const handleGetPosition = useCallback(
    (position: TLatLng) => {
      const positionUser: {
        isSharing: boolean;
        position?: LatLngTuple;
        firstTimeAskingForSharing?: boolean;
      } = position
        ? {
            isSharing: true,
            position: [position.lat, position.lng],
            firstTimeAskingForSharing: userPosition?.firstTimeAskingForSharing,
          }
        : {
            isSharing: false,
            firstTimeAskingForSharing: userPosition?.firstTimeAskingForSharing,
          };
      changeUserPosition(positionUser);
    },
    [changeUserPosition],
  );

  const getUserLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          handleGetPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        error => error.PERMISSION_DENIED && handleGetPosition(null),
      );
    }
  }, [handleGetPosition]);

  const handleListItemSelected = useCallback(
    (placeId: number) => {
      const List = document.getElementById(`searchList`);
      if (!List) return;
      if (!isMobile()) {
        const arrayItem = Array.from(List.children);

        arrayItem.map((item, index, array) => {
          if (index !== 0) {
            const attributes = item.attributes as {
              id?: { value: string };
            };
            const oldClass = item.className.split(' selected')[0];
            item.className = oldClass;
            if (
              attributes?.id &&
              attributes.id.value === `placeItem@${placeId}`
            ) {
              item.className = `${item.className} selected`;

              const propsItem = item.getBoundingClientRect();
              const scrollTo =
                index === 1
                  ? 0
                  : index === array.length - 1
                  ? List.scrollHeight
                  : item.scrollHeight + propsItem.height + 60;
              List.scrollTop = scrollTo;
              setTimeout(() => {
                item.className = oldClass;
              }, 2000);
            }
          }
        });
      }
      changeCurrentSelected(placeId);
    },
    [changeCurrentSelected, isMobileDevice, window],
  );

  const positions = useCallback(() => {
    const filterPositions: TLocation[] = places.map(place => {
      const placePosition: TLocation = {
        id: place.id,
        positions: place.location,
      };
      return placePosition;
    });

    return filterPositions;
  }, [places]);

  useEffect(() => {
    handleRenderPlaces();
  }, []);
  useEffect(() => {
    if (userPosition?.isSharing) {
      const closestRestaurantDistance = places.find(
        place => parseInt(place.distance, 10) <= 15,
      );
      console.log(closestRestaurantDistance);
      closestRestaurantDistance && setMapMode(true);
    }
  }, [places]);

  return (
    <>
      {loading ? (
        <LoadingPop />
      ) : (
        <Menu
          handleOpenModal={handleModal}
          openModal={openModal}
          id="storeListContainer"
          mapMode={mapMode}
          listPlacesSuggestion={listPlacesSuggestion.length > 1}
          isMobileDevice={isMobileDevice}
          changePosition={getUserLocation}
          currentSelected={placeSelected}
          mapPermission={userPosition && userPosition?.isSharing}
          positions={positions()}
          changeCurrentSelected={handleListItemSelected}
          userPosition={
            userPosition ? userPosition.position : positions()[0].positions
          }
          mustRender={mustRender}
          changeMustRender={setMustRender}
        />
      )}
    </>
  );
};
