import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const CouponContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 450px;
  height: 500px;
  margin: 0px 0px 32px;

  background-color: ${({ isOfferDay }) => (isOfferDay ? 'white' : '#f0f0f0')};
  border-radius: 4px;

  cursor: pointer;

  @media only all and (min-width: ${sizes.mobile}) {
    img {
      width: 100%;
    }
  }
`;

export const FakeComponent = styled.div`
  width: 144px;
  height: 212px;
  margin-left: 12px;
  margin-right: 12px;

  @media only all and (min-width: ${sizes.ipad}) {
    width: 250px;
    height: 300px;
    margin: 12px 12px 24px;
  }

  @media only all and (min-width: ${sizes.notebook}) {
    width: 350px;
    height: 400px;
  }

  @media only all and (min-width: ${sizes.desktop}) {
    width: 450px;
    height: 500px;
    margin-top: 24px;
  }
`;

export const Image = styled.img`
  margin: 12px 30px 0px;
  height: 60%;

  object-fit: contain;
`;

export const TitleContainer = styled.div`
  margin: 0px 8px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only all and (min-width: ${sizes.notebook}) {
    margin: 20px auto;
  }
`;

export const Title = styled.p`
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);

  @media only all and (min-width: ${sizes.notebook}) {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }
  @media only all and (min-width: ${sizes.desktop}) {
    font-size: 1.6rem;
    line-height: 1.5rem;
  }
`;

export const CircleLeft = styled.div`
  position: absolute;
  width: 12.68px;
  height: 14px;
  background-color: ${({ isOfferDayColor }) => isOfferDayColor || 'white'};
  border-radius: 40px;
  bottom: 62px;
  left: -6px;
`;

export const CircleRight = styled.div`
  position: absolute;
  width: 12.68px;
  height: 14px;
  background-color: ${({ isOfferDayColor }) => isOfferDayColor || 'white'};
  border-radius: 40px;
  bottom: 62px;
  right: -6px;
`;

export const LineOver = styled.hr`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 68px;
  border: none;
  border-top: 2px dashed ${({ isOfferDayColor }) => isOfferDayColor || 'white'};
`;

export const PriceContainer = styled.div`
  position: absolute;
  display: flex;
  bottom: 24px;
  left: 0;
  right: 0;
  justify-content: center;

  @media only all and (min-width: ${sizes.notebook}) {
    bottom: ${({ showRegularValue }) => (showRegularValue ? '17px' : '24px')};
  }
`;

export const SmallPriceText = styled.span`
  color: ${props =>
    props.textColor ? props.textColor : `var(--color-burnt-orange)`};
  font-size: 14px;
  line-height: 14px;
  font-family: 'ChickenSansBold' !important;
  display: inline-block;
  vertical-align: top;
  @media only all and (min-width: ${sizes.notebook}) {
    font-size: 1.6rem;
  }
`;

export const BigPriceText = styled.span`
  color: ${props =>
    props.textColor ? props.textColor : `var(--color-burnt-orange)`};
  font-size: 2.5rem;
  line-height: 20px;
  font-family: 'ChickenSansBold' !important;
  display: inline-block;
  vertical-align: top;
  margin: 0px 1px 0px 2px;
  padding-bottom: 10px;
`;

export const RegularValue = styled.div`
  margin: 10px 0px;

  font-family: 'ChickenSansRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 14px;
  text-decoration-line: line-through;

  color: #747474;
  opacity: 0.6;

  @media only all and (min-width: ${sizes.notebook}) {
    margin: 6px 0px;
    font-size: 17px;
  }

  @media only all and (max-width: ${sizes.mobile}) {
    margin: 0;
  }
`;
