import React from 'react';

import Button from 'components/Button';
import ModalResponsive from 'components/ModalResponsive';
import PropTypes from 'prop-types';

// Components

// Styles
import { ModalContent } from '../styles';

const ModalInvalid = ({ open, isEmail, onClose }) => {
  return (
    <>
      <ModalResponsive
        open={open}
        onClose={onClose}
        maxModalHeight={350}
        sizeModal="middle"
      >
        <ModalContent>
          <h3 className="title">
            {`${isEmail ? 'E-mail' : 'Número'} inválido`}
          </h3>

          <p>
            {isEmail
              ? 'Não conseguimos identificar esse e-mail. Confira se o e-mail informado está preenchido corretamente.'
              : 'Não conseguimos identificar esse número. Confira se o DDD e todos os números estão preenchidos corretamente.'}
          </p>

          <Button type="button" onClick={onClose}>
            {`Conferir ${isEmail ? 'e-mail' : 'número'}`}
          </Button>
        </ModalContent>
      </ModalResponsive>
    </>
  );
};

ModalInvalid.propTypes = {
  open: PropTypes.bool.isRequired,
  isEmail: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalInvalid;
