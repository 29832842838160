import ArrowIcon from 'assets/images/icons/back-popeyes-orange.svg';
import { Radio } from 'components/Atoms';
import { useState } from 'react';
import { useTagManager, ButtonEvents } from 'hooks/Analytics';
import {
  BoxCategoryItem,
  CategoryFilter,
  CategoryItem,
  FilterTitle,
  FiltersBar,
  HorizontalLine,
  Icon,
  ToggleIconButton,
} from './styles';

function SideProductsFilter({
  categories,
  selectedCategory,
  setSelectedCategory,
  selectedFilter,
  setSelectedFilter,
  filters,
}: any) {
  const [isCategoriesOpen, setCategoriesOpen] = useState(true);
  const [isFilterOneOpen, setIsFilterOneOpen] = useState(false);
  const [isFilterTwoOpen, setIsFilterTwoOpen] = useState(false);

  const { sendButtonClickEvent } = useTagManager();

  const toggleFilters = (type: any) => {
    if (type === 'categories') {
      setCategoriesOpen(!isCategoriesOpen);
    }
    if (type === 'filtro_1') {
      setIsFilterOneOpen(!isFilterOneOpen);
    }
    if (type === 'filtro_2') {
      setIsFilterTwoOpen(!isFilterTwoOpen);
    }
  };

  const firstFilter = filters[0];
  const secondFilter = filters[1];

  return (
    <FiltersBar>
      <CategoryFilter>
        <FilterTitle>
          Categorias
          <ToggleIconButton onClick={() => toggleFilters('categories')}>
            <Icon
              src={ArrowIcon}
              alt="Dropdown Menu"
              isCategoriesOpen={isCategoriesOpen}
            />
          </ToggleIconButton>
        </FilterTitle>
        {isCategoriesOpen && (
          <>
            <HorizontalLine />
            <BoxCategoryItem>
              <Radio
                checked={!selectedCategory}
                name="Todas"
                id="todas"
                onClick={() => {
                  setSelectedCategory('');
                  setSelectedFilter('');
                }}
                margin="mediumMargin"
              />
              {categories?.map((category: any) => (
                <CategoryItem key={category.id}>
                  <Radio
                    checked={selectedCategory === category.name}
                    name={category.name}
                    id={category.id.toString()}
                    onClick={() => {
                      sendButtonClickEvent(ButtonEvents.FILTER_CATEGORY, {
                        category: category.name,
                      });
                      setSelectedCategory(category.name);
                    }}
                    margin="mediumMargin"
                  />
                </CategoryItem>
              ))}
            </BoxCategoryItem>
          </>
        )}
        {filters && firstFilter && (
          <>
            <HorizontalLine />
            <FilterTitle key={firstFilter.ordem}>
              {firstFilter.nome}
              <ToggleIconButton
                onClick={() => {
                  toggleFilters(`filtro_${firstFilter.ordem + 1}`);
                }}
              >
                <Icon
                  src={ArrowIcon}
                  alt="Dropdown Menu"
                  isCategoriesOpen={isFilterOneOpen}
                />
              </ToggleIconButton>
            </FilterTitle>

            {isFilterOneOpen && (
              <>
                <HorizontalLine />

                <BoxCategoryItem>
                  {firstFilter.filtros.map((item: any) => (
                    <CategoryItem key={item.id}>
                      <Radio
                        checked={
                          selectedFilter === item.nome.replace(/ /g, '%20')
                        }
                        name={item.nome}
                        id={item.id.toString()}
                        onClick={() => {
                          // handleUpdateCategories(category.id)
                          setSelectedFilter(item.nome.replace(/ /g, '%20'));
                        }}
                        margin="mediumMargin"
                      />
                    </CategoryItem>
                  ))}
                </BoxCategoryItem>
              </>
            )}
          </>
        )}

        {filters && secondFilter && (
          <>
            <HorizontalLine />
            <FilterTitle key={secondFilter.ordem}>
              {secondFilter.nome}
              <ToggleIconButton
                onClick={() => {
                  toggleFilters(`filtro_${secondFilter.ordem + 1}`);
                }}
              >
                <Icon
                  src={ArrowIcon}
                  alt="Dropdown Menu"
                  isCategoriesOpen={isFilterTwoOpen}
                />
              </ToggleIconButton>
            </FilterTitle>

            {isFilterTwoOpen && (
              <>
                <HorizontalLine />
                <BoxCategoryItem>
                  {secondFilter.filtros.map((item: any) => (
                    <CategoryItem key={item.id}>
                      <Radio
                        checked={
                          selectedFilter === item.nome.replace(/ /g, '%20')
                        }
                        name={item.nome}
                        id={item.id.toString()}
                        onClick={() => {
                          // handleUpdateCategories(category.id)
                          setSelectedFilter(item.nome.replace(/ /g, '%20'));
                        }}
                        margin="mediumMargin"
                      />
                    </CategoryItem>
                  ))}
                </BoxCategoryItem>
              </>
            )}
          </>
        )}
      </CategoryFilter>
    </FiltersBar>
  );
}

export default SideProductsFilter;
