import { useCallback, useEffect, useRef, useState } from 'react';

import { getWindowDimensions } from 'utils/windowDimensions';

import * as T from './interfaces';
import { ScrollDrag } from './ScrollDrag';

export function Container({
  children,
  onClick,
  id,
  onMouseDown,
  onMouseUp,
  onMouseMove,
  handleScroll,
  showArrows = false,
  ...rest
}: T.IContainer) {
  const ref = useRef(document.createElement('div'));
  const [pressed, setPressed] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [pos, setPos] = useState({ top: 0, left: 0, x: 0, y: 0 });

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      const dimensions = getWindowDimensions();
      setWindowDimensions(dimensions);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  useEffect(() => {
    if (ref.current)
      setPos({
        left: ref.current.scrollLeft,
        top: ref.current.scrollTop,
        x: 0,
        y: 0,
      });
  }, [ref]);

  onMouseDown = (e: any) => {
    setPressed(true);
    setPos({
      left: ref.current.scrollLeft,
      top: ref.current.scrollTop,
      x: e.clientX,
      y: e.clientY,
    });
    e.preventDefault();
    e.stopPropagation();
  };

  onMouseUp = (e: any) => {
    setPressed(false);
    setIsScrolling(false);
    e.preventDefault();
    e.stopPropagation();
  };

  onMouseMove = (e: any) => {
    if (pressed) {
      // How far the mouse has been moved
      const dx = e.clientX - pos.x;
      // const dy = e.clientY - pos.y;

      // Scroll the element
      // ref.current.scrollTop = pos.top;
      ref.current.scrollLeft = pos.left - dx;
    }

    e.preventDefault();
    e.stopPropagation();
  };

  handleScroll = useCallback(
    (e: any) => {
      if (!isScrolling) setIsScrolling(true);
      e.stopPropagation();
    },
    [isScrolling, setIsScrolling],
  );

  return (
    <ScrollDrag
      {...rest}
      onClick={onClick}
      ref={ref}
      pressed={pressed}
      isScrolling={isScrolling}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseDown}
      onMouseMove={onMouseMove}
      handleScroll={handleScroll}
      showArrows={showArrows}
      id={`scrolldrag@${id}`}
      data-testid={`scrolldrag@${id}`}
    >
      {children}
    </ScrollDrag>
  );
}
