import { createContext, useCallback, useContext, useState } from 'react';

import SignOut from 'components/SignOut';
import { api } from 'services';
import * as statusSMS from 'utils/smsStatus';

import TagManager from 'react-gtm-module';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [preserveProfileData, setPreserveProfileData] = useState(false);
  const [signOutModal, setSignOutModal] = useState(false);
  const [data, setData] = useState(() => {
    const user = localStorage.getItem('@Popeyes.user');
    const customerId = localStorage.getItem('@Popeyes.customer_id');
    const accessToken = localStorage.getItem('@Popeyes.access_token');

    if (accessToken) api.defaults.headers.Authorization = accessToken; // automatizar o token nas rotas

    return {
      user: user ? JSON.parse(user) : null,
      customerId,
      accessToken,
    };
  });

  const [messageToken, setMessageToken] = useState(() => {
    const localMessageToken = localStorage.getItem('@Popeyes.messageToken');
    return localMessageToken ? JSON.parse(localMessageToken) : null;
  });

  const contactnumberLogin = useCallback(
    async (number, userData) => {
      const auxUser = { ...data.user };
      auxUser.cellNumber = number;
      auxUser.signed = false;

      if (userData) {
        if (userData.name) auxUser.name = userData.name;
        if (userData.lastname) auxUser.lastname = userData.lastname;
        if (userData.email) auxUser.email = userData.email;
        if (userData.cpf) auxUser.cpf = userData.cpf;
        auxUser.term_accepted = userData.term_accepted || false;
        auxUser.receive_mail = userData.receive_mail || false;
        auxUser.receive_sms = userData.receive_sms || false;
      }

      const response = await api
        .post('/authentication/v0.1/contactnumbersms/login', {
          contact_number: number,
          send_email: false,
        })
        .then(rep => rep)
        .catch(err => {
          auxUser.cellNumber = number;
          auxUser.signed = false;
          auxUser.name = null;
          auxUser.lastname = null;
          auxUser.email = null;
          auxUser.cpf = null;
          return err;
        });

      if (response?.data) {
        const auxMessageToken = {
          messageId: response.data.messageId,
          statusGroup: response.data.status_group,
          statusSms: response.data.status_sms,
        };

        localStorage.setItem(
          '@Popeyes.messageToken',
          JSON.stringify(auxMessageToken),
        );
        setMessageToken(auxMessageToken);
      } else if (response?.response?.data) {
        const auxMessageToken = {
          messageId: '',
          statusGroup: statusSMS.REJECTED,
          statusSms: '',
        };
        localStorage.setItem(
          '@Popeyes.messageToken',
          JSON.stringify(auxMessageToken),
        );
        setMessageToken(auxMessageToken);
      }

      const auxResponse = response.data ? response : response.response;
      if (auxResponse?.data?.email) auxUser.email = auxResponse.data.email;

      let auxCustomerId = null;
      if (auxResponse?.data?.customer_id) {
        auxCustomerId = auxResponse.data.customer_id;
        localStorage.setItem('@Popeyes.customer_id', auxCustomerId);
      }

      localStorage.setItem('@Popeyes.user', JSON.stringify(auxUser));

      TagManager.dataLayer({
        dataLayer: {
          event: 'login',
          userId: auxCustomerId,
        },
      });

      setData(prevState => ({
        ...prevState,
        user: auxUser,
        customerId: auxCustomerId,
      }));

      return response;
    },
    [setData, data, setMessageToken],
  );

  const facebookLogin = useCallback(
    async (facebook_id, facebook_data) => {
      const auxUser = { ...data.user };
      auxUser.signed = false;

      if (facebook_data.name) auxUser.name = facebook_data.name;
      if (facebook_data.lastname) auxUser.lastname = facebook_data.lastname;
      if (facebook_data.email) auxUser.email = facebook_data.email;
      if (facebook_data.cpf) auxUser.cpf = facebook_data.cpf;
      auxUser.cellNumber = facebook_data.number ? facebook_data.number : '';
      auxUser.term_accepted = facebook_data.term_accepted || false;
      auxUser.receive_mail = facebook_data.receive_mail || false;
      auxUser.receive_sms = facebook_data.receive_sms || false;

      const response = await api
        .post('/authentication/v0.1/facebookid/login', {
          facebook_id,
          send_email: false,
        })
        .then(rep => rep)
        .catch(err => err);

      if (response?.data) {
        const auxMessageToken = {
          messageId: response.data.messageId,
          statusGroup: response.data.status_group,
          statusSms: response.data.status_sms,
        };

        localStorage.setItem(
          '@Popeyes.messageToken',
          JSON.stringify(auxMessageToken),
        );
        setMessageToken(auxMessageToken);
      } else if (response?.response?.data) {
        const auxMessageToken = {
          messageId: '',
          statusGroup: statusSMS.REJECTED,
          statusSms: '',
        };
        localStorage.setItem(
          '@Popeyes.messageToken',
          JSON.stringify(auxMessageToken),
        );
        setMessageToken(auxMessageToken);
      }

      const auxResponse = response.data ? response : response.response;
      if (auxResponse?.data?.contact_number)
        auxUser.cellNumber = auxResponse.data.contact_number;
      if (auxResponse?.data?.email) auxUser.email = auxResponse.data.email;

      localStorage.setItem('@Popeyes.user', JSON.stringify(auxUser));
      localStorage.setItem('@Popeyes.access_token', facebook_id);

      setData(prevState => ({
        ...prevState,
        user: auxUser,
        accessToken: facebook_id,
      }));

      return response;
    },
    [data, setData, setMessageToken],
  );

  const signIn = useCallback(
    async ({ contact_number, facebook_id, user_data }) => {
      if (contact_number) {
        const response = await contactnumberLogin(contact_number, user_data);
        return response;
      }

      if (facebook_id) {
        const responseFacebook = await facebookLogin(facebook_id, user_data);
        return responseFacebook;
      }

      return null;
    },
    [contactnumberLogin, facebookLogin],
  );

  const validatePinNumber = useCallback(async (number, PIN) => {
    const response = await api.post(
      '/authentication/v0.1/contactnumber/login',
      { contact_number: number, PIN },
    );

    return response;
  }, []);

  const validatePinFacebook = useCallback(async (facebook_id, PIN) => {
    const response = await api.post('/authentication/v0.1/pinfacebook/login', {
      PIN,
      facebook_id,
    });
    return response;
  }, []);

  const numberValidation = useCallback(
    async ({ contact_number, facebook_id, PIN }) => {
      let response = null;
      if (contact_number) {
        response = await validatePinNumber(contact_number, PIN);
      }

      if (facebook_id) {
        response = await validatePinFacebook(facebook_id, PIN);
      }

      api.defaults.headers.Authorization = response.data.access_token;

      const responseUser = await api.get('/customer/v0.1/profile', {
        params: { customer_id: response.data.customer_id },
      });

      const {
        customer_birthday,
        customer_contact,
        customer_email,
        customer_gender,
        customer_lastname,
        customer_name,
        customer_cpf,
      } = responseUser.data;

      const auxUser = JSON.parse(localStorage.getItem('@Popeyes.user'));
      auxUser.signed = true;
      auxUser.name = customer_name;
      auxUser.lastname = customer_lastname;
      auxUser.birthday = customer_birthday;
      auxUser.email = customer_email;
      auxUser.cellNumber = customer_contact;
      auxUser.gender = customer_gender;
      auxUser.cpf = customer_cpf;

      localStorage.setItem('@Popeyes.already_visited', true);
      localStorage.setItem('@Popeyes.customer_id', response.data.customer_id);
      localStorage.setItem('@Popeyes.access_token', response.data.access_token);
      localStorage.setItem('@Popeyes.user', JSON.stringify(auxUser));

      setData({
        user: auxUser,
        accessToken: response.data.access_token,
        customerId: response.data.customer_id,
      });

      return response;
    },
    [validatePinNumber, validatePinFacebook],
  );

  const updateUser = useCallback(
    async userData => {
      const {
        name,
        lastname,
        email,
        cpf,
        birthday,
        gender,
        term_accepted,
        receive_mail,
        receive_sms,
        receive_push,
        number,
      } = userData;

      const auxUser = { ...data.user };
      if (name) auxUser.name = name;
      if (lastname) auxUser.lastname = lastname;
      if (email) auxUser.email = email;
      if (cpf) auxUser.cpf = cpf;
      if (birthday) auxUser.birthday = birthday;
      if (gender) auxUser.gender = gender;
      if (number) auxUser.cellNumber = number;
      auxUser.term_accepted = term_accepted || false;
      auxUser.receive_mail = receive_mail || false;
      auxUser.receive_sms = receive_sms || false;
      auxUser.receive_push = receive_push || false;

      localStorage.setItem('@Popeyes.user', JSON.stringify(auxUser));
      await setData(prevState => ({ ...prevState, user: auxUser }));
    },
    [data, setData],
  );

  const updateMessageToken = useCallback(
    async messageTokenData => {
      localStorage.setItem(
        '@Popeyes.messageToken',
        JSON.stringify(messageTokenData),
      );
      setMessageToken(messageTokenData);
    },
    [setMessageToken],
  );

  const fakeLogin = useCallback(async () => {
    const auxUser = {
      customer_id: '123456',
      signed: true,
      name: 'Non User',
      lastname: 'Non User',
      email: 'user@popeyesbrasil.com.br',
      cpf: '12345678901',
      cellNumber: '11999999999',
      birthday: '1990-01-01',
      fakeUser: true,
    };

    localStorage.setItem('@Popeyes.user', JSON.stringify(auxUser));
    localStorage.setItem('@Popeyes.customer_id', auxUser.customer_id);

    TagManager.dataLayer({
      dataLayer: {
        event: 'login',
        userId: auxUser.customer_id,
      },
    });

    setData(prevState => ({
      ...prevState,
      user: auxUser,
      customerId: auxUser.customer_id,
    }));
  }, []);

  const clearUser = useCallback(async () => {
    const alreadyVisited = localStorage.getItem('@Popeyes.already_visited');
    const auxUser = { signed: false };
    setData({ user: auxUser });
    localStorage.removeItem('@Popeyes.customer_id');
    localStorage.removeItem('@Popeyes.access_token');
    localStorage.removeItem('@Popeyes.chosenCoupon.v2');
    localStorage.removeItem('@Popeyes.cart.v2');
    localStorage.removeItem('@Popeyes.messageToken');
    localStorage.setItem('@Popeyes.user', JSON.stringify(auxUser));
    if (alreadyVisited) localStorage.setItem('@Popeyes.already_visited', true);
  }, [setData]);

  const signOut = useCallback(() => {
    setSignOutModal(true);
  }, [signOutModal]);

  api.interceptors.response.use(
    response => {
      return response;
    },
    async err => {
      if (err?.response?.status === 401) {
        if (process.env.REACT_APP_ENV !== 'prod') return Promise.reject(err);
        if (window.location.pathname === '/cupom') clearUser();
        else {
          clearUser();
          window.replace('/onboarding');
        }
      }
      return Promise.reject(err);
    },
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        accessToken: data.accessToken,
        customerId: data.customerId,
        preserveProfileData,
        signIn,
        signOut,
        numberValidation,
        updateUser,
        clearUser,
        messageToken,
        updateMessageToken,
        setPreserveProfileData,
        fakeLogin,
      }}
    >
      <SignOut
        open={signOutModal}
        clearUser={() => {
          clearUser();
        }}
        onClose={() => {
          setSignOutModal(false);
        }}
      />
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);
  return context;
}

export { AuthProvider, useAuth };
