import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoints, shadows } from 'styles';

import { IFilterCardapioStyles } from './interfaces';

export const Container = styled.div<IFilterCardapioStyles>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  background-color: #fff;

  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  box-shadow: ${shadows.shadow04};

  height: ${props => (props.inputSelected ? '60px' : '45px')};

  ${props =>
    props.inputSelectedMobile &&
    css`
      height: 75px;
    `};
`;

export const ItemsContainer = styled.div<IFilterCardapioStyles>`
  display: flex;
  align-items: center;
  margin-bottom: 1px;
  width: ${props => (props.sizeWidth === 'desktop' ? '75%' : '100%')};

  max-width: ${props => (props.inputSelected ? '0' : '100%')};

  ${props =>
    props.inputSelected &&
    css`
      width: 0;
      max-height: 0px;
    `}

  transition: all 0.5s linear;

  border-bottom: 1px solid #ccc;
  box-shadow: ${shadows.shadow04};

  max-height: 45px;

  overflow-y: hidden;
  overflow-x: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
    width: 0px;
    height: 0px;
  }
`;

export const InputContainer = styled.div<IFilterCardapioStyles>`
  height: 66px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;

  margin-left: 5px;

  transition: all 0.5s linear;

  cursor: pointer;

  max-width: ${props => (props.inputSelected ? '80%' : '25%')};

  width: ${props => (props.inputSelected ? '80%' : '25%')};

  ${props =>
    props.inputSelectedMobile &&
    css`
      max-width: 90%;
      width: 100%;

      margin-top: 15px;
    `};

  span {
    color: #929292;
  }

  img {
    background: transparent;
    border: none;

    opacity: 0.75;
    height: 20px;
    width: 20px;
  }

  div {
    ${props =>
      !props.inputSelected &&
      css`
        background-color: #fff;
        height: 15px;
        align-items: baseline;
        margin-bottom: 30px;
      `}

    ${props =>
      props.inputSelected &&
      css`
        div {
          margin-bottom: 15px;
        }
      `}
  }
`;
