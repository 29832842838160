import React from 'react';
import { useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';

// Styles
import { Container, Input } from './styles';

const Checkbox = props => {
  const methods = useFormContext();
  const { name } = props;

  return (
    <Container>
      <div className="checkbox-wrapper">
        <Input
          type="checkbox"
          ref={methods.register}
          name={name}
          {...props}
          hidden
        />

        <label htmlFor={name} className="checkmark" />
      </div>
    </Container>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Checkbox;
