import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import OkIcon from 'assets/images/icons/ok.svg';
import WarningIcon from 'assets/images/icons/warning.svg';
import PropTypes from 'prop-types';

import { InputContainer, ErrorMessage } from './styles';

const Input = ({
  htmlFor,
  text,
  errorMessage,
  showError,
  showIcon,
  isValid,
  loading,
  icon,
  ...rest
}) => {
  const { register, errors, watch } = useFormContext();

  const name = useMemo(() => rest.name, [rest]);

  return (
    <>
      <InputContainer
        hasText={!!text}
        inputIsValid={!(errors[name] || !isValid)}
      >
        <input {...rest} ref={register} />
        <label htmlFor={htmlFor} className="label-name">
          <span className="content-name">{text}</span>
        </label>
        {icon ? (
          <div className="icon-test-container show-icon">
            <img src={icon} alt="Campo inválido" />
          </div>
        ) : (
          <div
            className={`icon-test-container ${
              watch(name) !== '' || loading ? 'show-icon' : ''
            }`}
          >
            {loading ? (
              <div className="loader" />
            ) : (
              <>
                {showIcon && (errors[name] || !isValid) && (
                  <img src={WarningIcon} alt="Campo inválido" />
                )}
                {showIcon && !errors[name] && isValid && (
                  <img src={OkIcon} alt="Campo válido" />
                )}
              </>
            )}
          </div>
        )}
      </InputContainer>
      {errors[name] && watch(name) !== '' && !showError && (
        <ErrorMessage>{errors[name].message}</ErrorMessage>
      )}
      {showError && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  htmlFor: PropTypes.string.isRequired,
  text: PropTypes.string,
  errorMessage: PropTypes.string,
  showError: PropTypes.bool,
  showIcon: PropTypes.bool,
  isValid: PropTypes.bool,
  loading: PropTypes.bool,
  icon: PropTypes.any,
};

Input.defaultProps = {
  text: null,
  errorMessage: '',
  showError: false,
  showIcon: false,
  loading: false,
  type: 'text',
  icon: null,
};

export default Input;
