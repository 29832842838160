import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  padding: 60px 0 20px 0;
  background: #212121;
  /* height: 60%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
  z-index: 5;
  position: relative;

  #ChickenFooter {
    margin-bottom: 40px;
  }

  p {
    font-family: 'ChickenSansRegular';
    color: var(--color-salt-white);
    font-weight: 400;
    line-height: 31.92px;
    font-size: 24px;
  }

  p.footer_content_info {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-align: center;
  }

  img {
    height: 100px;
    width: auto;
    /* margin-top: 40px; */
  }

  /* @media all and (min-width: ${sizes.notebook}) {
    height: 50%;
  } */
  @media all and (min-width: ${sizes.desktop}) {
    img {
      height: 120px;
      width: auto;
    }
  }
  @media all and (max-width: ${sizes.notebook}) {
    display: none;
  }
`;

export const SocialMedial = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 30px 0;

  a {
    margin: 0 4%;
  }
  a:hover {
    svg {
      path {
        fill: #f25600;
      }
    }
  }
  img {
    height: 100%;
    width: 100%;
    max-width: 32px;
  }
  @media only all and (min-width: 1500px) {
    img {
      max-width: 40px;
    }
  }
`;

export const Links = styled.div`
  /* width: 60%; */
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 10px;

  a {
    font-family: 'ChickenSansRegular';
    color: var(--color-salt-white);

    font-size: 1rem;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-align: center;
  }
  a:hover {
    color: #f25600;
  }
  /* @media only all and (min-width: 1500px) {
    a {
      font-size: 1.3rem;
    }
  }
  @media only all and (min-width: 1500px) {
    a {
      font-size: 1.7rem;
    }
  } */
`;

export const LegalInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 40px;

  a {
    font-family: 'ChickenSansRegular';
    color: var(--color-salt-white);
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-align: center;
  }

  a:hover {
    color: #f25600;
  }
`;
