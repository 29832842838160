import styled, { css } from 'styled-components';
import {
  breakpoints,
  font as fontGlobal,
  popeyesPrimaryColors,
  popeyesSecondaryColors,
  spacings,
  transitions,
} from 'styles';

import * as T from './interfaces';

const ModifierFonts = {
  regular: () => css`
    font-style: normal;
    font-family: ${fontGlobal.family.ChickenText};
    font-weight: ${fontGlobal.weight.normal};
  `,
  bold: () => css`
    font-style: bold;
    font-family: ${fontGlobal.family.ChickenHeadline};
    font-weight: ${fontGlobal.weight.bold};
  `,
};

const ModifierDefaultStyle = () => css`
  width: 100%;

  h2,
  div,
  p {
    width: 100%;
  }
`;

const ModifierProductItem = {
  fonts: {
    regular: () => css`
      ${ModifierFonts.regular()}
    `,
    bold: () => css`
      ${ModifierFonts.bold()}
    `,
  },

  variants: {
    primary: () => css`
      ${ModifierFonts.bold()}
      ${ModifierDefaultStyle()}
      color: ${popeyesSecondaryColors.dark};
      border: none;
    `,
  },

  margin: {
    zeroMargin: () => css`
      margin: 0;
    `,
    highMargin: () => css`
      margin: ${spacings.space03};
    `,
  },

  textAlign: {
    center: () => css`
      text-align: center;
    `,
    left: () => css`
      text-align: left;
    `,
    right: () => css`
      text-align: right;
    `,
  },

  sizes: {
    S: () => css`
      height: 200px;
    `,
    M: () => css`
      height: 400px;
    `,
    L: () => css`
      height: 600px;
    `,
    Adaptable: () => css`
      height: 100%;
    `,
  },
};

export const ProductItem = styled.div<T.IStyleProductItem>`
  ${({ font, variant, margin, size, textAlign }) => css`
    ${!!variant && ModifierProductItem.variants[variant]()};
    ${!!font && ModifierProductItem.fonts[font]()}};
    ${!!margin && ModifierProductItem.margin[margin]()};
    ${!!size && ModifierProductItem.sizes[size]()};
    ${!!textAlign && ModifierProductItem.textAlign[textAlign]()};
  `}
`;
