/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';

import TitleSectionContainer from './styles';

function TitleSection(props) {
  const { title, subtitle, numberBlack, textBlack, lastSubtitle } = props;

  return (
    <TitleSectionContainer>
      <h2>{title}</h2>

      {numberBlack || textBlack ? (
        <p>
          {subtitle}
          {textBlack ? (
            <>
              <div>
                <strong>{textBlack}</strong>
              </div>
              <p>{lastSubtitle}</p>
            </>
          ) : (
            <>
              {` 55 ${numberBlack}.`}
              {lastSubtitle}
            </>
          )}
        </p>
      ) : (
        <p>{subtitle}</p>
      )}
    </TitleSectionContainer>
  );
}

TitleSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  numberBlack: PropTypes.string,
  textBlack: PropTypes.string,
  lastSubtitle: PropTypes.string,
};

export default TitleSection;
