import Slider from 'react-slick';
import { ButtonLinkHero } from '../ButtonLinkHero';
import Hero from '../Hero';
import { NextArrow, PrevArrow } from './SliderArrow';

export default function SliderCarousel({
  settings,
  dataBanners,
  btnLinkTo = '',
}) {
  const defaultSettings = {
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    ...settings,
  };

  return (
    <Slider {...defaultSettings}>
      {dataBanners.length > 0 &&
        dataBanners.map((banner, index) => {
          if (banner.bannerId === 210) {
            return (
              <>
                <Hero
                  index={index}
                  key={banner}
                  bannerId={banner.bannerId}
                  imgHero={banner.imageUrl}
                />
                <ButtonLinkHero btnLinkTo={btnLinkTo} />
              </>
            );
          }
          return (
            <Hero
              index={index}
              key={banner}
              btnLinkTo={btnLinkTo}
              bannerId={banner.bannerId}
              imgHero={banner.imageUrl}
            />
          );
        })}
    </Slider>
  );
}
