import React, { useEffect, useRef, useState } from 'react';

import BackButton from 'components/BackButton';
import FooterComponent from 'components/FooterComponent';
import HeaderComponent from 'components/HeaderComponent';
import { TalkToUsContainer } from 'containers';
import { useHeader } from 'hooks/HeaderContext';

import { Container } from './styles';

function TalkToUs() {
  const [urlBack, setUrlBack] = useState('/perfil');
  const { handleRef } = useHeader();
  const containerRef = useRef();

  useEffect(() => {
    handleRef(containerRef);
    const params = new URL(window.location).searchParams;
    const href = params.get('href');
    if (href) setUrlBack(href);
  }, []);

  return (
    <Container ref={containerRef}>
      <HeaderComponent title="Fale Conosco" href={urlBack} />
      <BackButton />

      <TalkToUsContainer />

      <FooterComponent />
    </Container>
  );
}

export default TalkToUs;
