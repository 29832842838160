import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  @media (max-width: ${sizes.notebook}) {
    height: 100%;
  }
`;

export const CountdownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ButtonsContainer = styled.div`
  margin-top: 30px;
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  height: 100%;
  width: 100%;

  button {
    margin-top: 15px;
  }
`;
