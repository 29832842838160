import React, { useMemo } from 'react';

import Button from 'components/Button';

// Hooks, Services and Utils
import { useCoupons } from 'hooks/Coupons';
import PropTypes from 'prop-types';

import { useTagManager, ButtonEvents } from 'hooks/Analytics';
import {
  CouponContainer,
  Image,
  TitleContainer,
  Title,
  CircleLeft,
  CircleRight,
  LineOver,
  PriceContainer,
  Container,
  RegularValue,
} from './styles';

const CouponHorizontallItem = ({
  coupon,
  onGetNow,
  onClick,
  abCouponConfig,
  priceTextColor,
  isOfferDayColor,
  buttonOnGetRemoteConfig,
  showRegularValue,
}) => {
  const { cartCoupon } = useCoupons();
  const { sendButtonClickEvent } = useTagManager();

  // Use individual coupon setting over general coupon list setting
  const textColor = abCouponConfig
    ? abCouponConfig.price_text_color
    : priceTextColor;

  const checkCouponAlreadyExists = useMemo(() => {
    if (coupon) {
      const couponFound = cartCoupon.find(
        couponFinded => couponFinded.id === coupon.id,
      );
      return !!couponFound;
    }
    return false;
  }, [cartCoupon, coupon]);

  const hanldleClickButton = () => {
    const couponData = {
      cupomName: coupon.nome,
      isDayOffer: coupon.isDayCoupon,
      cupomCode: coupon.codigo,
      categoryOrder: coupon.categoria.ordem,
      cupomValue: coupon.valor_descontado,
    };

    sendButtonClickEvent(ButtonEvents.PEGAR_CUPOM, couponData);
    onGetNow();
  };

  const hanldleClick = e => {
    if (e.target.name !== 'buttonGetNow') onClick();
  };

  return (
    <>
      <CouponContainer
        onClick={hanldleClick}
        buttonColor={buttonOnGetRemoteConfig.button_color}
        textColor={buttonOnGetRemoteConfig.button_text_color}
      >
        <Image
          src={abCouponConfig ? abCouponConfig.image_url : coupon.imageUrl}
        />

        <LineOver isOfferDayColor={isOfferDayColor}>
          <CircleLeft isOfferDayColor={isOfferDayColor} />
          <CircleRight isOfferDayColor={isOfferDayColor} />
        </LineOver>

        <Container>
          <TitleContainer>
            <Title>{abCouponConfig ? abCouponConfig.nome : coupon.nome}</Title>
          </TitleContainer>

          {coupon.valor_regular > 0 && (
            <RegularValue>
              {coupon.valor_regular.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                style: 'currency',
                currency: 'BRL',
              })}
            </RegularValue>
          )}

          <PriceContainer textColor={textColor}>
            {coupon.totalValue.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            })}
          </PriceContainer>

          <Button
            size="S"
            name="buttonGetNow"
            disabled={checkCouponAlreadyExists}
            onClick={hanldleClickButton}
          >
            {checkCouponAlreadyExists
              ? 'Cupom já pego'
              : buttonOnGetRemoteConfig.button_text}
          </Button>
        </Container>
      </CouponContainer>
    </>
  );
};

CouponHorizontallItem.propTypes = {
  coupon: PropTypes.object.isRequired,
  onGetNow: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  priceTextColor: PropTypes.string.isRequired,
  isOfferDayColor: PropTypes.string,
  abCouponConfig: PropTypes.objectOf(PropTypes.any),
  buttonOnGetRemoteConfig: PropTypes.objectOf(PropTypes.any).isRequired,
  showRegularValue: PropTypes.bool,
};

CouponHorizontallItem.defaultProps = {
  isOfferDayColor: null,
  abCouponConfig: null,
  showRegularValue: false,
};

export default CouponHorizontallItem;
