import React, { useEffect, useRef /* , useMemo */, useState } from 'react';

/* import HeaderComponent from 'components/HeaderComponent'; */
import BackButton from 'components/BackButton';
import FooterComponent from 'components/FooterComponent';
import HeaderComponent from 'components/HeaderComponent';
import { UseTermsContainer } from 'containers';
import { useHeader } from 'hooks/HeaderContext';

import { Container } from './styles';

function SignInNumber() {
  const { handleRef, showHeader } = useHeader();
  const containerRef = useRef();
  const [urlBack, setUrlBack] = useState('/direitos-e-solicitacoes');

  useEffect(() => {
    const params = new URL(window.location).searchParams;
    const href = params.get('href');
    if (href) setUrlBack(href);
  }, []);
  useEffect(() => {
    handleRef(containerRef);
  }, []);

  return (
    <Container ref={containerRef}>
      <HeaderComponent
        visible={showHeader}
        title="Termos de uso"
        href={urlBack}
      />
      <BackButton />

      <UseTermsContainer />

      <FooterComponent />
    </Container>
  );
}

export default SignInNumber;
