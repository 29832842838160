import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Pages and Components
// Icons and Images
import CardapioIcon from 'assets/images/icons/IconCardapio.svg';
import DeliveryIcon from 'assets/images/icons/delivery-icon.svg';
import HamburguerIcon from 'assets/images/icons/hamburguer-icon-white.svg';
import partnerLink from 'assets/images/icons/link-icon.svg';
import MenuIcon from 'assets/images/icons/menu-icon.svg';
import ifood from 'assets/images/ifood-logo.svg';
import popeyesDelivery from 'assets/images/popeyes-delivery-logo.svg';
import rappi from 'assets/images/rappi-logo.svg';
import ModalResponsive from 'components/ModalResponsive';
import CarouselContent from 'containers/Home/CarouselContent';
// Services and Utils
import PlkPoppyBag from 'assets/images/icons/plk-poppy-bag.svg';
import LoadingPop from 'pages/Loading';
import { api } from 'services';
import firebase from 'services/firebase';
import { breakpoints } from 'styles/themes';
import sizes from 'utils/screenSizes';
import SliderCarousel from '../../components/Organisms/SliderCarousel';

import { setDeliveryUrl } from '../../utils/deliveryUrl';
import Inapp from './Inapp';

// Styles
import {
  ButtonCampaignMobile,
  ButtonGroup,
  Container,
  ContainerButtonCampaignMobile,
  HamburguerButton,
  Header,
  ModalContainer,
} from './styles';

function Home() {
  const history = useHistory();

  const [banner, setBanner] = useState(null);
  const [desktopBanners, setDesktopBanners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [abConfig, setABConfig] = useState();
  const [loadingRemoteConfig, setLoadingRemoteConfig] = useState(false);
  const [isOpenInnapReview, setIsOpenInnapReview] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [sliderTimeDuration, setSliderTimeDuration] = useState(6000);

  // closeModal on click back button phone
  const onBackButtonEvent = e => {
    e.preventDefault();
    setOpenModal(false);
  };

  useEffect(() => {
    if (openModal) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', onBackButtonEvent);
    }

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [openModal]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    return {
      width,
      height,
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  const getBanner = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/menu/v0.1/banner');
      if (response.data) {
        const banners = response.data.bannerList;

        const bannerHome = banners.find(
          bannerItem =>
            (bannerItem.local === 'HOME_SUPERIOR' &&
              bannerItem.status === 'ativo') ||
            bannerItem.local === 'HOME',
        );

        if (bannerHome) setBanner(bannerHome.imageUrl);

        const findDesktopBannersAndFilter = banners
          .map(bannerDesktop => {
            if (bannerDesktop.local.includes('HOME_SITE')) {
              return bannerDesktop;
            }
            return null;
          })
          .filter(bannerDesktop => {
            return bannerDesktop != null;
          })
          .sort(
            (a, b) =>
              parseInt(a.bannerName.split(' ')[2], 10) -
              parseInt(b.bannerName.split(' ')[2], 10),
          );

        setDesktopBanners(findDesktopBannersAndFilter);

        // Realizar pre-load da imagem para cachea-la
        if (windowDimensions.width >= parseInt(sizes.notebook, 10)) {
          const { imageUrl } = findDesktopBannersAndFilter[0];

          let preloaderImg = document.createElement('img');
          preloaderImg.src = imageUrl;
          preloaderImg.addEventListener('load', () => {
            preloaderImg = null;
            setLoading(false);
          });
        } else {
          const { imageUrl } = bannerHome;
          let preloaderImg = document.createElement('img');
          preloaderImg.src = imageUrl;
          preloaderImg.addEventListener('load', () => {
            preloaderImg = null;
            setLoading(false);
          });
        }
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  }, [setLoading, api]);

  const setUpRemoteConfig = () => {
    setLoadingRemoteConfig(true);
    const remoteConfig = firebase.remoteConfig();
    remoteConfig
      .fetchAndActivate()
      .then(() => {
        setABConfig(JSON.parse(remoteConfig.getString('home')));
        setLoadingRemoteConfig(false);
      })
      .catch(() => {
        setLoadingRemoteConfig(false);
      });
  };

  const handleOpenDelivery = useCallback(() => {
    const params = new URL(window.location).searchParams;

    const delivery = params.get('openDelivery');
    if (delivery && windowDimensions.width <= parseInt(sizes.ipadPro, 10)) {
      setOpenModal(true);
    }
  }, [setOpenModal]);

  const handleOpenInnapReview = useCallback(async () => {
    const userIsAutenticate = JSON.parse(
      localStorage.getItem('@Popeyes.user'),
    )?.signed;
    const coupons = JSON.parse(localStorage.getItem('@Popeyes.cart.v2'))?.map(
      item => item.id,
    );
    if (userIsAutenticate && coupons) {
      const customer_id = localStorage
        .getItem('@Popeyes.customer_id')
        .toString();
      try {
        const { data } = await api.get('customer/v0.1/review', {
          params: { customer_id },
        });
        data.pop_up && setIsOpenInnapReview(true);
      } catch (err) {
        console.log(err);
      }
    }
  }, [api]);

  useEffect(() => {
    setUpRemoteConfig();
    getBanner();
    handleOpenDelivery();
    handleOpenInnapReview();
  }, []);

  // Init Analytics   ------------------------
  const onPressMenu = () => {
    firebase.analytics().logEvent('side_bar');
    history.push('/perfil');
  };

  const onPressCardapio = () => {
    firebase.analytics().logEvent('home_cardapio');
    history.push('/cardapio');
  };

  const onPressCoupons = () => {
    firebase.analytics().logEvent('home_cupons');
    history.push('/cupom');
  };

  const onPressDelivery = () => {
    firebase.analytics().logEvent('home_delivery');
    setOpenModal(true);
  };
  // Finish Analytics ------------------------

  useEffect(() => {
    function handleResize() {
      const dimensions = getWindowDimensions();
      setWindowDimensions(dimensions);
    }
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  useEffect(() => {
    const url = new URL(window.location.href);

    if (openModal) {
      url.searchParams.set('openDelivery', 'true');
      window.history.replaceState(null, null, url);
    }
    if (!openModal) {
      url.searchParams.delete('openDelivery');
      window.history.replaceState(null, null, url);
    }
  }, [openModal]);

  const CarouselContainer = () => {
    const currentBanners = desktopBanners;
    const banners = [];

    for (let i = 0; i !== currentBanners.length; i) {
      banners.push(
        CarouselContent({
          imageUrl: currentBanners[i]?.imageUrl,
          bannerId: currentBanners[i]?.bannerId,
        }),
      );

      i += 1;
    }

    return banners;
  };

  const checkIfIsMobile = useCallback(() => {
    const { innerWidth: width } = window;
    if (width < parseInt(breakpoints.large, 10)) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    checkIfIsMobile();
  });

  useEffect(() => {
    window.location === '/';
  }, []);

  const CarouselHomeSettings = {
    dots: true,
    fade: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: sliderTimeDuration,
    infinite: true,
    pauseOnHover: false,
  };

  const btnLinkToDelivery =
    'https://delivery.popeyes.com.br/?utm_source=site-institucional&utm_medium=aba-delivery&utm_campaign=aba-delivery-site-plk';

  const onPressBtn = link => {
    window.location = link;
  };

  return (
    <>
      {(loadingRemoteConfig || loading) && <LoadingPop />}
      {!loadingRemoteConfig && !loading && (
        <>
          <Inapp
            isOpen={isOpenInnapReview}
            onClose={() => setIsOpenInnapReview(false)}
          />
          <Container banner={banner} id="toScrollTop" isMobile={isMobile}>
            {/* {windowDimensions.width >= parseInt(sizes.notebook, 10) && (
              <Atoms.Carousel
                autoPlay={false}
                defaultActive={0}
                Keys={[0]}
                handleHomeButtons
                Components={CarouselContainer()}
                showCarrouselButtons
                showArrows
              />
            )} */}
            {!isMobile && (
              <SliderCarousel
                settings={CarouselHomeSettings}
                dataBanners={desktopBanners}
                btnLinkTo={btnLinkToDelivery}
              />
            )}
            <Header>
              <HamburguerButton onClick={onPressMenu}>
                <img src={HamburguerIcon} alt="Botão Hamburguer" />
              </HamburguerButton>
            </Header>
            <ButtonGroup>
              <button
                type="button"
                id="menu"
                style={{
                  backgroundColor: abConfig?.cupons_button?.background_color,
                }}
                onClick={onPressCoupons}
              >
                <img src={MenuIcon} alt="Coupons" />
                <label style={{ color: abConfig?.cupons_button?.title_color }}>
                  {abConfig.cupons_button?.title}
                </label>
              </button>
              <button
                type="button"
                id="cardapio"
                style={{
                  backgroundColor: abConfig?.menu_button?.background_color,
                }}
                onClick={onPressCardapio}
              >
                <img src={CardapioIcon} alt="Cardapio" />
                <label style={{ color: abConfig?.menu_button?.title_color }}>
                  {abConfig.menu_button?.title}
                </label>
              </button>
              <button
                type="button"
                id="delivery"
                onClick={onPressDelivery}
                style={{
                  backgroundColor: abConfig?.delivery_button?.background_color,
                }}
              >
                <img src={DeliveryIcon} alt="Delivery Icon" />
                <label
                  style={{ color: abConfig?.delivery_button?.title_color }}
                >
                  {abConfig?.delivery_button?.title}
                </label>
              </button>
            </ButtonGroup>

            <ModalResponsive
              open={openModal}
              onClose={() => setOpenModal(false)}
              maxModalHeight={350}
            >
              <ModalContainer>
                <h3 className="title">
                  Escolha o parceiro e peça seu Popeyes de onde estiver
                </h3>
                <div className="partners">
                  <a
                    href="https://ifoodbr.onelink.me/F4X4/popeyes"
                    id="ifood"
                    onClick={() => setDeliveryUrl('ifood')}
                  >
                    <img className="logo" src={ifood} alt="iFood" />
                    <img
                      className="link"
                      src={partnerLink}
                      alt="Redirect to Partner"
                    />
                  </a>
                  <a
                    href="https://www.rappi.com.br/restaurantes/popeyes"
                    id="rappi"
                    onClick={() => setDeliveryUrl('rappi')}
                  >
                    <img className="logo" src={rappi} alt="Rappi" />
                    <img
                      className="link"
                      src={partnerLink}
                      alt="Redirect to Partner"
                    />
                  </a>
                </div>
              </ModalContainer>
            </ModalResponsive>
          </Container>
        </>
      )}
    </>
  );
}

export default Home;
