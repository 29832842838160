/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/Button';
import Input from 'components/Input';
import ModalResponsive from 'components/ModalResponsive';
import TitleSection from 'components/TitleSection';
import { cpf } from 'cpf-cnpj-validator';

// Components

// Services, Utils and Hooks
import { useAuth } from 'hooks/Auth';
import { api } from 'services';
import firebase from 'services/firebase';
import hideKeyboard from 'utils/hideKeyboard';

import { Container, Form, ModalContent } from './styles';
import schema from './validation';

// Styles

function Validated() {
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      cpf: '',
    },
  });
  const { formState } = methods;

  const { updateUser } = useAuth();
  const history = useHistory();
  const [isNewUser, setIsNewUser] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  // validação
  const [isCpfError, setIsCpfError] = useState(false);
  const [isCpfCompleted, setIsCpfCompleted] = useState(false);

  const ToDoSignUp = () => {
    history.push('/onboarding?validated=true');
  };

  const normalizeCpf = value => {
    return cpf.format(value);
  };

  const checkCpf = async value => {
    if (value.length === 14) {
      const cpfUser = String(value.replace(/\D+/g, ''));
      // Formato CPF

      if (!cpf.isValid(value.replace(/\D+/g, ''))) {
        setIsCpfError(true);
      }

      // Existe CPF
      try {
        await api.post('/customer/v0.1/cpf/validation', {
          cpf: cpfUser,
        });

        // cpf existe
        setIsCpfError(false);
      } catch (err) {
        if (err?.response?.status === 422) {
          // erro no formato
          setIsCpfError(true);
        } else if (err?.response?.status === 404) {
          // cpf ainda não cadastrado
          setIsCpfError(false);
        }
      }
    }
  };

  const onSubmit = async data => {
    setLoadingButton(true);
    const newCpf = String(data.cpf.replace(/\D+/g, ''));

    try {
      const responseCpf = await api.post(
        '/customer/v0.1/cpfemail/accountrecovery',
        {
          cpf: newCpf,
        },
      );

      updateUser({
        cpf: newCpf,
        email: responseCpf.data.email,
      });

      firebase.analytics().logEvent('cadastro_continuar', {
        etapa_cadastro: 'recuperar_cpf_pwa',
      });

      history.push('/verificar-recuperacao');
    } catch (err) {
      if (!err.response) {
        console.log(err); // api executa mas nao tras response
      } else if (err.response.status === 404) {
        firebase.analytics().logEvent('modal_erro', {
          tipo_erro: 'cpf_cadastro_novo',
        });
        setIsNewUser(true);
      }
    }

    methods.reset();
    setLoadingButton(false);
  };

  return (
    <>
      <Container>
        <TitleSection
          title="Informe seu CPF"
          subtitle="Informe seu CPF para validar sua conta. Enviaremos um e-mail para o endereço cadastrado"
        />

        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Input
              text="CPF"
              name="cpf"
              type="tel"
              htmlFor="cpf"
              maxLength="14"
              required
              onChange={e => {
                e.target.value = normalizeCpf(e.target.value);
                checkCpf(e.target.value);
                if (e.target.value.length === 14) {
                  setIsCpfCompleted(true);
                  hideKeyboard();
                } else {
                  setIsCpfCompleted(false);
                }
              }}
              isValid={!isCpfError}
              showIcon={isCpfCompleted}
              showError={isCpfError}
              style={
                isCpfCompleted && isCpfError
                  ? { backgroundColor: 'var(--color-input-error)' }
                  : { backgroundColor: 'var(--color-input)' }
              }
            />

            <span />

            <Button type="submit" disabled={!formState.isValid || isCpfError}>
              {loadingButton ? 'Carregando...' : 'Continuar'}
            </Button>
          </Form>
        </FormProvider>
      </Container>

      <ModalResponsive
        maxModalHeight={350}
        open={isNewUser}
        sizeModal="middle"
        onClose={() => setIsNewUser(false)}
      >
        <ModalContent>
          <h3 className="title">Parece que você é novo por aqui</h3>
          <p className="subtitle">
            Não encontramos os seus dados. Aproveite e faça cadastro.
          </p>
          <Button onClick={ToDoSignUp}>Fazer cadastro</Button>
        </ModalContent>
      </ModalResponsive>
    </>
  );
}

export default Validated;
