import BackgroundLighter from '../../assets/images/home/bg-home-area-delivery.png';
import ifood from '../../assets/images/home/cta-delivery-ifood.svg';
import popeyesDelivery from '../../assets/images/home/cta-delivery-popeyes.svg';
import rappi from '../../assets/images/home/cta-delivery-rappi.svg';
import { setDeliveryUrl } from '../../utils/deliveryUrl';
import { ContainerDelivery, Content } from './styles';

function DeliveryComponent() {
  return (
    <ContainerDelivery banner={BackgroundLighter} id="delivery">
      <Content>
        <p>Bateu a fome? Vai de</p>
        <h1 id="DeliveryComponent">Delivery</h1>

        <div className="partners">
          <a
            href="https://ifoodbr.onelink.me/F4X4/popeyes"
            id="ifood"
            onClick={() => setDeliveryUrl('ifood')}
          >
            <img className="logo" src={ifood} alt="iFood" />
          </a>

          <a
            href="https://www.rappi.com.br/restaurantes/900277554"
            id="rappi"
            onClick={() => setDeliveryUrl('rappi')}
          >
            <img className="logo" src={rappi} alt="Rappi" />
          </a>
        </div>
      </Content>
    </ContainerDelivery>
  );
}

export default DeliveryComponent;
