import { useEffect, useRef, useState } from 'react';

import FooterComponent from 'components/FooterComponent';
import HeaderComponent from 'components/HeaderComponent';
import { Cardapio } from 'components/Pages/Cardapio';
import { useHeader } from 'hooks/HeaderContext';

import * as S from './styles';

export default function MenuCardapio() {
  const containerRef = useRef();
  const { handleRef } = useHeader();

  const [urlBack, setUrlBack] = useState('/');

  useEffect(() => {
    const params = new URL(window.location as unknown as string).searchParams;
    const href = params.get('href');
    if (href) setUrlBack(href);

    handleRef(containerRef);
  }, []);

  return (
    <S.Container ref={containerRef}>
      {/* <CardapioContextProvider> */}
      {/* <CouponsProvider> */}
      <HeaderComponent hideHeaderMobile title="Cardápio" href={urlBack} />
      <Cardapio containerRef={containerRef} />
      {/* </CouponsProvider> */}
      {/* </CardapioContextProvider> */}
      <FooterComponent />
    </S.Container>
  );
}
