import styled from 'styled-components';
import {
  font as fontGlobal,
  popeyesPrimaryColors,
  popeyesSecondaryColors,
  spacings,
} from 'styles';
import sizes from 'utils/screenSizes';

import * as T from './interfaces';

export const Container = styled.div<T.IStyleCheckbox>`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: left;
  flex-direction: row;

  ${props => props.margin === 'mediumMargin' && 'margin-bottom: 16px;'}
`;

export const CheckboxName = styled.h2`
  font-family: ${fontGlobal.family.ChickenText};
  font-size: 16px;
  color: #757575;

  @media (min-width: ${sizes.desktop}) {
    font-size: ${fontGlobal.sizes.textMedium};
  }
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .checkmark {
    display: block;
    width: ${spacings.space05};
    height: ${spacings.space05};
    background-color: ${popeyesSecondaryColors.light};
    border-radius: 50px;
    border: 1px solid #bdbdbd;
    transition: background-color 0.2s;
    cursor: pointer;
  }
`;

export const Input = styled.input`
  :checked ~ .checkmark {
    background-color: ${popeyesPrimaryColors.primary};
    border: 1px solid ${popeyesSecondaryColors.light};
  }

  :checked ~ .checkmark::after {
    opacity: 1;
  }
`;
