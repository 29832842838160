import { FormProvider, useForm } from 'react-hook-form';

// eslint-disable-next-line import-helpers/order-imports
import { yupResolver } from '@hookform/resolvers/yup';

// Components
import Button from 'components/Button';
import Countdown from 'components/Countdown';
import InputCode from 'components/InputCode';
import SafetySection from 'components/SafetySection';
import TitleSection from 'components/TitleSection';
import PropTypes from 'prop-types';

import {
  ButtonsContainer,
  Container,
  CountdownContainer,
  Form,
} from './styles';
import schema from './validation';

function VerifyToken({
  title,
  subTitle,
  counter,
  updateCounter,
  handleFinishCountdow,
  arrayButton,
  onSubmit,
}) {
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      token: '',
    },
  });
  const { formState } = methods;

  const onHandleSubmit = async dataForm => {
    let tokens = '';
    tokens += dataForm.name1;
    tokens += dataForm.name2;
    tokens += dataForm.name3;
    tokens += dataForm.name4;

    tokens = String(tokens);
    onSubmit(tokens);
    methods.reset();
  };

  return (
    <Container>
      <TitleSection title={title} subtitle={subTitle} />
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onHandleSubmit)}>
          <CountdownContainer>
            <InputCode
              name1="name1"
              name2="name2"
              name3="name3"
              name4="name4"
            />
            <Countdown
              counter={counter}
              onChange={newCounter => updateCounter(newCounter)}
              onFinish={handleFinishCountdow}
            />
          </CountdownContainer>
          <ButtonsContainer>
            <SafetySection />

            {arrayButton.map(({ title: buttonTitle, ...props }, index) => (
              <Button
                type={index === 0 ? 'submit' : 'button'}
                size={index === 0 ? 'M' : 'S'}
                variant={index === 0 ? 'primary' : 'secondary'}
                key={props.title}
                {...props}
                disabled={index === 0 ? !formState.isValid : props.disabled}
              >
                {buttonTitle}
              </Button>
            ))}
          </ButtonsContainer>
        </Form>
      </FormProvider>
    </Container>
  );
}

VerifyToken.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.node.isRequired,

  counter: PropTypes.number.isRequired,
  updateCounter: PropTypes.func.isRequired,
  handleFinishCountdow: PropTypes.func,

  onSubmit: PropTypes.func.isRequired,
  arrayButton: PropTypes.arrayOf(PropTypes.any).isRequired,
};

VerifyToken.defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleFinishCountdow: () => {},
};

export default VerifyToken;
