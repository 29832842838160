import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';

import { InputContainer } from './styles';

const InputCode = props => {
  const methods = useFormContext();
  const { name1, name2, name3, name4 } = props;
  const input1 = useRef();
  const input2 = useRef();
  const input3 = useRef();
  const input4 = useRef();

  useEffect(() => {
    input1.current.focus();
  }, []);

  const hideVirtualKeyboard = () => {
    if (
      document.activeElement &&
      document.activeElement.blur &&
      typeof document.activeElement.blur === 'function'
    ) {
      document.activeElement.blur();
    }
  };

  return (
    <InputContainer>
      <input
        type="tel"
        maxLength="1"
        name={name1}
        ref={e => {
          methods.register(e);
          input1.current = e;
        }}
        onChange={e => {
          if (e.target.value.length === 1) {
            input2.current.focus();
            input2.current.select();
          }
        }}
        onClick={() => {
          input1.current.select();
        }}
      />
      <input
        type="tel"
        maxLength="1"
        name={name2}
        ref={e => {
          methods.register(e);
          input2.current = e;
        }}
        onChange={e => {
          if (e.target.value.length === 1) {
            input3.current.focus();
            input3.current.select();
          } else if (e.target.value.length === 0) {
            input1.current.focus();
            input1.current.select();
          }
        }}
        onKeyDown={e => {
          if (e.key === 'Backspace' && !methods.getValues().name2) {
            e.preventDefault();
            input1.current.focus();
          }
        }}
        onClick={() => {
          input2.current.select();
        }}
      />
      <input
        type="tel"
        maxLength="1"
        name={name3}
        ref={e => {
          methods.register(e);
          input3.current = e;
        }}
        onChange={e => {
          if (e.target.value.length === 1) {
            input4.current.focus();
            input4.current.select();
          } else if (e.target.value.length === 0) {
            input2.current.focus();
            input2.current.select();
          }
        }}
        onKeyDown={e => {
          if (e.key === 'Backspace' && !methods.getValues().name3) {
            e.preventDefault();
            input2.current.focus();
          }
        }}
        onClick={() => {
          input3.current.select();
        }}
      />
      <input
        type="tel"
        maxLength="1"
        name={name4}
        ref={e => {
          methods.register(e);
          input4.current = e;
        }}
        onChange={e => {
          if (e.target.value.length === 1) {
            hideVirtualKeyboard();
          } else if (e.target.value.length === 0) {
            input3.current.focus();
            input3.current.select();
          }
        }}
        onKeyDown={e => {
          if (e.key === 'Backspace' && !methods.getValues().name4) {
            e.preventDefault();
            input3.current.focus();
          }
        }}
        onClick={() => {
          input4.current.select();
        }}
      />
    </InputContainer>
  );
};

InputCode.propTypes = {
  name1: PropTypes.string,
  name2: PropTypes.string,
  name3: PropTypes.string,
  name4: PropTypes.string,
};

export default InputCode;
