import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Hook
import { useAuth } from 'hooks/Auth';
import firebase from 'services/firebase';

// Utils
import { openPrivallyModal } from 'utils/privallyUtils';
import { getWindowDimensions } from 'utils/windowDimensions';

// Screens
import * as Screens from './screens';

// Styles
import { Container, ItemButton } from './styles';

export default function RightsAndRequestsContainer() {
  const { width } = getWindowDimensions();

  const history = useHistory();
  const { user } = useAuth();

  const [urlBack, setUrlBack] = useState('/perfil');
  const [openPage, setOpenPage] = useState('');

  const onBackButtonEvent = e => {
    e.preventDefault();
    if (openPage !== '') {
      setOpenPage('');
    } else {
      history.push(urlBack);
    }
  };

  useEffect(() => {
    const params = new URL(window.location).searchParams;
    const href = params.get('href');
    if (href) setUrlBack(href);

    const homeHref = href === '/';
    const permissionHref = href === '/permissoes-e-privacidade';

    if (width >= 1200) {
      const url = new URL(window.location);
      if (user?.signed && !permissionHref) {
        url.searchParams.append('href', '/permissoes-e-privacidade');
      }
      if (!user?.signed && !homeHref) {
        url.searchParams.append('href', '/');
      }

      if (!homeHref) window.history.pushState({}, '', url);
    }

    const open = params.get('open');
    if (open) setOpenPage(open);

    window.addEventListener('popstate', onBackButtonEvent);
    return () => window.removeEventListener('popstate', onBackButtonEvent);
  }, []);

  const backMainPage = () => setOpenPage('');

  const handleFirebaseAnalitycs = opcao => {
    firebase.analytics().logEvent('direitos_lgpd', {
      opcao,
    });
  };

  return (
    <>
      {openPage === '' && (
        <>
          <Container>
            <div className="first-group">
              <ItemButton
                onClick={() => {
                  handleFirebaseAnalitycs('termos_condicoes');
                  history.push({
                    pathname: '/termos-de-uso',
                    search: `?${new URLSearchParams({
                      href: '/direitos-e-solicitacoes',
                    }).toString()}`,
                  });
                }}
              >
                Termos de condição de uso
              </ItemButton>
              <ItemButton
                onClick={() => {
                  handleFirebaseAnalitycs('politica_privacidade');
                  history.push({
                    pathname: '/politica-de-privacidades',
                    search: `?${new URLSearchParams({
                      href: '/direitos-e-solicitacoes',
                    }).toString()}`,
                  });
                }}
              >
                Diretrizes de privacidade de dados
              </ItemButton>
            </div>

            <div className="second-group">
              {user?.signed && (
                <>
                  <ItemButton
                    onClick={() => {
                      handleFirebaseAnalitycs('lgpd_meusdados');
                      history.push('/direitos-e-solicitacoes/uso-de-dados');
                    }}
                  >
                    O que o Popeyes pode fazer com meus dados?
                  </ItemButton>
                  <ItemButton
                    onClick={() => {
                      handleFirebaseAnalitycs('lgpd_dadosutilizados');
                      history.push(
                        '/direitos-e-solicitacoes/o-que-popeyes-utiliza',
                      );
                    }}
                  >
                    Quais dos meus dados o Popeyes utiliza?
                  </ItemButton>
                  <ItemButton
                    onClick={() => {
                      handleFirebaseAnalitycs('lgpd_agentedados');
                      history.push({
                        pathname: '/enviar-email',
                        search: `?${new URLSearchParams({
                          href: `/direitos-e-solicitacoes?href=${urlBack}`,
                          subject:
                            'Falar com a gente sobre privacidade de dados',
                        }).toString()}`,
                      });
                    }}
                  >
                    Falar com a gente sobre privacidade de dados
                  </ItemButton>
                </>
              )}
            </div>

            {user?.signed && (
              <div className="delete-account">
                <ItemButton
                  onClick={() =>
                    history.push({
                      pathname: '/enviar-email',
                      search: `?${new URLSearchParams({
                        href: `/direitos-e-solicitacoes`,
                        isDelete: true,
                        subject: 'Excluir minha conta',
                      }).toString()}`,
                    })
                  }
                >
                  Excluir minha conta
                </ItemButton>
              </div>
            )}
          </Container>
        </>
      )}

      {openPage === 'ExcludeAccount' && (
        <Screens.ExcludeAccount onBack={backMainPage} />
      )}
    </>
  );
}
