import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';

const HeaderContext = createContext();
const HeaderContextProvider = ({ children }) => {
  const [currentScrollTop, setCurrentScrollTop] = useState(0);
  const [showHeader, setShowHeader] = useState(true);
  const [containerRef, setContainerRef] = useState(null);

  const handleScroll = useCallback(
    e => {
      const currentScroll = currentScrollTop;
      if (e.target.scrollTop >= currentScroll) {
        // down
        setShowHeader(false);
      } else {
        // up
        setShowHeader(true);
      }
      setCurrentScrollTop(e.target.scrollTop);
    },
    [currentScrollTop, setShowHeader, setCurrentScrollTop],
  );

  const handleRef = ref => {
    setContainerRef(ref);
  };
  useEffect(() => {
    if (containerRef?.current?.scrollTop === 0) {
      setShowHeader(true);
    }

    if (containerRef && containerRef.current) {
      containerRef.current.onscroll = handleScroll;
    }
  }, [containerRef, handleScroll]);

  return (
    <HeaderContext.Provider value={{ showHeader, handleRef, currentScrollTop }}>
      {children}
    </HeaderContext.Provider>
  );
};

const useHeader = () => {
  const context = useContext(HeaderContext);

  if (!context) {
    throw new Error('useHeader must be within HeaderContextProvider');
  }

  return context;
};

export { HeaderContextProvider, useHeader };
