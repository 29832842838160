import React from 'react';
import styled from 'styled-components';
import sizes from 'utils/screenSizes';
import * as T from './interfaces';

import { font } from 'styles';

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  padding: 10px 20px 24px;

  overflow-x: scroll;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
    width: 0px;
    height: 0px;
  }

  box-shadow: rgb(0 0 0 / 10%) 0px 4px 4px;
  @media only all and (min-width: ${sizes.notebook}) {
    box-shadow: none;
    justify-content: center;
  }
`;

export const ModalContent = styled.div`
  padding: 0px 24px;
`;

export const Title = styled.h3`
  font-family: ${font.family.ChickenHeadline};
  font-size: ${font.sizes.textLarge};
  margin-top: 16px;
  margin-bottom: 32px;
`;

export const CategoryRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  justify-content: space-between;

  .checkbox-wrapper {
    .checkmark {
      display: block;
      width: 23px;
      height: 23px;
      background-color: #fff;
      border-radius: 4px;
      border: 2px solid #ffb166;
      position: relative;
      transition: background-color 0.2s;
    }

    .checkmark::after {
      content: '';
      position: absolute;
      width: 5px;
      height: 10px;
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%) rotateZ(40deg);
      opacity: 0;
      transition: opacity 0.2s;
    }
  }
`;

export const CategoryName = styled.h2`
  font-family: 'ChickenSansRegular';
  font-size: ${font.sizes.textMedium};
  color: rgba(0, 0, 0, 0.4);
  @media (min-width: ${sizes.desktop}) {
    font-size: ${font.sizes.heading06};
  }
`;

export const CheckboxInput = styled.input`
  :checked ~ .checkmark {
    background-color: var(--color-popeyes-orange);
    border: 2px solid var(--color-popeyes-orange);
  }

  :checked ~ .checkmark::after {
    opacity: 1;
  }
`;

export const CategoryTextContainer = styled.div`
  @media only all and (min-width: ${sizes.notebook}) {
    max-width: 10rem;
  }
  @media only all and (min-width: ${sizes.desktop}) {
    max-width: 12rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  z-index: 2;
`;

export const CategorySelector = styled.button<T.IFilterStyles>`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 114px !important;
  font-family: 'ChickenSansBold';
  color: ${props => (props.isSelected ? 'var(--color-salt-white)' : '#303030')};
  font-size: ${font.sizes.textXSmall};
  padding: 8px 10px;
  margin-left: 4px;
  margin-right: 4px;
  background: ${props =>
    props.isSelected ? 'var(--color-burnt-orange)' : 'var(--color-salt-white)'};
  border: ${props =>
    props.isSelected
      ? '1px solid var(--color-burnt-orange)'
      : '1px solid rgba(0, 0, 0, 0.1)'};
  border-radius: 4px;

  &:focus {
    outline: none;
  }
  @media only all and (min-width: ${sizes.notebook}) {
    z-index: 2;
    font-size: ${font.sizes.textMedium};
    height: 4rem;
    width: 14rem;
  }
  @media only all and (min-width: ${sizes.desktop}) {
    font-size: ${font.sizes.textLarge};
    height: 5rem;
    width: 16rem;
  }

  cursor: pointer;
`;

export const Icon = styled.img`
  transform: rotate(270deg);
  pointer-events: none;
`;

export const CountText = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  line-height: 16px;
  border-radius: 50%;
  text-align: center;
  margin-left: 8px;
  font-size: ${font.sizes.textXSmall};
  background-color: white;
  color: var(--color-burnt-orange);
  font-family: 'ChickenSansBold' !important;
`;

export const CategoryDropDownContainer = styled.div<T.IFilterStyles>`
  display: none;
  position: absolute;
  z-index: 3;
  width: 232px;
  ${props =>
    props.isActive &&
    'display: flex; flex-direction: column; align-items: center;'}
  @media (min-width: ${sizes.desktop}) {
    width: 264px;
  }
`;

export const CategoryDropDown = styled.div`
  top: 65px;
  position: relative;

  padding: 10px;
  width: 300px;

  border-radius: 4px;

  border: solid 0.7px #e5e5e5;
  background: #fff;

  button {
    width: 80%;
    margin: 32px auto 16px;
  }
  @media (min-width: ${sizes.desktop}) {
    top: 80px;
  }
`;

export const ArrowUp = styled.div`
  position: relative;
  top: 65px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #e5e5e5;
  @media (min-width: ${sizes.desktop}) {
    top: 80px;
  }
`;

export const Overlay = styled.div<T.IFilterStyles>`
  display: none;
  ${props => props.isActive && 'display: block;'}
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`;

export const Button = styled.button`
  height: 48px;
  width: 100%;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  font-size: ${font.sizes.textLarge};
  background-color: var(--color-burnt-orange);
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-top: 16px;

  :disabled {
    opacity: 40%;
  }
`;
