import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  margin: 0 auto;

  padding: 0px 24px;
  padding-top: var(--size-header-height);
  padding-bottom: 40px;

  max-width: var(--size-max-width-container);

  font-family: 'ChickenSansRegular';
  font-style: normal;
  font-weight: normal;

  h1 {
    margin-top: 40px;

    font-size: 24px;
    line-height: 29px;
  }

  > p {
    margin-top: 3px;
    font-size: 18px;
    line-height: 22px;

    color: var(--color-text-switch);

    &.bold {
      margin-top: 40px;
      font-weight: bold;
    }

    ul li ::marker {
      font-size: 10px;
    }
  }

  form {
    input,
    button,
    textarea {
      margin-top: 12px;
    }

    textarea {
      margin-bottom: 40px;
    }

    p {
      font-size: 20px;
      margin-top: 50px;
    }
  }

  @media (min-width: ${sizes.notebook}) {
    form {
      button {
        max-width: 312px;
        margin: 12px auto 0px;
      }
    }
  }
`;

export const ModalContent = styled.div`
  padding: 32px 24px 0px 24px;

  font-family: 'ChickenSansRegular';
  font-style: normal;
  font-weight: normal;

  p {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }

  .text {
    margin: 30px 0px;

    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: rgba(0, 0, 0, 0.4);
  }
`;
