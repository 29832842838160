import styled, { css } from 'styled-components';
import {
  font as fontGlobal,
  popeyesPrimaryColors,
  popeyesSecondaryColors,
  popeyesNeutral,
  spacings,
  transitions,
} from 'styles';
import sizes from 'utils/screenSizes';

import * as T from './interfaces';

export const Container = styled.div<T.IStyleCheckbox>`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: left;
  flex-direction: row;

  ${props => props.margin === 'mediumMargin' && 'margin-bottom: 16px;'}
`;

export const CheckboxName = styled.h2`
  font-family: ${fontGlobal.family.ChickenText};
  font-size: ${fontGlobal.sizes.textMedium};
  color: rgba(0, 0, 0, 0.4);
  @media (min-width: ${sizes.desktop}) {
    font-size: ${fontGlobal.sizes.heading06};
  }
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .checkmark {
    display: block;
    width: ${spacings.space06};
    height: ${spacings.space06};
    background-color: ${popeyesSecondaryColors.light};
    border-radius: 6px;
    border: 2px solid ${popeyesPrimaryColors.primary};
    position: relative;
    transition: background-color 0.2s;
  }

  .checkmark::after {
    content: '';
    position: absolute;
    width: 5px;
    height: 10px;
    border-right: ${spacings.space00} solid ${popeyesSecondaryColors.light};
    border-bottom: ${spacings.space00} solid ${popeyesSecondaryColors.light};
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%) rotateZ(40deg);
    opacity: 0;
    transition: ${transitions.opacity02};
  }
`;

export const Input = styled.input`
  :checked ~ .checkmark {
    background-color: ${popeyesPrimaryColors.primary};
  }

  :checked ~ .checkmark::after {
    opacity: 1;
  }
`;
