import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Filter } from './Filter';

import * as I from './interfaces';

export const Container: React.FC<I.IFilter> = ({
  filtersItem,
  onChangeFilter,
  openCategories,
  onToogleCategories,
  id,
  ...rest
}) => {
  const [dropDown, setDropDown] = useState(false);

  const history = useHistory();

  const selectedCategoriesCount = useMemo(() => {
    const count = filtersItem.filter(category => category.selected).length;
    return count;
  }, [filtersItem]);

  const onSelectAll = useCallback(() => {
    let newCategories = [];
    if (selectedCategoriesCount === 0) {
      newCategories = filtersItem.map(category => {
        category.selected = true;
        return category;
      });
    } else {
      newCategories = filtersItem.map(category => {
        category.selected = false;
        return category;
      });
    }

    onChangeFilter(newCategories);
  }, [filtersItem]);

  const handleChangeCategories = (id: number) => {
    const newCategories = [...filtersItem];
    newCategories[id].selected = !filtersItem[id].selected;

    onChangeFilter(newCategories);
  };

  const handleDropDown = () => {
    if (dropDown) {
      setDropDown(false);
    } else {
      setDropDown(true);
    }
  };

  return (
    <Filter
      {...rest}
      filtersItem={filtersItem}
      id={id}
      data-testid={`Filter@${id}`}
      openCategories={openCategories}
      onChangeFilter={onChangeFilter}
      onSelectAll={onSelectAll}
      handleChangeCategories={handleChangeCategories}
      selectedCategoriesCount={selectedCategoriesCount}
      handleDropDown={handleDropDown}
      dropDown={dropDown}
      onToogleCategories={onToogleCategories}
    />
  );
};
