import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${sizes.notebook}) {
    height: 100%;
  }
  width: 100%;
`;

export const Form = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  height: 100%;
  width: 100%;

  > div:first-child {
    margin-top: 60px;
  }

  .safetyContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    width: 100%;
    margin-bottom: 17px;

    p {
      color: #000000;
      opacity: 80%;
      text-align: center;
      font-size: 12px;
      margin-bottom: 17px;
    }
  }

  @media (min-width: ${sizes.notebook}) {
    width: 80%;
    justify-content: flex-start;

    .safetyContainer {
      button {
        margin-bottom: 20px;
      }
    }
  }
`;

export const ModalContent = styled.div`
  padding: 24px 24px 0px 24px;

  font-family: 'ChickenSansRegular';
  font-style: normal;
  font-weight: normal;

  p {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }

  .text {
    margin: 30px 0px;

    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: rgba(0, 0, 0, 0.4);
  }

  a {
    text-decoration: underline;
    color: var(--color-burnt-orange);

    margin-left: 5px;
  }
`;
