import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  display: flex;
  height: auto;
  min-height: 100%;
  width: 100%;
  max-width: var(--size-max-width-container);
  margin: 0 auto;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  background-color: #ffff;
  position: relative;

  padding: 0px 24px 24px 24px;
  padding-top: var(--size-header-height);

  overflow: none;
  z-index: 0;
  transition: max-height 0.3s ease-out;
`;

export const Logo = styled.img`
  width: 200px;
  margin-bottom: 20px;
`;

export const ContentPolicies = styled.div`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  margin-top: 18px;
  z-index: 1;

  h2 {
    margin: 0 24px;
    color: var(--color-text);
    font-family: 'ChickenSansBold';
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-bottom: 27px;
  }

  h3 {
    font-family: 'ChickenSansBold';
    color: var(--color-text);
    font-size: 17px;
    font-weight: 400;
    margin: 30px 9px 2.5px 24px;
  }

  h4 {
    font-family: 'ChickenSansBold';
    color: var(--color-text);
    font-size: 17px;
    font-weight: 400;
    margin: 30px 9px 2.5px 24px;
  }

  strong {
    font-family: 'ChickenSansBold';
    color: var(--color-text);
    font-size: 16px;
    font-weight: 400;
  }

  p {
    font-family: 'ChickenSans';
    color: var(--color-text);
    font-size: 16px;
    font-weight: 400;
    margin: 10px 9px 10px 24px;
    text-align: justify;

    a {
      text-decoration: underline;
      font-size: 16px;
    }
  }

  table {
    border-collapse: collapse;
    border: 1px solid #000;
    margin: 10px 9px 10px 24px;

    caption {
      text-align: left;
      font-family: 'ChickenSansBold';
      color: var(--color-text);
      font-size: 17px;
      font-weight: 400;
      margin: 1rem 0;
    }

    tr {
      border: 1px solid #000;
      td {
        border-right: 1px solid #000;
        padding: 10px;
        font-family: 'ChickenSansRegular';
        color: var(--color-text);
        font-size: 16px;
        font-weight: 400;
        text-align: justify;
      }
    }
  }

  ol {
    margin: 30px 9px 30px 24px;
    padding-left: 30px;
    li {
      font-family: 'ChickenSansRegular' !important;
      color: var(--color-text);
      font-size: 16px;
      font-weight: 400;
      text-align: justify;
      margin-bottom: 10px;
    }
  }

  ul {
    margin: 30px 9px 30px 24px;
    padding-left: 30px;
    li {
      font-family: 'ChickenSansRegular' !important;
      color: var(--color-text);
      font-size: 16px;
      font-weight: 400;
      text-align: justify;
      margin-bottom: 20px;
    }
  }

  .button {
    margin: 40px 0px;

    @media (min-width: ${sizes.notebook}) {
      button {
        margin: 0px auto;
        width: 312px;
      }
    }
  }

  @media (min-width: ${sizes.notebook}) {
    margin-top: 40px;
  }
`;
