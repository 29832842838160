import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import calendarIcon from 'assets/images/icons/calendar.svg';

// Components
import SignOutIcon from 'assets/images/icons/logout.svg';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import InputNumber from 'components/InputNumber';
import SafetySection from 'components/SafetySection';
import { cpf } from 'cpf-cnpj-validator';

// Hooks
import { useAuth } from 'hooks/Auth';
import { api } from 'services';

import ModalInvalid from './ModalInvalid';
// import firebase from 'services/firebase';

import ModalRegistred from './ModalRegistred';
import ModalVerify from './ModalVerify';

// Styles
import {
  Container,
  Form,
  GenderContent,
  GenderItem,
  GenderNoReply,
  SignOut,
  SaveSection,
} from './styles';
import schema from './validation';

const GENDER_ENUM = {
  MALE: 1,
  FEMALE: 2,
  NOT_BINARY: 3,
  NO_REPLY: 4,
};

const formatDate = stringDate => {
  if (stringDate === '' || !stringDate) return '';
  const splitDate = stringDate.split('-');
  const year = splitDate[0];
  const month = splitDate[1];
  const day = splitDate[2];
  return `${day}/${month}/${year}`;
};

export default function MyProfile() {
  const history = useHistory();

  const [openVerifyModal, setOpenVerifyModal] = useState(false);
  const [openInvalidModal, setOpenInvalidModal] = useState(false);
  const [openRegistredModal, setOpenRegistredModal] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isEmail, setIsEmail] = useState(false);

  const {
    user,
    signOut,
    updateUser,
    customerId,
    preserveProfileData,
    setPreserveProfileData,
  } = useAuth();
  const fieldDDD = user?.cellNumber ? user.cellNumber.substr(0, 2) : '';
  const fieldNumber = user?.cellNumber ? user.cellNumber.substr(2, 9) : '';

  const [gender, setGender] = useState(user?.gender || '');
  const [errorEmail, setErrorEmail] = useState();
  const [savedUser, setSavedUser] = useState({ ...user });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ddd: fieldDDD === '[o' ? '' : fieldDDD,
      cellNumber: fieldNumber === 'bject Obj' ? '' : fieldNumber,
      name: user.name,
      lastname: user.lastname,
      email: user?.email || '',
      cpf: user.cpf ? cpf.format(user.cpf) : '',
      birthday: formatDate(user.birthday),
    },
  });
  const { watch, reset } = methods;

  const getUserProfile = useCallback(async () => {
    try {
      const responseUser = await api.get('/customer/v0.1/profile', {
        params: { customer_id: customerId },
      });

      const auxBirthday = responseUser.data.customer_birthday;
      const parsedUserProfile = {
        birthday: auxBirthday,
        number: responseUser.data.customer_contact,
        email: responseUser.data.customer_email,
        gender: responseUser.data.customer_gender,
        lastname: responseUser.data.customer_lastname,
        name: responseUser.data.customer_name,
        cpf: cpf.format(responseUser.data.customer_cpf),
      };
      if (!preserveProfileData) {
        await updateUser(parsedUserProfile);
        const ddd = responseUser.data.customer_contact
          ? responseUser.data.customer_contact.substr(0, 2)
          : '';
        const number = responseUser.data.customer_contact
          ? responseUser.data.customer_contact.substr(2, 9)
          : '';
        reset({
          ddd: ddd === '[o' ? '' : ddd,
          cellNumber: number === 'bject Obj' ? '' : number,
          name: responseUser.data.customer_name,
          lastname: responseUser.data.customer_lastname,
          email: responseUser.data.customer_email || '',
          cpf: responseUser.data.customer_cpf
            ? cpf.format(responseUser.data.customer_cpf)
            : '',
          birthday: formatDate(auxBirthday),
        });
      } else setPreserveProfileData(false);

      parsedUserProfile.cellNumber = responseUser.data.customer_contact;
      parsedUserProfile.birthday = auxBirthday
        ? formatDate(auxBirthday)
        : auxBirthday;
      setSavedUser(parsedUserProfile);
    } catch (error) {
      // error
    }
  }, [customerId, preserveProfileData, updateUser]);

  useEffect(() => {
    getUserProfile();
  }, []);

  const onSubmit = async formData => {
    setLoadingButton(true);
    try {
      const parsedBirthday = formData.birthday.split('/').reverse().join('-');
      await api.put('/customer/v0.1/profile', {
        cpf: cpf.strip(user.cpf),
        customer_name: formData.name,
        last_name: formData.lastname,
        gender_type_id: gender,
        birthday: parsedBirthday,
      });

      updateUser({
        name: formData.name,
        lastname: formData.lastname,
        gender,
        birthday: parsedBirthday,
      });

      setPreserveProfileData(false);

      history.push({
        pathname: '/sucesso',
        search: `?${new URLSearchParams({
          href: `/meu-perfil?${new URLSearchParams({ href: '/' })}`,
          urlButton: `/meu-perfil?${new URLSearchParams({ href: '/' })}`,
          showButton: true,
          buttonTitle: 'Finalizar',
          subTitle: 'Novas informações cadastradas com sucesso',
        }).toString()}`,
      });
    } catch (err) {
      // Erro
    }
    setLoadingButton(false);
  };

  const hasDiferenceToSave = useMemo(() => {
    const { signed, ...auxUser } = { ...savedUser };
    const { ddd, cellNumber, email, ...formData } = methods.getValues();
    delete auxUser.cellNumber;
    delete auxUser.email;
    formData.gender = gender;
    auxUser.cpf = cpf.format(auxUser.cpf);

    let flag = false;
    Object.keys(formData).forEach(key => {
      if (formData[key] !== auxUser[key]) {
        flag = true;
      }
    });
    return flag;
  }, [watch('birthday'), watch, savedUser, gender]);

  const hasModalOpen = useMemo(() => {
    return openVerifyModal || openInvalidModal || openRegistredModal;
  }, [openVerifyModal, openInvalidModal, openRegistredModal]);

  const handleContinue = async () => {
    setLoadingButton(true);
    const { ...formData } = methods.getValues();
    try {
      const { ddd, cellNumber, email } = methods.getValues();
      const changedUserProfile = {
        birthday: formData.birthday,
        gender,
        lastname: formData.lastname,
        name: formData.name,
      };

      let bodyParams = { cpf: cpf.strip(user.cpf) };
      if (isEmail) bodyParams.email = email;
      else {
        const number = `${ddd}${cellNumber}`;
        bodyParams.contact_number = number;
        changedUserProfile.number = number;
      }
      await api.post('/customer/v0.1/sendToken', bodyParams);

      bodyParams = { ...bodyParams, ...changedUserProfile };
      bodyParams.birthday = bodyParams.birthday.split('/').reverse().join('-');
      setPreserveProfileData(true);
      await updateUser(bodyParams);
      // Ir pra tela de token
      history.push({
        pathname: '/meu-perfil/verificacao',
        search: `?${new URLSearchParams({ isEmail }).toString()}`,
      });
    } catch (err) {
      if (err?.response?.status === 400) setOpenRegistredModal(true);
      else setOpenInvalidModal(true);
    }

    setOpenVerifyModal(false);
    setLoadingButton(false);
  };

  const maskDate = value => {
    const v = value.replace(/\D/g, '').slice(0, 10);
    if (v.length >= 5) {
      return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`;
    }
    if (v.length >= 3) {
      return `${v.slice(0, 2)}/${v.slice(2)}`;
    }
    return v;
  };

  return (
    <>
      <Container>
        <SignOut isLogged={user && user.signed} onClick={signOut}>
          <img src={SignOutIcon} alt="Sign Out Symbol" />
          <p>Sair</p>
        </SignOut>
        <h1>Caso necessário você pode alterar seus dados editáveis:</h1>
        <FormProvider {...methods}>
          <Form
            onSubmit={methods.handleSubmit(onSubmit)}
            fixed={hasDiferenceToSave}
          >
            <Input
              text="Nome"
              htmlFor="name"
              name="name"
              isValid={!methods.errors.name}
            />
            <Input
              text="Sobrenome"
              htmlFor="lastname"
              name="lastname"
              isValid={!methods.errors.lastname}
            />
            <Input
              text="E-mail"
              htmlFor="email"
              name="email"
              onFocus={() => setErrorEmail(null)}
              onBlur={e => {
                const email = e.target.value;
                if (email !== savedUser.email && !hasModalOpen) {
                  setIsEmail(true);
                  setOpenVerifyModal(true);
                }
              }}
              isValid={!errorEmail}
              showError
              showIcon
              errorMessage={errorEmail || methods.errors.email?.message.message}
            />
            <InputNumber
              nameDDD="ddd"
              textDDD="DDD"
              namePhone="cellNumber"
              textPhone="Número"
              handleValidBlur={value => {
                if (value !== savedUser.cellNumber && !hasModalOpen) {
                  setIsEmail(false);
                  setOpenVerifyModal(true);
                }
              }}
            />
            <Input text="CPF" htmlFor="cpf" name="cpf" disabled isValid />
            <Input
              onChange={e => {
                e.target.value = maskDate(e.target.value);
              }}
              type="tel"
              text="Data de nascimento"
              htmlFor="birthday"
              name="birthday"
              isValid
              showError
              errorMessage=""
              maxLength="10"
              icon={calendarIcon}
            />

            <>
              <h2>Com qual gênero você se identifica?</h2>
              <GenderContent disabled={gender === GENDER_ENUM.NO_REPLY}>
                <GenderItem
                  type="button"
                  selected={gender === GENDER_ENUM.MALE}
                  onClick={() => setGender(GENDER_ENUM.MALE)}
                >
                  Masculino
                </GenderItem>
                <GenderItem
                  type="button"
                  selected={gender === GENDER_ENUM.FEMALE}
                  onClick={() => setGender(GENDER_ENUM.FEMALE)}
                >
                  Feminino
                </GenderItem>
                <GenderItem
                  type="button"
                  selected={gender === GENDER_ENUM.NOT_BINARY}
                  onClick={() => setGender(GENDER_ENUM.NOT_BINARY)}
                >
                  Não-binário
                </GenderItem>
              </GenderContent>
              <GenderNoReply
                type="button"
                onClick={() => setGender(GENDER_ENUM.NO_REPLY)}
              >
                <Checkbox
                  name="gender"
                  checked={gender === GENDER_ENUM.NO_REPLY}
                  onChange={() => setGender(GENDER_ENUM.NO_REPLY)}
                />
                <p>Prefiro não responder</p>
              </GenderNoReply>
            </>

            <SafetySection />
            <SaveSection fixed={hasDiferenceToSave}>
              <Button type="submit" disabled={!hasDiferenceToSave}>
                {loadingButton ? 'Carregando...' : 'Salvar'}
              </Button>
            </SaveSection>
          </Form>
        </FormProvider>
      </Container>

      <ModalVerify
        open={openVerifyModal}
        onClose={() => setOpenVerifyModal(false)}
        onContinue={handleContinue}
        isEmail={isEmail}
        loading={loadingButton}
      />

      <ModalInvalid
        open={openInvalidModal}
        onClose={() => setOpenInvalidModal(false)}
        isEmail={isEmail}
      />

      <ModalRegistred
        open={openRegistredModal}
        onClose={() => setOpenRegistredModal(false)}
        isEmail={isEmail}
      />
    </>
  );
}
