import styled from 'styled-components';

export const FavoriteWrapper = styled.div`
  width: 100%;
  text-align: left;

  h1 {
    font-family: 'ChickenSansBold';

    span {
      font-size: 24px;
      color: #757575;
    }
  }
`;

export const FavoriteNotFoundWrapper = styled.div`
  text-align: center;

  min-height: 602px;

  margin: 0 auto;

  h3 {
    font-family: 'ChickenSansBold';
    margin-bottom: 15px;
  }

  p {
    font-family: 'ChickenSansRegular';
    font-size: 16px;
    color: #757575;
    margin-bottom: 40px;
  }

  max-width: 450px;
`;
