import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { IFilterItem } from 'components/Molecules/Filter/interfaces';
import sizes from 'utils/screenSizes';
import { useHistory } from 'react-router-dom';
import { IHeaderMenu } from './interfaces';
import { HeaderMenu } from './HeaderMenu';

export const Container: React.FC<IHeaderMenu> = ({
  id,
  hideBanner,
  hideFilters,
  onPressAnyCategoryFilter,
  storeSelected,
  handleClickOnFilter,
  ...rest
}) => {
  const history = useHistory();

  const [openCategories, setOpenCategories] = useState(false);
  const [hideBackButton, setHideBackButton] = useState(false);
  const [BackButtonColor, setBackButtonColor] = useState<'orange' | 'white'>(
    'white',
  );
  const [isSearchButtonClick, setIsSearchButtonClick] = useState(false);

  useEffect(() => {
    if (window.screen.width < parseInt(sizes.notebook, 10)) {
      setIsSearchButtonClick(false);
      if (hideBanner) {
        setBackButtonColor('orange');
      } else {
        setBackButtonColor('white');
      }
    } else if (hideBanner) {
      setBackButtonColor('orange');
      setHideBackButton(false);
      // eslint-disable-next-line no-dupe-else-if
    } else if (hideBanner) {
      setHideBackButton(true);
    } else {
      setBackButtonColor('white');
    }
  }, [hideBanner, storeSelected]);

  const numberOfPagesCarousel = useMemo(() => {
    // logic for calcule number of pages returned of api
    return 2;
  }, []);

  const handleUpdateCategories = useCallback((newCategories: IFilterItem) => {
    const count = newCategories.filter(category => category.selected).length;
    // chamar o hook
  }, []);

  const onToogleCategories = (option: boolean) => {
    setOpenCategories(option);
  };

  const handleClickSearchButtonMobile = () => {
    setIsSearchButtonClick(true);
  };

  return (
    <HeaderMenu
      {...rest}
      numberOfPagesCarousel={numberOfPagesCarousel}
      id={id}
      data-testid={`HeaderMenu@${id}`}
      openCategories={openCategories}
      hideBanner={hideBanner}
      hideBackButton={hideBackButton}
      backgroundColorBackButton={BackButtonColor}
      hideFilters={hideFilters}
      handleClickSearchButtonMobile={handleClickSearchButtonMobile}
      handleClickOnFilter={handleClickOnFilter}
      isSearchButtonClick={isSearchButtonClick}
      onPressAnyCategoryFilter={onPressAnyCategoryFilter}
      storeSelected={storeSelected}
    />
  );
};
