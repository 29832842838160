/* eslint-disable no-unused-vars */
import React from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import App from './App';

const tagManagerInit = {
  gtmId: process.env.REACT_APP_GTM_API_KEY,
};
TagManager.initialize(tagManagerInit);

const container = document.getElementById('root');
const root = createRoot(container!);

/* root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
); */

root.render(<App />);
