export const WeekdayFormatter = {
  numberToString: (day: number) => {
    const weekdays: string[] = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    return weekdays[day];
  },
  stringToNumber: (day: string) => {
    switch (day) {
      case 'Sunday':
        return 0;
      case 'Monday':
        return 1;
      case 'Tuesday':
        return 2;
      case 'Wednesday':
        return 3;
      case 'Thursday':
        return 4;
      case 'Friday':
        return 5;
      case 'Saturday':
        return 6;
      default:
        return 0;
    }
  },
  numberToStringAbbreviation: (day: number) => {
    const weekdays: string[] = [
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat',
    ];
    return weekdays[day];
  },
  stringAbbreviationToNumber: (day: string) => {
    switch (day) {
      case 'Sun':
        return 0;
      case 'Mon':
        return 1;
      case 'Tue':
        return 2;
      case 'Wed':
        return 3;
      case 'Thu':
        return 4;
      case 'Fri':
        return 5;
      case 'Sat':
        return 6;
      default:
        return 0;
    }
  },
  numberToStringPT: (day: number) => {
    const weekdays: string[] = [
      'Domingo',
      'Segunda-feira',
      'Terça-feira',
      'Quarta-feira',
      'Quinta-feira',
      'Sexta-feira',
      'Sábado',
    ];
    return weekdays[day];
  },
  stringToNumberPT: (day: string) => {
    switch (day) {
      case 'Domingo':
        return 0;
      case 'Segunda-feira':
        return 1;
      case 'Terça-feira':
        return 2;
      case 'Quarta-feira':
        return 3;
      case 'Quinta-feira':
        return 4;
      case 'Sexta-feira':
        return 5;
      case 'Sábado':
        return 6;
      default:
        return 0;
    }
  },
  numberToStringAbbreviationPT: (day: number) => {
    const weekdays: string[] = [
      'Dom',
      'Seg',
      'Ter',
      'Qua',
      'Qui',
      'Sex',
      'Sáb',
    ];
    return weekdays[day];
  },
  stringAbbreviationPTToNumber: (day: string) => {
    switch (day) {
      case 'Dom':
        return 0;
      case 'Seg':
        return 1;
      case 'Terça-feira':
        return 2;
      case 'Ter':
        return 3;
      case 'Qua':
        return 4;
      case 'Qui':
        return 5;
      case 'Sáb':
        return 6;
      default:
        return 0;
    }
  },
};
