import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100%;

  padding: 64px;
  padding-bottom: 0px;

  @media (max-width: ${sizes.ipadPro}) {
    padding: 0px;
    height: 100%;
  }
  overflow: auto;
`;

export const CouponsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex: 1;

  height: 100%;
  padding-bottom: 130px;
  overflow: scroll;
  z-index: 0;

  @media only all and (min-width: ${sizes.notebook}) {
    padding: 0px 24px 130px;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
