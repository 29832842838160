/* eslint-disable max-len */
import styled from 'styled-components';
import { popeyesPrimaryColors } from 'styles';

interface IRestaurantTagProps {
  open: boolean;
}

interface IRestaurantOpenHoursInfoProps {
  open?: boolean;
}

export const TraceRouteButton = styled.button`
  display: flex;
  align-items: start;
  font-size: 14px;
  text-decoration: underline;
  height: fit-content;
  background: none;
  border: none;
  font-family: 'ChickenSansRegular';
  color: ${popeyesPrimaryColors.secondary};
  margin-top: 16px;

  img {
    margin-left: 5px;
    width: 15px;
  }
`;

export const RestaurantOpenHoursListWrapper = styled.div`
  flex: 1;
`;

export const RestaurantOpenHoursInfo = styled.div<IRestaurantOpenHoursInfoProps>`
  display: flex;
  max-height: ${props => (props.open ? 'none' : '0')};
  transition: all 0.4s ease-in-out 0s;
  overflow: hidden;
  margin-left: 196px;

  h6 {
    margin-top: 16px;
    font-size: 15px;
    font-family: 'ChickenSansBold';
  }

  ul {
    margin-bottom: 16px;
  }
`;

export const RestaurantOpenHourListItem = styled.li<IRestaurantOpenHoursInfoProps>`
  font-family: ${props =>
    props.open ? 'ChickenSansBold' : 'ChickenSansRegular'};
  font-size: 14px;
  font-weight: ${props => (props.open ? 'bold' : '400')};
  color: ${props => (props.open ? '#212121' : '#757575')};
  list-style: none;
  margin-top: 4px;
`;

export const ShowMoreDetailesButton = styled.button<IRestaurantOpenHoursInfoProps>`
  background: none;
  border: none;
  margin-left: 277px;

  color: ${popeyesPrimaryColors.primary};
  font-weight: 700;
  text-decoration: underline;

  display: flex;
  align-items: center;
  outline: none;

  img {
    transform: ${props => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 0.2s ease-in-out;
  }

  &:focus {
    outline: none;
  }
`;

export const FavoriteButton = styled.button`
  background: none;
  border: none;
  margin-right: 32px;
  outline: none;

  img {
    outline: none;
  }

  &:focus {
    outline: none;
  }
`;

export const RestaurantListItemWrapper = styled.div`
  padding: 8px 32px;
  background-color: white;
  border-radius: 8px;
  cursor: auto;

  img {
    border-radius: 4px;
    object-fit: cover;
  }
`;

export const RestaurantListItemMainInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const RestaurantDescription = styled.div`
  margin-left: 16px;
  flex: 1;
  h4 {
    font-family: 'ChickenSansBold';
    font-size: 20px;
    line-height: 25px;

    span {
      font-size: 16px;
      margin-left: 24px;
      font-weight: 400;
      color: #757575;
    }
  }

  .opened-day {
    font-weight: 400;
    font-size: 12px;
    color: #757575;
    margin-left: 8px;

    b {
      color: #212121;
    }
  }

  p {
    font-family: 'ChickenSansRegular';
    font-weight: 400;
    color: #757575;
    font-size: 12px;
    line-height: 18px;
  }
`;

export const RestaurantStatusTag = styled.span<IRestaurantTagProps>`
  color: ${props => (props.open ? 'white' : '#212121')};
  padding: 2px 8px;
  line-height: 18px;
  font-weight: 400;
  font-size: 12px;
  background-color: ${props => (props.open ? '#00b2a9' : '#FFC800')};
  border-radius: 4px;
  text-align: center;
`;

export const ButtonChooseRestaurant = styled.button`
  background: transparent;
  border: 2px solid ${popeyesPrimaryColors.primary} !important;
  border-radius: 8px;
  color: ${popeyesPrimaryColors.primary} !important;
  padding: 8.5px 24px;
  font-size: 20px;
  font-weight: 700;
`;
