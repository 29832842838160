/* eslint-disable react/require-default-props */
import FacebookLogin from 'react-facebook-login/dist/facebook-login';
import { useHistory } from 'react-router-dom';

// Icons and Images
// eslint-disable-next-line import-helpers/order-imports
import FacebookIcon from 'assets/images/carousel/icons/facebook.svg';

// Services and Hooks
import { useAuth } from 'hooks/Auth';
import PropTypes from 'prop-types';

// Styles
import { Button, ContainerButton } from './styles';

function SignInFacebook({ getCoupon, onFailure, onClick }) {
  const { signIn } = useAuth();

  const history = useHistory();
  const responseFacebook = async response => {
    const idUser = response.id;

    if (idUser) {
      try {
        // already register
        const responseapi = await signIn({
          facebook_id: idUser,
          user_data: response,
        });

        if (
          responseapi?.data?.sms_confirmed ||
          responseapi?.response?.data?.sms_confirmed
        ) {
          history.push({
            pathname: '/verificar',
            search: `?${new URLSearchParams({
              href: '/onboarding',
              facebookUser: true,
            }).toString()}`,
          });
        } else {
          history.push({
            pathname: '/registro',
            search: `?${new URLSearchParams({
              facebookUser: true,
            }).toString()}`,
          });
        }
      } catch (err) {
        history.push({
          pathname: '/registro',
          search: `?${new URLSearchParams({
            facebookUser: true,
          }).toString()}`,
        });
      }
    } else if (onFailure) onFailure();
    else history.push('/onboarding');
  };

  return (
    <ContainerButton onClick={onClick}>
      <FacebookLogin
        appId={process.env.REACT_APP_BASE_APP_IDFACEBOOK}
        autoLoad={false}
        isMobile={false}
        fields="name,email,picture"
        callback={responseFacebook}
        render={renderProps => (
          <Button
            type="button"
            className="btn btn-facebook"
            onClick={renderProps.onClick}
            getCoupon={getCoupon}
          >
            <img src={FacebookIcon} alt="facebook-icon" />
            Entrar com o Facebook
          </Button>
        )}
      />
    </ContainerButton>
  );
}

SignInFacebook.propTypes = {
  getCoupon: PropTypes.bool,
  onFailure: PropTypes.func,
  onClick: PropTypes.func,
};

SignInFacebook.defaultProps = {
  onFailure: null,
};

export default SignInFacebook;
