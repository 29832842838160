import React from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Button from 'components/Button';
import ModalResponsive from 'components/ModalResponsive';
import PropTypes from 'prop-types';
import firebase from 'services/firebase';

// Styles
import { ModalContent } from '../styles';

const ModalRegistred = ({ open, isEmail, onClose }) => {
  const history = useHistory();

  return (
    <>
      <ModalResponsive
        open={open}
        onClose={onClose}
        maxModalHeight={380}
        sizeModal="middle"
      >
        <ModalContent>
          <h3 className="title">
            {`${isEmail ? 'E-mail' : 'Número'} já cadastrado`}
          </h3>

          <p>
            {isEmail
              ? 'Este e-mail já tem cadastro em outra conta. Volte e insira um novo e-mail.'
              : 'Este telefone já tem cadastro em outra conta. Volte e insira um novo número de telefone.'}
          </p>

          <Button type="button" onClick={onClose}>
            {`Inserir novo ${isEmail ? 'e-mail' : 'número'}`}
          </Button>

          <Button
            type="button"
            size="S"
            variant="secondary"
            onClick={() => {
              firebase.analytics().logEvent('ajudarecuperacao');
              history.push({
                pathname: '/enviar-email',
                search: `?${new URLSearchParams({
                  href: `/meu-perfil?href=/perfil`,
                  title: 'Fale conosco',
                  isHelp: true,
                  subject: 'Preciso de ajuda',
                }).toString()}`,
              });
            }}
          >
            Preciso de ajuda
          </Button>
        </ModalContent>
      </ModalResponsive>
    </>
  );
};

ModalRegistred.propTypes = {
  open: PropTypes.bool.isRequired,
  isEmail: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalRegistred;
