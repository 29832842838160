import React, { useCallback, useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  useTransition,
  useSpringRef,
  config as springConfig,
} from 'react-spring';

import PropTypes from 'prop-types';
import { api } from 'services';
import firebase from 'services/firebase';

import { steps } from './Steps';
import * as S from './styles';

const Inapp = ({ isOpen, onClose }) => {
  const [rating, setRating] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const transRef = useSpringRef();
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      comments: '',
    },
  });

  useEffect(() => {
    transRef.start();
  }, [currentStep]);

  const transitions = useTransition(currentStep, {
    ref: transRef,
    keys: null,
    enter: { opacity: 1, top: '50%' },
    leave: { opacity: 0, top: '-200%' },
    from: { opacity: 0, top: '200%' },
    delay: 200,
    duration: 150,
    config: springConfig.wobbly,
  });

  const handleSubmit = useCallback(
    async data => {
      const { comments } = data;
      const customer_id = localStorage
        .getItem('@Popeyes.customer_id')
        .toString();
      const cupom_id = JSON.parse(localStorage.getItem('@Popeyes.cart.v2')).map(
        item => item.id,
      );
      const body = {
        comments,
        rating,
        customer_id,
        cupom_id,
      };
      try {
        await api.post('customer/v0.1/review', body);
      } catch (err) {
        console.log(err);
      }
    },
    [rating, api],
  );

  const handleOnCloseEvent = useCallback(
    event => {
      !!event && firebase.analytics().logEvent('inapp_review', event);
      onClose();
    },
    [onClose, firebase],
  );

  const handleClickNextEvent = useCallback(
    event => {
      !!event && firebase.analytics().logEvent('inapp_review', event);
      setCurrentStep(oldState => ++oldState);
    },
    [setCurrentStep, firebase],
  );

  return (
    <S.ModalOverlay open={isOpen}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          {transitions((style, i) => {
            const Modal = steps[i];
            return (
              <Modal
                styles={style}
                onChange={setRating}
                onNext={handleClickNextEvent}
                onClose={handleOnCloseEvent}
                ratingType={rating < 4 ? 'bad' : 'good'}
                rating={rating}
              />
            );
          })}
        </form>
      </FormProvider>
    </S.ModalOverlay>
  );
};
Inapp.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Inapp;
