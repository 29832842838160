import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import styled from 'styled-components';
import { breakpoints, font } from 'styles/themes';

export const ArrowsLeft = styled(RiArrowLeftSLine)`
  position: absolute;
  top: 50%;
  left: 1%;
  transform: translate(-50%, -50%);
  z-index: 5;
  height: 30px;
  width: 30px;
  color: #d7d7d7;
  margin-top: 60px;
`;

export const ArrowsRight = styled(RiArrowRightSLine)`
  position: absolute;
  top: 50%;
  right: -6%;
  transform: translate(-50%, -50%);
  z-index: 5;
  height: 30px;
  width: 30px;
  color: #d7d7d7;
  margin-top: 60px;
`;

export const CategoryTitle = styled.h3`
  /* font-family: ${font.family.ChickenHeadline}; */
  /* font-size: ${font.sizes.heading03}; */
  margin-top: 16px;
  margin-bottom: 16px;
  /* color: var(--color-popeyes-orange); */
  //styleName: heading/h2;
  font-family: 'ChickenSansBold';
  font-size: 40px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: 0em;
  /* text-align: left; */

  @media (max-width: ${breakpoints.small}) {
    font-size: ${font.sizes.heading06};
    margin-left: 20px;
  }
`;

export const HorizontalLineOnProducts = styled.hr`
  border: none;
  /* border-top: 2px solid #979797; */
  border-top: 5px solid #979797;
  margin: 10px 0;

  @media (max-width: ${breakpoints.small}) {
    border-top: 5px solid #979797;
    margin-left: 20px;
    margin-top: 1px;
  }
`;

export const ProductsRow = styled.div`
  position: relative;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
  gap: 15px;
  /* justify-content: center; */
  @media (max-width: ${breakpoints.small}) {
    padding-left: 0;
    /* grid-template-columns: 1fr 1fr; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* flex-direction: column !important; */
    gap: 15px;
    /* margin-bottom: 15px;
  }
`;

export const ArrowsContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
`;

export const NoneProduct = styled.h1`
  font-family: ${font.family.ChickenHeadline};
  text-align: center;
  margin-top: 50vh;
`;

export const BorderLine = styled.hr`
  border: none;
  border: 1px solid #bdbdbd;
  margin: 60px 0;
`;
