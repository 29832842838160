import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const ContainerButtonCampaignMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${sizes.mobile}) {
    padding: 0 24px;
  }

  @media (min-width: ${sizes.notebook}) {
    position: absolute;
    padding: 0;
    z-index: 1;
    top: 63%;
    left: 13%;
  }
`;

export const ButtonCampaignMobile = styled.button`
  display: flex;
  align-items: center;
  position: relative;

  height: 56px;
  width: 326px;
  color: #ff7d00;
  background: #fff;
  border: none;
  border-radius: 6px;
  gap: 20px;
  padding-left: 20px;

  img {
    width: auto !important;
  }

  p {
    color: #ff7d00;
    font-family: 'ChickenSansBold';
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
  }
`;
