import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';

import { gql, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';

// Components
import Button from 'components/Button';
import InputNumber from 'components/InputNumber';
import ModalResponsive from 'components/ModalResponsive';
import TitleSection from 'components/TitleSection';

// Services, Utils and Hooks
import { useAuth } from 'hooks/Auth';
import { api } from 'services';
import firebase from 'services/firebase';
import * as statusSMS from 'utils/smsStatus';

// Styles
import { useTagManager, ButtonEvents } from 'hooks/Analytics';
import { Container, Form, ModalContent } from './styles';
import schema from './validation';

const CREATE_USER = gql`
  mutation (
    $user_id_facebook: String!
    $name: String!
    $lastname: String!
    $email: String!
    $cpf: String!
    $term_accepted: Boolean!
    $receive_mail: Boolean!
    $receive_sms: Boolean!
    $number: String!
  ) {
    saveCustomerPWA(
      user_id_facebook: $user_id_facebook
      customer_name: $name
      last_name: $lastname
      email: $email
      cpf: $cpf
      term_accepted: $term_accepted
      receive_mail: $receive_mail
      receive_sms: $receive_sms
      contact_number: $number
    )
  }
`;

export default function ValidateNumber() {
  const { user, accessToken, updateUser, updateMessageToken } = useAuth();
  const { sendButtonClickEvent } = useTagManager();
  const history = useHistory();

  const fieldDDD = user?.cellNumber ? user.cellNumber.substr(0, 2) : '';
  const fieldNumber = user?.cellNumber ? user.cellNumber.substr(2, 9) : '';

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ddd: fieldDDD === '[o' ? '' : fieldDDD,
      celNumber: fieldNumber === 'bject Obj' ? '' : fieldNumber,
    },
  });
  const { formState } = methods;

  const [loadingButton, setLoadingButton] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [createUser] = useMutation(CREATE_USER);

  const onBackButtonEvent = e => {
    e.preventDefault();
    history.push('/verification');
  };

  useEffect(() => {
    const params = new URL(window.location).searchParams;

    const openIsRegistered = params.get('openIsRegistered');
    if (openIsRegistered) setIsRegistered(true);

    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  async function onSubmit(dataForm) {
    setLoadingButton(true);
    const number = String(dataForm.ddd.concat(dataForm.celNumber));

    firebase.analytics().logEvent('cadastro_continuar', {
      etapa_cadastro: 'num_celular_pwa',
    });

    // check if already exists number if exists add if error of celnumber call modal
    const { name, lastname, email, cpf, term_accepted } = user;

    try {
      const response = await createUser({
        variables: {
          user_id_facebook: accessToken,
          name,
          lastname,
          email,
          cpf,
          term_accepted,
          receive_mail: true,
          receive_sms: true,
          number,
        },
      });

      if (response.data?.saveCustomerPWA) {
        const data = response.data.saveCustomerPWA;
        const removedKeys = data.replace(/[{}]/gi, '');

        const responseObject = removedKeys
          .trim()
          .split(',')
          .reduce((acc, item) => {
            const [key, val = ''] = item.trim().split('=');
            let newVal = val.trim();

            if (newVal === 'true' || newVal === 'false') {
              newVal = Boolean(newVal);
            }
            return { ...acc, [key.trim()]: newVal };
          }, {});

        if (responseObject?.statusCode === '422') {
          firebase.analytics().logEvent('modal_erro', {
            tipo_erro: 'num_cadastrado',
          });
          setIsRegistered(true);
          methods.reset();
          setLoadingButton(false);
          return;
        }
      }
    } catch (err) {
      if (err && err.message !== "'customer_id'") setIsRegistered(true);
    }

    const auxFacebookData = { ...user };
    auxFacebookData.number = number;

    await updateUser(auxFacebookData);
    try {
      const response = await api.post('/authentication/v0.1/cpfsms', {
        cpf,
      });

      if (response?.data) {
        const auxMessageToken = {
          messageId: response.data.messageId,
          statusGroup: response.data.status_group,
          statusSms: response.data.status_sms,
        };
        updateMessageToken(auxMessageToken);
      }
    } catch (err) {
      if (err.response.status === 422) {
        updateMessageToken({
          messageId: '',
          statusGroup: statusSMS.REJECTED,
          statusSms: '',
        });
      } else {
        updateMessageToken({
          messageId: '',
          statusGroup: statusSMS.PENDING,
          statusSms: '',
        });
      }
    }

    history.push({
      pathname: '/verificar',
      search: `?${new URLSearchParams({
        href: '/validar-numero',
        facebookUser: true,
      }).toString()}`,
    });
    methods.reset();
    setLoadingButton(false);
  }

  function redirectToThroubloshooting() {
    sendButtonClickEvent(ButtonEvents.LOGIN_ACTION_TROUBLESHOOTING);
  }

  return (
    <>
      <Container>
        <TitleSection title="Informe o número de seu celular" />
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <InputNumber
              nameDDD="ddd"
              textDDD="DDD"
              namePhone="celNumber"
              textPhone="Número"
            />
            <div className="safetyContainer">
              <p>
                Este é um ambiente seguro e todas as suas informações estão
                protegidas.
              </p>
              <Button type="submit" disabled={!formState.isValid}>
                {loadingButton ? 'Carregando...' : 'Continuar'}
              </Button>

              <div className="acessProblem">
                <Link to="/validado">
                  <h3>Problemas com o acesso</h3>
                </Link>
              </div>
            </div>
          </Form>
        </FormProvider>
      </Container>

      {/* Esta página possui um modal:
        - Numero já cadastrado */}
      <ModalResponsive
        open={isRegistered || isInvalid}
        maxModalHeight={380}
        sizeModal="middle"
        onClose={() => {
          setIsRegistered(false);
          setIsInvalid(false);
        }}
      >
        <ModalContent>
          <p>
            {isRegistered && 'Número já cadastrado'}
            {isInvalid && 'Número inválido'}
          </p>
          <p className="text">
            {isRegistered &&
              'Este telefone já tem cadastro em outra conta. Volte e insira um novo número de telefone.'}
            {isInvalid &&
              'Não conseguimos identificar esse número. Confira se o DDD e todos os números estão preenchidos corretamente.'}
          </p>
          <Button
            onClick={() => {
              setIsRegistered(false);
              setIsInvalid(false);
            }}
          >
            {isRegistered && 'Inserir novo número'}
            {isInvalid && 'Conferir número'}
          </Button>

          {isRegistered && (
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                firebase.analytics().logEvent('ajudarecuperacao');
                history.push({
                  pathname: '/enviar-email',
                  search: `?${new URLSearchParams({
                    href: `/validar-numero?openIsRegistered=true`,
                    title: 'Fale conosco',
                    isHelp: true,
                    isRecover: true,
                    subject: 'Preciso de ajuda',
                  }).toString()}`,
                });
              }}
            >
              Preciso de ajuda
            </Button>
          )}
        </ModalContent>
      </ModalResponsive>
    </>
  );
}
