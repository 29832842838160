import { number, string } from 'prop-types';

import { Container } from './styles';

function CarouselContent({ imageUrl, bannerId, link }) {
  return (
    <Container>
      {link ? (
        <button
          type="button"
          onClick={() => {
            return window.open(link, '_blank').focus();
          }}
        >
          <img
            src={imageUrl}
            alt="Banner Desktop"
            key={bannerId}
            className="banner-main"
          />
        </button>
      ) : (
        <img
          src={imageUrl}
          alt="Banner Desktop"
          key={bannerId}
          className="banner-main"
        />
      )}
    </Container>
  );
}

export default CarouselContent;

CarouselContent.propTypes = {
  imageUrl: string.isRequired,
  bannerId: number.isRequired,
  link: string,
};
CarouselContent.defaultProps = {
  link: null,
};
