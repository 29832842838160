import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import * as Compontents from 'components';
import ModalResponsive from 'components/ModalResponsive';
import { cpf } from 'cpf-cnpj-validator';

// Hooks, Utils and Services
import { useAuth } from 'hooks/Auth';
import { api } from 'services';

import { ModalContent } from '../styles';
import { Container } from './styles';

function ScreenVerify() {
  const history = useHistory();
  const { user, setPreserveProfileData, updateUser } = useAuth();

  const [openModal, setOpenModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [counter, setCounter] = useState(120);

  useEffect(() => {
    const params = new URL(window.location).searchParams;
    const isEmailParams = params.get('isEmail');
    if (isEmailParams && JSON.parse(isEmailParams)) setIsEmail(true);

    setPreserveProfileData(false);
  }, []);

  const subTitle = useMemo(() => {
    if (isEmail) {
      return (
        <>
          Um código de verificação foi enviado para o e-mail
          <strong>{user?.email ? ` ${user.email}. ` : '. '}</strong>
          Aguarde até 2 minutos para o recebimento do código.
        </>
      );
    }
    return (
      <>
        Um código de verificação foi enviado para o número
        <strong> 55 {user?.cellNumber}. </strong>
        Aguarde até 2 minutos para o recebimento do SMS
      </>
    );
  }, [isEmail, user]);

  const onSubmit = async token => {
    setLoading(true);
    try {
      const bodyParams = { cpf: cpf.strip(user.cpf), pin: token };
      if (isEmail) {
        bodyParams.email = user.email;
        await api.put('/customer/v0.1/putEmail', bodyParams);
      } else {
        bodyParams.contact_number = user.cellNumber;
        await api.put('/customer/v0.1/putContactNumber', bodyParams);
      }

      setPreserveProfileData(true);
      await updateUser(bodyParams);
      // push de sucesso
      history.push({
        pathname: '/sucesso',
        search: `?${new URLSearchParams({
          href: '/meu-perfil',
          urlButton: '/meu-perfil',
          showButton: true,
          buttonTitle: 'Finalizar',
          subTitle: isEmail
            ? 'Novo e-mail cadastrado com sucesso'
            : 'Novo número cadastrado com sucesso',
        }).toString()}`,
      });
    } catch (err) {
      setOpenModal(true);
    }

    setLoading(false);
  };

  const handleSendNewToken = async () => {
    setCounter(120);
    setOpenModal(false);
    try {
      const { cellNumber, email } = user;
      const bodyParams = { cpf: user.cpf };
      if (isEmail) bodyParams.email = email;
      else bodyParams.contact_number = cellNumber;
      await api.post('/customer/v0.1/sendToken', bodyParams);
    } catch (error) {
      console.error(error);
    }
  };

  const arrayButton = useMemo(() => {
    const buttons = [];
    buttons.push({ title: loading ? 'Carregando...' : 'Continuar' });

    buttons.push({
      title: `Reenviar ${isEmail ? 'código por e-mail' : 'SMS'}`,
      onClick: handleSendNewToken,
      disabled: counter > 0,
    });

    return buttons;
  }, [counter, loading, isEmail]);

  return (
    <>
      <Container>
        <Compontents.VerifyToken
          title="Insira o código de verificação"
          subTitle={subTitle}
          counter={counter}
          updateCounter={count => setCounter(count)}
          onSubmit={onSubmit}
          arrayButton={arrayButton}
        />
      </Container>

      <ModalResponsive
        open={openModal}
        onClose={() => setOpenModal(false)}
        maxModalHeight={350}
        sizeModal="middle"
      >
        <ModalContent>
          <h3 className="title">Código de verificação inválido</h3>

          <p>
            {isEmail ? (
              <>
                Volte e verifique o código que foi enviado para o e-mail
                <strong>{user?.email ? ` ${user.email}. ` : '. '}</strong>
              </>
            ) : (
              <>
                Volte e verifique novamente o código de verificação que foi
                enviado por SMS para o número
                <strong> 55 {user?.cellNumber}. </strong>
              </>
            )}
          </p>

          <Compontents.Button type="button" onClick={handleSendNewToken}>
            {`Reenviar ${isEmail ? 'código por e-mail' : 'SMS'}`}
          </Compontents.Button>
        </ModalContent>
      </ModalResponsive>
    </>
  );
}

export default ScreenVerify;
