import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  display: block;

  min-height: 100%;
  width: 100%;

  margin: 0 auto;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  /* background-color: var(--color-salt-white); */
  position: relative;

  html::-webkit-scrollbar {
    display: none;
  }
  @media only all and (max-width: ${sizes.notebook}) {
    max-width: 850px;
    padding: 10px 20px 20px 20px;
  }
  @media only all and (min-width: ${sizes.notebook}) {
    display: flex;

    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0px;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: center;
  padding: 24px;
  max-width: 850px;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  .facebookLogin {
    width: 100%;
    border: none;
    background: none;
  }
  #logo {
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: row;
    text-align: center;
    /* padding: 24px; */
    /* max-width: 850px; */
    height: auto;
    width: 100%;

    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;

    @media all and (min-width: ${sizes.notebook}) {
      display: none;
    }
  }

  h1 {
    font-family: 'ChickenSansBold';
    font-weight: 700;
    color: #434040;
    font-size: 24px;
    line-height: 29px;
  }

  p {
    font-family: 'ChickenSansExtraLight';
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #adadaf;
    line-height: 19px;
    margin-top: 10px;
  }

  @media all and (min-width: ${sizes.ipadPro}) and (max-width: ${sizes.notebook}) {
    h1 {
      font-size: 40px;
      line-height: 35px;
    }
    p {
      font-size: 32px;
      line-height: 30px;
      margin: 30px auto auto auto;
    }
  }

  @media all and (min-width: ${sizes.notebook}) {
    margin: 0 auto;
    h1 {
      font-size: 1.5rem;
    }
    p {
      max-width: 85%;
      font-size: 1rem;
      margin: 10px auto auto auto;
    }
  }

  @media all and (min-width: 1500px) {
    h1 {
      font-size: 2rem;
    }
    p {
      max-width: 100%;
      font-size: 1.2rem;
    }
  }

  .buttonLogin {
    bottom: 0;
    height: 48px;
    position: relative;
    width: 100%;
    max-width: 312px;
    border: none;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    background-color: var(--color-burnt-orange);
    margin-top: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    font-family: 'ChickenSansBold';
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;

    img {
      width: 25px;
      position: absolute;
      left: 18px;
    }
  }

  .acessProblem {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background: none;
    border: none;
    a {
      text-decoration: none;
      cursor: pointer;

      h3 {
        font-family: 'ChickenSansRegular';
        font-weight: normal;
        text-align: center;
        color: var(--color-burnt-orange);
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  .text-area {
    padding-bottom: 30px;
    width: 80%;
  }
  @media all and (min-width: ${sizes.ipadPro}) and (max-width: ${sizes.notebook}) {
    .buttonLogin {
      position: static;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      max-width: 400px;
      font-size: 28px;
      margin: 10px auto 20px auto;
      img {
        margin: 0px 20px;
        position: static;
      }
    }
  }
  @media only all and (min-width: ${sizes.ipadPro}) and (max-width: ${sizes.notebook}) {
    .acessProblem {
      a {
        h3 {
          font-size: 28px;
        }
      }
    }
  }
  @media only all and (min-width: ${sizes.notebook}) {
    max-width: 100vw;
    height: 80%;
    width: 40%;
    background: var(--color-salt-white);
    border-radius: 16px;
    position: relative;

    > div {
      padding: 2.5px 25px;
    }
    .text-area {
      padding-bottom: 0px;
      width: 80%;
    }
    .acessProblem {
      margin-top: 2rem;
    }

    a {
      width: 100%;
      height: 2rem;
      .buttonLogin {
        height: 2rem;
      }
    }
  }
  @media all and (min-width: ${sizes.notebook}) {
    width: 100%;
    a {
      width: 100%;
      height: 3rem;
      .buttonLogin {
        height: 3rem;
      }
    }
  }
`;

export const TSlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  text-align: center;
  height: 100%;

  > img {
    height: auto;
    width: 80%;
  }

  @media only all and (min-width: ${sizes.notebook}) {
    > img {
      height: 60%;
    }

    justify-content: space-evenly;
  }
`;

export const FSlideContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  text-align: center;
  height: 100%;

  > img {
    height: auto;
    width: 90%;
  }

  @media only all and (min-width: ${sizes.notebook}) {
    > img {
      height: 60%;
    }
    margin-bottom: 10px;
    justify-content: space-evenly;
  }
`;

export const SSlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  text-align: center;
  height: 100%;

  > img {
    height: auto;
    width: 90%;
  }

  @media only all and (min-width: ${sizes.notebook}) {
    > img {
      height: 50%;
    }

    justify-content: space-evenly;
  }
`;
