import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  height: 100%;
  width: 100%;
  margin: 0 auto;
  max-width: 850px;

  background-color: var(--color-popeyes-orange);
  padding-bottom: 5%;
  padding-top: var(--size-header-height);
  justify-content: space-evenly;
  h2 {
    width: 50%;
    color: var(--color-salt-white);
    font-family: 'ChickenSansRegular';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
  }

  p {
    font-family: 'ChickenSansRegular';
    color: var(--color-salt-white);
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    text-align: center;
    width: 70%;
  }
  button {
    width: 90%;
  }
  @media (min-width: ${sizes.ipadPro}) {
    max-width: 100vw;
  }
  @media (min-width: ${sizes.notebook}) {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;

    button {
      width: 305px;
    }
  }
`;

export const Content = styled.div`
  display: flex;

  height: 50%;
  max-width: 850px;

  background-color: var(--color-popeyes-orange);

  img {
    width: 96%;
  }
`;
