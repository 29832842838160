import styled from 'styled-components';
import { breakpoints } from 'styles';

export const Container = styled.div`
  background: #f5f1ef;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${breakpoints.small}) {
    max-width: 100%;
  }
`;

export const BoxProductsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  padding: 0 3%;

  @media (max-width: ${breakpoints.small}) {
    display: flex;
    padding: 0;
    /* margin-bottom: 30vh; */
  }
`;
