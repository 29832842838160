export const popeyesPrimaryColors = {
  primary: '#FF7D00',
  secondary: '#F25600',
  tertiary: '#FFC800',
  warn: '#F9DBDA',
  success: '#E5F7F6',
} as const;

export const popeyesSecondaryColors = {
  light: '#FFFFFF',
  dark: '#000000',
  teal: ' #00B2A9',
  purple: '#911987',
  red: '#D90F06',
  blue: '#0075FF',
} as const;

export const popeyesNeutral = {
  neutral01: '#F2F2F2',
  neutral02: '#F3F3F3',
  neutral03: '#FFF8DD',
  neutral04: '#D9D9D9',
  neutral05: '#BBBBBB',
  neutral06: '#929292',
  neutral07: '#515151',
  neutral08: '#3B3B3B',
  neutral09: '#303030',
} as const;

export const overlay = {
  modal: 'rgba(0, 0, 0, 0.6)',
} as const;

export const shadows = {
  shadow01: '0px 0px 20px rgba(0, 0, 0, 0.1)',
  shadow02: '0px 10px 5px 0px  rgba(0, 0, 0, 0.1)',
  shadow03:
    '0 -5px 60px 0 rgba(38, 89, 115, 0.11), 0 -1px 0 rgba(38, 89, 115, 0.05);',
  shadow04: 'rgb(0 0 0 / 10%) 0px 4px 4px',
};
