import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';

import { Container } from './styles';

const TextArea = ({ ...rest }) => {
  const { register } = useFormContext();

  const name = useMemo(() => rest.name, [rest]);

  return (
    <Container>
      <textarea name={name} {...rest} ref={register} />
    </Container>
  );
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
};

export default TextArea;
