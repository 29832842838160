import { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import ScrollDrag from 'components/ScrollDrag';

import ArrowIcon from 'assets/images/icons/back-popeyes-orange.svg';
import Button from 'components/Button';
import ModalResponsive from 'components/ModalResponsive';
import { useCoupons } from 'hooks/Coupons';

// Utils
import sizes from 'utils/screenSizes';

// Styles
import {
  ArrowUp,
  ButtonContainer,
  CategoryDropDown,
  CategoryDropDownContainer,
  CategoryName,
  CategoryRowContainer,
  CategorySelector,
  CategoryTextContainer,
  CheckboxInput,
  Container,
  CountText,
  Icon,
  ModalContent,
  Overlay,
  Title,
} from './styles';

const CategoriesComponent = ({
  onChange,
  openCategories,
  onToogleCategories,
}) => {
  const { categories } = useCoupons();

  const selectedCategoriesCount = useMemo(() => {
    const count = categories.filter(category => category.selected).length;
    return count;
  }, [categories]);

  const handleChangeCategories = id => {
    console.log(id);
    const newCategories = [...categories];
    newCategories[id].selected = !categories[id].selected;

    onChange(newCategories);
  };

  const onSelectAll = useCallback(() => {
    let newCategories = [];
    if (selectedCategoriesCount === 0) {
      newCategories = categories.map(category => {
        category.selected = true;
        return category;
      });
    } else {
      newCategories = categories.map(category => {
        category.selected = false;
        return category;
      });
    }

    onChange(newCategories);
  }, [categories]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    return {
      width,
      height,
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  useEffect(() => {
    function handleResize() {
      const dimensions = getWindowDimensions();
      setWindowDimensions(dimensions);
    }
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  const [dropDown, setDropDown] = useState(false);

  const handleDropDown = () => {
    if (dropDown) {
      setDropDown(false);
    } else {
      setDropDown(true);
    }
  };

  return (
    <>
      <Container>
        <ScrollDrag>
          {windowDimensions.width <= parseInt(sizes.notebook, 10) ? (
            <ButtonContainer>
              <CategorySelector
                selected={selectedCategoriesCount > 0}
                onClick={() => onToogleCategories(true)}
              >
                CATEGORIAS
                <CountText>
                  {selectedCategoriesCount === 0 ? (
                    <Icon src={ArrowIcon} alt="Dropdown Menu" />
                  ) : (
                    selectedCategoriesCount
                  )}
                </CountText>
              </CategorySelector>
            </ButtonContainer>
          ) : (
            <>
              <Overlay onClick={() => handleDropDown()} isActive={dropDown} />
              <ButtonContainer>
                <CategoryDropDownContainer isActive={dropDown}>
                  <ArrowUp />
                  <CategoryDropDown>
                    {/* conteudo do dropdown */}

                    <CategoryRowContainer onClick={onSelectAll}>
                      <CategoryName>Todos</CategoryName>
                      <div className="checkbox-wrapper">
                        <CheckboxInput
                          type="checkbox"
                          checked={
                            selectedCategoriesCount === 0 ||
                            selectedCategoriesCount === categories.length
                          }
                          hidden
                        />
                        <label htmlFor="Todos" className="checkmark" />
                      </div>
                    </CategoryRowContainer>
                    {categories &&
                      categories.map((category, index) => {
                        return (
                          <CategoryRowContainer
                            key={index}
                            onClick={() => handleChangeCategories(index)}
                          >
                            <CategoryName>{category.name}</CategoryName>
                            <div className="checkbox-wrapper">
                              <CheckboxInput
                                type="checkbox"
                                checked={category.selected}
                                hidden
                              />
                              <label
                                htmlFor={category.name}
                                className="checkmark"
                              />
                            </div>
                          </CategoryRowContainer>
                        );
                      })}
                    <Button onClick={() => handleDropDown()}>Filtrar</Button>
                  </CategoryDropDown>
                </CategoryDropDownContainer>
                <CategorySelector
                  onClick={() => {
                    handleDropDown();
                  }}
                  selected={selectedCategoriesCount > 0}
                >
                  CATEGORIAS
                  <CountText>
                    {selectedCategoriesCount === 0 ? (
                      <Icon src={ArrowIcon} alt="Dropdown Menu" />
                    ) : (
                      selectedCategoriesCount
                    )}
                  </CountText>
                </CategorySelector>
              </ButtonContainer>
            </>
          )}

          {categories.map((category, index) => (
            <ButtonContainer key={index} id={`category${index}`}>
              <CategorySelector
                selected={category.selected}
                onClick={() => handleChangeCategories(index)}
              >
                <CategoryTextContainer>
                  {category.name && category.name.toUpperCase()}
                </CategoryTextContainer>
              </CategorySelector>
            </ButtonContainer>
          ))}
        </ScrollDrag>
      </Container>

      <ModalResponsive
        open={openCategories}
        onClose={() => onToogleCategories(false)}
        maxModalHeight={480}
      >
        <ModalContent>
          <Title>Categoria</Title>
          <CategoryRowContainer onClick={onSelectAll}>
            <CategoryName>Todos</CategoryName>
            <div className="checkbox-wrapper">
              <CheckboxInput
                type="checkbox"
                checked={
                  selectedCategoriesCount === 0 ||
                  selectedCategoriesCount === categories.length
                }
                hidden
              />
              <label htmlFor="Todos" className="checkmark" />
            </div>
          </CategoryRowContainer>
          {categories &&
            categories.map((category, index) => (
              <CategoryRowContainer
                key={index}
                onClick={() => handleChangeCategories(index)}
              >
                <CategoryName>{category.name}</CategoryName>
                <div className="checkbox-wrapper">
                  <CheckboxInput
                    type="checkbox"
                    checked={category.selected}
                    hidden
                  />
                  <label htmlFor={category.name} className="checkmark" />
                </div>
              </CategoryRowContainer>
            ))}
          <Button onClick={() => onToogleCategories(false)}>Filtrar</Button>
        </ModalContent>
      </ModalResponsive>
    </>
  );
};

CategoriesComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  openCategories: PropTypes.bool.isRequired,
  onToogleCategories: PropTypes.func.isRequired,
};

export default CategoriesComponent;
