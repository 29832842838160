import { Route as ReactDOMRoute, Redirect } from 'react-router-dom';

import { useAuth } from 'hooks/Auth';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTagManager } from 'hooks/Analytics';

const Route = ({ isPrivate, component: Component, title, ...rest }) => {
  const { user } = useAuth();

  const { sendPageViewEvent } = useTagManager();

  useEffect(() => sendPageViewEvent(rest.path, title), []);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location, ...props }) => {
        return !user?.signed && isPrivate ? (
          <Redirect
            to={{
              pathname: '/onboarding',
              state: { from: location },
            }}
          />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

Route.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.node.isRequired,
  title: PropTypes.string,
};

Route.defaultProps = {
  isPrivate: false,
  title: '',
};

export default Route;
