/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { createContext, useCallback, useState } from 'react';

import axios from 'axios';
import { TSession } from 'components/Pages/Menu/HeaderSearch/interfaces';
import { IPlace } from 'components/Pages/Menu/PlacesItem/interfaces';
import { LatLngTuple } from 'leaflet';
import { manipulateGetStore } from 'source';
import { isMobile } from 'utils/isMobile';

import * as T from './interfaces';

export const StoreContext = createContext({} as T.IStoreContext);
export function StoreContextProvider({ children }: any) {
  const [mapMode, setMapMode] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [loading, setLoading] = useState(() => {
    const firstTimeAskingForSharing = localStorage.getItem(
      '@Popeyes.firstTimeAskingForSharing',
    );

    return !(
      firstTimeAskingForSharing === undefined ||
      firstTimeAskingForSharing === null
    );
  });
  const [mapPermission, setMapPermission] = useState(false);
  const [userPosition, setUserPosition] = useState<T.TUserPosition>(() => {
    const position = localStorage.getItem('@Popeyes.position');
    const isSharing = localStorage.getItem('@Popeyes.isSharing');
    const firstTimeAskingForSharing = localStorage.getItem(
      '@Popeyes.firstTimeAskingForSharing',
    );

    return {
      position: position ? JSON.parse(position) : null,
      isSharing: isSharing ? JSON.parse(isSharing) : null,
      firstTimeAskingForSharing: JSON.parse(firstTimeAskingForSharing),
    };
  });
  const [searchedLocation, setSearchedLocation] = useState<LatLngTuple>(null);
  const [listPlacesSuggestion, setListPlacesSuggestion] = useState([]);
  const [session, setSession] = useState<TSession>('nearStores');
  const [places, setPlaces] = useState<IPlace[]>([] as IPlace[]);
  const [placeSelected, setPlaceSelected] = useState<IPlace>({} as IPlace);
  const [favorites, setFavorites] = useState<string[]>(() =>
    localStorage.getItem('@Popeyes.favorites')
      ? JSON.parse(localStorage.getItem('@Popeyes.favorites') || '')
      : [],
  );

  const handleFavorites = useCallback(
    (id: string) => {
      if (favorites.includes(id)) {
        setFavorites(favorites.filter(item => item !== id));
        localStorage.setItem(
          '@Popeyes.favorites',
          JSON.stringify(favorites.filter(item => item !== id)),
        );
      } else {
        setFavorites([...favorites, id]);
        localStorage.setItem(
          '@Popeyes.favorites',
          JSON.stringify([...favorites, id]),
        );
      }
    },
    [favorites],
  );

  const toggleListPlacesWithPosition = useCallback(
    async (latitude: any, longitude: any) => {
      try {
        const { data } = await axios.get(
          'https://plk-microservice-store-ummgmx7irq-ue.a.run.app/distance',
          {
            params: {
              latitude,
              longitude,
            },
          },
        );
        const placesFormatted = manipulateGetStore(data);

        const orderPlaces = placesFormatted
          .sort(
            (a: any, b: any) =>
              parseInt(a.distance, 10) - parseInt(b.distance, 10),
          )
          .sort((a: any, b: any) => a.isOpen - b.isOpen);

        setPlaces(orderPlaces);
        setPlaceSelected(orderPlaces[0]);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    },
    [setPlaces, setPlaceSelected, userPosition],
  );

  const toggleSearchedLocation = useCallback(
    (position: LatLngTuple) => {
      setSearchedLocation(position);
    },
    [searchedLocation, setSearchedLocation],
  );

  const handleFirstTimeAskingForSharing = useCallback(() => {
    localStorage.setItem(
      '@Popeyes.firstTimeAskingForSharing',
      JSON.stringify(false),
    );
  }, [setUserPosition]);

  const toggleListPlaces = useCallback(async () => {
    try {
      const { data } = await axios.get(
        'https://plk-microservice-store-ummgmx7irq-ue.a.run.app/store',
      );
      const placesFormatted = manipulateGetStore(data);

      const orderPlaces = placesFormatted.sort(
        (a: any, b: any) => a.isOpen - b.isOpen,
      );

      setPlaces(orderPlaces);
      setPlaceSelected(orderPlaces[0]);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, [setPlaces, setPlaceSelected]);

  const handleCreateOverlay = useCallback(() => {
    const Overlay = document.createElement('div');
    Overlay.style.display = 'flex';
    Overlay.style.position = 'fixed';
    Overlay.style.top = '0';
    Overlay.style.left = '0';
    Overlay.style.zIndex = '20';
    Overlay.style.width = '100%';
    Overlay.style.height = '100%';
    Overlay.style.backgroundColor = 'rgba(0,0,0,0.5)';
    Overlay.id = 'overlayLocation';
    const divRoot = document.getElementById('root');
    divRoot.appendChild(Overlay);
  }, []);

  const handleRemoveOverlay = useCallback(() => {
    const Overlay = document.getElementById('overlayLocation');
    const divRoot = document.getElementById('root');
    divRoot.removeChild(Overlay);
  }, []);

  const loadListPlaces = useCallback(async () => {
    await navigator.geolocation.getCurrentPosition(
      async ({ coords }) => {
        localStorage.setItem(
          '@Popeyes.position',
          JSON.stringify([coords.latitude, coords.longitude]),
        );
        localStorage.setItem(
          '@Popeyes.isSharing',
          JSON.stringify({
            sharing: true,
          }),
        );

        changeUserPosition({
          isSharing: true,
          position: [coords.latitude, coords.longitude],
        });

        await toggleListPlacesWithPosition(coords.latitude, coords.longitude);
      },
      async () => {
        await toggleListPlaces();
        localStorage.setItem('@Popeyes.position', JSON.stringify(null));

        localStorage.setItem(
          '@Popeyes.isSharing',
          JSON.stringify({
            sharing: false,
          }),
        );
      },
    );
  }, [toggleListPlaces, toggleListPlacesWithPosition]);

  const handleModal = useCallback(
    (value: any) => {
      setOpenModal(value);
    },
    [openModal, setOpenModal],
  );

  const changeCurrentSelected = useCallback(
    (index: any) => {
      const selected = places.find(place => place.id === index);
      selected && setPlaceSelected(selected);
      isMobile() && handleModal(true);
    },
    [setPlaceSelected, places],
  );

  const changeViewMode = useCallback(() => {
    setMapMode(prev => !prev);
  }, [setMapMode]);

  const changePlacesSuggestion = useCallback(
    (arrayPlacesSuggestion: React.ReactNode[]) => {
      setListPlacesSuggestion(arrayPlacesSuggestion);
    },
    [setListPlacesSuggestion],
  );
  const changeUserPosition = useCallback(
    (position: T.TUserPosition) => {
      position?.isSharing &&
        localStorage.setItem(
          '@Popeyes.isSharing',
          JSON.stringify({
            sharing: position?.isSharing,
          }),
        );
      position?.position &&
        localStorage.setItem(
          '@Popeyes.position',
          JSON.stringify(position?.position),
        );
      setUserPosition(position);
    },
    [setUserPosition, userPosition],
  );

  const changeSession = useCallback(
    (session: TSession) => {
      setSession(session);
    },
    [setSession],
  );

  return (
    <StoreContext.Provider
      value={{
        mapMode,
        mapPermission,
        userPosition,
        listPlacesSuggestion,
        session,
        placeSelected,
        places,
        openModal,
        loading,
        searchedLocation,
        favorites,
        handleFavorites,
        handleFirstTimeAskingForSharing,
        handleCreateOverlay,
        handleRemoveOverlay,
        toggleListPlacesWithPosition,
        toggleSearchedLocation,
        setMapMode,
        changeCurrentSelected,
        changeViewMode,
        changePlacesSuggestion,
        changeUserPosition,
        changeSession,
        loadListPlaces,
        toggleListPlaces,
        handleModal,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
}
