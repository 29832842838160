import React, { useEffect, useState } from 'react';
import { Marker, useMap } from 'react-leaflet';

import UserMarker from 'assets/images/marker/user-marker.svg';
import L from 'leaflet';

interface IMapUserMarker {
  userPosition: L.LatLngTuple;
}

const MapUserMarker = ({ userPosition }: IMapUserMarker) => {
  const [position, setPosition] = useState(null);

  const map = useMap();
  useEffect(() => {
    async function verifyUserPermission() {
      map.locate().on('locationfound', function (e) {
        setPosition(e.latlng);

        const circle = L.circle(e.latlng, {
          radius: 50,
          stroke: false,
          fillColor: '#FFC80033',
          fillOpacity: 20,
        });
        circle.addTo(map);
      });
    }
    verifyUserPermission();
  }, [map, navigator, userPosition]);

  const icon = L.icon({
    iconSize: [15, 15],
    iconAnchor: [7.5, 7.5],
    iconUrl: UserMarker,
  });

  return position === null ? null : <Marker position={position} icon={icon} />;
};

export default MapUserMarker;
