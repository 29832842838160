import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';

// Components
import * as Components from 'components';
import ButtonComponent from 'components/Button';
import InputCode from 'components/InputCode';
import ModalResponsive from 'components/ModalResponsive';
import SafetySection from 'components/SafetySection';
import TitleSection from 'components/TitleSection';

// Services, Utils and Hooks
import { useAuth } from 'hooks/Auth';
import { api } from 'services';
import firebase from 'services/firebase';
import numberSecretMask from 'utils/numberSecretMask';

import { Container, Form, ResendSMS, ModalContent } from './styles';
import schema from './validation';

// Styles

export default function SmsVerify() {
  const { user, numberValidation } = useAuth();
  const history = useHistory();

  const [counter, setCounter] = useState(120);
  const [isInvalid, setIsInvalid] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const subTitle = useState(
    <>
      Um código de verificação foi enviado para o número
      <strong> 55 {numberSecretMask(user?.cellNumber)}. </strong>
      Aguarde até 2 minutos para o recebimento do SMS.
    </>,
  );

  const subTitleModal = useState(
    <p>
      Volte e verifique novamente o código de verificação que foi enviado por
      SMS para o número <strong>55 {numberSecretMask(user?.cellNumber)}</strong>
      .
    </p>,
  );

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      token: '',
    },
  });
  const { formState } = methods;

  const sendNewSms = async () => {
    setCounter(120);
    await api.post('/authentication/v0.1/cpfsms', {
      cpf: user.cpf,
    });
  };

  const sendSmsCloseModal = async () => {
    setIsInvalid(false);
    setCounter(120);

    await api.post('/authentication/v0.1/cpfsms', {
      cpf: user.cpf,
    });
  };

  const onSubmit = async dataForm => {
    setLoadingButton(true);
    let tokens = '';
    tokens += dataForm.name1;
    tokens += dataForm.name2;
    tokens += dataForm.name3;
    tokens += dataForm.name4;

    tokens = String(tokens);

    try {
      // trying validate sended token
      const response = await numberValidation({
        PIN: tokens,
        contact_number: user.cellNumber,
      });

      firebase.analytics().logEvent('cadastro_continuar', {
        etapa_cadastro: 'cod_auth_pwa',
      });

      if (response.data) {
        history.push('/numero-cadastrado');
      }
    } catch (err) {
      setIsInvalid(true);
      firebase.analytics().logEvent('modal_erro', {
        tipo_erro: 'token_invalido_sms',
      });
    }

    methods.reset();
    setLoadingButton(false);
  };

  return (
    <>
      <Container>
        <div className="content">
          <TitleSection
            title="Insira o código de verificação"
            subtitle={subTitle}
          />
        </div>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="countdown-code">
              <InputCode
                name1="name1"
                name2="name2"
                name3="name3"
                name4="name4"
              />
              <Components.Countdown
                counter={counter}
                onChange={newCounter => setCounter(newCounter)}
                onFinish={() => {}}
              />
            </div>

            <div className="container-buttons">
              <SafetySection />
              <ButtonComponent type="submit" disabled={!formState.isValid}>
                {loadingButton ? 'Carregando...' : 'Confirmar'}
              </ButtonComponent>
              <ButtonComponent
                size="S"
                variant="secondary"
                onClick={sendNewSms}
                disabled={counter > 0}
              >
                Reenviar SMS
              </ButtonComponent>
              <ButtonComponent
                type="button"
                size="S"
                variant="secondary"
                onClick={() => {
                  firebase.analytics().logEvent('ajudarecuperacao');
                  history.push({
                    pathname: '/enviar-email',
                    search: `?${new URLSearchParams({
                      href: `/token-recuperacao`,
                      title: 'Fale conosco',
                      isHelp: true,
                      isRecover: true,
                      subject: 'Problemas com acesso na minha conta Popeyes',
                    }).toString()}`,
                  });
                }}
              >
                Preciso de ajuda
              </ButtonComponent>
            </div>
          </Form>
        </FormProvider>
      </Container>

      <ModalResponsive
        maxModalHeight={380}
        sizeModal="middle"
        scrollable={false}
        open={isInvalid}
        onClose={() => setIsInvalid(false)}
      >
        <ModalContent>
          <h3 className="title">Código de verificação inválido</h3>
          {subTitleModal}
          <ButtonComponent
            className="button-modal"
            font="bold"
            onClick={() => history.push('/definir-numero-recuperacao')}
          >
            Conferir número
          </ButtonComponent>

          <ResendSMS onClick={sendSmsCloseModal}>Reenviar SMS</ResendSMS>
        </ModalContent>
      </ModalResponsive>
    </>
  );
}
