/* eslint-disable import-helpers/order-imports */
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';

// Components
import Button from 'components/Button';
import InputNumber from 'components/InputNumber';
import TitleSection from 'components/TitleSection';
import { useAuth } from 'hooks/Auth';
import LoadingPop from 'pages/Loading';

// Hooks
import { useTagManager, ButtonEvents } from 'hooks/Analytics';

import { Container, Form } from './styles';
import schema from './validation';

// Styles

// Images

export default function SignInNumber() {
  const { signIn, user } = useAuth();
  const history = useHistory();

  const { sendButtonClickEvent } = useTagManager();

  const [isLoading, setIsLoading] = useState(false);

  const fieldDDD = user?.cellNumber ? user.cellNumber.substr(0, 2) : '';
  const fieldNumber = user?.cellNumber ? user.cellNumber.substr(2, 9) : '';

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ddd: fieldDDD === '[o' ? '' : fieldDDD,
      celNumber: fieldNumber === 'bject Obj' ? '' : fieldNumber,
    },
  });
  const { formState } = methods;

  const onSubmitLogin = async dataForm => {
    setIsLoading(true);
    const number = String(dataForm.ddd.concat(dataForm.celNumber));

    sendButtonClickEvent(ButtonEvents.LOGIN_ACTION_PHONE_CONTINUAR);

    try {
      const response = await signIn({ contact_number: number });

      if (response.data && response.data.sms_confirmed) {
        history.push({
          pathname: '/verificar',
          search: `?${new URLSearchParams({
            href: '/login',
          }).toString()}`,
        });
      } else {
        history.push({ pathname: '/registro' });
      }
    } catch (err) {
      if (err?.response?.status === 404) {
        history.push({ pathname: '/registro' });
      }
    }

    methods.reset();
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <LoadingPop />}

      <Container>
        <TitleSection title="Informe o número de seu celular" />
        <FormProvider {...methods}>
          <Form>
            <InputNumber
              defaultValues={fieldDDD}
              nameDDD="ddd"
              textDDD="DDD"
              namePhone="celNumber"
              textPhone="Número"
            />
            <div className="safetyContainer">
              <p>
                Este é um ambiente seguro e todas as suas informações estão
                protegidas.
              </p>
              <Button
                type="button"
                onClick={methods.handleSubmit(onSubmitLogin)}
                disabled={!formState.isValid}
              >
                Continuar
              </Button>

              <Button
                type="button"
                variant="secondary"
                size="S"
                onClick={() => {
                  sendButtonClickEvent(
                    ButtonEvents.LOGIN_ACTION_TROUBLESHOOTING,
                  );
                  history.push({
                    pathname: '/validado',
                    search: `?${new URLSearchParams({
                      href: '/login',
                    }).toString()}`,
                  });
                }}
              >
                Problemas com o acesso
              </Button>
            </div>
          </Form>
        </FormProvider>
      </Container>
    </>
  );
}
