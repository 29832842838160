import styled, { css } from 'styled-components';
import {
  font,
  popeyesPrimaryColors,
  popeyesSecondaryColors,
  spacings,
  ZIndex,
  popeyesNeutral,
  transitions,
  breakpoints,
} from 'styles';

import * as T from './interfaces';

export const BoxMessage = styled.span`
  display: block;
  position: absolute;
  top: ${spacings.space09};
  z-index: ${ZIndex.projecting};

  height: ${spacings.space04};
  margin-top: ${spacings.space01};
  padding-left: ${spacings.space04};
  font-size: ${spacings.space03};
  line-height: ${spacings.space04};
  color: ${popeyesNeutral.neutral05};
`;

export const Icon = styled.div`
  height: ${spacings.space05};
  width: ${spacings.space05};
  margin-right: ${spacings.space05};

  opacity: 0.75;
  background: transparent;
  border: none;

  transition: ${transitions.all02};

  img {
    height: ${spacings.space05};
    width: ${spacings.space05};
  }

  &:hover {
    opacity: 1;
  }
`;

export const AccessibilityLabel = styled.span`
  visibility: hidden;
`;
export const Label = styled.label`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: ${spacings.space04} ${spacings.space04};
  font-size: ${font.sizes.textMedium};
  line-height: ${font.lineHeight.small};
  color: ${popeyesNeutral.neutral06};
  transition: ${transitions.all01};
`;

export const Input = styled.input`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  font-size: ${font.sizes.textMedium};
  line-height: ${font.lineHeight.small};
  outline: none;

  padding: ${spacings.space06} ${spacings.space10} ${spacings.space03}
    ${spacings.space06};
  color: ${popeyesNeutral.neutral08};

  &::placeholder {
    color: transparent;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  border-radius: ${spacings.space01};
  align-items: center;
  width: 100%;
  background: ${popeyesNeutral.neutral01};
`;

const InputWrapperModifier = {
  focus: () => css`
    ${InputWrapper} {
      :after {
        height: ${spacings.space00};
      }
    }

    ${Input} {
      :focus + label {
        margin-top: ${spacings.space01};
        font-size: ${font.sizes.textXSmall};
        line-height: ${font.lineHeight.small};
      }
    }
  `,
  error: () => css`
    ${InputWrapper} {
      :after {
        background-color: ${popeyesSecondaryColors.red};
      }
    }
    ${BoxMessage} {
      color: ${popeyesSecondaryColors.red};
    }
  `,
  isFilled: () => css`
    ${Icon} {
      img {
        margin-right: ${spacings.space03};
      }
    }

    ${Label} {
      margin-top: ${spacings.space01};
      font-size: ${font.sizes.textXSmall};
      line-height: ${font.lineHeight.small};
    }
  `,
  disabled: () => css`
    ${InputWrapper} {
      border-radius: ${spacings.space01} ${spacings.space01} 0 0;
      &:after {
        height: 0;
      }
    }

    ${Input} {
      color: ${popeyesNeutral.neutral03};
    }

    ${Label} {
      color: ${popeyesNeutral.neutral03};
    }
  `,

  loading: () => css`
    @keyframes around {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    position: relative;

    .loader-icon {
      position: absolute;
      right: ${spacings.space05};
      top: 50%;
      transform: translateY(-50%);
      transition: ${transitions.opacity01};
    }

    .loader {
      position: relative;
      height: ${spacings.space05};
      width: ${spacings.space05};
      display: inline-block;
      animation: around 5.4s infinite;

      :after,
      :before {
        content: '';
        background: transparent;
        position: absolute;
        display: inline-block;
        width: 100%;
        height: 100%;
        border-width: 2px;
        border-color: ${popeyesPrimaryColors.secondary}
          ${popeyesPrimaryColors.secondary} transparent transparent;
        border-style: solid;
        border-radius: 20px;
        box-sizing: border-box;
        top: 0;
        left: 0;
        animation: around 0.7s ease-in-out infinite;
      }

      :after {
        animation: around 0.7s ease-in-out 0.1s infinite;
        background: transparent;
      }
    }
  `,
  type: {
    search: () => css`
      ${Input} {
        /* clears the 'X' from Internet Explorer */
        [type='search']&::-ms-clear,
        [type='search']&::-ms-reveal {
          display: none;
          width: 0;
          height: 0;
        }

        /* clears the 'X' from Chrome */
        [type='search']&::-webkit-search-decoration,
        [type='search']&::-webkit-search-cancel-button,
        [type='search']&::-webkit-search-results-button,
        [type='search']&::-webkit-search-results-decoration {
          display: none;
        }
      }

      ${Label} {
        margin-left: ${spacings.space10};

        @media (max-width: ${breakpoints.xSmall}) {
          margin: ${spacings.space04} ${spacings.space00} ${spacings.space04}
            ${spacings.space09};
        }
      }

      ${Icon}:first-child {
        margin-right: 0;
        margin-left: ${spacings.space05};
      }
    `,
    number: () => css`
      ${Input} {
        [type='number']&::-webkit-inner-spin-button,
        [type='number']&::-webkit-outer-spin-button {
          -webkit-appearance: none;
        }
      }
    `,
    email: () => css``,
    password: () => css``,
    tel: () => css``,
    text: () => css``,
  },
};

export const Wrapper = styled.div<T.IInputStyles>`
  position: relative;
  width: 100%;
  ${({ focused, disabled, error, isFilled, loading, type }) => css`
    ${focused && InputWrapperModifier.focus()};
    ${error && InputWrapperModifier.error()};
    ${disabled && InputWrapperModifier.disabled()};
    ${isFilled && InputWrapperModifier.isFilled()};
    ${loading ? InputWrapperModifier.loading() : css``};
    ${type && InputWrapperModifier.type[type]()};
  `}
`;
