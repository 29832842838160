import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface IButtonLinkProps {
  link?: string;
}

export const ButtonLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonLink = styled(Link)<IButtonLinkProps>`
  display: flex;
  align-items: center;
  /* justify-content: lefy; */

  top: 68%;
  position: absolute;

  width: 335px;
  height: 50px;
  color: #ff7d00;
  background: #fff;
  /* padding: 0px 20px 4px 110px; */
  border-radius: 6px;
  gap: 16px;

  svg {
    padding-left: 4px;
  }

  p {
    font-family: 'ChickenSansBold' !important;
    font-weight: 700;
    font-size: 20px;
  }

  @media (min-width: 1300px) {
    top: 73%;
  }
`;
