import styled, { css } from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  margin: 0 auto;
  max-width: var(--size-max-width-container);

  padding: 0px 24px;
  padding-top: var(--size-header-height);

  font-family: 'ChickenSansRegular';
  font-style: normal;
  font-weight: normal;

  h1 {
    margin: 32px 0px;

    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
  }

  h2 {
    margin: 24px 0px;

    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }
`;

export const Form = styled.form`
  padding-bottom: ${({ fixed }) => (fixed ? '90px' : '40px')};

  > div {
    margin: 12px 0px;
  }

  @media (min-width: ${sizes.notebook}) {
    padding-bottom: 40px;
  }
`;

export const GenderContent = styled.div`
  display: flex;
  height: 48px;

  border-radius: 6px;
  background: #f3f3f3;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `}
`;

export const GenderItem = styled.button`
  width: 100%;

  background: transparent;
  border: none;

  font-family: 'ChickenSansRegular' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 17px !important;

  :nth-child(2) {
    border-radius: 0px;
    ${({ selected }) =>
      !selected &&
      css`
        border-left: 1px solid var(--color-text-switch);
        border-right: 1px solid var(--color-text-switch);
      `}
  }

  :nth-child(1) {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  :nth-child(3) {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  ${({ selected }) =>
    selected
      ? css`
          color: white;
          background: var(--color-burnt-orange);
          border-color: none !important;
        `
      : css`
          background: transparent;
          color: var(--color-text-switch);
        `}

  outline: none !important;
  cursor: pointer;
`;

export const GenderNoReply = styled.button`
  display: flex;
  align-items: center;

  margin-left: auto;
  margin-bottom: 50px !important;

  color: var(--color-text-switch);

  font-family: 'ChickenSansRegular' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 14px !important;

  background: transparent;
  border: none;
  outline: none !important;
`;

export const SignOut = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-top: 20px;
  margin-left: auto;
  color: var(--color-burnt-orange);

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  img {
    height: 15px;
    margin-right: 8px;
  }

  background: transparent;
  border: none;

  @media (min-width: ${sizes.notebook}) {
    display: none;
  }
`;

export const SaveSection = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 12px;

  @media only all and (max-width: ${sizes.mobile}) {
    ${({ fixed }) =>
      fixed &&
      css`
        position: fixed;
        background: white;

        bottom: 0;
        left: 0;
        right: 0;
        padding: 24px;
        margin: 0px !important;
      `}
  }

  @media (min-width: ${sizes.notebook}) {
    ${({ fixed }) =>
      fixed &&
      css`
        position: sticky;
        background: white;

        bottom: 0;
        left: 0;
        right: 0;
        padding-bottom: 24px;
        margin: 0px !important;
      `}
    button {
      width: 350px;
    }
  }

  transition: bottom 0.5s;
`;

export const ModalContent = styled.div`
  padding: 24px 24px 0px 24px;
  font-family: 'ChickenSansRegular';
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin: 0px 24px 24px 24px;
    text-align: center;
  }

  p {
    margin-bottom: 24px;
    text-align: center;
  }

  a {
    text-decoration: underline;
    color: var(--color-burnt-orange);
    margin-left: 5px;
  }

  button {
    margin-bottom: 10px;
  }
  @media (min-width: ${sizes.notebook}) {
    padding: 0;
  }
`;
