import { WeekdayFormatter } from 'utils/formatter/Date';

import * as T from './interfaces';

export const manipulateGetStore: T.TManipulateGetStore = (
  data: T.TParamsGetStore[],
) => {
  const places: T.TReturnGetStore[] = data.map((place: T.TParamsGetStore) => {
    const address = place?.address;
    const ordenateDates = () => {
      const today = new Date().getDay();
      const cronCopy = [...place.cron];

      const realocate = cronCopy.splice(0, today);

      return cronCopy.concat(realocate);
    };

    const longAddress = `${address?.street}, ${address?.city} - ${address?.state}, ${address?.zipcode}`;
    const placeFormatted: T.TReturnGetStore = {
      id: Number(place?.bk_number),
      bk_number: place?.bk_number,
      name: place?.name,
      address: longAddress,
      floor: address?.floor <= 0 ? 'Térreo' : `${address?.floor}º piso`,
      complement: address?.complement,
      isOpen: place?.status === 'aberto',
      distance:
        place?.location?.distance &&
        `${Math.round(place?.location?.distance)}km`,
      location: [place?.location?.latitude, place?.location?.longitude],
      openingHours: ordenateDates().map((cron: T.TCron) => ({
        day: WeekdayFormatter.numberToStringPT(cron.weekday),
        hours: {
          open: cron.open,
          close: cron.close,
        },
      })),
    };
    return placeFormatted;
  });
  return places;
};
