import React from 'react';
import styled from 'styled-components';
import {
  breakpoints,
  font,
  popeyesNeutral,
  popeyesPrimaryColors,
} from 'styles';
import { IFilterCardapioItemStyles } from './interfaces';

export const Container = styled.button<IFilterCardapioItemStyles>`
  height: 40px;
  width: 250px;

  font-size: ${font.sizes.textMedium};
  padding: 0 5px;
  margin: 0 10px;

  font-weight: ${props => (props.isSelected ? 'bold' : 'regular')};
  border: none;
  border-bottom: ${props =>
    props.isSelected && `3px solid ${popeyesPrimaryColors.primary}`};

  background: #fff;
  color: ${props =>
    props.isSelected ? popeyesNeutral.neutral09 : popeyesNeutral.neutral06};

  cursor: pointer;

  :focus {
    outline: 0 !important;
  }

  @media (max-width: ${breakpoints.intermediary}) {
  }

  @media (min-width: ${breakpoints.intermediary}) {
  }
`;
