import React, { useEffect, useRef, useState } from 'react';

import BackButton from 'components/BackButton';
import FooterComponent from 'components/FooterComponent';
import HeaderComponent from 'components/HeaderComponent';
import { ValidatedContainer } from 'containers';
import { useHeader } from 'hooks/HeaderContext';
import { AuthLayout } from 'layouts';

import { Container } from './styles';

function Validated() {
  const [urlBack, setUrlBack] = useState('/login');
  const { handleRef } = useHeader();
  const containerRef = useRef();

  useEffect(() => {
    handleRef(containerRef);
    const params = new URL(window.location).searchParams;
    const href = params.get('href');
    if (href) setUrlBack(href);
  }, []);

  return (
    <Container ref={containerRef}>
      <HeaderComponent title="Validação" href={urlBack} />
      <BackButton />
      <AuthLayout ref={containerRef}>
        <ValidatedContainer />
      </AuthLayout>
      <FooterComponent />
    </Container>
  );
}

export default Validated;
