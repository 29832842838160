import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';

// Components
import * as Components from 'components';
import ButtonComponent from 'components/Button';
import InputCode from 'components/InputCode';
import SafetySection from 'components/SafetySection';
import TitleSection from 'components/TitleSection';

// Hooks, Utils and Services
import { ButtonEvents, ExtraEvents, useTagManager } from 'hooks/Analytics';
import { useAuth } from 'hooks/Auth';
import { api } from 'services';
import firebase from 'services/firebase';
import emailSecretMask from 'utils/emailSecretMask';
import numberSecretMask from 'utils/numberSecretMask';
import * as statusSMS from 'utils/smsStatus';

// Modals
import ModalEndCountdown from './components/ModalEndCountdown';
import ModalErrorCode from './components/ModalErrorCode';

// Styles
import { Container, Form } from './styles';
import schema from './validation';

export default function SmsVerify() {
  const isFacebookFlow = useMemo(() => {
    const params = new URL(window.location).searchParams;
    const facebookUser = params.get('facebookUser');
    return !!(facebookUser && JSON.parse(facebookUser));
  }, []);

  const [counter, setCounter] = useState(120);

  const { sendExtraEvents, sendButtonClickEvent } = useTagManager();

  const {
    user,
    accessToken,
    customerId,
    numberValidation,
    messageToken,
    updateMessageToken,
  } = useAuth();
  const history = useHistory();

  const onBackButtonEvent = e => {
    e.preventDefault();
    history.push('/login');
  };

  useEffect(() => {
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);

  const [urlBack, setUrlBack] = useState('/login');
  const [loadingButton, setLoadingButton] = useState(false);
  const [isModalFinish, setIsModalFinish] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isEmail, setIsEmail] = useState(false);

  const subTitle = useMemo(() => {
    if (isEmail) {
      return (
        <>
          Um código de verificação foi enviado para o e-mail
          <strong>
            {user?.email ? ` ${emailSecretMask(user.email)}. ` : '. '}
          </strong>
          Aguarde até 2 minutos para o recebimento do código.
        </>
      );
    }
    return (
      <>
        Um código de verificação foi enviado para o número
        <strong> 55 {numberSecretMask(user?.cellNumber)} </strong>
        Aguarde até 2 minutos para o recebimento do SMS
      </>
    );
  }, [isEmail, user]);

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      token: '',
    },
  });
  const { formState } = methods;

  const handleSendNewSms = async ({ sendEmail }) => {
    setCounter(120);
    let response;
    try {
      if (user.cpf) {
        if (sendEmail) {
          response = await api.post('/customer/v0.1/cpfemail/accountrecovery', {
            cpf: user.cpf,
          });
        } else {
          response = await api.post('/authentication/v0.1/cpfsms', {
            cpf: user.cpf,
          });
        }
      } else if (isFacebookFlow) {
        response = await api.post('/authentication/v0.1/facebookid/login', {
          facebook_id: accessToken,
          send_email: !!sendEmail,
        });
      } else {
        response = await api.post(
          '/authentication/v0.1/contactnumbersms/login',
          {
            contact_number: user?.cellNumber,
            send_email: !!sendEmail,
          },
        );
      }

      if (response?.data) {
        const auxMessageToken = {
          messageId: response.data.messageId,
          statusGroup: response.data.status_group,
          statusSms: response.data.status_sms,
        };
        updateMessageToken(auxMessageToken);
      }
    } catch (error) {
      if (error.response.status === 422) {
        updateMessageToken({
          messageId: '',
          statusGroup: statusSMS.REJECTED,
          statusSms: '',
        });
      }
    }
  };

  useEffect(() => {
    const params = new URL(window.location).searchParams;
    const href = params.get('href');
    if (href) setUrlBack(href);

    const openModalFinishParams = params.get('openModalFinish');
    if (openModalFinishParams) setCounter(0);

    const openIsInvalid = params.get('openIsInvalid');
    if (openIsInvalid) setIsInvalid(true);

    const isEmailParams = params.get('isEmail');
    if (isEmailParams && JSON.parse(isEmailParams)) setIsEmail(true);

    if ((!customerId && !isFacebookFlow) || (isFacebookFlow && isEmailParams)) {
      if (!(openModalFinishParams && JSON.parse(openModalFinishParams)))
        handleSendNewSms({
          sendEmail: isEmailParams && JSON.parse(isEmailParams),
        });
    }
  }, []);

  const sendNewSms = async ({ sendEmail }) => {
    setIsInvalid(false);
    setIsModalFinish(false);

    setTimeout(() => {
      if (sendEmail) {
        firebase.analytics().logEvent('token_email');
        setIsEmail(true);
      } else {
        setIsEmail(false);
      }
    }, 500);

    handleSendNewSms({ sendEmail });
  };

  const onSubmit = async dataForm => {
    setLoadingButton(true);
    sendButtonClickEvent(ButtonEvents.FINISH_LOGIN_ACTION_SMS);
    let tokens = '';
    tokens += dataForm.name1;
    tokens += dataForm.name2;
    tokens += dataForm.name3;
    tokens += dataForm.name4;

    tokens = String(tokens);

    try {
      // trying validate sended token
      const params = { PIN: tokens };
      const cuponVisited = localStorage.getItem('@Popeyes.cupon.visited.v2');
      if (isFacebookFlow) params.facebook_id = accessToken;
      else params.contact_number = user?.cellNumber;

      const response = await numberValidation(params);

      sendExtraEvents(ExtraEvents.SUCCESS_LOGIN);
      if (cuponVisited !== null) {
        if (response.data)
          history.push(`/cupom?code=${JSON.parse(cuponVisited)}`);
        localStorage.removeItem('@Popeyes.cupon.visited.v2');
      } else if (response.data) history.push('/');
    } catch (err) {
      setIsInvalid(true);
      firebase.analytics().logEvent('modal_erro', {
        tipo_erro: isEmail ? 'token_invalido_email' : 'token_invalido_sms',
      });
    }

    methods.reset();
    setLoadingButton(false);
  };

  const verifyMessageToken = useCallback(async () => {
    if (messageToken?.statusGroup === statusSMS.PENDING && !isEmail) {
      try {
        const response = await api.get(
          '/authentication/v0.1/omni-failover-report',
          {
            params: {
              channel: 'SMS',
              messageId: messageToken.messageId,
            },
          },
        );

        if (response.data) {
          updateMessageToken({
            group_name: response.data.group_name,
            messageId: response.data.messageId,
            status_sms: response.data.status_sms,
          });

          if (
            response.data.group_name === statusSMS.REJECTED ||
            response.data.group_name === statusSMS.EXPIRED
          ) {
            setCounter(0);
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else if (
      messageToken?.statusGroup === statusSMS.REJECTED ||
      messageToken?.statusGroup === statusSMS.EXPIRED
    ) {
      setCounter(0);
    }
  }, [messageToken]);

  useEffect(() => {
    if (counter === 30 || counter === 60 || counter === 90) {
      verifyMessageToken();
    }
  }, [counter]);

  const isRegister = useMemo(() => {
    const url = new URL(window.location.origin + urlBack);
    return url.pathname === '/registro' || url.pathname === '/validar-numero';
  }, [urlBack]);

  const getUrlback = useMemo(() => {
    const url = new URL(window.location.origin + urlBack);
    const pathName =
      isRegister && isFacebookFlow && isEmail ? '/registro' : url.pathname;

    url.searchParams.set('goToValidateEmail', isEmail);
    if (isFacebookFlow) url.searchParams.set('facebookUser', isFacebookFlow);

    return `${pathName}${url.search}`;
  }, [urlBack, isEmail, isFacebookFlow, isRegister]);

  return (
    <>
      <Container>
        <div className="content">
          <TitleSection
            title="Insira o código de verificação"
            subtitle={subTitle}
          />
        </div>

        <FormProvider {...methods}>
          <Form isFacebookFlow={isFacebookFlow}>
            <div className="countdown-code">
              <InputCode
                name1="name1"
                name2="name2"
                name3="name3"
                name4="name4"
              />
              <Components.Countdown
                counter={counter}
                onChange={newCounter => setCounter(newCounter)}
                onFinish={() => setIsModalFinish(true)}
              />
            </div>

            <div className="container-buttons">
              <SafetySection />
              <ButtonComponent
                size="M"
                type="button"
                onClick={methods.handleSubmit(onSubmit)}
                disabled={!formState.isValid}
              >
                {loadingButton ? 'Carregando...' : 'Continuar'}
              </ButtonComponent>

              {isEmail && (
                <ButtonComponent
                  size="S"
                  type="button"
                  variant="secondary"
                  onClick={() => sendNewSms({ sendEmail: true })}
                  disabled={counter > 0}
                >
                  Reenviar código por e-mail
                </ButtonComponent>
              )}

              <ButtonComponent
                size="S"
                type="button"
                variant="secondary"
                onClick={() => sendNewSms({ sendEmail: false })}
                disabled={counter > 0}
              >
                Reenviar SMS
              </ButtonComponent>

              {isFacebookFlow && urlBack === '/onboarding' && (
                <ButtonComponent
                  variant="secondary"
                  type="button"
                  size="S"
                  onClick={() =>
                    history.push({
                      pathname: '/validado',
                      search: `?${new URLSearchParams({
                        href: `/`,
                      }).toString()}`,
                    })
                  }
                >
                  Problemas com acesso
                </ButtonComponent>
              )}
            </div>
          </Form>
        </FormProvider>
      </Container>

      <ModalEndCountdown
        open={!isInvalid && isModalFinish}
        onClose={() => setIsModalFinish(false)}
        isRegister={isRegister}
        isEmail={isEmail}
        urlBack={getUrlback}
        sendNewSms={sendNewSms}
      />

      <ModalErrorCode
        open={isInvalid}
        onClose={() => {
          setIsInvalid(false);
          setIsModalFinish(false);
        }}
        isRegister={isRegister}
        isEmail={isEmail}
        urlBack={getUrlback}
        sendNewSms={sendNewSms}
      />
    </>
  );
}
