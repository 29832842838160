import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Images
import alertIcon from 'assets/images/icons/alertIcon.svg';

// Components
import Button from 'components/Button';
import Switch from 'components/Switch';
import { useAuth } from 'hooks/Auth';
import LoadingPop from 'pages/Loading';

// Hook and services
import { api } from 'services';
import firebase from 'services/firebase';
import { useTagManager, ButtonEvents } from 'hooks/Analytics';

// Utils
import { getWindowDimensions } from 'utils/windowDimensions';

// Styles
import { Container, Option, Attention, SpanText } from './styles';

export default function PermissionsAndPrivacyContainer() {
  const history = useHistory();
  const { user, updateUser } = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const { sendButtonClickEvent } = useTagManager();

  const [pushNotification, setPushNotification] = useState(false);
  const [emailNotification, setEmailNotification] = useState(false);
  const [smsNotification, setSmsNotification] = useState(false);

  const fieldDDD = useMemo(
    () => (user?.cellNumber ? user.cellNumber.substr(0, 2) : ''),
    [user],
  );
  const fieldNumber = useMemo(() => {
    if (user?.cellNumber) {
      const firstNumer = user.cellNumber.substr(2, 5);
      const secondNumer = user.cellNumber.substr(7, 9);
      return `${firstNumer}-${secondNumer}`;
    }
    return '';
  }, [user]);

  const { width } = getWindowDimensions();

  const handleUpdateUser = useCallback(
    async responseUser => {
      const { receive_mail, receive_push, receive_sms, email, contact_number } =
        responseUser;

      const paramsUpdateUser = {
        ...user,
        email: email || null,
        number: contact_number || null,
        receive_mail,
        receive_push,
        receive_sms,
      };

      setPushNotification(receive_push);
      setEmailNotification(receive_mail);
      setSmsNotification(receive_sms);

      await updateUser(paramsUpdateUser);
      setIsLoading(false);
    },
    [user],
  );

  useEffect(() => {
    setIsLoading(true);
    async function fetchData() {
      try {
        const response = await api.get('/authentication/v0.1/receives');

        if (response.data) {
          await handleUpdateUser(response.data.customer);
        }
      } catch (err) {
        setIsLoading(false);
        console.log('Erro GET /v0.1/receives', err);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    const url = new URL(window.location);
    const params = new URL(window.location).searchParams;
    const onboarding = params.get('href') === '/onboarding';
    const profile = params.get('href') === '/perfil';
    if (!profile && !onboarding) {
      if (width >= 1200) {
        url.searchParams.append('href', '/');
      } else {
        url.searchParams.append('href', '/perfil');
      }
      window.history.pushState({}, '', url);
    }
  }, []);

  const updateParams = useCallback(
    async type => {
      try {
        switch (type) {
          case 'receive_push':
            firebase.analytics().logEvent('push_notification', {
              aceito: pushNotification ? 'nao' : 'sim',
            });
            setPushNotification(!pushNotification);
            break;
          case 'receive_mail':
            firebase.analytics().logEvent('email_notification', {
              aceito: emailNotification ? 'nao' : 'sim',
            });
            setEmailNotification(!emailNotification);
            break;
          case 'receive_sms':
            firebase.analytics().logEvent('sms_notification', {
              aceito: smsNotification ? 'nao' : 'sim',
            });
            setSmsNotification(!smsNotification);
            break;
          default:
            break;
        }

        const params = {
          receive_mail:
            type === 'receive_mail' ? !emailNotification : emailNotification,
          receive_push:
            type === 'receive_push' ? !pushNotification : pushNotification,
          receive_sms:
            type === 'receive_sms' ? !smsNotification : smsNotification,
        };

        await api.put('/authentication/v0.1/receives', params);
      } catch (error) {
        console.log('Erro PUt /v0.1/receives', error);
      }
    },
    [
      user,
      setPushNotification,
      setEmailNotification,
      setSmsNotification,
      pushNotification,
      emailNotification,
      smsNotification,
      handleUpdateUser,
    ],
  );

  return (
    <>
      {isLoading && <LoadingPop />}

      <Container>
        <h1>Ajuste suas preferências</h1>
        <h4>Controle onde você recebe novidades e promoções do Popeyes</h4>

        <Option>
          <p>Notificações do aplicativo</p>
          <Switch
            name="switch-pushNotification"
            toggled={pushNotification}
            onChange={() => updateParams('receive_push')}
          />
        </Option>

        <Option>
          <div>
            <p>Receber e-mails</p>
            {user?.email && <span>{`E-mail cadastrado: ${user.email}`}</span>}
          </div>
          <Switch
            name="switch-emailNotification"
            toggled={emailNotification}
            onChange={() => updateParams('receive_mail')}
          />
        </Option>

        <Option>
          <div>
            <p>Receber SMS</p>
            <span>{`Número cadastrado: (${fieldDDD}) ${fieldNumber}`}</span>
          </div>
          <Switch
            name="switch-smsNotification"
            toggled={smsNotification}
            onChange={() => updateParams('receive_sms')}
          />
        </Option>

        <Attention>
          <img src={alertIcon} alt="Atenção" />
          <div>
            Você continuará recebendo notificações que são fundamentais para sua
            experiência, como código de recuperação de senha.
          </div>
        </Attention>

        <Button
          variant="secondary"
          onClick={() =>
            history.push({
              pathname: '/direitos-e-solicitacoes',
              search: `?${new URLSearchParams({
                href: '/permissoes-e-privacidade',
              }).toString()}`,
            })
          }
        >
          Direitos e solicitações
        </Button>

        <span>
          Consultar
          <SpanText
            onClick={() => {
              sendButtonClickEvent(ButtonEvents.TERMOS_CONDICOES);
              history.push({
                pathname: '/termos-de-uso',
                search: `?${new URLSearchParams({
                  href: '/permissoes-e-privacidade',
                }).toString()}`,
              });
            }}
          >
            Termos de condição de uso
          </SpanText>
          e
          <SpanText
            onClick={() => {
              firebase.analytics().logEvent('direitos_lgpd', {
                opcao: 'politica_privacidade',
              });
              history.push({
                pathname: '/politica-de-privacidades',
                search: `?${new URLSearchParams({
                  href: '/permissoes-e-privacidade',
                }).toString()}`,
              });
            }}
          >
            Diretrizes de privacidade de dados.
          </SpanText>
        </span>
      </Container>
    </>
  );
}
