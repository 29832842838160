import React, { useRef, useEffect, useState } from 'react';

import FooterComponent from 'components/FooterComponent';
import HeaderComponent from 'components/HeaderComponent';
import { Menu as PageMenu } from 'components/Pages/Menu';
import { useHeader } from 'hooks';
import { StoreContextProvider } from 'providers';
import { CardapioContextProvider } from 'hooks/Cardapio';

import * as S from './styles';

export default function Menu() {
  const containerRef = useRef();
  const { handleRef } = useHeader();

  const [urlBack, setUrlBack] = useState('/');

  useEffect(() => {
    const params = new URL(window.location).searchParams;
    const href = params.get('href');
    if (href) setUrlBack(href);

    handleRef(containerRef);
  }, []);

  return (
    <StoreContextProvider>
      <CardapioContextProvider>
        <S.Container ref={containerRef}>
          <HeaderComponent title="Cardápio" href={urlBack} />
          <PageMenu />
          <FooterComponent />
        </S.Container>
      </CardapioContextProvider>
    </StoreContextProvider>
  );
}
