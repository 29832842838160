import styled from 'styled-components';
import sizes from 'utils/screenSizes';

import stars from '../../assets/images/home/stars.svg';

export const ContainerDelivery = styled.section`
  position: relative;

  width: 100%;
  height: 100%;

  background-image: ${({ banner }) => (banner ? `url(${banner})` : 'none')};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 5%;

  @media (min-width: ${sizes.notebook}) {
    min-height: 768px;
  }

  @media all and (max-width: ${sizes.notebook}) {
    display: none;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  margin-right: 18.5%;

  @media screen and (min-width: ${sizes.ipad}) and (max-width: 1480px) {
    margin-right: 12%;
  }

  &:after {
    content: '';
    width: 112px;
    height: 130px;
    background: url(${stars}) no-repeat;
    position: absolute;
    top: 22%;
    right: 11.8%;
    transform: rotate(1deg);

    @media screen and (min-width: ${sizes.ipad}) and (max-width: 1480px) {
      top: 23%;
      right: 2.8%;
    }
  }

  p {
    margin-top: 60px;
    font-family: 'ChickenSansBold' !important;
    font-weight: 700;
    text-align: center;
    color: #212121;
    font-size: 48px;
    line-height: 55.2px;
  }

  h1 {
    font-family: 'ChickenScript';
    font-size: 96px;
    font-weight: 400;
    line-height: 110.4px;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    color: #911987;
    margin-top: -21px;
  }

  .partners {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
    gap: 15px;
  }

  a {
    border: none;
    border-radius: 6px;
    outline: none;
    cursor: pointer;
    max-width: 200px;
    max-height: 120px;
  }
`;
