import { IoIosArrowBack } from 'react-icons/io';

export function PrevArrow({ onClick }) {
  return (
    <button
      type="button"
      id="btn-prev"
      data-testid="btn-prev"
      onClick={onClick}
    >
      <IoIosArrowBack size={48} color="#D7D7D7" />
    </button>
  );
}
