/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import FamilyDesktop from 'assets/images/carousel/family-white-desktop.svg';
import Family from 'assets/images/carousel/family-white.svg';
import ChickenDesktop from 'assets/images/carousel/horas-desktop.svg';
import Chicken from 'assets/images/carousel/horas.svg';
import MsgIcon from 'assets/images/carousel/icons/msg.svg';
import PopeyesName from 'assets/images/carousel/logo-name.png';
import FamousLoisianaDesktop from 'assets/images/carousel/louisiana-white-desktop.svg';
import FamousLoisiana from 'assets/images/carousel/louisiana-white.svg';
import OnboardingBackground from 'assets/images/onboarding_background.svg';
import * as Atoms from 'components/Atoms';
import { useAuth } from 'hooks/Auth';
import LoadingPop from 'pages/Loading';
import Sizes from 'utils/screenSizes';

import { useTagManager, ButtonEvents } from 'hooks/Analytics';
import {
  Container,
  Content,
  FSlideContainer,
  SSlideContainer,
  TSlideContainer,
} from './styles';

function Index() {
  const { user, clearUser, fakeLogin } = useAuth();
  const history = useHistory();

  const { sendButtonClickEvent } = useTagManager();

  const alreadyVisited = localStorage.getItem('@Popeyes.already_visited');
  const [isLoading, setIsloading] = useState(false);

  const params = new URL(window.location).searchParams;
  const isFromValidated = params.get('validated');

  useEffect(() => {
    if (user && user.signed) {
      history.push('/');
    } else {
      clearUser();
    }
  }, []);

  function getWindowDimensions() {
    const { width, height } = window.screen;
    return {
      width,
      height,
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      const dimensions = getWindowDimensions();
      setWindowDimensions(dimensions);
    }
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  /*
  const logLoginEvent = async loginType => {

  };

  const onClickFacebookLogin = async () => {
    setIsloading(true);
    logLoginEvent('facebook');
  };

  const onClickWithoutLogin = async () => {
    setIsloading(false);
    logLoginEvent('sem_cadastro');
  };

  */

  const onClickPhoneLogin = async () => {
    setIsloading(false);
    sendButtonClickEvent(ButtonEvents.LOGIN_ACTION_PHONE);
  };

  function FSlide() {
    return (
      <FSlideContainer>
        <img id="logo" src={PopeyesName} alt="Popeyes" />

        {windowDimensions.width <= parseInt(Sizes.notebook, 10) ? (
          <img src={Chicken} alt="Chicken" />
        ) : (
          <img src={ChickenDesktop} alt="Chicken" />
        )}

        <div className="text-area">
          <h1>Marinado por 12 horas</h1>
          <p>
            Crocante por fora e suculento por dentro, do jeito que você ama
            &#60;&#51;
          </p>
        </div>
      </FSlideContainer>
    );
  }

  function SSlide() {
    return (
      <SSlideContainer>
        <img id="logo" src={PopeyesName} alt="Popeyes" />

        {windowDimensions.width <= parseInt(Sizes.notebook, 10) ? (
          <img src={FamousLoisiana} alt="FamousLoisiana" />
        ) : (
          <img src={FamousLoisianaDesktop} alt="FamousLoisiana" />
        )}
        <div className="text-area">
          <h1>O sabor que veio de um lugar especial</h1>
          {windowDimensions.width <= parseInt(Sizes.notebook, 10) ? (
            <p>
              Conheça a culinária da Louisiana e descubra o que é comida feita
              com alma!
            </p>
          ) : (
            <p>
              Conheça a deliciosa cozinha da Louisiana numa comida feita com a
              alma!
            </p>
          )}
        </div>
      </SSlideContainer>
    );
  }

  function TSlide() {
    return (
      <TSlideContainer>
        <img id="logo" src={PopeyesName} alt="Popeyes" />

        {windowDimensions.width <= parseInt(Sizes.notebook, 10) ? (
          <img src={Family} alt="Family" />
        ) : (
          <img src={FamilyDesktop} alt="Family" />
        )}

        <div className="text-area">
          <h1>Ofertas para pedir sozinho ou dividir com a família</h1>
        </div>
      </TSlideContainer>
    );
  }

  function Buttons() {
    return (
      <>
        {/* <button
          type="button"
          className="facebookLogin"
          onClick={() => onClickFacebookLogin()}
        >
          <SignInFacebook onFailure={() => setIsloading(false)} />
        </button> */}

        <Link to="/login">
          <button
            type="button"
            className="buttonLogin"
            onClick={() => onClickPhoneLogin()}
          >
            <img src={MsgIcon} alt="Mensagem" />
            Entrar com o celular
          </button>
        </Link>
        {process.env.REACT_APP_ENV !== 'prod' && (
          <button
            type="button"
            className="acessProblem"
            onClick={() => fakeLogin()}
          >
            <Link to="/">
              <h3>Entre sem cadastro</h3>
            </Link>
          </button>
        )}
      </>
    );
  }

  return (
    <>
      {isLoading && <LoadingPop />}
      <Container background={OnboardingBackground}>
        <Content>
          <div>
            <Atoms.Carousel
              DefaultActive={2}
              Buttons={Buttons}
              Keys={[0]}
              Components={[TSlide()]}
              id="alreadyVisited"
            />
          </div>
        </Content>
      </Container>
    </>
  );
}

export default Index;
