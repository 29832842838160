/* eslint-disable no-await-in-loop */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useCallback, useEffect, useMemo, useState } from 'react';

// Images and Icons
import { FiMoreVertical, FiXCircle } from 'react-icons/fi';

import PropTypes from 'prop-types';
import addIcon from 'assets/images/icons/addIcon.svg';
import alertIcon from 'assets/images/icons/alertIcon.svg';
import ArrowIcon from 'assets/images/icons/back-popeyes-orange.svg';

// Components
import Button from 'components/Button';
import ModalResponsive, {
  getWindowDimensions,
} from 'components/ModalResponsive';

// Hooks, Services and Utils
import { useCoupons } from 'hooks/Coupons';
import firebase from 'services/firebase';
import { formatAllValue } from 'utils/formatValue';
import sizes from 'utils/screenSizes';

import { ButtonEvents, useTagManager } from 'hooks/Analytics';

// Styles
import {
  AlertTotemValidated,
  ContainerCoupons,
  Content,
  ContentCoupons,
  ContentItemsCoupons,
  Coupons,
  Footer,
  GetMoreContainer,
  HeaderModal,
  ModalContent,
  RemoveCoupons,
  TextTotem,
} from './styles';

const ModalCart = ({ open, onClose }) => {
  const { cartRemoteConfig, cartCoupon, onRequestMyCart, onDeleteCoupon } =
    useCoupons();

  const { sendButtonClickEvent } = useTagManager();

  const [windowSize, setWindowSize] = useState(getWindowDimensions());

  const auxScrollEffect = (element, containerChild, parent) => {
    const parentNode = document.getElementById(parent);
    const positionParent = parentNode.getBoundingClientRect();

    if (positionParent.left < 0) {
      document
        .getElementById(`coupon-${parent}`)
        .style.setProperty('transition', '1s');
      document
        .getElementById(`coupon-${parent}`)
        .style.setProperty('padding-left', '0%');
      document.getElementById(containerChild).scrollLeft = 0;
    }
    if (positionParent.left >= 0) {
      document
        .getElementById(`coupon-${parent}`)
        .style.setProperty('transition', '1s');
      document
        .getElementById(`coupon-${parent}`)
        .style.setProperty('padding-left', '15%');
      document.getElementById(element).scrollIntoView();
    }
  };
  const scroll = (element, containerChild, parent) => {
    const parentNode = document.getElementById(parent);
    const positionParent = parentNode.getBoundingClientRect();

    if (windowSize.width < parseInt(sizes.ipadPro, 10)) {
      if (positionParent.left < 0) {
        document.getElementById(containerChild).scrollLeft = 0;
      }
      if (positionParent.left >= 0) {
        document.getElementById(element).scrollIntoView();
      }
    } else {
      auxScrollEffect(element, containerChild, parent);
    }
  };

  const dragScrollHorizontal = (element, elementToDrag, parent) => {
    const slider = document.getElementById(elementToDrag);
    document.getElementById(elementToDrag).style.setProperty('cursor', 'grab');
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener('mousedown', e => {
      isDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
      document
        .getElementById(elementToDrag)
        .style.setProperty('cursor', 'grabbing');
    });

    slider.addEventListener('mouseleave', () => {
      isDown = false;
    });

    slider.addEventListener('mouseup', () => {
      isDown = false;
      document
        .getElementById(elementToDrag)
        .style.setProperty('cursor', 'grab');
    });

    slider.addEventListener('mousemove', e => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3;
      slider.scrollLeft = scrollLeft - walk;

      auxScrollEffect(element, elementToDrag, parent);
    });
  };

  let deleteCouponTimeout;
  const [openModalClear, setOpenModalClear] = useState(false);

  useEffect(() => {
    function handleResize() {
      const dimensions = getWindowDimensions();
      setWindowSize(dimensions);
    }
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [windowSize, cartCoupon, onRequestMyCart]);

  const footerModalComponent = useMemo(
    () => (
      <Footer>
        <h6>Apresente o código do cupom no balcão ou digite no totem.</h6>
      </Footer>
    ),
    [],
  );

  const onClickGetMore = useCallback(() => {
    onClose();
  }, [onClose]);

  const onClickClearAll = () => {
    setOpenModalClear(true);
    sendButtonClickEvent(ButtonEvents.CLEAR_ALL_CUPOM_LIST);
  };

  const headerModalComponent = useMemo(
    () => (
      <>
        <HeaderModal>
          <Button
            type="button"
            variant="secondary"
            onClick={onClose}
            id="btn01"
          >
            <img
              className="arrowIcon"
              src={ArrowIcon}
              alt="Fechar modal de cupons"
            />
            <h5 className="back-btn">Voltar</h5>
          </Button>
          <h5>Meus cupons</h5>

          <Button type="button" variant="secondary" onClick={onClickClearAll}>
            {cartCoupon.length > 0 ? 'Limpar' : ''}
          </Button>
        </HeaderModal>
        <TextTotem
          style={{
            background: cartRemoteConfig.apresentar_text.background_color,
            color: cartRemoteConfig.apresentar_text.text_color,
          }}
        >
          {cartRemoteConfig.apresentar_text.text}
        </TextTotem>

        <AlertTotemValidated
          style={{
            background: cartRemoteConfig.validade_text.background_color,
            color: cartRemoteConfig.validade_text.text_color,
          }}
        >
          <img src={alertIcon} alt="Alerta de validade de tokem" />
          {cartRemoteConfig.validade_text.text}
        </AlertTotemValidated>

        <GetMoreContainer
          style={{ background: cartRemoteConfig.mais_button.background_color }}
          onClick={onClickGetMore}
        >
          <div
            id="circle"
            style={{
              background: cartRemoteConfig.mais_button.icon_background_color,
            }}
          >
            <img
              src={addIcon}
              color={cartRemoteConfig.mais_button.icon_color}
              alt="Alerta de validade de tokem"
            />
          </div>
          <span style={{ color: cartRemoteConfig.mais_button.title_color }}>
            {cartRemoteConfig.mais_button.title}
          </span>
        </GetMoreContainer>
      </>
    ),
    [onClose, cartCoupon, cartRemoteConfig, onDeleteCoupon],
  );

  const removeCoupon = async coupon => {
    document.getElementById(coupon.nome).style.display = 'none';
    document.getElementById(`recover-${coupon.nome}`).style.display = '';

    deleteCouponTimeout = setTimeout(async () => {
      sendButtonClickEvent(ButtonEvents.REMOVE_CUPOM, {
        cupomName: coupon.nome,
        isDayOffer: false,
      });
      try {
        await onDeleteCoupon(coupon);
      } catch (err) {
        console.log(err);
      }

      // document.getElementById(`recover-${coupon.nome}`).style.display = 'none';
      // document.getElementById(coupon.nome).style.display = '';
      await onRequestMyCart();
    }, 2 * 1000);
  };

  const recoverCoupon = async coupon => {
    clearTimeout(deleteCouponTimeout);

    if (document.getElementById(coupon.nome).style.display === 'none') {
      document.getElementById(coupon.nome).style.display = '';
      document.getElementById(`recover-${coupon.nome}`).style.display = 'none';
    }
  };

  const removeAll = async () => {
    setOpenModalClear(false);

    for (let index = 0; index < cartCoupon.length; index += 1) {
      const coupon = cartCoupon[index];
      try {
        await onDeleteCoupon(coupon);
        document.getElementById(coupon.nome).style.display = 'none';
      } catch (err) {
        console.log(err);
      }
    }

    await onRequestMyCart();
    onClose();
  };

  return (
    <>
      <ModalResponsive
        open={open}
        onClose={onClose}
        headerModal={headerModalComponent}
        footerModal={footerModalComponent}
        sizeModal="high"
        showCloseButton={false}
      >
        <Content>
          <ContainerCoupons>
            <div className="desktop-card" id="desktop-card">
              {headerModalComponent}
            </div>
            <div className="desktop-coupons">
              {cartCoupon?.map(coupon => (
                <>
                  <ContentItemsCoupons
                    id={`safe-${coupon.nome}`}
                    onMouseOver={() =>
                      dragScrollHorizontal(
                        coupon.id,
                        `safe-${coupon.nome}`,
                        coupon.nome,
                      )
                    }
                  >
                    <ContentCoupons id={coupon.nome}>
                      <Coupons key={coupon.nome} id={`coupon-${coupon.nome}`}>
                        <div id="namePrice">
                          <p>{coupon.nome}</p>
                          <strong>
                            {`R$ ${
                              coupon.valor_descontado
                                ? formatAllValue(coupon.valor_descontado)
                                : formatAllValue(coupon.valor_regular)
                            }`}
                          </strong>
                        </div>

                        <div id="codeCoupon">
                          <span>Código do cupom:</span>
                          <h3
                            style={{
                              background:
                                cartRemoteConfig.cupom_code.background_color,
                              color: cartRemoteConfig.cupom_code.text_color,
                            }}
                          >
                            {coupon.codigo}
                          </h3>
                        </div>

                        <FiMoreVertical
                          size={20}
                          onClick={() =>
                            scroll(
                              coupon.id,
                              `safe-${coupon.nome}`,
                              coupon.nome,
                            )
                          }
                        />
                      </Coupons>
                      <RemoveCoupons
                        id={`${coupon.id}`}
                        onClick={() => removeCoupon(coupon)}
                      >
                        <FiXCircle size={30} />
                        <p>Excluir</p>
                      </RemoveCoupons>
                    </ContentCoupons>
                  </ContentItemsCoupons>
                  <div
                    id={`recover-${coupon.nome}`}
                    style={{
                      display: 'none',
                      marginTop: '2%',
                      cursor: 'pointer',
                    }}
                    onClick={() => recoverCoupon(coupon)}
                  >
                    <h4>{`Você excluiu ${coupon.nome}`}</h4>
                    <h6>Desfazer</h6>
                  </div>
                </>
              ))}
            </div>
            <div className="desktop-footer" id="desktop-footer">
              {footerModalComponent}
            </div>
          </ContainerCoupons>
        </Content>
      </ModalResponsive>

      <ModalResponsive
        open={openModalClear}
        onClose={() => setOpenModalClear(false)}
        maxModalHeight={380}
        sizeModal="middle"
      >
        <ModalContent>
          <h3 className="title">Limpar cupons</h3>
          <p className="subtitle">Deseja mesmo excluir todos os seus cupons?</p>
          <Button type="button" onClick={() => setOpenModalClear(false)}>
            Cancelar
          </Button>
          <Button type="button" variant="secondary" onClick={removeAll}>
            Sim, excluir cupons
          </Button>
        </ModalContent>
      </ModalResponsive>
    </>
  );
};

ModalCart.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalCart;
