import styled, { css } from 'styled-components';
import sizes from 'utils/screenSizes';

import * as T from './interfaces';

export const Container = styled.div<T.IStyleScrollDrag>`
  cursor: ${({ pressed }) => (pressed ? 'grabbing' : 'grab')};

  display: flex;
  align-items: center;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;

  ${({ isScrolling }) =>
    isScrolling &&
    css`
      div {
        pointer-events: none;
      }
    `}

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
    width: 0px;
    height: 0px;
  }

  #category3 {
    display: none;
  }
`;

export const ArrowsLeft = styled.div<T.IStyleScrollDrag>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;

  left: 0;

  z-index: 5;

  button {
    min-width: 0px;
    background: none;
    border: none;

    :hover {
      cursor: pointer;
      opacity: 0.7;
    }
    :focus {
      outline: none;
    }
    img {
      height: 30px;
    }
  }

  ${props => !props.showArrows && 'display:none;'}

  @media only all and (max-width: ${sizes.notebook}) {
    display: none;
  }
`;

export const ArrowsRight = styled.div<T.IStyleScrollDrag>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;

  right: 0;

  z-index: 5;

  button {
    min-width: 0px;
    background: none;
    border: none;

    :hover {
      cursor: pointer;
      opacity: 0.7;
    }
    :focus {
      outline: none;
    }
    img {
      height: 30px;
    }
  }

  ${props => !props.showArrows && 'display:none;'}

  @media only all and (max-width: ${sizes.notebook}) {
    display: none;
  }
`;
