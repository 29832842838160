import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTagManager, ButtonEvents } from 'hooks/Analytics';
import * as S from './styles';

interface IProduct {
  id?: number;
  name?: string;
  image?: string;
  description?: string;
  pedacos?: string[];
  suave?: boolean;
  vibrante?: boolean;
  labels?: any;
  categoriesIds?: any;
}

interface IMenuItem {
  product?: IProduct;
  showDescription?: boolean;
  onClick?: () => void;
}

const MenuItem: React.FC<IMenuItem> = ({
  product,
  showDescription,
  onClick,
}) => {
  const history = useHistory();
  const { sendButtonClickEvent } = useTagManager();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      history.push(`/cardapio/${product.id}`);
    }
    sendButtonClickEvent(ButtonEvents.PRODUCT_VIEW, {
      productId: product.id,
      productName: product.name,
    });
  };

  return (
    <S.ProductContainer onClick={handleClick}>
      <S.Image src={product.image} />
      <S.ContainerTitle>
        <S.Title>{product.name}</S.Title>
        <S.ContainerLabels>
          {product.labels.map((item: any) => (
            <S.Labels key={item.nome}>{item.nome}</S.Labels>
          ))}
        </S.ContainerLabels>
      </S.ContainerTitle>
    </S.ProductContainer>
  );
};

export default MenuItem;
