import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  padding: var(--size-header-height) 24px 40px;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${sizes.notebook}) {
    max-width: 800px;
    margin: auto;
    button {
      margin: auto;
      width: 312px;
    }
  }
  > p:last-of-type {
    color: var(--color-text-switch);
    font-size: 12px;
    margin-top: auto;
    text-align: center;
  }
  .first-section,
  .second-section,
  .third-section,
  .fourth-section {
    > p {
      color: var(--color-text-switch);
    }
    > h3 {
      font-family: 'ChickenSansRegular';
      color: var(--color-text-dark);
      font-size: 1.2rem;
      font-weight: normal;
    }
  }
  .first-section,
  .second-section {
    margin-top: 40px;
    margin-bottom: 12px;

    > p {
      margin: 0 0 12px 0;
    }
  }

  .third-section {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    > h3 {
      margin-bottom: 14px;
    }
    > p {
      margin-bottom: 20px;
    }
    > p:last-of-type {
      margin-left: auto;
    }
  }

  .fourth-section {
    margin-bottom: 55px;
  }
  textarea {
    height: 100px;
    width: 100%;
    font-family: 'ChickenSansRegular';
    border: 1px solid var(--color-text-switch);
    color: var(--color-text-switch);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 17px;
    resize: none;

    :focus,
    :active {
      outline: none;
    }
  }
`;
