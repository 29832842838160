import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

// Components
import Button from 'components/Button';
import HeaderComponent from 'components/HeaderComponent';
import Input from 'components/Input';
import PropTypes from 'prop-types';

// Styles
import { ContainerScreens } from '../../styles';

function ExcludeAccount({ onBack }) {
  const history = useHistory();

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      delete: '',
    },
  });
  const { watch } = methods;

  const deleteAccount = async dataForm => {
    console.log('dataForm');
    console.log(dataForm);

    history.push('/');
  };

  return (
    <>
      <HeaderComponent title="Direitos e solicitações" onBackClick={onBack} />
      <ContainerScreens>
        <div style={{ textAlign: 'center' }}>
          <h1>Atenção! Ação permanente.</h1>
          <p style={{ marginTop: '22px' }}>
            Ao completar essa ação, TODOS os seus dados serão excluídos,
            INCLUINDO SUA CONTA POPEYES.
          </p>

          <p style={{ marginTop: '22px' }}>
            Consequentemente, você perderá todo o seu registro de cupons e
            futuros benefícios.
          </p>

          <p style={{ marginTop: '22px' }}>
            Para confirmar que isso não é um engano e excluir a sua conta,
            digite &quot;EXCLUIR&quot; abaixo.
          </p>
        </div>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(deleteAccount)}>
            <Input
              htmlFor="delete"
              type="text"
              name="delete"
              maxLength="14"
              placeholder={`Digite "EXCLUIR" aqui`}
              required
              isValid
            />

            <div className="action-buttons">
              <Button
                type="submit"
                variant="quaternary"
                onClick={onBack}
                disabled={watch('delete') !== 'EXCLUIR'}
              >
                Confirmar
              </Button>
              <Button type="button" onClick={onBack}>
                Cancelar
              </Button>
            </div>
            <Button type="button" variant="secondary" onClick={onBack}>
              Voltar
            </Button>
          </form>
        </FormProvider>
      </ContainerScreens>
    </>
  );
}

ExcludeAccount.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default ExcludeAccount;
