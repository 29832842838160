import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Services and Utils
import { useCoupons } from 'hooks/Coupons';
import { useTagManager, ButtonEvents } from 'hooks/Analytics';

// Pages and Components
import LoadingPop from 'pages/Loading';
import firebase from 'services/firebase';
import Cart from './components/Cart';
import CouponsComponent from './components/Coupons';
import Hero from './components/Hero';
import ModalCart from './components/ModalCart';
import ModalCoupon from './components/ModalCoupon';
import ScrollToTop from './components/ScrollToTop';

import Categories from './components/Categories';
import { Container, CouponsContainer } from './styles';

// Modals Components

// Styles

const Coupon = ({ ContainerRef }: any) => {
  const history = useHistory();
  const containerCouponsRef = useRef(null);
  const { sendButtonClickEvent } = useTagManager();

  const {
    loading,
    loadingRemoteConfig,
    coupons,
    categories,
    onUpdateCategories,
    onChoseCoupon,
  } = useCoupons();

  // Modals
  const [openModalCart, setOpenModalCart] = useState(false);
  const [openModalCoupon, setOpenModalCoupon] = useState(false);
  const [openModalLogin, setOpenModalLogin] = useState(false);
  const [openCategories, setOpenCategories] = useState(false);

  // Urls
  const [urlOpenCart, setUrlOpenCart] = useState(false);
  const [urlCuponCode, setUrlCuponCode] = useState();
  const [urlCategoryCode, setUrlCategoryCode] = useState();

  const [hideBanner, setHideBanner] = useState(false);

  // ScrollToTop Button
  const [scrollButtonVisible, setScrollButtonVisible] = useState(false);

  useEffect(() => {
    const params: any = new URLSearchParams(window.location.search);
    const openCart = params.get('cartModal');
    if (openCart) setUrlOpenCart(true);
    else setUrlCuponCode(null);

    const code = params.get('code');
    if (code) setUrlCuponCode(code);
    else setUrlCuponCode(null);

    const category = params.get('category');
    if (category) setUrlCategoryCode(category);
    else setUrlCategoryCode(null);
  }, []);

  const scrollBanner = () => {
    containerCouponsRef.current.scrollTo({
      top: 1,
      behavior: 'smooth',
    });
  };

  const backgroundChange = useCallback(() => {
    const { scrollTop }: any = containerCouponsRef.current;
    if (scrollTop === 0) {
      setHideBanner(false);
    } else {
      setHideBanner(true);
    }
  }, [setHideBanner]);

  const handleUpdateCategories = useCallback(
    (newCategories: any) => {
      const count = newCategories.filter(
        (category: any) => category.selected,
      ).length;
      if (count > 0) scrollBanner();
      onUpdateCategories(newCategories);
    },
    [onUpdateCategories],
  );

  const changeUrlOnChoseCoupon = useCallback(
    (coupon: any) => {
      const url = new URL(window.location.href);
      url.searchParams.set('code', coupon.codigo);
      window.history.pushState({}, '', url);
    },
    [onChoseCoupon],
  );

  const clearUrlWhenClose = useCallback(() => {
    window.history.replaceState(null, null, window.location.pathname);
  }, []);

  const handleChoseCoupon = useCallback(
    (couponSelected: any) => {
      onChoseCoupon(couponSelected);
      setOpenModalCoupon(true);
      changeUrlOnChoseCoupon(couponSelected);
    },
    [onChoseCoupon, setOpenModalCoupon],
  );

  const openCouponUrl = useCallback(
    (code: any) => {
      const couponCode = String(code);

      const couponFound = coupons.find(
        (coupon: any) => coupon.couponCode === couponCode,
      );

      if (couponFound) {
        handleChoseCoupon(couponFound);
      }
    },
    [handleChoseCoupon, coupons],
  );

  const openCategoryUrl = useCallback(
    (code: any) => {
      const categoryCode = Number(code);

      const categoriesUpdated = categories.map((category: any) => {
        if (category.id === categoryCode) {
          category.selected = true;
        } else {
          category.selected = false;
        }

        return category;
      });

      if (categoriesUpdated) handleUpdateCategories(categoriesUpdated);
    },
    [handleUpdateCategories, categories],
  );

  const handleOpenModalCart = () => {
    sendButtonClickEvent(ButtonEvents.VIEW_CUPOM_LIST);
    setOpenModalCart(true);
  };

  useEffect(() => {
    if (coupons.length > 0) {
      if (urlOpenCart) {
        handleOpenModalCart();
        setUrlOpenCart(false);
      } else if (urlCuponCode) {
        openCouponUrl(urlCuponCode);
        setUrlCuponCode(null);
      } else if (categories.length > 0 && urlCategoryCode) {
        openCategoryUrl(urlCategoryCode);
        setUrlCategoryCode(null);
      }
    }
  }, [coupons, categories]);

  useEffect(() => {
    return history.listen((location: any) => {
      if (location.search.includes('cartModal=true')) {
        setOpenModalCart(true);
      }
    });
  }, [history]);

  const onBackButtonEvent = (e: any) => {
    e.preventDefault();
    if (openModalLogin) setOpenModalLogin(false);
    else if (openCategories) setOpenCategories(false);
    else if (openModalCart) setOpenModalCart(false);
    else if (openModalCoupon) setOpenModalCoupon(false);
    else history.push('/');
  };

  useEffect(() => {
    window.history.pushState(
      '',
      '',
      window.location.pathname + window.location.search,
    );
    window.addEventListener('popstate', onBackButtonEvent);
    return () => window.removeEventListener('popstate', onBackButtonEvent);
  }, [openModalCart, openModalCoupon, openModalLogin, openCategories]);

  const HandleScroll = () => {
    const scroll = ContainerRef.current.scrollTop || 0;
    const docHeight = document.body.scrollHeight || 0;
    const winHeight = window.outerHeight || 0;
    const scrollCalc = Number(winHeight - docHeight) / Number(scroll) || 0;
    const scrollPercent = Number(scrollCalc) * 2;
    scrollPercent.toFixed();

    if (scrollPercent === 0) {
      setHideBanner(false);
    } else {
      setHideBanner(true);
    }

    if (scrollPercent >= 25) {
      setScrollButtonVisible(true);
    } else {
      setScrollButtonVisible(false);
    }
  };

  useEffect(() => {
    ContainerRef.current.addEventListener('scroll', HandleScroll, false);
  }, []);

  return (
    <>
      {(loading || loadingRemoteConfig) && <LoadingPop />}

      <Container>
        <Hero hideBanner={hideBanner} handleChoseCoupon={handleChoseCoupon} />
        <Categories
          openCategories={openCategories}
          onToogleCategories={option => setOpenCategories(option)}
          onChange={newCategories => handleUpdateCategories(newCategories)}
        />
        <CouponsContainer onScroll={backgroundChange} ref={containerCouponsRef}>
          <CouponsComponent
            onSelectCoupon={handleChoseCoupon}
            onGetCoupon={() => handleOpenModalCart()}
            onChangeCategories={(newCategories: any) =>
              handleUpdateCategories(newCategories)
            }
          />
        </CouponsContainer>

        <Cart openModal={() => handleOpenModalCart()} />
      </Container>

      <ModalCart
        open={openModalCart}
        onClose={() => {
          setOpenModalCart(false);
        }}
      />
      <ModalCoupon
        openModalLogin={openModalLogin}
        onToogleModalLogin={(option: any) => setOpenModalLogin(option)}
        open={openModalCoupon}
        onClose={props => {
          setOpenModalCoupon(false);
          if (props?.openMyCart) handleOpenModalCart();
          clearUrlWhenClose();
        }}
      />
      <ScrollToTop
        onClick={() => {
          ContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        isVisible={scrollButtonVisible}
      />
    </>
  );
};

export default Coupon;
