import * as T from './interfaces';
import * as S from './styles';

export const Menu = ({
  id,
  changePosition,
  currentSelected,
  mapPermission,
  positions,
  changeCurrentSelected,
  userPosition,
  mapMode,
  listPlacesSuggestion,
  isMobileDevice,
  mustRender,
  openModal,
  handleOpenModal,
  changeMustRender,
}: T.IMenuProps) => (
  <S.Container
    mapMode={mapMode}
    listPlacesSuggestion={listPlacesSuggestion}
    id={id}
    data-testid={id}
    isMobileDevice={isMobileDevice}
    mustRender={mustRender}
  >
    {/* <SearchContainer
      openModal={openModal}
      handleModal={handleOpenModal}
      isMobileDevice={isMobileDevice}
      changePosition={changePosition}
      mustRender={mustRender}
      changeChildRender={changeMustRender}
    /> */}

    {/* <Map
      currentSelected={currentSelected}
      mapPermission={mapPermission}
      getUserLocation={changePosition}
      positions={positions}
      changeCurrentSelected={changeCurrentSelected}
      userPosition={userPosition}
    /> */}
  </S.Container>
);
