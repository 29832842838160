import React, { useEffect, useRef } from 'react';

import BackButton from 'components/BackButton';
import FooterComponent from 'components/FooterComponent';
import HeaderComponent from 'components/HeaderComponent';
import { MyDataWasUsedContainer } from 'containers';
import { useHeader } from 'hooks/HeaderContext';

import { Container } from './styles';

function MyDataWasUsed() {
  const { handleRef, showHeader } = useHeader();
  const containerRef = useRef();

  useEffect(() => {
    handleRef(containerRef);
  }, []);

  return (
    <Container ref={containerRef}>
      <HeaderComponent
        title="Meus dados já foram usados?"
        href="/direitos-e-solicitacoes"
        visible={showHeader}
      />
      <BackButton />

      <MyDataWasUsedContainer />

      <FooterComponent />
    </Container>
  );
}

export default MyDataWasUsed;
