import { useEffect, useRef } from 'react';

import AboutAppComponent from 'components/AboutAppComponent';
import DeliveryComponent from 'components/DeliveryComponent';
import FooterComponent from 'components/FooterComponent';
import HeaderComponent from 'components/HeaderComponent';
import { HomeContainer } from 'containers';
import { useHeader } from 'hooks/HeaderContext';

import { Container } from './styles';

export default function Home({ title, ...rest }) {
  const containerRef = useRef();
  const { handleRef } = useHeader();

  useEffect(() => {
    handleRef(containerRef);
  }, []);

  return (
    <Container ref={containerRef}>
      <HeaderComponent />
      <HomeContainer />
      <DeliveryComponent />
      <AboutAppComponent />
      <FooterComponent />
    </Container>
  );
}
