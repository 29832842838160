import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import sizes from 'utils/screenSizes';

import {
  BigPriceText,
  CircleLeft,
  CircleRight,
  CouponContainer,
  FakeComponent,
  Image,
  LineOver,
  PriceContainer,
  RegularValue,
  SmallPriceText,
  Title,
  TitleContainer,
} from './styles';

const CouponItem = ({
  coupon,
  onClick,
  quantityTotal,
  actualCouponIndex,
  abCouponConfig,
  priceTextColor,
  isOfferDay,
  isOfferDayColor,
  showRegularValue,
}) => {
  const splitValue = coupon?.valor_descontado?.toFixed(2).split('.');

  const [inteiro, decimal] = (
    coupon && coupon.valor_descontado ? coupon.valor_descontado : 0
  )
    .toFixed(2)
    .split('.');

  // Use individual coupon setting over general coupon list setting
  const textColor = abCouponConfig
    ? abCouponConfig.price_text_color
    : priceTextColor;

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    return {
      width,
      height,
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  useEffect(() => {
    function handleResize() {
      const dimensions = getWindowDimensions();
      setWindowDimensions(dimensions);
    }
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  return (
    <>
      <CouponContainer
        onClick={onClick}
        isOfferDay={isOfferDay}
        className="CouponElement"
      >
        <Image
          src={abCouponConfig ? abCouponConfig.image_url : coupon?.imagem?.url}
        />
        <TitleContainer>
          <Title>{abCouponConfig ? abCouponConfig.name : coupon.nome}</Title>
        </TitleContainer>
        <CircleLeft isOfferDayColor={isOfferDayColor} />
        <CircleRight isOfferDayColor={isOfferDayColor} />
        <LineOver isOfferDayColor={isOfferDayColor} />

        {coupon.valor_regular > 0 && (
          <RegularValue>
            {coupon.valor_regular.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              style: 'currency',
              currency: 'BRL',
            })}
          </RegularValue>
        )}

        <PriceContainer showRegularValue={showRegularValue}>
          <SmallPriceText textColor={textColor}>R$</SmallPriceText>
          <BigPriceText textColor={textColor}>{inteiro}</BigPriceText>
          <SmallPriceText textColor={textColor}>,{decimal}</SmallPriceText>
        </PriceContainer>
      </CouponContainer>
    </>
  );
};

CouponItem.propTypes = {
  coupon: PropTypes.object.isRequired,
  isOfferDayColor: PropTypes.string,
  isOfferDay: PropTypes.bool,
  onClick: PropTypes.func,
  quantityTotal: PropTypes.number,
  actualCouponIndex: PropTypes.number,
  abCouponConfig: PropTypes.objectOf(PropTypes.any),
  priceTextColor: PropTypes.string,
  showRegularValue: PropTypes.bool,
};

CouponItem.defaultProps = {
  isOfferDayColor: null,
  isOfferDay: false,
  showRegularValue: false,
};

export default CouponItem;
