import React, { useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';

import { gql, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/Button';
import Input from 'components/Input';
import SafetySection from 'components/SafetySection';
import TitleSection from 'components/TitleSection';
import { cpf } from 'cpf-cnpj-validator';

// Components

// Services, Utils and Hooks
import { useAuth } from 'hooks/Auth';
import { api } from 'services';
import firebase from 'services/firebase';
import hideKeyboard from 'utils/hideKeyboard';

import { useTagManager, ButtonEvents, ExtraEvents } from 'hooks/Analytics';
import { Container, Form, SpanText } from './styles';
import schema from './validation';

// Styles

const CREATE_USER = gql`
  mutation (
    $user_id_facebook: String
    $name: String!
    $lastname: String!
    $email: String!
    $cpf: String!
    $term_accepted: Boolean!
    $receive_mail: Boolean!
    $receive_sms: Boolean!
    $number: String!
  ) {
    saveCustomerPWA(
      user_id_facebook: $user_id_facebook
      customer_name: $name
      last_name: $lastname
      email: $email
      cpf: $cpf
      term_accepted: $term_accepted
      receive_mail: $receive_mail
      receive_sms: $receive_sms
      contact_number: $number
    )
  }
`;

function SignUp() {
  const isFacebookFlow = useMemo(() => {
    const params = new URL(window.location).searchParams;
    const facebookUser = params.get('facebookUser');
    return !!facebookUser;
  }, []);

  const { user, signIn, updateUser, accessToken } = useAuth();
  const { sendButtonClickEvent, sendExtraEvents } = useTagManager();
  const [createUser] = useMutation(CREATE_USER);
  const history = useHistory();

  const [loadingButton, setLoadingButton] = useState(false);
  const [emailFilled, setEmailFilled] = useState('');
  const [isEmailConfirmationFilled, setIsEmailConfirmationFilled] =
    useState('');

  // navigation
  const [goToValidateEmail, setGoToValidateEmail] = useState(false);

  // validações
  const [isCpfError, setIsCpfError] = useState(false);
  const [messageErrorCpf, setMessageErrorCpf] = useState('');
  const [isCpfCompleted, setIsCpfCompleted] = useState(false);

  const [isEmailError, setIsEmailError] = useState(false);
  const [messageErrorEmail, setMessageErrorEmail] = useState('');
  const [showIconEmail, setShowIconEmail] = useState(false);

  const [isEmailConfirmError, setIsEmailConfirmError] = useState(false);
  const [messageErrorEmailConfirm, setMessageErrorEmailConfirm] = useState('');

  const name = useMemo(() => user.name || '', [user]);
  const arrayName = useMemo(() => name.split(' '), [name]);
  const lastName = useMemo(() => {
    if (user.lastname) return user.lastname;
    return name !== '' ? name.split(' ').slice(-1).join(' ') : '';
  }, [name, user]);

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      name: arrayName[0],
      lastname: lastName,
      email: user?.email || '',
      emailConfirmation: user?.email || '',
      cpf: user.cpf ? cpf.format(user.cpf) : '',
      acceptTerms: true,
      emailNotif: true,
      smsNotif: true,
    },
  });
  const { formState, watch, getValues } = methods;

  const getUserFromFormData = formData => {
    const newCpf = String(formData.cpf.replace(/\D+/g, ''));
    const userParams = {
      user_id_facebook: '',
      name: formData.name || '',
      lastname: formData.lastname || '',
      email: formData.email || '',
      cpf: newCpf,
      term_accepted: true,
      receive_mail: true,
      receive_sms: true,
      number: user.cellNumber,
    };

    return userParams;
  };

  async function newUser(dataForm) {
    setLoadingButton(true);

    sendButtonClickEvent(ButtonEvents.REGISTER_CONTINUE);

    const newCpf = String(dataForm.cpf.replace(/\D+/g, ''));
    const userParams = {
      user_id_facebook: '',
      name: dataForm.name,
      lastname: dataForm.lastname,
      email: dataForm.email,
      cpf: newCpf,
      term_accepted: true,
      receive_mail: true,
      receive_sms: true,
      number: user.cellNumber,
    };

    if (isFacebookFlow && !goToValidateEmail) {
      updateUser(userParams);
      history.push('/validar-numero');
    } else {
      try {
        if (isFacebookFlow) userParams.user_id_facebook = accessToken;
        await createUser({
          variables: userParams,
        });
      } catch (err) {
        console.log(err);
      }

      try {
        await signIn({
          contact_number: user.cellNumber,
          user_data: userParams,
        });
      } catch (err) {
        console.log(err);
      }
      sendExtraEvents(ExtraEvents.SUCCESS_REGISTRED);
      history.push({
        pathname: '/verificar',
        search: `?${new URLSearchParams({
          href: '/registro',
          isEmail: goToValidateEmail,
          facebookUser: isFacebookFlow,
        }).toString()}`,
      });
    }

    methods.reset();
  }

  // validate cpf
  const normalizeCpf = value => {
    return cpf.format(value);
  };

  const checkCpf = async value => {
    if (value.length === 14) {
      setIsCpfCompleted(true);
      hideKeyboard();

      // Formato CPF
      const cpfUser = String(value.replace(/\D+/g, ''));

      if (cpf.isValid(value.replace(/\D+/g, ''))) {
        setIsCpfError(false);
      } else {
        setIsCpfError(true);
      }

      // Existe CPF
      try {
        await api.post('/customer/v0.1/cpf/validation', {
          cpf: cpfUser,
        });

        // cpf já existe
        setIsCpfError(true);
        setMessageErrorCpf('Já existe um cadastro com este CPF');
      } catch (err) {
        if (err?.response?.status === 422) {
          // erro no formato
          setIsCpfError(true);
          setMessageErrorCpf('CPF inválido. Verifique a numeração');
        } else if (err?.response?.status === 404) {
          // cpf valido
          setIsCpfError(false);
          setMessageErrorCpf('');
        }
      }
    } else {
      setIsCpfCompleted(false);
    }
  };

  // validate email
  const changeFocusEmail = async () => {
    setShowIconEmail(true);
    if (!methods.errors.email) {
      if (!emailFilled) {
        setEmailFilled(user?.email);
      }

      const email = emailFilled || user?.email;
      try {
        await api.post('/customer/v0.1/email/validation', {
          email,
        });

        setIsEmailError(true);
        setMessageErrorEmail('Já existe um cadastro com este e-mail');
      } catch (err) {
        if (err?.response?.status === 404) {
          setIsEmailError(false);
          setMessageErrorEmail('');
        } else {
          setIsEmailError(true);
          setMessageErrorEmail(
            'Erro na verificação do e-mail, tente novamente',
          );
        }
      }
    }
  };

  const focusEmail = () => {
    setIsEmailError(false);
    setShowIconEmail(false);
  };

  const checkEmail = email => {
    setEmailFilled(email);
    setShowIconEmail(true);
    if (isEmailConfirmationFilled) {
      if (email === isEmailConfirmationFilled) {
        setIsEmailConfirmError(false);
        setMessageErrorEmailConfirm('');
      } else {
        setIsEmailConfirmError(true);
        setMessageErrorEmailConfirm('Os emails não são iguais');
      }
    }
  };

  const checkEmailConf = emailConf => {
    setIsEmailConfirmationFilled(emailConf);
    if (emailFilled === emailConf) {
      setIsEmailConfirmError(false);
      setMessageErrorEmailConfirm('');
    } else {
      setIsEmailConfirmError(true);
      setMessageErrorEmailConfirm('Os emails não são iguais');
    }
  };

  useEffect(() => {
    const emailWatched = watch('email');
    const emailConfirmationWatched = watch('emailConfirmation');
    checkEmail(emailWatched);
    checkEmailConf(emailConfirmationWatched);
  }, [watch]);

  useEffect(() => {
    const params = new URL(window.location).searchParams;
    const isEmail = params.get('goToValidateEmail');
    if (isEmail && JSON.parse(isEmail)) setGoToValidateEmail(true);

    const cpfWatched = normalizeCpf(watch('cpf'));
    checkCpf(cpfWatched);
  }, []);

  const handleNavigate = url => {
    const userData = getUserFromFormData(getValues());
    updateUser(userData);

    window.scrollTo(0, 0);

    history.push({
      pathname: url,
      search: `?${new URLSearchParams({
        href: isFacebookFlow ? '/registro?facebookUser=true' : '/registro',
      }).toString()}`,
    });
  };

  /*   return (
    <Container>
      <TitleSection
        title="Confira e complete as suas informações"
        subtitle="Essas informações garantem sua identificação e segurança de acesso."
      />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Input
            htmlFor="name"
            text="Nome"
            type="text"
            name="name"
            maxLength="35"
            required
            showIcon
            isValid
          />
          <input type="submit" />
        </form>
      </FormProvider>
    </Container>
  ); */

  return (
    <>
      <Container>
        <TitleSection
          title="Confira e complete as suas informações"
          subtitle="Essas informações garantem sua identificação e segurança de acesso."
        />
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(newUser)}>
            <Input
              htmlFor="name"
              text="Nome"
              type="text"
              name="name"
              maxLength="35"
              required
              showIcon
              isValid
            />
            <Input
              htmlFor="lastname"
              text="Sobrenome"
              type="text"
              name="lastname"
              maxLength="35"
              required
              showIcon
              isValid
            />
            <Input
              htmlFor="email"
              text="E-mail"
              type="email"
              name="email"
              maxLength="50"
              data-testid="register email"
              required
              onFocus={() => focusEmail()}
              isValid={
                !isEmailError &&
                !(isEmailConfirmError && watch('emailConfirmation') !== '')
              }
              showIcon={showIconEmail}
              showError={isEmailError}
              style={
                isEmailError ||
                (isEmailConfirmError && watch('emailConfirmation') !== '')
                  ? { backgroundColor: 'var(--color-input-error)' }
                  : { backgroundColor: 'var(--color-input)' }
              }
              errorMessage={messageErrorEmail}
            />
            {watch('email') !== '' && (
              <Input
                htmlFor="emailConfirmation"
                text="Confirme seu e-mail"
                type="email"
                name="emailConfirmation"
                data-testid="register email Confirmation"
                maxLength="50"
                required
                showIcon
                isValid={!isEmailConfirmError && !isEmailError}
                showError={isEmailConfirmError || isEmailError}
                errorMessage={messageErrorEmailConfirm}
                onFocus={() => changeFocusEmail()}
                style={
                  isEmailConfirmError || isEmailError
                    ? { backgroundColor: 'var(--color-input-error)' }
                    : { backgroundColor: 'var(--color-input)' }
                }
              />
            )}
            <Input
              text="CPF"
              name="cpf"
              type="tel"
              data-testid="register cpf"
              htmlFor="cpf"
              maxLength="14"
              required
              onChange={e => {
                e.target.value = normalizeCpf(e.target.value);
                checkCpf(e.target.value);
              }}
              isValid={!isCpfError}
              showIcon={isCpfCompleted}
              showError={isCpfError}
              errorMessage={isCpfCompleted ? messageErrorCpf : ''}
              style={
                isCpfCompleted && isCpfError
                  ? { backgroundColor: 'var(--color-input-error)' }
                  : { backgroundColor: 'var(--color-input)' }
              }
            />

            <div className="text-lgpd">
              <p>
                Ao continuar, você concorda com as
                <SpanText
                  onClick={() => handleNavigate('/politica-de-privacidades')}
                >
                  Diretrizes de Privacidade de Dados
                </SpanText>
                e
                <SpanText onClick={() => handleNavigate('/termos-de-uso')}>
                  Termos de condições de Uso
                </SpanText>
                do aplicativo Popeyes Brasil.
              </p>

              <p>
                Você também concorda com o recebimento de ações promocionais via
                e-mail, sms e push. Na página Permissões e privacidade você pode
                gerenciar suas preferências.
              </p>
            </div>

            <Button
              type="submit"
              disabled={
                !formState.isValid ||
                isCpfError ||
                isEmailError ||
                isEmailConfirmError
              }
            >
              {loadingButton ? 'Carregando...' : 'Continuar'}
            </Button>

            <div className="registered">
              <Link to="validado">
                <h3>JÁ TENHO CADASTRO</h3>
              </Link>
            </div>

            <SafetySection />
          </Form>
        </FormProvider>
      </Container>
    </>
  );
}

export default SignUp;
