import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const CouponContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;

  min-width: 255px;
  width: 255px;
  height: 144px;
  margin: 12px 24px 0px 0px;

  background-color: #f0f0f0;
  border-radius: 4px;

  cursor: pointer;

  button {
    width: 94px;
    margin: 0px 17px;

    font-size: 14px !important;
    line-height: 14px;

    background-color: ${({ buttonColor }) => buttonColor};
    color: ${({ textColor }) => textColor};
  }

  @media only all and (min-width: ${sizes.ipad}) {
  }

  @media only all and (min-width: ${sizes.notebook}) {
    min-width: 500px;
    width: 500px;
    height: 200px;

    button {
      width: 180px;
      margin: 0px auto;

      font-size: 14px !important;
      line-height: 14px;
    }
  }

  @media only all and (min-width: ${sizes.desktop}) {
  }
`;

export const Image = styled.img`
  width: 50%;
  object-fit: contain;

  @media only all and (min-width: ${sizes.notebook}) {
    margin: 0px 20px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;

  margin: 0px 8px;

  @media only all and (min-width: ${sizes.notebook}) {
    margin: 20px 10px;
  }
`;

export const Title = styled.p`
  display: -webkit-box;

  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;

  @media only all and (min-width: ${sizes.notebook}) {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }
  @media only all and (min-width: ${sizes.desktop}) {
    font-size: 1.6rem;
    line-height: 1.5rem;
  }
`;

export const CircleLeft = styled.div`
  position: absolute;
  top: -7px;
  left: -5px;

  width: 13px;
  height: 14px;

  border-radius: 40px;
  background-color: ${({ isOfferDayColor }) => isOfferDayColor || 'white'};
`;

export const CircleRight = styled.div`
  position: absolute;
  bottom: -7px;
  left: -5px;

  width: 13px;
  height: 14px;

  border-radius: 40px;
  background-color: ${({ isOfferDayColor }) => isOfferDayColor || 'white'};
`;

export const LineOver = styled.div`
  position: relative;
  height: 100%;
  border-right: 2px dashed
    ${({ isOfferDayColor }) => isOfferDayColor || 'white'};
`;

export const PriceContainer = styled.div`
  display: flex;
  justify-content: center;

  font-family: 'ChickenSansBold';
  font-style: bold;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  margin: 8px 0px;

  color: ${props =>
    props.textColor ? props.textColor : `var(--color-burnt-orange)`};
`;

export const Container = styled.div`
  width: 100%;
`;

export const RegularValue = styled.div`
  margin: 10px 0px 0px 0px;

  text-align: center;

  font-family: 'ChickenSansRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-decoration-line: line-through;

  color: #747474;
  opacity: 0.6;

  @media only all and (min-width: ${sizes.notebook}) {
    margin: 10px 0px 0px 0px;
    font-size: 15px;
  }

  @media only all and (max-width: ${sizes.mobile}) {
    margin: 0;
    position: relative;
    bottom: 4px;
  }
`;
