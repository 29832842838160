import LoadingPop from 'pages/Loading';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MdLaunch } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
// eslint-disable-next-line import/no-cycle
import Files from 'assets/images/menu/pieces/plk-files.svg';
import Pieces from 'assets/images/menu/pieces/plk-pedacos.svg';
import Suave from 'assets/images/menu/spice/plk-tempero-suave.svg';
import Vibrante from 'assets/images/menu/spice/plk-tempero-vibrante.svg';
import CouponsComponent from 'containers/ProductDetails/components/Coupons';
import ModalCart from 'containers/ProductDetails/components/ModalCart';
import ModalCoupon from 'containers/ProductDetails/components/ModalCoupon';
import { useProductDetails } from 'hooks/ProductDetails';
import { breakpoints } from 'styles/themes';
import { useTagManager, ButtonEvents } from 'hooks/Analytics';
import IlustraTitle from '../../../assets/images/menu/ilustra-nome-produto.svg';
import Cart from '../../../containers/Coupon/components/Cart';
import { IProductDetails } from './interfaces';
import * as S from './styles';

export const ProductDetails: React.FC<IProductDetails> = ({
  id,
  containerRef,
  hideBanner,
  ...rest
}) => {
  const { loading, product, coupons, categories, onChoseCoupon } =
    useProductDetails();

  const history = useHistory();
  const { sendButtonClickEvent } = useTagManager();
  const containerCouponsRef = useRef<HTMLDivElement>(null);

  // Modals
  const [openModalCart, setOpenModalCart] = useState(false);
  const [openModalCoupon, setOpenModalCoupon] = useState(false);
  const [openModalLogin, setOpenModalLogin] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const hasCupons = coupons.length > 0;

  // Urls
  const [urlOpenCart, setUrlOpenCart] = useState(false);

  const checkIfIsMobile = useCallback(() => {
    const { innerWidth: width } = window;
    if (width < parseInt(breakpoints.large, 10)) {
      setIsMobile(true);
      hideBanner = true;
    }
  }, []);

  useEffect(() => {
    checkIfIsMobile();
  });

  const changeUrlOnChoseCoupon = useCallback(
    (coupon: any) => {
      const url = new URL(window.location.href);
      url.searchParams.set('code', coupon.codigo);
      window.history.pushState({}, '', url);
    },
    [onChoseCoupon],
  );

  const clearUrlWhenClose = useCallback(() => {
    window.history.replaceState(null, null, window.location.pathname);
  }, []);

  const handleChoseCoupon = useCallback(
    (couponSelected: any) => {
      const couponData = {
        cupomName: couponSelected.nome,
        cupomCode: couponSelected.codigo,
        cupomValue: couponSelected.valor_descontado,
      };

      sendButtonClickEvent(ButtonEvents.VIEW_CUPOM, couponData);
      onChoseCoupon(couponSelected);
      setOpenModalCoupon(true);
      changeUrlOnChoseCoupon(couponSelected);
    },
    [onChoseCoupon, setOpenModalCoupon],
  );

  const handleOpenModalCart = () => {
    setOpenModalCart(true);
  };

  useEffect(() => {
    if (coupons.length > 0) {
      if (urlOpenCart) {
        handleOpenModalCart();
        setUrlOpenCart(false);
      }
    }
  }, [coupons, categories]);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
  };

  return (
    <S.Container>
      {loading && <LoadingPop />}
      {product && (
        <>
          <S.BoxImageBgContainer hasCupons={hasCupons}>
            <S.BoxContainer>
              <p className="menuBreadcrumb">
                CARDÁPIO | {product.categorias[0].nome.toUpperCase()}
              </p>
              <S.TitleContainer>
                <img src={IlustraTitle} alt="Ilustração para título" />
                <h1>{product.nome}</h1>
              </S.TitleContainer>
              <S.ProductContainer
                {...rest}
                data-testid={`ProductDetails@${id}`}
              >
                <S.ProductPreviewContainer>
                  <S.ImageContainer>
                    <Slider {...settings}>
                      {product.imagem.map((img: any, index: any) => (
                        <div key={index}>
                          <img src={img.url} alt="Imagem do produto" />
                        </div>
                      ))}
                    </Slider>
                  </S.ImageContainer>
                </S.ProductPreviewContainer>
                <S.ProductDescriptionContainer>
                  <S.BoxProductInfo>
                    <S.ProductTitle>
                      <h2>{product.nome}</h2>
                    </S.ProductTitle>
                    <S.ProductDescription>
                      {product.descricao}
                    </S.ProductDescription>
                  </S.BoxProductInfo>

                  <S.ContainerDescription>
                    {product.quantidade_itens.length > 0 && (
                      <S.ContainerItemDescription>
                        <S.ChoiceTitle>
                          <div>
                            <p>OPÇÕES DE BALDE</p>
                            <hr />
                          </div>
                          <p>
                            Você pode escolher em{' '}
                            {product.quantidade_itens.length} tamanhos:
                            {product.quantidade_itens.map((item: any) => (
                              <li key={item.id}>{item.descricao}</li>
                            ))}
                          </p>
                        </S.ChoiceTitle>
                      </S.ContainerItemDescription>
                    )}
                    {product.pedacos && product.files && (
                      <S.ContainerItemDescription>
                        <S.ChoiceTitle>
                          <div>
                            <p>CORTE DO FRANGO</p>
                            <hr />
                          </div>
                          <p>Escolha entre Pedaço ou filé:</p>
                        </S.ChoiceTitle>
                        <S.ChoiceOptions>
                          <div>
                            <div>
                              <img
                                id="pieces"
                                src={Pieces}
                                alt="Pedaços com osso"
                                style={{
                                  maxWidth: '100%',
                                  maxHeight: '100%',
                                  objectFit: 'cover',
                                }}
                              />
                            </div>
                            <h3>{product.pedacos} Pedaços</h3>
                            <p>Com osso</p>
                          </div>
                          <p>ou</p>
                          <div>
                            <div>
                              <img
                                id="files"
                                src={Files}
                                alt="Sem osso"
                                style={{
                                  maxWidth: '100%',
                                  maxHeight: '100%',
                                  objectFit: 'cover',
                                }}
                              />
                            </div>
                            <h3>{product.files} Filés</h3>
                            <p>Sem osso</p>
                          </div>
                        </S.ChoiceOptions>
                      </S.ContainerItemDescription>
                    )}
                    {product.pedacos && !product.files && (
                      <S.ContainerItemDescription>
                        <S.ChoiceTitle>
                          <div>
                            <p>CORTE DO FRANGO</p>
                            <hr />
                          </div>
                          <p>Escolha entre Pedaço ou filé:</p>
                        </S.ChoiceTitle>
                        <S.ChoiceOptions>
                          <div>
                            <div>
                              <img
                                id="pieces"
                                src={Pieces}
                                alt="Pedaços com osso"
                                style={{
                                  maxWidth: '100%',
                                  maxHeight: '100%',
                                  objectFit: 'cover',
                                }}
                              />
                            </div>
                            <h3>{product.pedacos} Pedaços</h3>
                            <p>Com osso</p>
                          </div>
                        </S.ChoiceOptions>
                      </S.ContainerItemDescription>
                    )}
                    {!product.pedacos && product.files && (
                      <S.ContainerItemDescription>
                        <S.ChoiceTitle>
                          <div>
                            <p>CORTE DO FRANGO</p>
                            <hr />
                          </div>
                          <p>Você pode escolher filés:</p>
                        </S.ChoiceTitle>
                        <S.ChoiceOptions>
                          <div>
                            <div>
                              <img
                                id="files"
                                src={Files}
                                alt="Sem osso"
                                style={{
                                  maxWidth: '100%',
                                  maxHeight: '100%',
                                  objectFit: 'cover',
                                }}
                              />
                            </div>
                            <h3>{product.files} Filés</h3>
                            <p>Sem osso</p>
                          </div>
                        </S.ChoiceOptions>
                      </S.ContainerItemDescription>
                    )}
                    {product.suave && product.vibrante && (
                      <S.ContainerItemDescription>
                        <S.ChoiceTitle>
                          <div>
                            <p>TEMPERO</p>
                            <hr />
                          </div>
                          <p>Escolha entre Suave ou Vibrante:</p>
                        </S.ChoiceTitle>
                        <S.ChoiceOptions>
                          <div>
                            <div>
                              <img
                                id="suave"
                                src={Suave}
                                alt="Tempero suave"
                                style={{
                                  maxWidth: '100%',
                                  maxHeight: '100%',
                                  objectFit: 'cover',
                                }}
                              />
                            </div>
                            <h3>Suave</h3>
                            <p>Mix de temperos de Louisiana</p>
                          </div>
                          <p>ou</p>
                          <div>
                            <div>
                              <img
                                id="vibrante"
                                src={Vibrante}
                                alt="Tempero vibrante"
                                style={{
                                  maxWidth: '100%',
                                  maxHeight: '100%',
                                  objectFit: 'cover',
                                }}
                              />
                            </div>
                            <h3>Vibrante</h3>
                            <p>Especiarias levemente apimentadas</p>
                          </div>
                        </S.ChoiceOptions>
                      </S.ContainerItemDescription>
                    )}
                  </S.ContainerDescription>

                  <S.ContainerInfoNutri>
                    <h4>Informação Nutricional</h4>
                    <S.LinkText
                      href="https://plk-app-media.s3.amazonaws.com/Tabela_Nutricional_A3_Novembro_2022.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <MdLaunch size="24px" color="#FF7D00" />
                    </S.LinkText>
                  </S.ContainerInfoNutri>
                </S.ProductDescriptionContainer>
              </S.ProductContainer>
              {!isMobile && (
                <S.RedirectButtonsContainer>
                  <S.ButtonRedirect
                    id="storyExpand"
                    title="storyExpand"
                    onClick={() => history.push('/cardapio')}
                  >
                    <p>Voltar para Cardápio</p>
                  </S.ButtonRedirect>

                  <S.ButtonRedirect
                    id="storyExpand"
                    title="storyExpand"
                    onClick={() => history.push('/cupom')}
                  >
                    <p>Ver Todos os Cupons</p>
                  </S.ButtonRedirect>
                </S.RedirectButtonsContainer>
              )}
            </S.BoxContainer>
          </S.BoxImageBgContainer>

          {coupons.length > 0 && (
            <S.SectionCoupons>
              <S.CouponsTitle>Cupons</S.CouponsTitle>
              <S.CouponsContainer ref={containerCouponsRef}>
                <CouponsComponent
                  onSelectCoupon={handleChoseCoupon}
                  onGetCoupon={() => handleOpenModalCart()}
                />
              </S.CouponsContainer>
              <ModalCart
                open={openModalCart}
                onClose={() => {
                  setOpenModalCart(false);
                }}
              />
              <ModalCoupon
                openModalLogin={openModalLogin}
                onToogleModalLogin={option => setOpenModalLogin(option)}
                open={openModalCoupon}
                onClose={props => {
                  setOpenModalCoupon(false);
                  if (props?.openMyCart) handleOpenModalCart();
                  clearUrlWhenClose();
                }}
              />
            </S.SectionCoupons>
          )}
        </>
      )}
      <Cart openModal={() => handleOpenModalCart()} />
    </S.Container>
  );
};
