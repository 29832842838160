import { shade } from 'polished';
import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  @media only all and (max-width: ${sizes.ipadPro}) {
    .desktop-card {
      display: none;
    }
  }
`;

export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px;
  padding-top: 0px;

  button {
    height: auto;
    width: auto;
    min-width: 80px;

    font-weight: normal;
    font-style: normal;
    font-size: 14px !important;
  }

  img {
    transform: rotate(270deg);
  }

  h5 {
    font-weight: normal;
    font-style: normal;
    font-family: 'ChickenSansRegular';
    font-size: 20px;
    line-height: 24px;
  }

  * {
    outline: none;
  }

  .back-btn {
    font-size: 14px;
    color: var(--color-popeyes-orange);
  }

  @media only all and (max-width: ${sizes.ipadPro}) {
    .back-btn {
      display: none;
    }

    .arrowIcon {
      transform: rotate(270deg);
    }

    button {
      min-width: 35px;

      font-size: 12px !important;
    }
  }

  @media only all and (min-width: ${sizes.ipadPro}) {
    img {
      transform: rotate(0deg);
    }
  }
`;

export const TextTotem = styled.div`
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 6px 0px;
  text-align: center;

  background: var(--color-nola-teal);
  text-align: center;

  font-size: 12px;
  font-weight: 400;
  font-family: 'ChickenSansRegular';
  color: var(--color-salt-white);
`;

export const AlertTotemValidated = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: start;

  height: 40px;
  width: 100%;
  margin-bottom: 20px;

  font-family: 'ChickenSansRegular';
  text-align: center;
  color: var(--color-text-switch);
  font-size: 12px;

  background: var(--color-background-switch);

  opacity: 45%;

  img {
    margin: 0px 10px 0 20px;
  }

  @media only all and (min-width: ${sizes.ipadPro}) and (min-width: 1025px) {
    justify-content: center;
    opacity: 100%;
  }
`;

export const GetMoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 92%;

  margin-right: 16px;
  margin-left: 16px;
  border-radius: 4px;
  opacity: 45%;

  #circle {
    align-items: center;
    justify-content: center;
    text-align: center;

    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-top: 5%;
    margin-bottom: 2%;

    opacity: 30%;
    background: var(--color-text-switch);

    img {
      height: 700;
      width: 13px;
      height: 29px;
      margin-top: 5px;
    }
  }

  span {
    font-size: 10px;
    font-weight: 400;
    color: var(--color-text-switch);
    padding-bottom: 13px;
  }

  @media only all and (min-width: ${sizes.ipadPro}) {
    justify-content: center;
    opacity: 100%;
    width: 100%;
    margin-right: 0;
    margin-left: 0;

    #circle {
      margin-top: 1%;
      margin-bottom: 1%;
    }
  }

  @media all and (min-width: ${sizes.notebook}) {
    margin: 0px 20px;
    width: auto;
  }

  cursor: pointer;
`;

export const Footer = styled.div`
  display: flex;

  margin-top: auto;
  margin-bottom: 20px;
  margin-top: 5px;

  h6 {
    padding: 0 40px;

    color: #929292 !important;
    text-align: center;

    font-family: 'ChickenSansRegular';
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    opacity: 90%;
  }

  @media only all and (min-width: ${sizes.ipadPro}) {
    width: 100%;
    position: sticky;
    bottom: 10px;
    justify-content: center;
    background: #ffffff;
    padding-bottom: 0.6rem;
  }
`;

export const ContainerCoupons = styled.div`
  height: 100%;
  width: 95%;

  padding-bottom: 20px;
  overflow: hidden;
  scroll-behavior: smooth;

  h4 {
    font-size: 12px;
    font-weight: 400;
    color: var(--color-text);

    text-align: center;
    align-items: center;
    justify-content: center;
    font-family: 'ChickenSansRegular';
    margin-bottom: 2%;
  }

  h6 {
    font-size: 12px;
    font-family: 'ChickenSansRegular';
    font-weight: 400;
    text-align: center;
    color: var(--color-popeyes-orange);
  }

  @media only all and (min-width: ${sizes.ipadPro}) {
    width: 100%;
    overflow: visible;
  }

  .desktop-card {
    position: absolute;
    height: 228px;
    width: 100%;
    top: 10px;
  }

  .desktop-footer {
    position: absolute;
    height: 20px;
    width: 100%;
    bottom: 10px;
    left: 0;
    right: 0;
  }

  .desktop-coupons {
    position: absolute;
    top: 250px;
    bottom: 50px;

    width: 100%;
    overflow-y: scroll;
  }

  @media only all and (min-width: ${sizes.ipadPro}) {
    .desktop-coupons {
      ::-webkit-scrollbar {
        width: 0.6rem;
      }

      ::-webkit-scrollbar-track {
        background: #f3f3f3;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb {
        background: #bdc3c7;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: #95a5a6;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb:active {
        background: #7f8c8d;
        border-radius: 10px;
      }
    }
  }

  @media only all and (max-width: ${sizes.ipadPro}) {
    .desktop-card {
      display: none;
    }
    .desktop-footer {
      display: none;
    }

    .desktop-coupons {
      position: unset;
    }
  }
`;

export const ContentItemsCoupons = styled.div`
  overflow: scroll;
  scroll-behavior: smooth;

  width: 100%;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
    width: 0px;
    height: 0px;
  }

  @media only all and (min-width: ${sizes.ipadPro}) {
    margin-top: 1rem;
    padding: 0 1rem;
  }

  @media only all and (max-width: ${sizes.ipadPro}) {
    margin-top: 0;
  }
`;

export const ContentCoupons = styled.div`
  display: flex;
  flex-direction: row;
  width: 120%;
  overflow: scroll;

  @media only all and (min-width: ${sizes.ipadPro}) {
    height: 100px;
  }
  @media only all and (max-width: ${sizes.ipadPro}) {
    height: auto;
  }
  overflow: hidden;
`;

export const Coupons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 4%;
  padding: 11px 3px 10px 16px;
  background-color: #f3f3f3;
  margin-left: 16px;
  //margin-right: 16px;
  border-radius: 4px;
  width: 120%;
  overflow: hidden;

  #namePrice {
    width: 40%;
    margin-right: 29px;

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color: #303030;
      margin-bottom: 13px;
    }

    strong {
      font-size: 14px;
      font-weight: 700;
      font-family: 'ChickenSansRegular';
      line-height: 17px;
      letter-spacing: -0.5px;
      color: #303030;
    }
  }

  #codeCoupon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 95px;
    margin-right: 16px;

    span {
      font-size: 10px;
      font-weight: 400;
      margin-bottom: 3px;
    }

    h3 {
      font-size: 22px;
      font-weight: 700;
      font-family: 'ChickenSansRegular';
      color: var(--color-salt-white);
      padding: 3px 14px;
      background: #00b2a9;
    }
  }

  svg {
    color: #929292;
    margin-right: 10px;
  }

  @media only all and (min-width: ${sizes.ipadPro}) {
    padding: 11px 3px 10px 16px;
    overflow: hidden;
    padding: 0px 0px 0px 0px;
    margin-left: 0;
    margin-top: 0%;
    justify-content: flex-start;

    #namePrice {
      width: 70%;
      margin-left: 3%;

      p {
        font-size: 22px;
        margin-bottom: 1.5rem;
      }
      strong {
        font-size: 18px;
      }
    }
    #codeCoupon {
      max-width: 195px;
      span {
        font-size: 14px;
      }
      h3 {
        padding: 10px 34px;
      }
    }
  }

  @media only all and (max-width: ${sizes.ipadPro}) {
    #namePrice {
      margin-left: 1%;
    }
  }
`;

export const RemoveCoupons = styled.div`
  display: flex;
  flex-direction: column;
  //flex: 1;
  align-items: center;
  justify-content: center;
  width: 20%;
  margin-top: 4%;
  background: var(--color-spicy-red);
  padding: 10px 0;
  border-radius: 4px;
  transition: background-color 0.2s;
  cursor: pointer;

  svg {
    color: var(--color-salt-white);
    margin-bottom: 7px;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    color: var(--color-salt-white);
    font-family: 'ChickenSansRegular';
    line-height: 14px;
  }

  &.hover {
    background: ${shade(0.9, '#D90F06')};
  }

  @media only all and (min-width: ${sizes.ipadPro}) {
    margin-top: 0;
    width: 10%;
  }
`;

export const ModalContent = styled.div`
  padding: 32px 24px 0px 24px;
  font-family: 'ChickenSansRegular';
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 24px 0px;
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
  }

  h3 {
    margin: 0px 24px;
  }

  a {
    text-decoration: underline;
    color: var(--color-burnt-orange);
    margin-left: 5px;
  }
`;
