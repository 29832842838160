import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: var(--size-max-width-container);
  margin: 0 auto;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  background-color: #ffff;
  position: relative;
  padding: 0px 24px 50px 24px;
  padding-top: var(--size-header-height);

  .bg-overlay {
    position: absolute;
    max-width: 850px;
    height: 100%;
    bottom: 0;
    left: 0;
    background-color: #000000;
    z-index: 1;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
  }

  .show {
    opacity: 20%;
    width: 100%;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
  }

  @media (min-width: ${sizes.notebook}) {
    width: 50%;
    align-items: center;
    p {
      max-width: 312px;
      margin: 24px auto 32px;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  width: 100%;
  height: 100%;

  button {
    margin-top: auto;
  }

  @media (min-width: ${sizes.notebook}) {
    justify-content: flex-start;

    max-width: 312px;
    button {
      margin-top: initial;
      margin-top: 6px;
    }
  }
`;

export const ModalContent = styled.div`
  padding: 24px 24px 0px 24px;

  font-family: 'ChickenSansRegular';
  font-style: normal;
  font-weight: normal;

  p {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }

  .text {
    margin: 30px 0px;

    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: rgba(0, 0, 0, 0.4);
  }

  a {
    text-decoration: underline;
    color: var(--color-burnt-orange);

    margin-left: 5px;
  }
`;
