export const spacings = {
  space00: '2px',
  space01: '4px',
  space02: '8px',
  space03: '12px',
  space04: '16px',
  space05: '20px',
  space06: '24px',
  space07: '32px',
  space08: '40px',
  space09: '48px',
  space10: '56px',
  space11: '64px',
  space12: '72px',
  space13: '80px',
  space14: '88px',
  space15: '96px',
} as const;

export const font = {
  weight: {
    light: 300,
    normal: 400,
    semiBold: 600,
    bold: 700,
  },
  sizes: {
    // REM measure relative to font-size 100% to root
    textLittle: '0.625rem', // 10px
    textXSmall: '0.75rem', // 12px
    textSmall: '0.875rem', // 14px
    textMedium: '1rem', // 16px
    textLarge: '1.125rem', // 18px
    heading06: '1.25rem', // 20px
    heading05: '1.5rem', // 24px
    heading04: '1.75rem', // 28px
    heading03: '2rem', // 32px
    heading02: '2.25rem', // 36px
    heading01: '2.625rem', // 42px
    heading00: '3.375rem', // 54px
    ultraHeading: '4rem', // 64px
  },
  lineHeight: {
    /*
      To get the line-height size you must multiply
      its value by the font-size value.
      The example results will be based on 16px or 1 rem
    */
    little: 0.75, // 12px
    xSmall: 1, // 16px
    small: 1.2, // 19,2px
    medium: 1.4, // 22,4px
    large: 1.8, // 28,8px
  },
  family: {
    ChickenHeadline: 'ChickenSansBold, sans-serif',
    ChickenMicroText: 'ChickenSansExtraLight, sans-serif',
    ChickenText: 'ChickenSansRegular, sans-serif',
    Roboto: 'Roboto, sans-serif',
  },
} as const;

export const ZIndex = {
  base: 1,
  overhang: 5,
  projecting: 10,
  dropdown: 15,
  menu: 20,
  overlay: 30,
  modal: 40,
  overlap: 50,
} as const;

export const breakpoints = {
  xSmall: '375px',
  small: '600px',
  medium: '768px',
  large: '1024px',
  intermediary: '1280px',
  xLarge: '1400px',
  xXLarge: '1800px',
} as const;

export const transitions = {
  opacity01: 'opacity 0.3s ease',
  opacity02: 'opacity 0.2s',
  filter01: 'filter 0.3s ease',
  transform01: 'transform 0.3s ease',
  transform02: 'transform 0.6s ease',
  transform03: 'transform 1s ease',
  height01: 'max-height 0.3s ease',
  width01: 'max-width 0.3s ease',
  width02: 'max-width 1s ease',
  all01: 'all 0.3s ease',
  all02: 'all 0.25s ease-in-out',
  all03: 'all 0.6s ease',
  background01: 'background 0.3s ease',
  color01: 'color 0.3s ease',
  border01: 'border 0.3s ease',
} as const;
