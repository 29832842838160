import styled, { keyframes } from 'styled-components';
import sizes from 'utils/screenSizes';

const fadeIn = keyframes`
  from {
    top: 100vh;
  }
  to {
    top: 0vh;
  }
`;

export const Container = styled.div`
  position: relative;

  width: 100%;
  /* height: 100%; */
  /* height: calc(97vh - 88px); */
  height: auto;

  ${({ isMobile }) =>
    isMobile &&
    `
      margin-top: 0;
      width: 100%;
      height: 100%;
  `};

  background-image: ${({ banner }) => (banner ? `url(${banner})` : 'none')};
  background-position-y: -240px;
  background-repeat: no-repeat;
  background-size: cover;

  animation: ${fadeIn} 0.7s;

  @media (max-width: 1024px) {
    background-position-y: 0px;
    background-position-x: center;
  }

  @media (min-width: ${sizes.notebook}) {
    background-position-y: 0;
    background-size: 0;
    /* height: 565px; */
    background: var(--color-popeyes-orange);
  }

  @media (min-width: 1300px) {
    /* height: 600px; */
  }

  @media (min-width: ${sizes.desktop}) {
    /* height: 1000px; */
  }
  div .ButtonAnimationContainer {
    display: none;
  }
`;

export const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--size-header-height);
  padding: 24px;
  @media only all and (min-width: ${sizes.notebook}) {
    display: none;
  }
`;

export const HamburguerButton = styled.button`
  display: flex;

  width: 25px;
  height: 25px;

  border: none;
  outline: none;

  background-repeat: no-repeat;
  background-color: transparent;
  background-position-y: center;
  img {
    width: 100%;
    height: 100%;
    padding: 2px;
  }
`;

export const ButtonGroup = styled.div`
  position: absolute;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  left: 0;
  right: 0;
  bottom: 24px;

  padding: 0px 24px;
  margin-left: auto;
  margin-right: auto;

  button {
    border: none;
    border-radius: 6px;
    outline: none;
    cursor: pointer;
    background-color: var(--color-burnt-orange);
  }

  #delivery {
    position: relative;
    min-width: 100px;
    width: 100%;
    height: 100px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 15px;
    }

    label {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 700;
      color: var(--color-salt-white);
      pointer-events: none;
    }
  }

  #menu {
    position: relative;
    min-width: 100px;
    width: 100%;
    height: 100px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 15px;
    }

    label {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 700;
      color: var(--color-salt-white);
      pointer-events: none;
    }
  }

  #cardapio {
    position: relative;
    min-width: 100px;
    width: 100%;
    height: 100px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }

    label {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 700;
      color: var(--color-salt-white);
      pointer-events: none;
    }
  }

  @media only all and (min-width: ${sizes.notebook}) {
    display: none;
  }
`;

export const AnimationContainer = styled.div`
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 6% 24px;

  h3 {
    font-family: 'ChickenSansBold';
    font-weight: bold;
    margin: 12px 36px 36px 36px;
    text-align: center;
  }

  .partners {
    display: flex;
    flex-direction: row;
    margin: auto;
  }

  a {
    border: none;
    border-radius: 6px;
    outline: none;
    cursor: pointer;
    max-width: 100px;
    max-height: 100px;
  }

  #uber {
    position: relative;
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    margin-right: 5px;
    background-color: #000000;

    .logo {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 15px;
      width: 60px;
    }

    .link {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
    }
  }

  #ifood {
    position: relative;
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #ed1722;

    .logo {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 15px;
      width: 70px;
    }

    .link {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
    }
  }

  #rappi {
    position: relative;
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #fd6250;

    .logo {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 15px;
      width: 60px;
    }

    .link {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
    }
  }

  #plk-delivery {
    position: relative;
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    margin-left: 5px;
    background-color: #fd6250;

    .logo {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 15px;
      width: 60px;
    }

    .link {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
    }
  }
`;

export const ContainerButtonCampaignMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;

  @media (min-width: ${sizes.notebook}) {
    position: absolute;
    z-index: 1;
    top: 68%;
    left: 12%;
  }
`;

export const ButtonCampaignMobile = styled.button`
  display: flex;
  align-items: center;
  position: relative;
  /* top: ${props =>
    props.isMobile ? `calc(${props.windowDimensions}px - 184px)` : '20px'}; */

  height: 56px;
  width: 326px;
  color: #ff7d00;
  background: #fff;
  border: none;
  border-radius: 6px;
  gap: 20px;
  padding-left: 20px;

  p {
    color: #ff7d00;
    font-family: 'ChickenSansBold';
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
  }

  @media (max-width: ${sizes.ipadPro}) {
    width: 100%;
    top: 65vh;
  }
`;
