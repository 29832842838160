import React from 'react';

import Button from 'components/Button';
import ModalResponsive from 'components/ModalResponsive';
import PropTypes from 'prop-types';

// Components

// Styles
import { ModalContent } from '../styles';

const ModalVerify = ({ open, isEmail, onClose, onContinue, loading }) => {
  return (
    <>
      <ModalResponsive
        open={open}
        onClose={onClose}
        maxModalHeight={300}
        sizeModal="middle"
      >
        <ModalContent>
          <h3 className="title">
            {isEmail
              ? 'Você receberá um código de verificação no novo e-mail'
              : 'Você receberá um código de verificação no novo número'}
          </h3>

          <Button type="button" onClick={onContinue}>
            {loading ? 'Carregando...' : 'Continuar'}
          </Button>
          <Button type="button" size="S" variant="secondary" onClick={onClose}>
            Cancelar
          </Button>
        </ModalContent>
      </ModalResponsive>
    </>
  );
};

ModalVerify.propTypes = {
  open: PropTypes.bool.isRequired,
  isEmail: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ModalVerify;
