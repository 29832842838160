import { IRestaurantModel } from 'types/restaurant';
import RestaurantListItem from 'components/RestaurantListItem';

import {
  RestaurantsFavoriteStateListWrapper,
  RestaurantFavoritesList,
  StateTitle,
} from './styles';

interface IRestaurantStateProps {
  state: {
    id: number;
    state: string;
  };
  onToogleModalLogin: (value: boolean) => void;
  restaurants: IRestaurantModel[];
}

export default function RestaurantFavoriteState({
  state,
  restaurants,
  onToogleModalLogin,
}: IRestaurantStateProps) {
  return (
    <RestaurantsFavoriteStateListWrapper>
      <StateTitle>{state.state}</StateTitle>
      <RestaurantFavoritesList>
        {restaurants.map(restaurant => (
          <RestaurantListItem
            key={restaurant.id}
            onToogleModalLogin={onToogleModalLogin}
            restaurant={restaurant}
          />
        ))}
      </RestaurantFavoritesList>
    </RestaurantsFavoriteStateListWrapper>
  );
}
