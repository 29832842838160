/* eslint-disable no-unsafe-optional-chaining */
import styled, { css } from 'styled-components';
import sizes from 'utils/screenSizes';

export const FakeElementHeader = styled.div`
  width: 100%;
  height: 80px;
`;

export const ContainerHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 88px;

  h3 {
    color: #ff7d00;
    font-size: 20px !important;
    font-weight: 700 !important;
  }

  img {
    height: 50px;
  }

  @media all and (min-width: ${sizes.ipad}) {
    padding: 0 3%;
    display: flex;
    position: sticky;
    align-items: center;
    z-index: 7;
    left: 0%;
    transform: translate(-0%, 0%);
    width: 100%;
    transition: 0.5s top ease-in-out;
    background: var(--color-salt-white);
    top: 0px;
    position: fixed;

    /* ${props =>
      props.visible
        ? 'top: -0px; position: sticky; '
        : 'top: -150px; position: relative;'} */

    ${props =>
      props.hideHeaderMobile &&
      css`
        display: none;
      `};

    ${({ isSuccess }) =>
      isSuccess
        ? css`
            background: var(--color-popeyes-orange);
            color: var(--color-salt-white);
          `
        : css`
            background: var(--color-salt-white);
          `}
  }

  z-index: 1;

  ${({ noShadow }) =>
    !noShadow &&
    css`
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    `}
`;

export const ContainerGrid = styled.div`
  width: 1288px;
  display: flex;
  justify-content: space-between;
  height: inherit;

  > button {
    border: none;
    background: none;
    :hover {
      cursor: pointer;
    }
  }
`;

export const BackButton = styled.div`
  position: absolute;
  left: 24px;

  display: flex;
  align-items: center;

  cursor: pointer;
`;

export const HeaderButton = styled.button`
  border: none;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0.02em;
  text-align: center;
  position: relative;

  height: 30px;
  color: var(--color-text);
  display: flex;
  justify-content: center;
  align-items: center;

  background: none;
  font-family: 'ChickenSansBold';
  cursor: pointer;
  min-width: max-content;

  ${props => props.isActive && 'color: #FF7D00;'}
  ${props => props.moreActive && 'color: #FF7D00;'}
  ${props => props.isLogged && 'margin-right: 0;'}


  ${props =>
    props.isActive &&
    `
    &::before {
      content: '';
      position: absolute;
      top: 184%;
      height: 4px;
      width: 130%;
      background-color: #ff7d00;
      border-radius: 2px 2px 0px 0px;
    }`}

  &:focus {
    outline: none;
  }
  &:hover {
    color: #f25600;
  }

  /* &:hover::before {
    content: '';
    position: absolute;
    top: 184%;
    left: -14%;
    height: 4px;
    width: 130%;
    background-color: #ff7d00;
    border-radius: 2px, 2px, 0px, 0px;
  } */
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;

  > button:last-of-type {
    width: 110px;
    height: 40px;
    padding: 0px 24px 4px;
    border-radius: 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 133%;

    text-align: center;
    letter-spacing: 0.02em;

    color: #fff;
    background-color: var(--color-burnt-orange);

    ${props =>
      props?.children[0][props?.children[0].length - 1]?.props?.isActive &&
      'background-color: #8A3100;'}

    ${props => props?.isLogged && 'display: none;'}

    outline: none;
    :hover {
      background-color: #8a3100;
    }

    &::before {
      content: none;
    }

    &:hover::before {
      content: none;
    }

    content: none;
  }
`;

export const MoreContainer = styled.div`
  position: absolute;
  top: 100%;
  display: none;
  align-items: center;
  flex-direction: column;
  ${props => props.moreActive && 'display: flex;'}
`;

export const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f3f3f3;
`;

export const MoreContent = styled.div`
  background-color: #f3f3f3;
  width: 450px;
  height: 500px;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  cursor: default;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  background: #303030;
  opacity: 0.2;
`;

export const SignOut = styled.div`
  display: none;
  ${props => props.isLogged && 'display: flex;'}
  width: 20%;
  border-radius: 4px;
  line-height: 24px;

  align-items: center;
  justify-content: center;
  img {
    height: 25px;
    width: auto;
    margin: 0px 3px 0px 0px;
  }
  span {
    font-size: 1.2rem;
    font-family: 'ChickenSansRegular';
    color: #e16237;
  }
  :hover {
    cursor: pointer;
  }
  @media (min-width: ${sizes.desktop}) {
    text {
      height: 1.8rem;
      font-size: 1.9rem;
    }
    img {
      height: 28px;
    }
  }
`;

export const ModalContent = styled.div`
  padding: 12px 10px;
  font-family: 'ChickenSansRegular';
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 24px 0px;
    text-align: center;
  }

  h3 {
    margin: 0px 24px;
  }

  a {
    text-decoration: underline;
    color: var(--color-burnt-orange);
    margin-left: 5px;
  }
`;
