import styled, { keyframes } from 'styled-components';
import sizes from 'utils/screenSizes';

const around = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  position: fixed;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  text-align: center;

  height: 85px;
  width: 90%;

  bottom: 16px;
  left: 0;
  transform: translate(calc(50vw - 50%));

  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  background-color: var(--color-salt-white);

  z-index: 2;

  max-width: 685px;

  @media (max-width: ${sizes.ipadPro}) {
    max-width: 100%;
  }

  cursor: pointer;
`;

export const LabelCart = styled.text`
  margin: 32px 48px;
  font-family: 'ChickenSansRegular';
  @media (min-width: ${sizes.desktop}) {
    font-size: 1.5rem;
    margin: auto 32px;
  }
`;

export const QuantityCart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 50px;
  min-width: 60px;

  margin: 16px;
  padding: 0 20px;

  line-height: 50px;

  color: var(--color-salt-white);
  background-color: var(--color-burnt-orange);
  border: 0;
  border-radius: 4px;
`;

export const Loader = styled.div`
  position: relative;
  height: 20px;
  width: 20px;
  display: inline-block;
  animation: ${around} 5.4s infinite;

  ::after,
  ::before {
    content: '';
    background: transparent;
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-color: white white transparent transparent;
    border-style: solid;
    border-radius: 20px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    animation: ${around} 0.7s ease-in-out infinite;
  }

  ::after {
    animation: ${around} 0.7s ease-in-out 0.1s infinite;
    background: transparent;
  }
`;
