import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';

// Components
import * as Components from 'components';
import Button from 'components/Button';
import InputCode from 'components/InputCode';
import ModalResponsive from 'components/ModalResponsive';
import SafetySection from 'components/SafetySection';
import TitleSection from 'components/TitleSection';

// Services, Utils and Hooks
import { useAuth } from 'hooks/Auth';
import { api } from 'services';
import firebase from 'services/firebase';
import emailSecretMask from 'utils/emailSecretMask';

import { Container, Form, ModalContent } from './styles';
import schema from './validation';

// Styles

export default function Verification() {
  const { user, updateUser } = useAuth();
  const { push, goBack } = useHistory();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      token: '',
    },
  });
  const { formState } = methods;

  const [counter, setCounter] = useState(120);
  const [isInvalid, setIsInvalid] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const [subtitle] = useState(
    <>
      Um código de verificação foi enviado para o e-mail
      <strong> {emailSecretMask(user.email)} </strong> para recuperar sua conta
    </>,
  );

  const [subtitleModal] = useState(
    <p>
      Volte e verifique o código que foi enviado para o e-mail
      <strong> {emailSecretMask(user.email)}</strong>.
    </p>,
  );

  useEffect(() => {
    const params = new URL(window.location).searchParams;

    const openIsInvalid = params.get('openIsInvalid');
    if (openIsInvalid) setIsInvalid(true);
  }, []);

  async function sendNewEmail() {
    setIsInvalid(false);
    setCounter(120);
    await api.post('/customer/v0.1/cpfemail/accountrecovery', {
      cpf: user.cpf,
    });
  }

  const onSubmit = async data => {
    setLoadingButton(true);
    let tokens = '';
    tokens += data.name1;
    tokens += data.name2;
    tokens += data.name3;
    tokens += data.name4;

    tokens = String(tokens);

    if (user.cpf) {
      try {
        await api.post('/customer/v0.1/cpfpin/accountrecovery', {
          cpf: user.cpf,
          PIN: tokens,
        });

        firebase.analytics().logEvent('cadastro_continuar', {
          etapa_cadastro: 'cod_auth_pwa',
        });

        const tempUser = { ...user };
        tempUser.number = '';

        await updateUser(tempUser);

        push({
          pathname: '/sucesso',
          search: `?${new URLSearchParams({
            href: '/definir-numero-recuperacao',
            title: 'Sucesso!',
            subTitle: 'Código de verificação identificado com sucesso',
          }).toString()}`,
        });
        setTimeout(() => {
          push('/definir-numero-recuperacao');
        }, 3000);
      } catch (error) {
        firebase.analytics().logEvent('modal_erro', {
          tipo_erro: 'token_invalido_email',
        });
        setIsInvalid(true);
      }
    } else {
      goBack();
    }

    setLoadingButton(false);

    methods.reset();
  };

  return (
    <>
      <Container>
        <TitleSection
          title="Insira o código de verificação"
          subtitle={subtitle}
        />
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="countdown-code">
              <InputCode
                name1="name1"
                name2="name2"
                name3="name3"
                name4="name4"
              />
              <Components.Countdown
                counter={counter}
                onChange={newCounter => setCounter(newCounter)}
                onFinish={() => {}}
              />
            </div>

            <div className="safetyContainer">
              <SafetySection />
              <Button type="submit" disabled={!formState.isValid}>
                {loadingButton ? 'Carregando...' : 'Continuar'}
              </Button>

              <Button
                type="button"
                variant="secondary"
                onClick={sendNewEmail}
                disabled={counter > 0}
              >
                Reenviar código por e-mail
              </Button>
              <Button
                type="button"
                variant="secondary"
                onClick={() => {
                  firebase.analytics().logEvent('ajudarecuperacao');
                  push({
                    pathname: '/enviar-email',
                    search: `?${new URLSearchParams({
                      href: `/verificar-recuperacao`,
                      title: 'Fale conosco',
                      isHelp: true,
                      isRecover: true,
                      subject: 'Problemas com acesso na minha conta Popeyes',
                    }).toString()}`,
                  });
                }}
              >
                Preciso de ajuda
              </Button>
            </div>
          </Form>
        </FormProvider>
      </Container>

      <ModalResponsive
        maxModalHeight={380}
        sizeModal="middle"
        open={isInvalid}
        scrollable={false}
        onClose={() => setIsInvalid(false)}
      >
        <ModalContent>
          <h3 className="title">Código de verificação inválido</h3>
          {subtitleModal}
          <Button type="button" onClick={sendNewEmail}>
            Reenviar código por e-mail
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={() => {
              firebase.analytics().logEvent('ajudarecuperacao');
              push({
                pathname: '/enviar-email',
                search: `?${new URLSearchParams({
                  href: `/verificar-recuperacao?openIsInvalid=true`,
                  title: 'Fale conosco',
                  isHelp: true,
                  isRecover: true,
                  subject: 'Problemas com acesso na minha conta Popeyes',
                }).toString()}`,
              });
            }}
          >
            Preciso de ajuda
          </Button>
        </ModalContent>
      </ModalResponsive>
    </>
  );
}
