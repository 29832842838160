import * as yup from 'yup';

const schema = yup.object().shape({
  name1: yup.number().required('Este campo é obrigatório!'),
  name2: yup.number().required('Este campo é obrigatório!'),
  name3: yup.number().required('Este campo é obrigatório!'),
  name4: yup.number().required('Este campo é obrigatório!'),
});

export default schema;
