import styled from 'styled-components';

export const ModalContent = styled.div`
  padding: 12px 24px 0px 24px;
  font-family: 'ChickenSansRegular';
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 24px 0px;
    text-align: center;
  }

  h3 {
    font-size: 18px;
    font-family: 'ChickenSansBold';
    margin: 0px 24px;
  }

  a {
    text-decoration: underline;
    color: var(--color-burnt-orange);
    margin-left: 5px;
  }
`;
