import BgItemDetails from 'assets/images/bg-cardapio-item.png';
import { FaRegUser } from 'react-icons/fa';
import styled from 'styled-components';
import sizes from 'utils/screenSizes';
import { breakpoints } from '../../../styles/themes';

export const Container = styled.div`
  padding-top: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media all and (max-width: ${sizes.notebook}) {
    padding-top: 0;
  }

  @media all and (max-width: ${sizes.mobile}) {
    padding: 3%;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: -30px;
  margin-bottom: -30px;
  margin-left: -114px;

  h1 {
    font-family: 'ChickenScript';
    font-size: 96px;
    font-weight: 400;
    line-height: 110.4px;
    -webkit-font-smoothing: antialiased;
    color: #911987;
    z-index: 1;
  }

  @media (max-width: 1280px) {
    margin-top: -10px;
    margin-left: -77px;

    h1 {
      font-size: 76px;
    }

    img {
      height: 80px;
    }
  }

  @media (max-width: ${sizes.mobile}) {
    h1 {
      display: none;
    }

    img {
      display: none;
    }
  }
`;

export const BoxImageBgContainer = styled.div<{ hasCupons: boolean }>`
  background-image: url(${BgItemDetails});
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0 80px 0;

  @media all and (max-width: ${sizes.notebook}) {
    padding: ${({ hasCupons }) => (hasCupons ? '40px 0;' : '40px 0 120px 0;')};
  }

  @media all and (max-width: ${sizes.mobile}) {
    width: 100%;
    background-image: none;
  }
`;

export const BoxContainer = styled.div`
  width: 1280px;

  p {
    font-family: 'ChickenSans';
    font-weight: 400;
    font-size: 16px;
    color: #757575;
    padding: 10px 0;
  }

  @media (max-width: 1280px) {
    padding: 0 3%;
  }

  @media (min-width: 1540px) {
    p {
      padding: 0 0 !important;
    }
  }

  @media (max-width: ${sizes.mobile}) {
    width: 100%;
    padding: 0;

    .menuBreadcrumb {
      display: none;
    }
  }
`;

export const ProductContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  background: #ffffff;

  @media all and (max-width: ${sizes.notebook}) {
    display: flex;
    flex-direction: column;
    margin-top: -30px;
  }

  @media all and (max-width: ${sizes.mobile}) {
    display: flex;
    flex-direction: column;
  }
`;

export const ProductPreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProductDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 556px;
  overflow-y: auto;

  border-left: 2px solid #bdbdbd;

  @media all and (max-width: ${sizes.notebook}) {
    border-left: none;
  }

  @media all and (max-width: ${sizes.mobile}) {
    height: auto;
  }
`;

export const ImageContainer = styled.div`
  width: 556px;

  @media all and (max-width: ${sizes.mobile}) {
    width: 300px;

    /* .slick-next:before,
    .slick-prev:before {
      color: #ff7d00;
    } */

    .slick-dots {
      bottom: 0;
    }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 10px;
    font-size: 18px;
    @media (max-width: ${breakpoints.medium}) {
      font-size: 14px;
    }
  }
`;

export const ChoiceContainer = styled.div`
  margin-top: 20px;
  display: flex;
`;

export const ChoiceColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ChoiceItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ChoiceItemText = styled.h3`
  font-family: 'ChickenSansBold';
  margin-right: 10px;
`;

export const CouponsTitle = styled.h1`
  font-family: 'ChickenSansBold';
  font-size: 40px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: left;
`;

export const NutritionalInfo = styled.div`
  margin-top: 20px;
`;

export const BoxProductInfo = styled.div`
  padding: 25px 25px 0 25px;
`;

export const ProductTitle = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;

  @media all and (max-width: ${sizes.mobile}) {
    display: flex;
  }

  h2 {
    font-size: 24px;
    font-family: 'ChickenSansBold';
    font-weight: 700px;
    color: #212121;
  }

  > div {
    background: #e5e5e5;
    height: fit-content;
    border-radius: 4px;

    p {
      font-family: 'ChickenSansRegular';
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0.02em;
      text-align: center;
      color: #8f4600;
      padding: 3px;
    }

    @media all and (max-width: ${sizes.mobile}) {
      p {
        font-size: 14px;
      }
    }
  }
`;

export const ProductDescription = styled.p`
  margin-top: 20px;
  font-size: 18px;
  font-family: 'ChickenSansRegular';

  @media (max-width: ${breakpoints.medium}) {
    margin-top: 12px;
  }

  @media all and (max-width: ${sizes.mobile}) {
    margin-top: 0;
  }
`;

export const ContainerItemDescription = styled.div``;

export const ContainerDescription = styled.div`
  border-bottom: 2px solid #bdbdbd;
  padding: 0 25px 25px 25px;
  flex: 1;
`;

export const ChoiceTitle = styled.div`
  margin-top 40px;
  margin-bottom 15px;

  > div {
    display: grid;
    grid-template-columns: auto 2fr;
    align-items: center;
    gap: 5px;

    p {
      font-family: 'ChickenSansBold';
      font-size: 16px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.02em;
      text-align: left;
      color: #757575;
    }

    hr {
      border: 1px solid #e5e5e5;

    }
  }

  p {
    font-family: 'ChickenSansRegular';
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #757575;
  }

   @media all and (max-width: ${sizes.mobile}) {
    p {
      font-size: 14px;
    }
  }
`;

export const ChoiceOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div {
      height: 75px;
      width: 75px;

      img {
        width: auto;
        height: auto;
      }
    }

    h3 {
      font-family: 'ChickenSansBold';
      font-size: 20px;
      font-weight: 700;
      line-height: 27px;
      letter-spacing: 0.02em;
      text-align: center;
      color: #8f4600;
    }

    p {
      font-family: 'ChickenSansRegular';
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.02em;
      text-align: center;
      color: #757575;
    }
  }

  p {
    font-family: 'ChickenSansBold';
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    color: #212121;
  }
`;

export const UserIcon = styled(FaRegUser)`
  font-size: 25px;

  @media (max-width: ${breakpoints.medium}) {
    padding-left: 4px;
    font-size: 20px;
  }
`;

export const ContainerInfoNutri = styled.div`
  display: flex;
  padding: 15px 25px;
  justify-content: space-between;

  h4 {
    font-family: 'ChickenSansBold';
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-align: left;
  }
`;

export const LinkContainer = styled.div`
  margin-top: 20px;
  text-align: center;

  @media (max-width: ${breakpoints.medium}) {
    margin-bottom: 5vh;
  }
`;

export const LinkText = styled.a`
  color: #a6a6a6;
  text-decoration: none;
  border-bottom: none;
  font-family: 'ChickenSansRegular';
`;

export const RedirectButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 28px;
  margin-top: 30px;
`;

export const ButtonRedirect = styled.button`
  width: 245px;
  height: 40px;
  padding: 0px, 24px, 4px, 24px;
  border-radius: 8px;
  border: 2px;
  gap: 10px;
  border: 2px solid #f25600;
  background: #ffffff;

  p {
    font-family: 'ChickenSansBold';
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.02em;
    text-align: center;
    color: #f25600;
    padding: 0;
  }
`;

export const SectionCoupons = styled.div`
  width: 1280px;
  padding: 80px 0;

  @media (max-width: 1280px) {
    width: 100%;
    padding: 3%;
  }

  @media all and (max-width: ${sizes.notebook}) {
    width: 100%;
    padding-bottom: 110px;
    padding-top: 20px;

    h1 {
      font-size: 28px;
      text-align: center;
    }
  }

  @media all and (max-width: ${sizes.mobile}) {
    padding-bottom: 160px;

    h1 {
      font-size: 28px;
      text-align: center;
    }
  }
`;

export const CouponsContainer = styled.div`
  margin-top: 20px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: 30px;

  > div > div > div {
    display: flex;
    gap: 30px;
    justify-content: flex-start;
  }

  > div > div > div > div {
    margin: 0 !important;
    border-radius: 20px;

    @media all and (max-width: ${sizes.mobile}) {
      width: 144px;
      height: 212px;
    }
  }
`;
