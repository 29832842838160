import styled from 'styled-components';
import sizes from 'utils/screenSizes';

const TitleSectionContainer = styled.div`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  margin-top: 24px;

  h2 {
    margin: 0 24px;
    color: var(--color-text);
    font-family: 'ChickenSansBold';
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
  }

  p {
    margin-top: 24px;
    margin-bottom: 32px;
    font-family: 'ChickenSansRegular';
    font-weight: normal;
    color: var(--color-text);
    font-size: 16px;
    line-height: 18px;
    text-align: center;

    > div {
      margin: 5px 0px;
    }

    > p {
      padding: 0px 50px;
    }
  }

  @media (min-width: ${sizes.notebook}) {
    text-align: center;
    margin-top: 48px;
  }

  @media (min-width: ${sizes.desktop}) {
    width: 80%;
    text-align: center;
    margin-top: 48px;
    h2 {
      font-size: 3rem;
    }
  }
`;

export default TitleSectionContainer;
