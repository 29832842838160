import React, { useRef, useEffect, useState } from 'react';

import FooterComponent from 'components/FooterComponent';
import HeaderComponent from 'components/HeaderComponent';
import { ProfileContainer } from 'containers';
import { useHeader } from 'hooks/HeaderContext';

import { Container } from './styles';

export default function Profile() {
  const containerRef = useRef();
  const { handleRef } = useHeader();

  const [urlBack, setUrlBack] = useState('/');

  useEffect(() => {
    const params = new URL(window.location).searchParams;
    const href = params.get('href');
    if (href) setUrlBack(href);

    handleRef(containerRef);
  }, []);

  return (
    <Container ref={containerRef}>
      <HeaderComponent title="Perfil" href={urlBack} />
      <ProfileContainer />
      <FooterComponent />
    </Container>
  );
}
