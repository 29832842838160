/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-danger */
import React, {
  useMemo,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';

// Images and Icons
import MsgIcon from 'assets/images/carousel/icons/msg.svg';
import ArrowIcon from 'assets/images/icons/back-popeyes-orange.svg';
import Share from 'assets/images/icons/share.svg';

// Components
import Button from 'components/Button';
import ModalResponsive, {
  getWindowDimensions,
} from 'components/ModalResponsive';
import SignInFacebook from 'components/SignInFacebook';

// Hooks, Services and Utils
import { useAuth } from 'hooks/Auth';
import { useProductDetails } from 'hooks/ProductDetails';
import PropTypes from 'prop-types';
import firebase from 'services/firebase';
import { formatFirstValue, formatValueCents } from 'utils/formatValue';
import sizes from 'utils/screenSizes';

import { useTagManager, ButtonEvents } from 'hooks/Analytics';

// Styles
import { formatTextWithLineBreaks } from 'utils/formatText';
import {
  Content,
  HeaderModal,
  Footer,
  ModalContent,
  DesktopContent,
  AlertContent,
} from './styles';

const ModalCart = ({ open, onClose, openModalLogin, onToogleModalLogin }) => {
  const myRef = useRef();
  const history = useHistory();

  const { sendButtonClickEvent } = useTagManager();

  const { customerId } = useAuth();
  const { chosenCoupon, cartCoupon, couponDetailRemoteConfig, onGetCoupon } =
    useProductDetails();
  const [isGotCoupon, setIsGotCoupon] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowDimensions());

  const checkCouponAlreadyExists = useMemo(() => {
    if (chosenCoupon) {
      const couponFound = cartCoupon.find(
        couponFinded => couponFinded.id === chosenCoupon.id,
      );
      return !!couponFound;
    }
    return false;
  }, [cartCoupon, chosenCoupon]);

  const savingEarlyCoupon = id => {
    const couponCodeVisited = JSON.stringify(id.couponCode);
    localStorage.setItem('@Popeyes.cupon.visited.v2', couponCodeVisited);
  };
  const handleGetCoupon = async () => {
    const couponData = {
      cupomName: chosenCoupon.nome,
      cupomCode: chosenCoupon.codigo,
      cupomValue: chosenCoupon.valor_descontado,
    };

    sendButtonClickEvent(ButtonEvents.PEGAR_CUPOM, couponData);

    if (!customerId) {
      onToogleModalLogin(true);
      savingEarlyCoupon(chosenCoupon);
    } else if (checkCouponAlreadyExists) {
      onClose();
    } else {
      try {
        await onGetCoupon(chosenCoupon);
        onClose({ openMyCart: true });
      } catch (error) {
        console.log(error);
      }
      onClose();
    }
  };

  const clipboard = () => {
    const url = new URL(window.location);

    navigator.clipboard.writeText(url);
    setToggle(!toggle);
    setTimeout(() => {
      setToggle(prev => !prev);
    }, 5000);
  };

  const onShare = async () => {
    const urlParams = `?code=${chosenCoupon.codigo}`;
    const url = document.querySelector('link[rel=canonical]')
      ? document.querySelector('link[rel=canonical]').href + urlParams
      : document.location.href + urlParams;

    const text = `Popeyes Brasil | ${chosenCoupon?.name || 'Cupons'}`;

    try {
      await navigator.share({
        url,
        title: 'Louisiana Kitchen - Popeyes',
        text,
      });
    } catch (err) {
      console.log(`Couldn't share ${err}`);
    }
  };

  const footerModalComponent = useMemo(
    () => (
      <Footer>
        <div className="price">
          <p
            className="get-coupons-textValue"
            style={{ color: couponDetailRemoteConfig.preco_text.text_color }}
          >
            R$
          </p>
          <p
            className="get-coupons-valueFirst"
            style={{ color: couponDetailRemoteConfig.preco_text.text_color }}
          >
            {chosenCoupon?.valor_descontado &&
              formatFirstValue(chosenCoupon.valor_descontado)}
          </p>
          <p
            className="get-coupons-valueSecond"
            style={{ color: couponDetailRemoteConfig.preco_text.text_color }}
          >
            {chosenCoupon?.valor_descontado &&
              formatValueCents(chosenCoupon.valor_descontado)}
          </p>
        </div>

        <div
          className={
            isGotCoupon ? 'get-coupons-label-alreadyGot' : 'get-coupons-label'
          }
          style={{
            backgroundColor:
              couponDetailRemoteConfig.resgate_button.background_color,
          }}
          onClick={handleGetCoupon}
        >
          <p
            style={{
              color: couponDetailRemoteConfig.resgate_button.title_color,
            }}
          >
            {isGotCoupon
              ? 'Cupom já pego'
              : couponDetailRemoteConfig.resgate_button.title}
          </p>
        </div>
      </Footer>
    ),
    [onClose, isGotCoupon, couponDetailRemoteConfig, chosenCoupon],
  );

  const headerModalComponent = useMemo(
    () => (
      <HeaderModal>
        <Button
          className="rotate"
          type="button"
          variant="secondary"
          onClick={onClose}
        >
          <img src={ArrowIcon} alt="Fechar modal de cupons" />
          <span className="votar-btn">Voltar</span>
        </Button>

        <h5>Cupom</h5>
        <Button type="button" variant="secondary" onClick={onShare}>
          <img src={Share} alt="compartilhar cupom" className="iconShare" />
        </Button>
      </HeaderModal>
    ),
    [onClose, onShare],
  );

  useEffect(() => {
    const couponFound = checkCouponAlreadyExists;

    if (couponFound) {
      setIsGotCoupon(true);
    } else {
      setIsGotCoupon(false);
    }

    if (open && myRef.current)
      myRef.current.scrollTo({
        top: 1,
        behavior: 'smooth',
      });
    setWindowSize(getWindowDimensions());
  }, [open]);

  /* Refazer */
  useEffect(() => {
    function handleResize() {
      const dimensions = getWindowDimensions();
      setWindowSize(dimensions);
    }
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [windowSize]);

  /*
  const onClickFacebookLogin = async () => {
    firebase.analytics().logEvent('login_pwa', {
      tipo_login: 'modal_cupom_facebook',
    });
  };
  */

  const onClickPhoneLogin = async () => {
    /*
    firebase.analytics().logEvent('login_pwa', {
      tipo_login: 'modal_cupom_celular',
    });
    */

    history.push({
      pathname: '/login',
      search: `?${new URLSearchParams({
        href: '/cupom',
      }).toString()}`,
    });
  };

  return (
    <>
      <ModalResponsive
        open={open}
        onClose={onClose}
        headerModal={headerModalComponent}
        footerModal={footerModalComponent}
        sizeModal="full"
        showCloseButton={false}
      >
        {windowSize.width > parseInt(sizes.ipadPro, 10) ? (
          <DesktopContent>
            <AlertContent visibility={toggle}>
              Link copiado para a área de transferência!
            </AlertContent>
            <section className="sideImg">
              <div className="header-desktop">{headerModalComponent}</div>
              <div className="sizeImg">
                <img src={chosenCoupon?.imagem?.url} alt={chosenCoupon?.nome} />
              </div>
            </section>

            <section className="descriptionSide">
              <div className="contentDescriptionSide">
                <b>
                  {chosenCoupon?.nome}
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={clipboard}
                    className="iconShareAside"
                  >
                    <img src={Share} alt="compartilhar cupom" />
                  </Button>
                </b>

                <span>
                  Mostre o código do seu cupom para o atendente do caixa ou
                  digite no totem de auto atendimento.
                </span>

                <div
                  className="description-desktop product-description"
                  dangerouslySetInnerHTML={{
                    __html: formatTextWithLineBreaks(chosenCoupon?.descricao),
                  }}
                />
              </div>
              {footerModalComponent}
            </section>
          </DesktopContent>
        ) : (
          <Content ref={myRef}>
            <img src={chosenCoupon?.imagem?.url} alt={chosenCoupon?.nome} />

            <b>{chosenCoupon?.nome}</b>

            <span>
              Mostre o código do seu cupom para o atendente do caixa ou digite
              no totem de auto atendimento.
            </span>

            <div
              className="description-desktop product-description"
              dangerouslySetInnerHTML={{
                __html: formatTextWithLineBreaks(chosenCoupon?.descricao),
              }}
            />
          </Content>
        )}
      </ModalResponsive>

      <ModalResponsive
        open={openModalLogin}
        onClose={() => onToogleModalLogin(false)}
        maxModalHeight={380}
        sizeModal="middle"
      >
        <ModalContent>
          <h3 className="title">Pegue este cupom</h3>
          <p className="subtitle">
            Para pegar os cupons você precisa estar logado. Acesse sua conta
            abaixo.
          </p>

          <Button
            type="button"
            className="buttonLogin"
            onClick={onClickPhoneLogin}
          >
            <img src={MsgIcon} alt="Mensagem" className="MsgIcon" />
            Continuar com celular
          </Button>
        </ModalContent>
      </ModalResponsive>
    </>
  );
};

ModalCart.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  openModalLogin: PropTypes.bool.isRequired,
  onToogleModalLogin: PropTypes.func.isRequired,
};

export default ModalCart;
