import styled, { css } from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  position: relative;

  transition: all 0.5s ease;

  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-repeat: no-repeat;

  width: 100%;
  height: 335px;

  img {
    display: none;
  }

  @media (max-width: ${sizes.ipadPro}) {
    width: auto;
    height: auto;
    background-image: none;

    img {
      width: 100%;
      display: flex;
    }

    ${({ hideBanner }) =>
      hideBanner
        ? css`
            > img {
              max-height: 0px;
              transition: max-height 0.5s ease;
            }
          `
        : css`
            > img {
              max-height: ${({ imgHeight }) =>
                imgHeight ? `${imgHeight}px` : '100%'};
              transition: max-height 0.5s ease;
            }
          `}
  }
  @media (min-width: ${sizes.notebook}) {
    /* ${props =>
      props.hideBanner
        ? 'transition: 1s ease-in-out;  height: 0px;  button { display: none; }'
        : 'transition: 0.5s ease; height: 100%'} */
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  @media (min-width: ${sizes.desktop}) {
    width: 70%;
    margin: 0 auto;
  }
`;

export const OfferButton = styled.button`
  position: absolute;
  right: 16px;
  bottom: 16px;
  height: 30px;
  width: 92px;
  min-width: 0px !important;
  border-radius: 4px;

  font-size: 14px;
  font-family: 'ChickenSansRegular';
  font-weight: 700;
  color: ${props =>
    props.titleColor ? props.titleColor : `var(--color-burnt-orange)`};
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : `var(--color-salt-white)`};
  border: 0px;

  @media (min-width: ${sizes.notebook}) {
    position: static;
    margin: 0 0 20px;
    width: 180px;
    :focus {
      outline: none;
    }
  }
`;

export const Title = styled.h1`
  font-family: 'ChickenSansBold';
  font-size: ${({ hideBanner }) => (hideBanner ? '12px' : '54px')};
  text-align: center;

  transition: all 0.3s ease;
  @media (min-width: ${sizes.notebook}) {
    font-size: 54px;
  }
  @media (max-width: ${sizes.notebook}) {
    opacity: ${({ hideBanner }) => (hideBanner ? 0 : 1)};
    margin-top: ${({ hideBanner }) => (hideBanner ? '24px' : '15px')};
    margin-bottom: ${({ hideBanner }) => (hideBanner ? '0px' : '-10px')};
  }
`;

export const Header = styled.div`
  display: none;
  flex-direction: column;

  padding: 24px;

  button {
    border: none;
    background: transparent;
  }

  img {
    height: 100%;
    width: auto;
  }

  @media (max-width: ${sizes.ipadPro}) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    flex-direction: row;
    width: 100%;

    position: absolute;
    top: 0px;

    z-index: 10;
  }

  p {
    opacity: ${({ hideBanner }) => (hideBanner ? 1 : 0)};
    font-family: 'ChickenSansRegular';
    font-size: 14px;
    transition: opacity 0.5s;
  }

  ${({ hideBanner }) =>
    hideBanner &&
    css`
      background-color: var(--color-salt-white);
    `};
`;

export const ShadowGradient = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 60px;
  background: linear-gradient(
    180deg,
    rgba(67, 64, 64, 0.6) 0%,
    rgba(67, 64, 64, 0) 100%
  );

  opacity: 0;

  @media (max-width: ${sizes.ipadPro}) {
    opacity: 0.7;
  }
`;
