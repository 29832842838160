import { createGlobalStyle } from 'styled-components';

import ChickenSansBoldWf from '../fonts/ChickenSans-Bold.woff';
import ChickenSansBoldWf2 from '../fonts/ChickenSans-Bold.woff2';
import ChickenSansExtraLightWf from '../fonts/ChickenSans-ExtraLight.woff';
import ChickenSansExtraLightWf2 from '../fonts/ChickenSans-ExtraLight.woff2';
import ChickenSansRegularWf from '../fonts/ChickenSans-Regular.woff';
import ChickenSansRegularWf2 from '../fonts/ChickenSans-Regular.woff2';
import ChickenScriptRegular from '../fonts/ChickenScript_2.0.woff2';

export default createGlobalStyle`
  @font-face {
        font-family: 'ChickenSansBold';
        src: local('ChickenSansBold'), local('ChickenSansBold'),
             url(${ChickenSansBoldWf2}) format("woff2"),
             url(${ChickenSansBoldWf}) format("woff");
     }

  @font-face {
      font-family: 'ChickenSansRegular';
      src: local('ChickenSansRegular'), local('ChickenSansRegular'),
             url(${ChickenSansRegularWf2}) format("woff2"),
             url(${ChickenSansRegularWf}) format("woff");
     }

  @font-face {
      font-family: 'ChickenSansExtraLight';
      src: local('ChickenSansExtraLight'), local('ChickenSansExtraLight'),
             url(${ChickenSansExtraLightWf2}) format("woff2"),
             url(${ChickenSansExtraLightWf}) format("woff");
     }

  @font-face {
    font-family: 'ChickenScript';
    src: url(${ChickenScriptRegular}) format("woff2");
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .specialFont {
    font-family: 'ChickenScript';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }

  button:focus,a:focus {
    outline: auto 5px var(--color-popeyes-orange);
  }

  html, body, #root {
    width: 100%;
    height: 100%;

    a {
      text-decoration: none;
    }

    h1, h2, h3 {
      text-decoration: none;
    }

    p, span {
      text-decoration: none;
      font-family: 'ChickenSansRegular';
    }

    label {
      text-decoration: none;
      font-family: 'ChickenSansRegular';
    }

    input {
      text-decoration: none;
      font-family: 'ChickenSansRegular';
    }

    img {
      text-decoration: none;
    }

    header {
      h3 {
          font-family: 'ChickenSansRegular';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
      }
    }

    form {
      button {
        font-family: 'ChickenSansBold';
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
      }
    }



    .acessProblem {
      font-family: 'ChickenSansBold';
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
    }

    .registered {
      font-family: 'ChickenSansBold';
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
    }
  }

  button{
    cursor: pointer;
  }

  :root {
    --color-salt-white: #fff;
    --color-popeyes-orange: #FF7D00;
    --color-burnt-orange: #F25600;
    --color-butter-yellow: #FFC800;
    --color-nola-teal: #00B2A9;
    --color-nola-purple: #911987;
    --color-spicy-red: #D90F06;
    --color-text: #000;
    --color-text-dark: #303030;
    --color-input-block: #BBBBBB;
    --color-dark-theme: #515151;
    --color-dark-header: #3B3B3B;
    --color-blue-theme: #0075FF;
    --color-input-error: #F9DBDA;
    --color-input-ok: #E5F7F6;
    --color-input: #f2f2f2;
    --color-text-switch: #929292;
    --color-background-switch: #fff8dd;
    --color-background-switch-alt: #F3F3F3;

    --size-header-height: 61px;
    --size-max-width-container: 850px;

    --size-smallMobile: '375px';
    --size-mobile: '600px';
    --size-ipad: '768px';
    --size-ipadPro: '1024px';
    --size-notebook: '1200px';
    --size-desktop: '1800px';
  }

  /** styles for react-slick **/
  .slick-slider .slick-list,
  .slick-slider .slick-track {
    transition: transform 500ms ease-in-out;
    transition-delay: 300ms;
  }

  #btn-prev {
    left: 0;
    z-index: 1;
    position: absolute;
    left: 2%;
    width: 50px;
    height: 50px;
    top: calc(50% - 25px);
    background: transparent;
    border: none;
    outline: none;
  }
  #btn-next {
    right: 0;
    z-index: 1;
    position: absolute;
    right: 2%;
    width: 50px;
    height: 50px;
    top: calc(50% - 25px);
    background: transparent;
    border: none;
    outline: none;
  }

  .slick-dots {
    bottom: 5%;
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;
  }

  .slick-dots li {
    position: relative;
    display: inline-flex;
    width: 18px;
    height: 18px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    &.slick-active {
      button {
        background: #f25600;
      }
    }
  }

  .slick-dots li button {
    pointer-events: all;
    background-color: #ffffff;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    margin: 0;
    width: 12px;
    height: 12px;
    border: 1px solid #8f4600;

    &::before {
      display: none !important;
    }
  }

  .slick-track {
    div {
      div {
        div {
          img {
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  .slick-slide {
  visibility: hidden;
  }
  .slick-slide.slick-active {
    visibility: visible;
  }

  /* .slick-slide {
    transform: scale(0.85);
    pointer-events: none;
    transition: transform 0.6s linear;
  }

  .slick-center {
    transform: scale(1);
    pointer-events: all;
  }  */
  /** end of styles for react-slick **/
`;
