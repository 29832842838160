import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: left;
  margin: 0 auto;
  max-width: 850px;
  flex-direction: column;
  background-color: var(--color-popeyes-orange);
  padding-bottom: 10%;
  label {
    color: var(--color-salt-white);
  }

  h2 {
    color: var(--color-salt-white);
  }

  p {
    color: var(--color-salt-white);
  }

  padding-top: var(--size-header-height);
  @media (min-width: ${sizes.notebook}) {
    max-width: none;

    padding-bottom: 0;
    padding-top: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: auto;

    div:first-of-type {
      margin-top: 20px;
    }
  }
`;

export const Content = styled.div`
  align-items: left;
  height: 80%;
  max-width: 850px;
  background-color: var(--color-popeyes-orange);
  display: flex;
  align-items: flex-end;
  position: relative;
  right: 7%;
  img {
    width: 96%;
    opacity: 0.5;
  }
  @media (min-width: ${sizes.notebook}) {
    position: static;
    margin: 0;
    max-width: 100vw;
    justify-content: center;
    margin: 0;
    img {
      width: 30%;
    }
  }
`;

export const Button = styled.button`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 48px 14px;
  margin-bottom: 20px;
  margin-right: 5%;
  margin-left: 8%;

  width: 85%;
  height: 48px;

  background: #f25600;
  border: none;

  p {
    font-family: 'ChickenSansRegular';
    font-size: 18px;
    letter-spacing: -0.002em;
    //font-weight: bold;
  }
  @media (min-width: ${sizes.notebook}) {
    padding: 0;
    margin: 0;
    width: 205px;
  }
`;
