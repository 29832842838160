import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';

// Components
import Button from 'components/Button';
import Input from 'components/Input';
import ModalResponsive from 'components/ModalResponsive';
import TitleSection from 'components/TitleSection';
import { cpf } from 'cpf-cnpj-validator';
import PropTypes from 'prop-types';

// Services, Utils and Hooks
import { api } from 'services';
import firebase from 'services/firebase';
import hideKeyboard from 'utils/hideKeyboard';

// Styles
import { Container, Form, ModalContent } from './styles';
import schema from './validation';

function MyDataWasUsed() {
  const history = useHistory();
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      cpf: '',
    },
  });
  const { formState } = methods;
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  // validação
  const [isCpfCompleted, setIsCpfCompleted] = useState(false);
  const [isCpfError, setIsCpfError] = useState(false);
  const [isCpfExist, setIsCpfExist] = useState(false);

  const normalizeCpf = value => {
    return cpf.format(value);
  };

  const checkCpf = async value => {
    setIsCpfExist(false);
    if (value.length === 14) {
      setIsCpfCompleted(true);
      hideKeyboard();
      setLoading(true);

      const cpfUser = String(value.replace(/\D+/g, ''));
      // Formato CPF

      if (!cpf.isValid(value.replace(/\D+/g, ''))) {
        setIsCpfError(true);
      }

      // Existe CPF
      try {
        await api.post('/customer/v0.1/cpf/validation', {
          cpf: cpfUser,
        });

        // cpf existe
        setIsCpfExist(true);
        setLoading(false);
      } catch (err) {
        if (err?.response?.status === 422) {
          // erro no formato
          setIsCpfError(true);
        } else if (err?.response?.status === 404) {
          // cpf ainda não cadastrado
          setIsCpfError(false);
        }
        setLoading(false);
      }
    } else {
      setIsCpfCompleted(false);
    }
  };

  const onSubmit = () => {
    firebase.analytics().logEvent('busca_cpf');
    setOpenModal(true);
  };

  useEffect(() => {
    const params = new URL(window.location).searchParams;

    const isCpfExistParams = params.get('isCpfExist');
    if (isCpfExistParams) setIsCpfExist(true);

    const openModalParams = params.get('openModal');
    if (openModalParams) setOpenModal(true);
  }, []);

  return (
    <>
      <Container>
        <TitleSection
          title="Informe seu CPF"
          subtitle="Informe seu CPF para verificarmos se existe alguma conta ligada a ele"
        />

        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Input
              text="CPF"
              name="cpf"
              type="tel"
              htmlFor="cpf"
              maxLength="14"
              onChange={e => {
                e.target.value = normalizeCpf(e.target.value);
                checkCpf(e.target.value);
              }}
              showIcon={isCpfCompleted}
              isValid={!isCpfError}
              showError={isCpfError}
              errorMessage="Digite um CPF válido"
              loading={loading}
            />
            <span />

            <Button
              type="submit"
              disabled={!formState.isValid || isCpfError || loading}
            >
              Continuar
            </Button>
          </Form>
        </FormProvider>
      </Container>

      <ModalResponsive
        open={openModal}
        maxModalHeight={isCpfExist ? 380 : 350}
        sizeModal="middle"
        onClose={() => setOpenModal(false)}
      >
        <ModalContent>
          {isCpfExist && (
            <>
              <p>Já existe uma conta com esse CPF</p>
              <p className="text">
                Quer saber como o Popeyes usa seus dados?
                <a
                  href={`/enviar-email?${new URLSearchParams({
                    href: `/direitos-e-solicitacoes/uso-do-cpf?isCpfExist=true&openModal=true&href=/direitos-e-solicitacoes?${new URLSearchParams(
                      'href=/',
                    )}`,
                    isHelp: true,
                    subject: 'Quer saber como o Popeyes usa seus dados?',
                  }).toString()}`}
                >
                  Envie uma solicitação.
                </a>
              </p>
              <p className="text">Não foi você? Reporte abaixo.</p>
              <Button
                type="button"
                onClick={() => {
                  firebase.analytics().logEvent('faleconosc_cpfexistente');
                  history.push({
                    pathname: '/enviar-email',
                    search: `?${new URLSearchParams({
                      href: `/direitos-e-solicitacoes/uso-do-cpf?isCpfExist=true&openModal=true&href=/direitos-e-solicitacoes?${new URLSearchParams(
                        'href=/',
                      )}`,
                      title: 'Fale conosco',
                      isHelp: true,
                      subject: 'Preciso de ajuda',
                    }).toString()}`,
                  });
                }}
              >
                Preciso de ajuda
              </Button>
              <Button
                type="button"
                variant="secondary"
                onClick={() => setOpenModal(false)}
              >
                Cancelar
              </Button>
            </>
          )}

          {!isCpfExist && (
            <>
              <p>Você não tem conta aqui</p>
              <p className="text">
                Seu CPF nunca foi utilizado em uma conta Popeyes.
              </p>
              <Button
                type="button"
                onClick={() => {
                  firebase.analytics().logEvent('criar_conta');
                  history.push({
                    pathname: '/onboarding',
                    search: `?${new URLSearchParams({
                      href: `/direitos-e-solicitacoes/uso-do-cpf?href=/direitos-e-solicitacoes?${new URLSearchParams(
                        'href=/',
                      )}&openModal=true`,
                    }).toString()}`,
                  });
                }}
              >
                Criar conta agora
              </Button>
            </>
          )}
        </ModalContent>
      </ModalResponsive>
    </>
  );
}

MyDataWasUsed.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default MyDataWasUsed;
