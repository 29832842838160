import React from 'react';

import Button from 'components/Button';
import ModalResponsive from 'components/ModalResponsive';
import PropTypes from 'prop-types';

import { ModalContent } from './styles';

function SignOut({ open, onClose, clearUser }) {
  return (
    <ModalResponsive
      open={open}
      onClose={onClose}
      maxModalHeight={350}
      sizeModal="middle"
    >
      <ModalContent>
        <h3 className="title">Deseja sair da sua conta?</h3>
        <p className="subtitle">
          Você pode logar novamente depois e seus dados e preferências
          continuarão salvos!
        </p>

        <Button
          onClick={() => {
            clearUser();
            window.location.replace('/onboarding');
          }}
        >
          <p>Sair</p>
        </Button>
        <Button variant="secondary" onClick={() => onClose(false)}>
          <p>Cancelar</p>
        </Button>
      </ModalContent>
    </ModalResponsive>
  );
}

SignOut.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  clearUser: PropTypes.func.isRequired,
};

export default SignOut;
