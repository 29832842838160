import styled, { css } from 'styled-components';
import { popeyesSecondaryColors, spacings, popeyesNeutral } from 'styles';

import * as T from './interfaces';

const ModifierStatus = {
  status: {
    active: () => css`
      background: ${popeyesSecondaryColors.teal};
      color: ${popeyesSecondaryColors.light};
    `,
    inactive: () => css`
      background: transparent;
      background: ${popeyesSecondaryColors.teal};
      color: ${popeyesSecondaryColors.light};
    `,
    neutral: () => css`
      background: ${popeyesNeutral.neutral02};
    `,
  },
};
export const Status = styled.span<T.IStatus>`
  border-radius: 14px;
  padding: ${spacings.space00} ${spacings.space02} ${spacings.space01}
    ${spacings.space02};

  ${({ status }) => css`
    ${status && ModifierStatus.status[status]()}
  `}
`;
