import styled from 'styled-components';
import { breakpoints, font } from 'styles';

export const CategoryTitle = styled.h3`
  margin-top: 16px;
  margin-bottom: 16px;
  font-family: 'ChickenSansBold';
  color: #212121;
  font-size: 40px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: ${breakpoints.small}) {
    font-size: ${font.sizes.heading06};
    margin-left: 20px;
  }
`;

export const BorderLine = styled.hr`
  border: none;
  border: 1px solid #bdbdbd;
  margin: 60px 0;

  @media (max-width: ${breakpoints.small}) {
    display: none;
  }
`;

export const ProductsRow = styled.div`
  position: relative;
  margin-bottom: 70px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;

  @media (max-width: ${breakpoints.intermediary}) {
    display: grid;
    grid-template-columns: 300px 300px 300px;
  }

  @media (max-width: ${breakpoints.small}) {
    padding: 0 5%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    margin-bottom: 15px;
  }
`;

export const ContainerProductsMenu = styled.div`
  /* width: 960px; */

  /* @media (max-width: ${breakpoints.intermediary}) {
  } */

  @media (max-width: ${breakpoints.small}) {
    width: 100%;
  }
`;

export const ContentMenu = styled.div<{
  ref: any;
}>``;
