import styled from 'styled-components';
import sizes from 'utils/screenSizes';
import bgAreaApp from '../../assets/images/home/bg-home-area-app-bk.png';

export const Container = styled.div`
  background-image: url(${bgAreaApp});
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 50px;

  @media all and (max-width: ${sizes.notebook}) {
    display: none;
  }
`;

export const BoxContent = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  gap: 100px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 7.7%;
`;

export const MainText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > div {
    display: flex;

    > p {
      line-height: 126px;
      margin: 0;
    }
  }

  h1 {
    font-family: 'ChickenScript';
    font-size: 96px;
    font-weight: 400;
    line-height: 110.4px;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    color: #ff7d00;
  }

  p {
    font-family: 'ChickenSansBold' !important;
    font-weight: 700;
    text-align: left;
    color: #212121;
    font-size: 48px;
    margin-top: -30px;
    margin-bottom: 40px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 380px;

  > div {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
  }

  span {
    font-family: 'ChickenSans' !important;
    font-size: 10px;
    font-weight: 400;
    color: #757575;
    line-height: 15px;
  }
`;

export const ImageAppMockup = styled.img`
  height: 560px;
`;
