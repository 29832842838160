import React from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Button from 'components/Button';

// Styles
import { ContainerScreens, Footer } from '../styles';

function WhatDoWithMyData() {
  const history = useHistory();

  return (
    <>
      <ContainerScreens>
        <h1>O que o Popeyes pode fazer com meus dados?</h1>
        <p className="bold">Para identificar ou autenticar um Cliente BK:</p>
        <p>
          Verificação de credenciais, incluindo senhas, dicas de senha,
          informações e perguntas sobre segurança, ID, número do profissional de
          saúde, etc.
        </p>

        <p className="bold">
          Desenvolver e aprimorar produtos, serviços ou campanhas:
        </p>
        <p>
          Identificar tendências de uso e desenvolver novos conteúdos, entender
          como o Cliente BK e seu dispositivo interagem com a BK BRASIL, etc.
        </p>

        <p className="bold">
          Personalizar a experiência dos Clientes BK ao navegar nas Plataformas
          de Interação:
        </p>
        <p>
          Garantir a pertinência da disponibilização de um conteúdo a cada
          Cliente BK, entender os interesses de consumo de cada Cliente BK,
          personalizar, na medida do possível, produtos, serviços ou conteúdo às
          preferências de cada Cliente, estruturação de índices estatísticos no
          geral, etc.
        </p>

        <p className="bold">Vendas:</p>
        <p>
          Realização de vendas, entrega e retirada de produtos BURGER KING® ou
          POPEYES® em Plataformas de Interação. Promoções: Permitir que Clientes
          BK participem de promoções como sorteios, concursos e campanhas
          similares.
        </p>

        <p className="bold">Contatos com Clientes BK:</p>
        <p>
          Estabelecimento de contato com Clientes BK para compartilhamento de
          informações, tendências e/ou lançamentos da BURGER KING® ou da
          POPEYES®.
        </p>

        <p className="bold">Redes de conectividade:</p>
        <p>
          Viabilizar a disponibilização de redes Wi-Fi aos Clientes BK nos
          restaurantes BURGER KING® ou POPEYES®.
        </p>

        <p className="bold">Marketing e Comunicação:</p>
        <p>
          Responder aos questionamentos de Clientes BK, fornecer informações a
          destinatários específicos como notícias, realização de eventos ou
          lançamentos de produtos, impactar clientes de um nicho específico com
          campanhas específicas, etc.
        </p>

        <p className="bold">Recrutamento e Seleção:</p>
        <p>
          Nos casos de navegação em Plataformas de Interação cuja finalidade
          seja a busca por oportunidades de trabalho/emprego, a BK BRASIL fará a
          retenção dos dados cedidos pelo respectivo interessado para avaliações
          do seu perfil e eventuais chances de sua contratação.
        </p>

        <p className="bold">
          Apuração de eventuais fraudes em compras pagas por meios eletrônicos:
        </p>
        <p>
          É possível que a BK BRASIL colete dados pessoais dos Clientes BK para
          verificação de eventuais fraudes em pagamentos com intuito de mitigar
          prejuízos relacionados a esse tipo de prática (chargebacks).
        </p>
      </ContainerScreens>
      <hr />
      <Footer>
        <p className="to-know">Para saber mais acesse</p>

        <Button
          variant="secondary"
          onClick={() =>
            history.push({
              pathname: '/politica-de-privacidades',
              search: `?${new URLSearchParams({
                href: '/direitos-e-solicitacoes',
              }).toString()}`,
            })
          }
        >
          Diretrizes de Privacidade de Dados
        </Button>

        <Button
          variant="secondary"
          onClick={() =>
            history.push({
              pathname: '/termos-de-uso',
              search: `?${new URLSearchParams({
                href: '/direitos-e-solicitacoes',
              }).toString()}`,
            })
          }
        >
          Termos de uso
        </Button>
      </Footer>
    </>
  );
}

export default WhatDoWithMyData;
