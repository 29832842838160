// const HOME = { title: 'Início', url: ['/'], key: 0 };
const STORES = { title: 'Restaurantes', url: ['/restaurantes'], key: 1 };
const MENU = { title: 'Cardápio', url: ['/cardapio'], key: 2 };
const DELIVERY = { title: 'Delivery', url: ['/#delivery'], key: 2 };
const COUPON = { title: 'Cupons', url: ['/cupom'], key: 3 };
const CONTACT = {
  title: 'Fale Conosco',
  url: ['/enviar-email'],
  key: 5,
};
const LOGIN = {
  title: 'Entrar',
  url: ['/onboarding', '/login', '/verificar'],
  key: 6,
};
export default [MENU, STORES, DELIVERY, COUPON, CONTACT, LOGIN];
