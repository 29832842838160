import { useEffect, useRef, useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';

import CloseIcon from 'assets/images/icons/close.svg';
// eslint-disable-next-line import-helpers/order-imports
import PropTypes from 'prop-types';

import './styleBottomSheet.css';

import { handleClickOutside } from 'utils/handleClickOutsideOld';
import sizes from 'utils/screenSizes';

import { CloseModal, Container, ModalContent } from './styles';

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
}

const snapPoints = {
  full: max => max - max / 10,
  high: max => max * 0.6,
  middle: max => max / 2.1,
  halflow: max => max / 2.44,
  low: max => max / 4,
};

function ModalResponsive({
  open,
  onClose,
  children,
  openHeight,
  headerModal,
  footerModal,
  maxModalHeight,
  scrollable,
  sizeModal,
  showCloseButton,
}) {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  const sheetRef = useRef();

  useEffect(() => {
    function handleResize() {
      const dimensions = getWindowDimensions();
      setWindowDimensions(dimensions);
    }
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  const wrapperRef = useRef(document.createElement('div'));
  useEffect(() => {
    if (windowDimensions.width > 1024) handleClickOutside(wrapperRef, onClose);
  }, [wrapperRef]);

  const handleScroll = () => {
    if (!scrollable && document.querySelector('div[data-rsbs-scroll]')) {
      document.querySelector('div[data-rsbs-scroll]').classList.add('scroll');
    }
  };
  handleScroll();
  const focusRef = useRef();
  return (
    <>
      {windowDimensions.width > parseInt(sizes.ipadPro, 10) && (
        <Container open={open}>
          <ModalContent ref={wrapperRef} open={open} sizeModal={sizeModal}>
            {showCloseButton && (
              <CloseModal onClick={onClose}>
                <img src={CloseIcon} alt="Fechar" />
              </CloseModal>
            )}
            {children}
          </ModalContent>
        </Container>
      )}

      {windowDimensions.width <= parseInt(sizes.ipadPro, 10) && (
        <BottomSheet
          header={headerModal}
          footer={footerModal}
          open={open}
          onDismiss={onClose}
          ref={sheetRef}
          initialFocusRef={focusRef}
          expandOnContentDrag
          maxHeight={maxModalHeight}
          defaultSnap={({ maxHeight }) => snapPoints[openHeight](maxHeight)}
          snapPoints={({ maxHeight }) => [
            maxHeight - 50,
            maxHeight / 2.1,
            maxHeight * 0.6,
          ]}
        >
          {children}
        </BottomSheet>
      )}
    </>
  );
}

ModalResponsive.propTypes = {
  headerModal: PropTypes.node,
  footerModal: PropTypes.node,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  maxModalHeight: PropTypes.number,
  scrollable: PropTypes.bool,
  openHeight: PropTypes.oneOf(['full', 'high', 'middle', 'halflow', 'low']),
  sizeModal: PropTypes.oneOf(['full', 'high', 'middle', 'normal', 'low', '']),
  showCloseButton: PropTypes.bool,
};

ModalResponsive.defaultProps = {
  scrollable: true,
  maxModalHeight: window.innerHeight,
  openHeight: 'full',
  headerModal: null,
  footerModal: null,
  sizeModal: 'normal',
  showCloseButton: true,
};

export default ModalResponsive;
