import React from 'react';

import CloseIcon from 'assets/images/icons/close.svg';
import Button from 'components/Button';
import PropTypes from 'prop-types';

import * as S from '../styles';

const ModalContainer = ({ children, onClose, typeButton }) => (
  <>
    <S.Header>
      <Button variant="tertiary" type={typeButton} size="S" onClick={onClose}>
        <img src={CloseIcon} alt="Fechar Innap modal" />
      </Button>
    </S.Header>
    <S.Wrapper>{children} </S.Wrapper>
  </>
);

ModalContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  typeButton: PropTypes.oneOf(['button', 'submit']),
};

ModalContainer.defaultProps = {
  typeButton: 'button',
};

export { ModalContainer };
