import styled from 'styled-components';
import Arrow from 'assets/images/carousel/arrow-up.png';
import sizes from 'utils/screenSizes';

export const Container = styled.button`
  position: fixed;
  height: 73px;
  width: 97px;
  bottom: -15%;
  ${({ isVisible }) => !isVisible && 'bottom: 0px;'}
  z-index: 3;
  background-color: var(--color-burnt-orange);

  font-family: 'ChickenSansRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 20px;
  text-align: center;

  color: var(--color-salt-white);
  padding: 21px 9px 12px;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;

  left: 22px;

  transition: bottom 0.8s;

  outline: none;
  border: 0;

  :focus {
    outline: none;
  }

  @keyframes SlideArrow {
    0% {
      background-position-y: -18px;
    }
    to {
      background-position-y: -25px;
    }
  }

  :hover {
    cursor: pointer;
    span::after {
      animation: SlideArrow 0.5s ease-in-out infinite;
    }
  }

  span {
    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    left: calc(50% - 20px);
    bottom: calc(100% - 14px);
    background: var(--color-salt-white);
    align-items: center;
    justify-content: center;
  }
  span::after {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-image: url(${Arrow});
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 40px;
    background-color: var(--color-burnt-orange);
    position: relative;
  }
  @media (min-width: ${sizes.desktop}) {
    height: 90px;
    width: 130px;
    div {
      font-size: 1.5rem;
    }
  }

  @media (max-width: ${sizes.ipadPro}) {
    display: none !important;
  }
`;
