import styled, { css } from 'styled-components';

const fonts = {
  regular: css`
    font-style: normal !important;
    font-family: 'ChickenSansRegular' !important;
    font-weight: normal !important;
  `,

  bold: css`
    font-style: bold !important;
    font-family: 'ChickenSansBold' !important;
    font-weight: bold !important;
  `,
};

const variants = {
  primary: css`
    ${fonts.bold};

    background: var(--color-burnt-orange);
    color: var(--color-salt-white);

    :active {
      opacity: 80%;
    }

    :hover {
      opacity: 40%;
    }
  `,

  secondary: css`
    background: var(--color-salt-white);
    color: var(--color-burnt-orange);

    :active {
      opacity: 40%;
    }

    :hover {
      opacity: 40%;
    }
  `,

  tertiary: css`
    background: transparent;
    color: var(--color-burnt-orange);
  `,

  quaternary: css`
    background: var(--color-salt-white);
    color: var(--color-burnt-orange);
    border: 1px solid var(--color-burnt-orange);
  `,
};

const margin = {
  zeroMargin: css`
    margin: 0 0 0 0;
  `,

  highMargin: css`
    margin: 25px 0 15px 0;
  `,
};

const sizes = {
  S: css`
    height: 32px;
  `,

  M: css`
    height: 48px;
  `,

  L: css`
    height: 60px;
  `,
};

export const ButtonContainer = styled.button`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;

  width: 100%;
  height: 48px;

  font-size: 18px !important;

  border: none;
  border-radius: 4px;
  cursor: pointer;

  ${props => fonts[props.font || 'regular']};
  ${props => sizes[props.size || 'M']};
  ${props => margin[props.margin || 'zeroMargin']};
  ${props => variants[props.variant || 'primary']};

  transition: all 0.3s ease;
  cursor: pointer;

  :disabled {
    opacity: 40%;
    cursor: not-allowed;
  }

  .loader-icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.3s ease;
  }

  .loader {
    position: relative;
    height: 20px;
    width: 20px;
    display: inline-block;
    animation: around 5.4s infinite;
  }

  @keyframes around {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loader::after,
  .loader::before {
    content: '';
    background: transparent;
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-color: white white transparent transparent;
    border-style: solid;
    border-radius: 20px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    animation: around 0.7s ease-in-out infinite;
  }

  .loader::after {
    animation: around 0.7s ease-in-out 0.1s infinite;
    background: transparent;
  }
`;
