import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  display: none;
  @media all and (min-width: ${sizes.notebook}) {
    width: 91px;
    margin: 7px 0 0 4%;
    position: absolute;
    top: 15%;

    z-index: 3;
    :hover {
      cursor: pointer;
    }
    display: flex;
    align-items: flex-end;
    img {
      height: 1.5rem;
      width: auto;
      margin: 0 14% 0 0;
    }
    p {
      font-size: 1.5rem;
      color: var(--color-popeyes-orange);
    }

    ${props =>
      props.isSuccess &&
      `position: absolute;
    top: 18%;
    p { color: var(--color-salt-white); }`}
  }
`;
