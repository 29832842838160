import * as yup from 'yup';

const ddds = [
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '21',
  '22',
  '24',
  '27',
  '28',
  '31',
  '32',
  '33',
  '34',
  '35',
  '37',
  '38',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '51',
  '53',
  '54',
  '55',
  '61',
  '62',
  '63',
  '64',
  '65',
  '66',
  '67',
  '68',
  '69',
  '71',
  '73',
  '74',
  '75',
  '77',
  '79',
  '81',
  '82',
  '83',
  '84',
  '85',
  '86',
  '87',
  '88',
  '89',
  '91',
  '92',
  '93',
  '94',
  '95',
  '96',
  '97',
  '98',
  '99',
];

const schema = yup.object().shape({
  ddd: yup
    .string()
    .matches(/\d{2}/g, 'Insira um DDD válido')
    // eslint-disable-next-line
    .test('test-ddd', function (value) {
      if (ddds.find(item => item === value)) return value;
      return this.createError({ message: 'invalid', path: 'ddd' });
    })
    .required('Este campo é obrigatório!'),
  celNumber: yup
    .string()
    .matches(/\d{9}/g, 'Insira um número válido')
    .matches('((9)|([1-9][1-9]))', 'Insira um número válido')
    .required('Este campo é obrigatório'),
});

export default schema;
