import ChickenFooter from 'assets/images/footer/chicken-footer.png';

import IconTwitter from 'components/Atoms/Icons/footer/IconTwitter';
import IconYoutube from 'components/Atoms/Icons/footer/IconYoutube';
import IconFacebook from 'components/Atoms/Icons/footer/iconFacebook';
import IconInstagram from 'components/Atoms/Icons/footer/iconInstagram';
import { isAfter } from 'date-fns';
import { Container, LegalInfo, Links, SocialMedial } from './styles';

function FooterComponent() {
  // const currentDate = new Date();
  // const targetDate = new Date('2024-05-24');
  return (
    <Container>
      <img id="ChickenFooter" src={ChickenFooter} alt="Logo" />
      <p>#AmoEsseFrango</p>
      <SocialMedial>
        <a href="https://www.instagram.com/popeyesbrasil/?hl=pt-br">
          <IconInstagram />
        </a>
        <a href="https://pt-br.facebook.com/popeyesbrasil/">
          <IconFacebook />
        </a>
        <a href="https://twitter.com/popeyesbrasil/">
          <IconTwitter />
        </a>
        <a href="https://www.youtube.com/channel/UC6lMe8Db-hKQUDjDSeIPYCg">
          <IconYoutube />
        </a>
      </SocialMedial>
      <Links>
        <a href="/cardapio">Cardápio</a>
        <a href="/restaurantes">Restaurantes</a>
        <a href="/cupom">Cupons</a>
        <a href="/enviar-email?isHelp=true">Fale conosco</a>
      </Links>

      <LegalInfo>
        <a href="/direitos-e-solicitacoes">Direitos e solicitações</a>
        <a href="https://ri.zamp.com.br/">Relação com investidores</a>
        <a href="/politica-de-privacidades?href=/">
          Diretrizes e privacidade de dados
        </a>
        <a href="/termos-de-uso?href=/">Informações legais</a>
        <a href="/regulamento-acao-mini-titas">
          Regulamento Promoção 24 e 25/05
        </a>
        {
          // <a href="#" onClick={openPrivallyModal}>
          /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
          <a href="https://privacyportal-br.onetrust.com/webform/178e65db-f98c-4c7c-8f4b-8636690f283f/d04a5d39-b6e4-4b9e-90cc-5fcbe0e10721">
            Opções de privacidade
          </a>
        }
      </LegalInfo>
      <p className="footer_content_info">
        Imagens meramente ilustrativas. TM & © 2023 POPEYES®. Todos os direitos
        reservados.
      </p>
    </Container>
  );
}

export default FooterComponent;
