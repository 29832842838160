import React, { useState, useRef, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';

import OkIcon from '../../assets/images/icons/ok.svg';
import WarningIcon from '../../assets/images/icons/warning.svg';
import {
  Container,
  InputContainer,
  DDD,
  Number,
  IconContainer,
  ErrorMessage,
} from './styles';

const InputNumber = props => {
  const methods = useFormContext();
  const { nameDDD, namePhone, textDDD, textPhone, handleValidBlur } = props;
  const inputDDD = useRef();
  const inputCel = useRef();
  const [dddBlurred, setDDDBlurred] = useState(true);
  const [phonedBlurred, setPhoneBlurred] = useState(true);

  const hideVirtualKeyboard = () => {
    if (
      document.activeElement &&
      document.activeElement.blur &&
      typeof document.activeElement.blur === 'function'
    ) {
      document.activeElement.blur();
    }
  };

  const handleBur = e => {
    if (e.target.name === nameDDD) setDDDBlurred(true);
    else if (e.target.name === namePhone) setPhoneBlurred(true);

    if (
      !methods.errors[nameDDD] &&
      methods.watch(nameDDD).length === 2 &&
      !methods.errors[namePhone] &&
      methods.watch(namePhone).length === 9 &&
      e.target.name === namePhone
    ) {
      if (handleValidBlur)
        handleValidBlur(methods.watch(nameDDD) + methods.watch(namePhone));
    }
  };

  const hasError = useMemo(() => {
    if (
      (methods.errors[nameDDD] && dddBlurred) ||
      (methods.errors[namePhone] && phonedBlurred)
    )
      return true;
    return false;
  }, [methods, dddBlurred, phonedBlurred]);

  return (
    <Container>
      <InputContainer>
        <DDD>
          <input
            name={nameDDD}
            type="tel"
            ref={e => {
              methods.register(e);
              inputDDD.current = e;
            }}
            onChange={e => {
              if (e.target.value.length === 2) {
                inputCel.current.focus();
                inputCel.current.select();
              }
            }}
            onFocus={() => setDDDBlurred(false)}
            onBlur={handleBur}
            style={
              methods.errors[nameDDD] && dddBlurred
                ? { background: '#F9DBDA' }
                : {}
            }
            maxLength="2"
            required
          />
          <label htmlFor={nameDDD} className="label-name">
            <span className="content-name">{textDDD}</span>
          </label>
          <IconContainer showIcon={dddBlurred}>
            {methods.errors[nameDDD] ? (
              <img src={WarningIcon} alt="Campo DDD inválido" />
            ) : (
              methods.watch(nameDDD).length === 2 && (
                <img src={OkIcon} alt="Campo DDD válido" />
              )
            )}
          </IconContainer>
        </DDD>

        <Number>
          <input
            name={namePhone}
            type="tel"
            ref={e => {
              methods.register(e);
              inputCel.current = e;
            }}
            onFocus={() => setPhoneBlurred(false)}
            onBlur={handleBur}
            style={
              methods.errors[namePhone] && phonedBlurred
                ? { background: '#F9DBDA' }
                : {}
            }
            onChange={e => {
              if (e.target.value.length === 9) {
                hideVirtualKeyboard();
              }
            }}
            maxLength="9"
            required
          />
          <label htmlFor={namePhone} className="label-name">
            <span className="content-name">{textPhone}</span>
          </label>
          <IconContainer showIcon={phonedBlurred}>
            {methods.errors[namePhone] ? (
              <img src={WarningIcon} alt="Campo telefone inválido" />
            ) : (
              methods.watch(namePhone).length === 9 && (
                <img src={OkIcon} alt="Campo telefone válido" />
              )
            )}
          </IconContainer>
        </Number>
      </InputContainer>
      {hasError && (
        <InputContainer>
          <DDD>
            {methods.errors[nameDDD] && dddBlurred && (
              <ErrorMessage>DDD inválido</ErrorMessage>
            )}
          </DDD>
          <Number>
            {methods.errors[namePhone] && phonedBlurred && (
              <ErrorMessage>Número inválido</ErrorMessage>
            )}
          </Number>
        </InputContainer>
      )}
    </Container>
  );
};

InputNumber.propTypes = {
  nameDDD: PropTypes.string.isRequired,
  namePhone: PropTypes.string.isRequired,
  textDDD: PropTypes.string.isRequired,
  textPhone: PropTypes.string.isRequired,
  handleValidBlur: PropTypes.func,
};

InputNumber.defaultProps = {
  handleValidBlur: null,
};

export default InputNumber;
