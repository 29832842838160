import styled, { css } from 'styled-components';

import { FaFilter } from 'react-icons/fa';
import sizes from 'utils/screenSizes';

import { breakpoints, font } from 'styles/themes';
import * as T from './interfaces';

export const Container = styled.div`
  width: 100%;
  /* display: flex; */
  align-items: center;
  flex-direction: column;
  background: #f30001;

  /* @media (max-width: ${breakpoints.large}) {
    width: auto;
    height: auto;
    background-image: none;

    > div {
      > div {
        > div {
          > ul {
            > li {
              margin: 0px;
            }
          }
      }
    }
  } */
`;

export const ContainerMobile = styled.div`
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
`;

export const MenuBars = styled.div`
  display: flex;
  > button,
  svg {
    margin-right: 5px;
  }
`;

export const Filters = styled(FaFilter)`
  width: 25px;
  height: 25px;
  padding: 4px;
  color: #929292;
`;

export const FiltersBar = styled.div`
  display: flex;
  width: 20%;

  > p {
    margin-top: 2px;
    font-family: 'ChickenSansBold', serif !important;
    color: rgb(146, 146, 146);
  }
`;

export const ContainerCarousel = styled.div<T.IHeaderMenuStyles>`
  transition: all 0.5s ease;

  width: 100%;
  /* height: 335px; */

  object-fit: cover;

  img {
    width: 100%;
    display: flex;
    object-fit: cover !important;
  }

  > div:first-child {
    padding: 0;
    > div:first-child {
      margin: 0;
    }
  }

  ${({ hideBanner }) =>
    hideBanner
      ? css`
          > div {
            max-height: 0px;
            min-height: 0px;
            transition: max-height 0.9s ease-in-out;
          }
        `
      : css`
          > div {
            max-height: 600px;
            min-height: 0px;
            padding: 0px;
            transition: max-height 0.9s ease;
          }
        `}

  @media (max-width: ${sizes.ipadPro}) {
    width: auto;
    height: auto;

    img {
      width: 100%;
      display: flex;
    }
  }

  @media (min-width: ${sizes.notebook}) {
    ${props =>
      props.hideBanner &&
      'transition: 1s ease-in-out;  height: 0px;  button { display: none; }'}
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  @media (min-width: ${sizes.desktop}) {
    width: 70%;
    margin: 0 auto;
  }
`;

export const ContainerButton = styled.div<T.IHeaderMenuStyles>`
  width: 230px;
  font-size: ${font.sizes.textXSmall};
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 5px;

  @media (max-width: ${breakpoints.intermediary}) {
    width: 80%;
  }

  max-height: auto;

  ${({ hideBanner }) =>
    hideBanner
      ? css`
          transition: all 0.3s ease 0s;
          display: none;

        }
      `
      : css`
          display: flex;
          transition: all 0.3s ease 0s;
        }
      `}
`;

export const Highlights = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 10px 0px;

  > div {
    display: flex;
    flex-direction: row;

    width: 100%;
    padding-right: 24px;

    overflow-x: scroll;
    overflow-y: hidden;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      display: none;
      width: 0px;
      height: 0px;
    }
  }

  h1 {
    font-family: 'ChickenSansRegular';
    font-style: normal;
    font-weight: normal;
    font-size: ${font.sizes.textLarge};
    line-height: 22px;
  }

  @media only all and (min-width: ${sizes.ipad}) {
    padding: 24px 0px 24px 48px;
  }

  @media only all and (min-width: ${sizes.notebook}) {
    padding: 24px 0px 24px 0px;
    > div {
      padding-right: 0px;
    }
  }
`;

export const ContainerInput = styled.div`
  width: 80%;
  align-items: center;
  justify-content: center;
  display: flex;

  @media (max-width: ${breakpoints.intermediary}) {
    width: 90%;
    margin-top: 7px;
  }
`;

export const ContainerFilterCardapio = styled.div<T.IHeaderMenuStyles>`
  display: ${props => !props.hideSearch && 'none'};

  @media (max-width: ${breakpoints.intermediary}) {
    width: 100%;
    top: ${props =>
      props.isSearchButtonClick || props.positionFilterMobileTop
        ? '0'
        : '53px'};
    position: fixed;
    z-index: 4;
  }

  @media (min-width: ${breakpoints.intermediary}) {
    width: 99%;
    top: 98px;
    position: fixed;
    z-index: 4;
  }
`;

export const ShadowGradient = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 60px;
  background: linear-gradient(
    180deg,
    rgba(67, 64, 64, 0.6) 0%,
    rgba(67, 64, 64, 0) 100%
  );

  opacity: 0;

  @media (max-width: ${sizes.ipadPro}) {
    opacity: 0.7;
  }
`;

export const ContainerHeading = styled.div<T.IHeaderMenuStyles>`
  span {
    font-size: ${font.sizes.textSmall};
    transition: opacity 0.5s ease 0s;
    position: absolute;
  }

  h1 {
    transition: all 0.3s ease 0s;
  }

  @media (max-width: ${breakpoints.intermediary}) {
    ${props =>
      props.hideBanner
        ? css`
            height: 36px;
            display: flex;
            align-items: end;
            justify-content: center;
            margin-top: 55px;
            transition: all 0.5s ease;

            h1 {
              opacity: 0;
              //transition: all 0.3s ease 0s;
            }

            span {
              display: flex;
              transition: opacity 0.5s ease 0s;
            }
          `
        : css`
            > span {
              display: none;
              transition: opacity 0.5s ease 0s;
            }

            h1 {
              opacity: 1;
              //transition: all 0.3s ease 0s;
            }
          `}
  }

  @media (min-width: ${breakpoints.intermediary}) {
    > span {
      display: none;
    }
  }
`;

export const PageTitle = styled.h2<{ hide: boolean }>`
  @media (max-width: ${breakpoints.small}) {
    display: none;
  }
  margin-top: 60px;
  font-family: 'ChickenSansRegular';
  padding-left: 10px;
  transition: transform 0.3s ease;
  transform: translateY(${props => (props.hide ? '-100%' : '0')});
`;

export const MenuPageTitle = styled.p`
  transition: opacity 0.3s ease;
  font-size: 22px;
  color: var(--color-popeyes-orange);
  font-family: 'ChickenSansBold', serif !important;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1vh;
`;

export const HeaderContent = styled.div`
  background-color: var(--color-salt-white);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10vh;
  padding: 18px;
  display: flex;
  flex-direction: column;
  z-index: 10;
  box-shadow: 2px 10px 5px -2px rgba(0, 0, 0, 0.2);
`;

export const MenuButton = styled.button`
  display: flex;

  width: 25px;
  height: 25px;

  border: none;
  outline: none;

  background-repeat: no-repeat;
  background-color: transparent;
  background-position-y: center;
  img {
    width: 100%;
    height: 100%;
    padding: 2px;
  }
`;
