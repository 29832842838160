import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 80vh;

  @media (max-width: ${sizes.notebook}) {
    height: 100%;
  }

  @media (min-width: ${sizes.notebook}) {
    justify-content: center;
  }
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;

  height: 100%;
  width: 100%;

  p {
    display: none;
  }

  span {
    margin-top: 100px;
  }

  button {
    margin-bottom: 20px;
  }

  @media (max-width: ${sizes.notebook}) {
    justify-content: space-between;
  }

  @media (min-width: ${sizes.notebook}) {
    height: auto;
    width: 80%;

    button {
      margin-top: 12px;
    }
    span {
      display: none;
    }
  }
`;

export const ModalContent = styled.div`
  padding: 12px 24px 0px 24px;
  font-family: 'ChickenSansRegular';
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 24px 0px;
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
  }

  h3 {
    margin: 0px 24px;

    @media (min-width: ${sizes.notebook}) {
      text-align: center;
    }
  }

  a {
    text-decoration: underline;
    color: var(--color-burnt-orange);
    margin-left: 5px;
  }
`;
