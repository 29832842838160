import { useEffect, useRef, useState } from 'react';

import { useAuth } from 'hooks';
import { useHeader } from 'hooks/HeaderContext';

import poppy from 'assets/images/poppy-popeyes.svg';

import { menuApi } from 'services';

import type { IRestaurantModel } from 'types/restaurant';
import RestaurantList from 'components/RestaurantList';
import ModalResponsive from 'components/ModalResponsive';
import Button from 'components/Button';
import FooterComponent from 'components/FooterComponent';
import HeaderComponent from 'components/HeaderComponent';

import { useHistory } from 'react-router-dom';
import { LocationProvider } from 'context/LocationContext';
import RestaurantFavoritesList from 'components/RestaurantFavoritesList';
import {
  Container,
  Content,
  RestaurantFilterType,
  RestaurantFilterWrapper,
  RestaurantFilterTypeList,
  RestaurantFilterTypeOption,
  RestaurantFilterLogo,
  ModalContent,
} from './styles';

import { ListIcon } from './ListIcon';
import FavoritosIcon from './FavoritosIcon';

export enum FilterType {
  LIST = 'LIST',
  FAVORITES = 'FAVORITES',
  MAP = 'MAP',
}

function StoreAddreess() {
  const { handleRef } = useHeader();
  const containerRef = useRef();
  const history = useHistory();
  const [urlBack, setUrlBack] = useState('/perfil');

  const { customerId, user } = useAuth();
  const [openModal, onToggleModal] = useState(false);

  const [filterType, setFilterType] = useState<FilterType>(FilterType.LIST);

  const [restaurants, setRestaurants] = useState<IRestaurantModel[]>([]);
  const [originalRestaurants, setOriginalRestaurants] =
    useState<IRestaurantModel[]>(restaurants);

  function switchToFavorites() {
    if (!customerId || user?.fakeUser) {
      onToggleModal(true);
      return;
    }

    setFilterType(FilterType.FAVORITES);
  }

  async function loadRestaurants(): Promise<IRestaurantModel[]> {
    try {
      const restaurantsResponse = await menuApi.get(
        `/listar-restaurantes?customer_id=
        ${customerId && !user?.fakeUser ? customerId : ''}&search=`,
      );

      if (restaurantsResponse.data && restaurantsResponse.data.results) {
        const allRestaurants: IRestaurantModel[] =
          restaurantsResponse.data.results;
        return allRestaurants;
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  useEffect(() => {
    handleRef(containerRef);

    async function fetchData() {
      const data = await loadRestaurants();
      console.log(data);
      setRestaurants(data || []);
      setOriginalRestaurants(data || []);
    }

    fetchData();
  }, []);

  return (
    <>
      <Container ref={containerRef}>
        <HeaderComponent title="Restaurantes" href={urlBack} />
        <RestaurantFilterType>
          <RestaurantFilterWrapper>
            <RestaurantFilterLogo>
              <h1>
                Encontre um <br />
                <span>Restaurante</span>
              </h1>
              <img src={poppy} alt="Poppy" />
            </RestaurantFilterLogo>
            <RestaurantFilterTypeList>
              <RestaurantFilterTypeOption
                selected={filterType === FilterType.LIST}
                onClick={() => setFilterType(FilterType.LIST)}
              >
                <ListIcon />
                Lista
              </RestaurantFilterTypeOption>
              <RestaurantFilterTypeOption
                selected={filterType === FilterType.FAVORITES}
                onClick={switchToFavorites}
              >
                <FavoritosIcon />
                Favoritos
              </RestaurantFilterTypeOption>
            </RestaurantFilterTypeList>
          </RestaurantFilterWrapper>
        </RestaurantFilterType>

        <LocationProvider>
          <Content>
            {filterType === FilterType.LIST ? (
              <RestaurantList
                restaurants={restaurants}
                originalRestaurants={originalRestaurants}
                setRestaurants={setRestaurants}
                onToogleModalLogin={onToggleModal}
              />
            ) : (
              <RestaurantFavoritesList
                restaurants={restaurants}
                onToogleModalLogin={onToggleModal}
                setFilterType={setFilterType}
              />
            )}
          </Content>
        </LocationProvider>

        <FooterComponent />
      </Container>
      <ModalResponsive
        open={openModal}
        onClose={() => onToggleModal(false)}
        maxModalHeight={380}
        sizeModal="middle"
      >
        <ModalContent>
          <h3 className="title">Acesse sua página de favoritos</h3>
          <p className="subtitle">
            Para acessar sua página de restaurantes favoritos, você precisa
            estar logado. Acesse sua conta clicando no botão abaixo.
          </p>

          <Button
            className="rotate"
            type="button"
            variant="primary"
            font="regular"
            loading={false}
            margin={undefined}
            size={undefined}
            onClick={() =>
              history.push({
                pathname: '/login',
                search: `?${new URLSearchParams({
                  href: '/cupom',
                }).toString()}`,
              })
            }
          >
            <span className="votar-btn">Acessar minha conta</span>
          </Button>
        </ModalContent>
      </ModalResponsive>
    </>
  );
}

export default StoreAddreess;
