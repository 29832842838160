import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import BackWhiteIcon from 'assets/images/icons/back-desktop-white.svg';
import BackIcon from 'assets/images/icons/back-desktop.svg';

import { Container } from './styles';

function BackButton({ isSuccess }) {
  const [urlBack, setUrlBack] = useState();
  const { goBack, push } = useHistory();

  const onBack = () => {
    if (urlBack) {
      push(urlBack);
    } else {
      goBack();
    }
  };

  useEffect(() => {
    const params = new URL(window.location).searchParams;

    const href = params.get('href');
    if (href) setUrlBack(href);
    if (window.location.pathname === '/onboarding' && !href) setUrlBack('/');

    window.addEventListener('popstate', onBack);
    return () => window.removeEventListener('popstate', onBack);
  }, [window.location, window.location.search]);

  return (
    <Container
      isSuccess={isSuccess}
      onClick={() => {
        onBack();
      }}
      role="button"
      onKeyDown={() => {
        onBack();
      }}
      tabIndex={0}
    >
      <img
        src={isSuccess ? BackWhiteIcon : BackIcon}
        alt="Seta para Esquerda"
      />
      <p>Voltar</p>
    </Container>
  );
}

export default BackButton;
