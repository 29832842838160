import React from 'react';

import { AuthProvider } from './Auth';
import { HeaderContextProvider } from './HeaderContext';
import { ModalMobileContextProvider } from './ModalMobileContext';

const AppProvider = ({ children }) => (
  <AuthProvider>
    <HeaderContextProvider>
      <ModalMobileContextProvider>{children}</ModalMobileContextProvider>
    </HeaderContextProvider>
  </AuthProvider>
);

export default AppProvider;
