import React, { useEffect, useRef, useState, useMemo } from 'react';

import BackButton from 'components/BackButton';
import FooterComponent from 'components/FooterComponent';
import HeaderComponent from 'components/HeaderComponent';
import { SmsVerifyContainer } from 'containers';
import { useHeader } from 'hooks/HeaderContext';
import { AuthLayout } from 'layouts';

import { Container } from './styles';

function SignInNumber() {
  const containerRef = useRef();
  const { handleRef } = useHeader();

  const [urlBack, setUrlBack] = useState('/login');
  const [isEmail, setIsEmail] = useState(false);

  useEffect(() => {
    handleRef(containerRef);
    const params = new URL(window.location).searchParams;
    const href = params.get('href');
    if (href) setUrlBack(href);

    const isEmailParams = params.get('isEmail');
    if (isEmailParams && JSON.parse(isEmailParams)) setIsEmail(true);
  }, []);

  const getUrlback = useMemo(() => {
    const url = new URL(window.location.origin + urlBack);
    if (url.search) return `${urlBack}&goToValidateEmail=${isEmail}`;
    return `${urlBack}?goToValidateEmail=${isEmail}`;
  }, [urlBack, isEmail]);

  return (
    <Container ref={containerRef}>
      <HeaderComponent title="Verificação" href={getUrlback} />
      <BackButton />
      <AuthLayout ref={containerRef}>
        <SmsVerifyContainer />
      </AuthLayout>
      <FooterComponent />
    </Container>
  );
}

export default SignInNumber;
