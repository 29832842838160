import React, { useEffect, useRef } from 'react';

import BackButton from 'components/BackButton';
import FooterComponent from 'components/FooterComponent';
import HeaderComponent from 'components/HeaderComponent';
import { AuthContainer } from 'containers';
import { useHeader } from 'hooks/HeaderContext';
import { AuthLayout } from 'layouts';

import { Container } from './styles';

function SignInNumber() {
  const { handleRef, showHeader } = useHeader();
  const containerRef = useRef();

  useEffect(() => {
    handleRef(containerRef);
  }, []);

  return (
    <Container ref={containerRef}>
      <HeaderComponent
        title="Celular"
        href="/onboarding"
        visible={showHeader}
      />
      <BackButton />
      <AuthLayout>
        <AuthContainer />
      </AuthLayout>
      <FooterComponent />
    </Container>
  );
}

export default SignInNumber;
