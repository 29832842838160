import { IRestaurantModel } from 'types/restaurant';

import RestaurantFavoriteState from 'components/RestaurantFavoriteState';
import Button from 'components/Button';
import { FilterType } from 'pages/StoreAddress';
import { FavoriteNotFoundWrapper, FavoriteWrapper } from './styles';

import restaurantFavoriteImage from '../../assets/images/icons/favoritos-icone-notfound.svg';

interface IRestaurantStateProps {
  restaurants: IRestaurantModel[];
  onToogleModalLogin: (value: boolean) => void;
  setFilterType(value: any): void;
}

export default function RestaurantFavoritesList({
  restaurants,
  onToogleModalLogin,
  setFilterType,
}: IRestaurantStateProps) {
  const favoriteRestaurants = restaurants.filter(
    restaurant => restaurant.favorito,
  );

  return (
    <FavoriteWrapper>
      <h1>
        Meus restaurantes favoritos <span>({favoriteRestaurants.length})</span>
      </h1>
      {favoriteRestaurants.length > 0 ? (
        Array.from(
          new Set(favoriteRestaurants.map(restaurant => restaurant.estado)),
        ).map((estado, index) => (
          <RestaurantFavoriteState
            key={index}
            state={{
              id: index,
              state: estado,
            }}
            onToogleModalLogin={onToogleModalLogin}
            restaurants={favoriteRestaurants.filter(
              restaurant => restaurant.estado === estado,
            )}
          />
        ))
      ) : (
        <FavoriteNotFoundWrapper>
          <img width={320} height={320} src={restaurantFavoriteImage} alt="" />
          <h3>Você ainda não tem restaurantes favoritos!</h3>
          <p>
            Encontre todos os restaurantes em “Lista” ou “Mapa”. Aproveite e
            ative a localização para resultados mais precisos.
          </p>
          <Button
            className="rotate"
            type="button"
            variant="primary"
            font="regular"
            loading={false}
            margin={undefined}
            size={undefined}
            onClick={() => setFilterType(FilterType.LIST)}
          >
            <span className="votar-btn">Voltar para a lista</span>
          </Button>
        </FavoriteNotFoundWrapper>
      )}
    </FavoriteWrapper>
  );
}
