import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--color-background-switch-alt);
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  > img {
    transform: rotate(270deg);
    padding-bottom: 16px;
    pointer-events: none;
    position: absolute;
  }
`;

export const DropdownContainer = styled.ul`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

export const DropdownHeader = styled.div`
  padding: 0px 1em;
  color: var(--color-text-switch);
  font-weight: 400;
  font-size: 1rem;
  list-style: none;
  font-family: 'ChickenSansRegular';
  height: 100%;
  display: flex;
  align-items: center;
`;

export const DropdownSelected = styled.p`
  display: inline-block;
  vertical-align: middle;
  line-height: normal;

  ${props =>
    props.selectedOption && `color: var(--color-text-dark); font-weight: 500;`}
`;

export const DropdownListContainer = styled.div`
  position: absolute;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 2;
  width: 100%;
  background-color: var(--color-background-switch-alt);
`;
export const DropdownList = styled.ul`
  padding: 0px 1em;
  margin: 7px 0 0;
  box-sizing: border-box;
  font-size: 1rem;

  div {
    width: 100%;
    border-bottom: 1px solid #929292;
    margin: 14px 0px;
    opacity: 0.3;
  }
  li:last-of-type {
    margin-bottom: 14px;
  }
  div:last-of-type {
    display: none;
  }
`;
export const ListItem = styled.li`
  color: var(--color-text-dark);
  font-size: 1rem;
  list-style: none;
  font-weight: 500;
  font-family: 'ChickenSansRegular';
`;
