import React, { useEffect, useRef } from 'react';

import BackButton from 'components/BackButton';
import FooterComponent from 'components/FooterComponent';
import HeaderComponent from 'components/HeaderComponent';
import { MyProfileVerifyTokenContainer } from 'containers';
import { useHeader } from 'hooks/HeaderContext';
import { AuthLayout } from 'layouts';

import { Container } from '../styles';

function SignInNumber() {
  const containerRef = useRef();
  const { handleRef } = useHeader();

  useEffect(() => {
    handleRef(containerRef);
  }, []);

  return (
    <Container ref={containerRef}>
      <HeaderComponent title="Verificação" href="/meu-perfil" />
      <BackButton />
      <AuthLayout ref={containerRef}>
        <MyProfileVerifyTokenContainer />
      </AuthLayout>
      <FooterComponent />
    </Container>
  );
}

export default SignInNumber;
