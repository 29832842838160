import React from 'react';

import PropTypes from 'prop-types';

import { ButtonContainer } from './styles';

const Button = ({
  children,
  size,
  variant,
  font,
  margin,
  onClick,
  loading,
  ...props
}) => {
  const handleClick = e => {
    e.stopPropagation();
    if (onClick) onClick();
  };

  return (
    <ButtonContainer
      size={size}
      variant={variant}
      font={font}
      margin={margin}
      onClick={handleClick}
      {...props}
    >
      {children}

      <div className="loader-icon">{loading && <div className="loader" />}</div>
    </ButtonContainer>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary']),
  font: PropTypes.oneOf(['regular', 'bold']),
  size: PropTypes.oneOf(['S', 'M', 'L']),
  margin: PropTypes.oneOf(['zeroMargin', 'highMargin']),
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

Button.defaultProps = {
  variant: 'primary',
  font: 'regular',
  size: 'M',
  margin: 'zeroMargin',
  loading: false,
};

export default Button;
