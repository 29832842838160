import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const ModalContent = styled.div`
  font-family: 'ChickenSansRegular';
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 24px 0px;
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
  }

  h3 {
    margin: 0px 24px;
    text-align: center;
  }

  > button {
    margin-top: 15px;

    > img {
      max-width: 25px;
      position: relative;
      right: 35px;
    }
  }

  @media (max-width: ${sizes.ipadPro}) {
    padding: 32px 24px 0px 24px;
    .MsgIcon {
      max-width: 25px;
      position: absolute;
      left: 40px;
    }
  }
`;
