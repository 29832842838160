import styled from 'styled-components';

export const RestaurantsStatesList = styled.div`
  width: 100%;
`;

export const SearchRestaurantBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding-bottom: 37px;
  border-bottom: 1px solid #bdbdbd;

  p {
    font-size: 12px;
    color: #757575;
    margin-top: 5px;
  }
`;

export const SearchInputWrapper = styled.div`
  position: relative;

  input {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #bdbdbd;
    padding: 13px 16px;

    &::placeholder {
      font-size: 16px;
    }
  }

  svg {
    position: absolute;
    right: 16px;
    top: 10px;
  }
`;
