import styled, { css } from 'styled-components';
import { breakpoints, spacings, transitions } from 'styles';

import * as T from './interfaces';

const MediaModifier = {
  mobile: () => css`
    height: 100vh;
  `,
  desktop: () => css`
    height: 105%;
  `,
};

const WrapperModifier = {
  viewMode: {
    desktop: {
      map: () => css`
        > div {
          :first-child {
            min-width: 50%;
          }
          :last-child {
            min-width: 50%;
          }
        }
      `,
      list: () => css`
        > div {
          :first-child {
            min-width: 100%;
          }
          :last-child {
            min-width: 50%;
          }
        }
      `,
    },
    mobile: {
      map: () => css`
        > div {
          :first-child {
            ol {
              li:not(:first-child) {
                transition: ${transitions.transform03};
              }
            }
          }
          :last-child {
            transition: ${transitions.transform02};
            transform: translateY(0);
          }
        }
      `,
      list: () => css`
        > div {
          :first-child {
            ol {
              li:not(:first-child) {
                transition: ${transitions.transform03};
                transform: translateY(0);
              }
            }
          }
          :last-child {
            transition: ${transitions.transform02};
            transform: translateY(100vh) scaleY(0);
          }
        }
      `,
    },
  },
};

export const Container = styled.div<T.IMenuStyles>`
  width: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  > div:first-of-type {
    height: 100%;
    transition: 1s;
  }
  @media (max-width: ${breakpoints.intermediary}) {
    flex-direction: column;
    > div {
      :last-child {
        transition: 1s;
        position: absolute;
      }
    }
  }
  ${({ mapMode, isMobileDevice }) => css`
    ${WrapperModifier.viewMode[isMobileDevice ? 'mobile' : 'desktop'][
      mapMode ? 'map' : 'list'
    ]()}
  `}
  @media (max-width: ${breakpoints.intermediary}) {
    ${MediaModifier.mobile()}
  }
  @media (min-width: ${breakpoints.intermediary}) {
    ${MediaModifier.desktop()}
  }
`;
