import { useEffect, useRef, useState } from 'react';
import FooterComponent from 'components/FooterComponent';
import HeaderComponent from 'components/HeaderComponent';
import { ProductDetails } from 'components/Organisms';
import { ProductDetailsProvider } from 'hooks/ProductDetails';

import { CouponsProvider } from '../../hooks/Coupons';
import * as S from './styles';

export default function Product() {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [hideBanner, setHideBanner] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const currentPosition = containerRef.current.scrollTop;
        if (currentPosition > 0) {
          setHideBanner(true);
        } else {
          setHideBanner(false);
        }
      }
    };

    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [hideBanner]);

  return (
    <S.Container ref={containerRef}>
      <HeaderComponent title="Detalhes do produto" />
      <CouponsProvider>
        <ProductDetailsProvider>
          <ProductDetails hideBanner={hideBanner} containerRef={containerRef} />
        </ProductDetailsProvider>
      </CouponsProvider>
      <FooterComponent />
    </S.Container>
  );
}
