import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'ChickenSansRegular';
  font-style: normal;
  font-weight: normal;

  color: var(--color-burnt-orange);

  margin-top: 10px;
`;
