export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
}

// export function handleResize(onUpdateDimensions) {
//   const dimensions = getWindowDimensions();
//   onUpdateDimensions(dimensions);
// }
