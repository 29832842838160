import React, { createContext, useCallback, useContext, useState } from 'react';

import { IModalMobileContextData } from './intefaces';

const ModalMobileContext = createContext<IModalMobileContextData>(
  {} as IModalMobileContextData,
);

type ModalMobileContextProviderProps = {
  children: React.ReactNode;
};

function ModalMobileContextProvider({
  children,
}: ModalMobileContextProviderProps) {
  const [scrollAllTop, setScrollAllTop] = useState(false);
  const [modalMode, setModalMode] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const handleSetScrollAllTop = useCallback((scrollTop: boolean) => {
    setScrollAllTop(scrollTop);
  }, []);

  const handleSetModalMode = useCallback((status: boolean) => {
    setModalMode(status);
  }, []);
  const handleSetIsDragging = useCallback((status: boolean) => {
    setIsDragging(status);
  }, []);

  return (
    <ModalMobileContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        scrollAllTop,
        modalMode,
        isDragging,
        handleSetIsDragging,
        handleSetScrollAllTop,
        handleSetModalMode,
      }}
    >
      {children}
    </ModalMobileContext.Provider>
  );
}

function useModalMobileContext(): IModalMobileContextData {
  const context = useContext(ModalMobileContext);

  if (!context)
    throw new Error(
      'useModalMobileContext must be used with ModalMobileContextProvider',
    );

  return context;
}

export { ModalMobileContextProvider, useModalMobileContext };
