import React, { useEffect, useRef } from 'react';

import BackButton from 'components/BackButton';
import FooterComponent from 'components/FooterComponent';
import HeaderComponent from 'components/HeaderComponent';
import { WhatDoWithMyDataContainer } from 'containers';
import { useHeader } from 'hooks/HeaderContext';

import { Container } from './styles';

function WhatDoWithMyData() {
  const { handleRef, showHeader } = useHeader();
  const containerRef = useRef();

  useEffect(() => {
    handleRef(containerRef);
  }, []);

  return (
    <Container ref={containerRef}>
      <HeaderComponent
        title="Direitos e solicitações"
        href="/direitos-e-solicitacoes"
        visible={showHeader}
      />
      <BackButton />

      <WhatDoWithMyDataContainer />

      <FooterComponent />
    </Container>
  );
}

export default WhatDoWithMyData;
