import { useEffect, useRef } from 'react';

import FooterComponent from 'components/FooterComponent';
import HeaderComponent from 'components/HeaderComponent';
import { CouponContainer } from 'containers';
import { CouponsProvider } from 'hooks/Coupons';
import { useHeader } from 'hooks/HeaderContext';

import { Container } from './styles';

const Coupon = () => {
  const { handleRef, showHeader } = useHeader();
  const containerRef = useRef();

  useEffect(() => {
    handleRef(containerRef);
  }, []);

  return (
    <Container ref={containerRef}>
      <HeaderComponent visible={showHeader} />
      <CouponsProvider>
        <CouponContainer ContainerRef={containerRef} />
      </CouponsProvider>
      <FooterComponent />
    </Container>
  );
};

export default Coupon;
