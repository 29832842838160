import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import BackIcon from 'assets/images/icons/back.svg';
import Button from 'components/Button';

// Images and Icons

// Hooks and Services
import { api } from 'services';

// Styles
import {
  Container,
  Footer,
  SubjectContainer,
  Item,
  ArrowSpace,
} from './styles';

export default function Faq() {
  const history = useHistory();
  const [subjects, setSubjects] = useState();

  useEffect(() => {
    async function getFaq() {
      try {
        const response = await api.get('/customer/v0.1/faq');
        setSubjects(response.data);
      } catch (error) {
        console.log('error ', error);
      }
    }

    getFaq();
  }, []);

  return (
    <>
      <Container>
        {subjects &&
          subjects.map((subject, subjectIdex) => (
            <SubjectContainer key={`${subject.subject}_${subject.id}`}>
              <h1>{subject.subject}</h1>

              {subject.questions.map((question, questionIndex) => (
                <Item
                  key={`question_${question.id}`}
                  onClick={() => {
                    history.push(`/faq/${subjectIdex}-${questionIndex}`);
                  }}
                >
                  <p>{question.name}</p>
                  <span>{question.answer}</span>
                  <ArrowSpace>
                    <img src={BackIcon} alt="Ir ao perfil do usuario" />
                  </ArrowSpace>
                </Item>
              ))}
            </SubjectContainer>
          ))}
      </Container>

      <Footer>
        <hr style={{ position: 'absolute', width: '100%' }} />
        <p>Não encontrou o que procurava?</p>

        <Button
          variant="tertiary"
          onClick={() =>
            history.push({
              pathname: '/fale-conosco',
            })
          }
        >
          Fale conosco
        </Button>
      </Footer>
    </>
  );
}
