export default function IconTwitter() {
  return (
    <svg
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M19.5 38C30.2695 38 39 29.4934 39 19C39 8.5066 30.2695 0 19.5 0C8.73049 0 0 8.5066 0 19C0 29.4934 8.73049 38 19.5 38ZM29.315 9.025H25.8881L20.2411 15.3144L15.3589 9.025H8.2875L16.7366 19.79L8.72882 28.7078H12.1577L18.3381 21.8269L23.7396 28.7078H30.6357L21.8282 17.3624L29.315 9.025ZM26.5842 26.7092H24.6854L12.2894 10.9186H14.327L26.5842 26.7092Z"
        fill="white"
      />
    </svg>
  );
}
