import { format } from 'date-fns';

const getToday = () => {
  const today = new Date();
  return format(today, "dd'/'MM'/'yyyy");
};

const defaultRemoteConfig = {
  home: {
    cupons_button: {
      title: 'Cupons',
      title_color: '#FFFFFF',
      background_color: '#F25600',
    },
    delivery_button: {
      title: 'Delivery',
      title_color: '#FFFFFF',
      background_color: '#F25600',
    },
  },
  cupons: {
    ofertas_button: {
      title: 'Ver Ofertas',
      title_color: '#F25600',
      background_color: '#FFFFFF',
    },
    cart_button: {
      text: 'Meus cupons',
      text_color: '#303030',
      number_text_color: '#FFFFFF',
      number_background_color: '#F25600',
    },
    cupons_price_text: {
      text_color: '#F25600',
    },
    oferta_dia_section: {
      background_color: '#F25600',
      title_text: 'Crocância do dia',
      title_color: '#FFFFFF',
      subtitle_text: 'Exclusivo no App',
      subtitle_color: '#FFFFFF',
      date_text: 'Oferta $dia_da_semana',
      date_color: '#000000',
      date_background_color: '#FFFFFF',
    },
    destaques_section: {
      title_text: 'Destaques pra você',
      title_color: '#000000',
      button_text: 'Pegar agora',
      button_color: '#F25600',
      button_text_color: '#FFFFFF',
    },
    cupons: [],
    show_preco_cheio: false,
  },
  cupons_detail: {
    resgate_button: {
      title: 'Pegar cupom',
      title_color: '#FFFFFF',
      background_color: '#F25600',
    },
    preco_text: {
      text_color: '#FF7D00',
    },
  },
  cupons_cart: {
    apresentar_text: {
      text: 'Apresente o código do cupom no balcão ou digite no totem',
      text_color: '#FFFFFF',
      background_color: '#00B2A9',
    },
    validade_text: {
      text: `Cupons válidos para hoje ${getToday()}`,
      text_color: '#929292',
      background_color: '#FFF8DD',
    },
    cupom_code: {
      background_color: '#00B2A9',
      text_color: '#FFFFFF',
    },
    mais_button: {
      title: 'Pegue quantos cupons quiser',
      title_color: '#929292',
      background_color: '#FAFAFA',
      icon_color: '#D9D9D9',
      icon_background_color: '#929292',
    },
  },
};

export default defaultRemoteConfig;
