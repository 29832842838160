import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${sizes.notebook}) {
    height: 100%;
  }
  width: 100%;
`;

export const Form = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  height: 100%;
  width: 100%;

  > div:first-child {
    margin-top: 60px;
  }

  .safetyContainer {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 100%;

    p {
      color: #000000;
      opacity: 80%;
      text-align: center;
      font-size: 12px;
      margin-bottom: 17px;
    }

    button {
      margin-bottom: 20px;
    }
  }

  @media (min-width: ${sizes.notebook}) {
    width: 80%;

    .safetyContainer {
      p {
        font-size: 14px;
      }
    }
  }
`;

export const FakeHeader = styled.div`
  height: 61px;
`;
