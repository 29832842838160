import React, { useEffect, useRef, useState } from 'react';

import BackButton from 'components/BackButton';
import FooterComponent from 'components/FooterComponent';
import HeaderComponent from 'components/HeaderComponent';
import { RightsAndRequestsContainer } from 'containers';
import { useHeader } from 'hooks/HeaderContext';

import { Container } from './styles';

function RightsAndRequests() {
  const [urlBack, setUrlBack] = useState('/perfil');
  const { handleRef, showHeader } = useHeader();
  const containerRef = useRef();

  useEffect(() => {
    handleRef(containerRef);
    const params = new URL(window.location).searchParams;
    const href = params.get('href');
    if (href) setUrlBack(href);
  }, []);

  return (
    <Container ref={containerRef}>
      <HeaderComponent
        title="Direitos e solicitações"
        href={urlBack}
        visible={showHeader}
      />
      <BackButton />

      <RightsAndRequestsContainer />

      <FooterComponent />
    </Container>
  );
}

export default RightsAndRequests;
