/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';
import { breakpoints } from 'styles';

interface IMediaModifier {
  mobileTop?: number;
  initialPosition?: number;
  isDragging?: boolean;
  openModal?: boolean;
}

const MediaModifier = {
  mobile: () => css`
    right: 64px;
  `,
  desktop: () => css`
    bottom: 60px;
    right: 64px;
  `,
};

export const Container = styled.div<IMediaModifier>`
  width: 0px;
  height: 0px;

  position: absolute;
  button {
    width: 55px;
    height: 50px;
    background: var(--color-salt-white);
    box-shadow: 0px 0px 47.3965px rgba(0, 0, 0, 0.1);
    position: absolute;
    border-radius: 9.47929px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 35px;
      width: auto;
    }
    :focus {
      outline: none;
    }
  }

  @media (min-width: ${breakpoints.intermediary}) {
    ${MediaModifier.desktop()}
  }
  @media (max-width: ${breakpoints.intermediary}) {
    ${MediaModifier.mobile()} top: ${({
      openModal,

      mobileTop,
    }) => {
      return openModal
        ? `${window.innerHeight - 70 - mobileTop}px;`
        : `${window.innerHeight - 70}px;`;
    }}
    ${({ initialPosition, isDragging, mobileTop }) => {
      if (initialPosition && !isDragging) {
        return ` top: ${window.innerHeight - 70 - mobileTop}px`;
      }
    }}
  }
`;
