import { useCallback } from 'react';

import { useCoupons } from 'hooks/Coupons';
import PropTypes from 'prop-types';
import firebase from 'services/firebase';

// Hooks, Services and Utils

// Styles
import { Container, LabelCart, QuantityCart } from './styles';

const Cart = ({ openModal }) => {
  const { cartCoupon, couponsRemoteConfig } = useCoupons();
  const handleOpen = useCallback(() => {
    openModal();
  }, [openModal, firebase]);
  return (
    <Container
      style={{ color: couponsRemoteConfig.cart_button?.text_color }}
      onClick={handleOpen}
      className="modalCart"
    >
      <LabelCart>{couponsRemoteConfig.cart_button.text}</LabelCart>

      <QuantityCart
        style={{
          backgroundColor:
            couponsRemoteConfig.cart_button.number_background_color,
          color: couponsRemoteConfig.cart_button.number_text_color,
        }}
      >
        <p>{cartCoupon?.length}</p>
      </QuantityCart>
    </Container>
  );
};

Cart.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default Cart;
