import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import defaultRemoteConfig from 'defaultRemoteConfig';
import { useAuth } from 'hooks/Auth';
import { useParams } from 'react-router-dom';
import { api, menuApi } from 'services';
import firebase from 'services/firebase';

const ProductDetailsContext = createContext();

const ProductDetailsProvider = ({ children }) => {
  const { customerId, user } = useAuth();

  const { productId } = useParams();

  const [loading, setLoading] = useState(true);
  const [loadingCart, setLoadingCart] = useState(false);
  const [loadingRemoteConfig, setLoadingRemoteConfig] = useState(false);

  const [product, setProduct] = useState(null);

  const [remoteConfig, setRemoteConfig] = useState(defaultRemoteConfig);

  const [chosenCoupon, setChosenCoupon] = useState(() => {
    const chosenCouponDataStorage = localStorage.getItem(
      '@Popeyes.chosenCoupon.v2',
    );
    const chosenCouponData = JSON.parse(chosenCouponDataStorage);
    return chosenCouponData;
  });

  const [cartCoupon, setCartCoupon] = useState(() => {
    const cartDataStorage = localStorage.getItem('@Popeyes.cart.v2');
    const cart = JSON.parse(cartDataStorage);
    return cart || [];
  });

  const [categories, setCategories] = useState(() => {
    const categoriesDataStorage = localStorage.getItem(
      '@Popeyes.categories.v2',
    );
    const categoriesData = JSON.parse(categoriesDataStorage);
    return categoriesData || [];
  });

  const [coupons, setCoupons] = useState(() => {
    const couponsDataStorage = localStorage.getItem('@Popeyes.coupons.v2');
    const couponsData = JSON.parse(couponsDataStorage);
    return couponsData || [];
  });

  const [highlightsCoupons, setHighlightsCoupons] = useState(() => {
    const highlightsCouponsDataStorage = localStorage.getItem(
      '@Popeyes.highlightsCoupons.v2',
    );
    const highlightsCouponsData = JSON.parse(highlightsCouponsDataStorage);
    return highlightsCouponsData || [];
  });

  const setUpRemoteConfig = useCallback(async () => {
    setLoadingRemoteConfig(true);

    const remoteConfigFirebase = firebase.remoteConfig();
    remoteConfigFirebase
      .fetchAndActivate()
      .then(() => {
        const remoteCoupons = JSON.parse(
          remoteConfigFirebase.getString('cupons'),
        );
        const remoteCouponsCart = JSON.parse(
          remoteConfigFirebase.getString('cupons_cart'),
        );
        const remoteCouponsDetail = JSON.parse(
          remoteConfigFirebase.getString('cupons_detail'),
        );

        remoteCouponsCart.validade_text.text += ` (${new Date().toLocaleDateString(
          'pt-BR',
        )})`;

        const remoteConfigData = {
          cupons: remoteCoupons,
          cupons_detail: remoteCouponsDetail,
          cupons_cart: remoteCouponsCart,
        };

        setRemoteConfig(remoteConfigData);
        setLoadingRemoteConfig(false);
      })
      .catch(() => {
        setLoadingRemoteConfig(false);
      });
  }, [remoteConfig, setRemoteConfig, setLoadingRemoteConfig]);

  const onRequestMyCart = useCallback(
    async couponsItens => {
      setLoadingCart(true);
      if (customerId) {
        if (user.fakeUser) {
          const cart = JSON.parse(
            localStorage.getItem('@Popeyes.cart.v2') || '[]',
          );
          console.log(`cart: :${cart}`);
          return cart;
        }
        try {
          const response = await api.get('menu/v0.1/cuponsCart', {
            params: { customer_id: customerId },
          });

          const quantyTotal = response.data;

          const filteredCoupons = couponsItens.filter(coupon =>
            quantyTotal.find(couponGot => couponGot.cupom_id === coupon.id),
          );
          setLoadingCart(false);
          return filteredCoupons;
        } catch (err) {
          if (err.response.status === 404 || err.response.status === 401) {
            setLoadingCart(false);
            return [];
          }
          setLoadingCart(false);
          return null;
        }
      } else {
        setLoadingCart(false);
        return null;
      }
    },
    [customerId, setLoadingCart],
  );

  const onRequestCoupons = useCallback(async () => {
    setLoading(true);
    try {
      const productResponse = await menuApi.get(
        `/listar-produtos/${productId}`,
      );

      if (productResponse.data) {
        setProduct(productResponse.data);
      }

      const productCouponCodes = productResponse.data.codigoCupom.map(
        cupom => cupom.codigo,
      );

      const response = await menuApi.get('/listar/cupons');
      if (response.data) {
        const { cupons } = response.data;
        const couponsList = cupons;
        console.log(couponsList);
        const filteredCoupons = couponsList.filter(coupon =>
          productCouponCodes.includes(coupon.codigo),
        );
        console.log(productCouponCodes);
        console.log(filteredCoupons);
        filteredCoupons.sort((a, b) => a.categoria.ordem - b.categoria.ordem);

        try {
          const myCart = await onRequestMyCart(couponsList);
          if (myCart) {
            localStorage.setItem('@Popeyes.cart.v2', JSON.stringify(myCart));
            setCartCoupon(myCart);
          }
        } catch (error) {
          console.log(error);
        }

        setCoupons(filteredCoupons);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  }, [
    customerId,
    setProduct,
    setCoupons,
    setCartCoupon,
    setLoading,
    setCategories,
    setHighlightsCoupons,
  ]);

  const updateMyCart = useCallback(async () => {
    const myCart = await onRequestMyCart(coupons);
    if (myCart) {
      localStorage.setItem('@Popeyes.cart.v2', JSON.stringify(myCart));
      setCartCoupon(myCart);
    }
  }, [coupons, setCartCoupon]);

  useEffect(() => {
    setUpRemoteConfig();
    onRequestCoupons();
  }, []);

  const onUpdateCategories = useCallback(
    async newCategories => {
      localStorage.setItem(
        '@Popeyes.categories.v2',
        JSON.stringify(newCategories),
      );
      setCategories(newCategories);
    },
    [setCategories],
  );

  const onChoseCoupon = useCallback(
    async couponSelected => {
      localStorage.setItem(
        '@Popeyes.chosenCoupon.v2',
        JSON.stringify(couponSelected),
      );
      setChosenCoupon(couponSelected);
    },
    [setChosenCoupon],
  );

  const onDeleteCoupon = useCallback(
    async coupon => {
      const bodyParams = {
        customer_id: customerId,
        cupom_id: coupon.id,
      };

      try {
        await api.delete('menu/v0.1/cuponsCart', { data: bodyParams });
      } catch (err) {
        console.log(err);
      }
    },
    [customerId],
  );

  const onGetCoupon = useCallback(
    async coupon => {
      setLoading(true);

      try {
        if (!user.fakeUser) {
          const bodyParams = {
            customer_id: parseInt(customerId, 10),
            cupom_id: coupon.id,
            replay_cupom: !!coupon.replay_cupom,
          };

          await api.post('menu/v0.1/cuponsCart', bodyParams);
        } else {
          const cart = JSON.parse(
            localStorage.getItem('@Popeyes.cart.v2') || '[]',
          );
          cart.push(coupon);
          localStorage.setItem('@Popeyes.cart.v2', JSON.stringify(cart));
        }

        await updateMyCart();
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    },
    [customerId, updateMyCart, setLoading],
  );

  return (
    <ProductDetailsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        product,
        coupons,
        highlightsCoupons,
        cartCoupon,
        categories,
        chosenCoupon,
        remoteConfig,
        couponsRemoteConfig: remoteConfig.cupons,
        couponDetailRemoteConfig: remoteConfig.cupons_detail,
        cartRemoteConfig: remoteConfig.cupons_cart,
        loading,
        loadingCart,
        loadingRemoteConfig,
        onRequestCoupons,
        onUpdateCategories,
        onChoseCoupon,
        onRequestMyCart: updateMyCart,
        onDeleteCoupon,
        onGetCoupon,
      }}
    >
      {children}
    </ProductDetailsContext.Provider>
  );
};

function useProductDetails() {
  const context = useContext(ProductDetailsContext);
  return context;
}

export { ProductDetailsProvider, useProductDetails };
