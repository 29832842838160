import React, { useEffect, useState } from 'react';

import Arrow from 'assets/images/icons/back.svg';

import {
  Container,
  DropdownContainer,
  DropdownHeader,
  DropdownList,
  DropdownListContainer,
  DropdownSelected,
  ListItem,
} from './styles';

function Dropdown({ matters, onChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const [mattersList, setMattersList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setMattersList(matters);
  }, [matters]);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = value => () => {
    onChange(value);
    setSelectedOption(value.subject);
    setIsOpen(false);
  };

  return (
    <Container>
      <DropdownContainer>
        <DropdownHeader onClick={toggling}>
          <DropdownSelected selectedOption={!!selectedOption}>
            {selectedOption || 'Selecione um assunto'}
          </DropdownSelected>
        </DropdownHeader>
        {isOpen && (
          <DropdownListContainer>
            <DropdownList>
              {mattersList.map(option => (
                <>
                  <ListItem onClick={onOptionClicked(option)} key={option.id}>
                    {option.subject}
                  </ListItem>
                  <div />
                </>
              ))}
            </DropdownList>
          </DropdownListContainer>
        )}
      </DropdownContainer>
      <img src={Arrow} alt="Seta apontando para baixo" />
    </Container>
  );
}

export default Dropdown;
