/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';

// Components
import Button from 'components/Button';
import Input from 'components/Input';
import ModalResponsive from 'components/ModalResponsive';
import TextArea from 'components/TextArea';

// Utils and api
import { api } from 'services';
import firebase from 'services/firebase';
import sizes from 'utils/screenSizes';
import { getWindowDimensions } from 'utils/windowDimensions';

import { Container, ModalContent } from './styles';
import schema from './validation';

// Styles

function SendEmailContainer() {
  const history = useHistory();

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      email: '',
      requestText: '',
    },
  });
  const { formState } = methods;

  const [openModal, setOpenModal] = useState(false);
  const [urlBack, setUrlBack] = useState('/perfil');
  const [title, setTitle] = useState('Direitos e solicitações');
  const [subject, setSubject] = useState('Preciso de ajuda');
  const [isEdit, setIsEdit] = useState(false);
  const [isHelp, setIsHelp] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isRecover, setIsRecover] = useState(false);

  const [loadingSend, setLoadingSend] = useState(false);

  const { width } = getWindowDimensions();

  const onBackButtonEvent = e => {
    e.preventDefault();

    if (openModal) {
      setOpenModal(false);
    } else {
      history.push(urlBack);
    }
  };

  useEffect(() => {
    const params = new URL(window.location).searchParams;

    const isEditParam = params.get('isEdit');
    if (isEditParam) setIsEdit(true);

    const href = params.get('href');
    if (href) setUrlBack(href);

    if (!href && width >= 1200) {
      setUrlBack('/');
    }

    const titleParams = params.get('title');
    if (titleParams) setTitle(titleParams);

    const isDeleteParams = params.get('isDelete');
    if (isDeleteParams) setIsDelete(isDeleteParams);

    const isHelpParams = params.get('isHelp');
    if (isHelpParams) setIsHelp(isHelpParams);

    const isRecoverParams = params.get('isRecover');
    if (isRecoverParams) setIsRecover(isRecoverParams);

    const subjectParams = params.get('subject');
    if (subjectParams) setSubject(subjectParams);
  }, []);

  useEffect(() => {
    if (width < parseInt(sizes.notebook, 10)) {
      window.history.pushState(
        '',
        '',
        window.location.pathname + window.location.search,
      );
      window.addEventListener('popstate', onBackButtonEvent);
    }
    return () => window.removeEventListener('popstate', onBackButtonEvent);
  }, [openModal, urlBack]);

  const newEmail = async dataForm => {
    setLoadingSend(true);

    if (isEdit) {
      firebase.analytics().logEvent('editar_cadastro');
    } else if (isHelp) {
      if (isRecover)
        firebase.analytics().logEvent('envioemail_ajudarecuperacao');
      else firebase.analytics().logEvent('preciso_ajuda');
    } else if (isDelete) {
      firebase.analytics().logEvent('excluir_conta');
    } else {
      firebase.analytics().logEvent('falar_dpo');
    }

    const { email, name, requestText } = dataForm;
    const params = {
      name,
      subject,
      toemail:
        !isEdit && !isHelp && !isDelete
          ? process.env.REACT_APP_EMAIL_LGPD
          : process.env.REACT_APP_EMAIL_SAC,
      bodyhtml: `<text><b>CLIENTE: </b>${name}<br><b>EMAIL DO CLIENTE: </b>${email}</text><br><br><b>MENSAGEM:</b> ${requestText}`,
    };

    try {
      await api.post('/authentication/v0.1/sendmail', params);

      history.push({
        pathname: '/sucesso',
        search: `?${new URLSearchParams({
          href: urlBack,
          isMessage: true,
          showButton: true,
          buttonTitle: 'Fechar',
          title,
          subTitle: 'Retornaremos para o e-mail cadastrado:',
          textBold: email,
          lastSubtitle:
            isEdit || isHelp || isDelete
              ? 'Fique ligado na Caixa de spam! Prazo de retorno de 1 dia útil.'
              : 'Fique ligado na Caixa de spam! Prazo de retorno de 15 dias úteis.',
        }).toString()}`,
      });

      setLoadingSend(false);
    } catch (error) {
      setOpenModal(true);
      setLoadingSend(false);

      if (isEdit) {
        firebase.analytics().logEvent('erroemail_editarcadastro');
      } else if (isHelp) {
        if (isRecover)
          firebase.analytics().logEvent('erroemail_ajudarecuperacao');
        else firebase.analytics().logEvent('erroemail_faleconosco');
      } else if (isDelete) {
        firebase.analytics().logEvent('erroemail_excluirconta');
      } else {
        firebase.analytics().logEvent('erroemail_dpo');
      }
    }
  };

  return (
    <>
      <Container>
        <h1>
          {isEdit && 'Editar informações de cadastro'}
          {isHelp && 'Preciso de ajuda'}
          {isDelete && 'Quero excluir minha conta'}
          {!isEdit &&
            !isHelp &&
            !isDelete &&
            'Falar com agente sobre privacidade de dados'}
        </h1>

        <p style={{ marginTop: '24px' }}>
          {isEdit || isHelp || isDelete
            ? 'Entraremos em contato com você em até 1 dia útil.'
            : 'Entraremos em contato com você em até 15 dias úteis.'}
        </p>

        <p style={{ marginTop: '32px' }}>Informe seus dados de contato</p>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(newEmail)}>
            <Input
              htmlFor="name"
              text="Nome"
              type="text"
              name="name"
              maxLength="35"
              required
              isValid
            />
            <Input
              htmlFor="email"
              text="E-mail"
              type="email"
              name="email"
              maxLength="50"
              required
              isValid
            />

            <p>Escreva sua solicitação</p>
            <TextArea
              name="requestText"
              rows="6"
              placeholder="Digite aqui o que deseja"
            />

            <Button type="submit" disabled={!formState.isValid}>
              {loadingSend ? 'Enviando email ...' : 'Enviar'}
            </Button>

            <Button
              type="button"
              variant="secondary"
              onClick={() => history.push(urlBack)}
            >
              Voltar
            </Button>
          </form>
        </FormProvider>
      </Container>

      <ModalResponsive
        open={openModal}
        maxModalHeight={350}
        onClose={() => setOpenModal(false)}
      >
        <ModalContent>
          <p>Tivemos um erro ao enviar mensagem</p>
          <p className="text">
            Por favor, tente enviar sua solicitação novamente.
          </p>

          <Button type="button" onClick={() => setOpenModal(false)}>
            Tentar Novamente
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={() => setOpenModal(false)}
          >
            Cancelar
          </Button>
        </ModalContent>
      </ModalResponsive>
    </>
  );
}

export default SendEmailContainer;
