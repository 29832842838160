// eslint-disable-next-line no-restricted-globals

import React, { useCallback, FC, useState, useMemo } from 'react';

import { fireLogEvent } from 'services/firebase';
import sizes from 'utils/screenSizes';

import { Carousel } from './Carousel';
import * as T from './interfaces';

export const Container: FC<T.IContainer> = ({
  children,
  Components,
  Buttons = null,
  autoPlay = true,
  showCarrouselButtons = true,
  showArrows = false,
  handleHomeButtons = false,
  onChangeCarrousselPage,
  alreadyVisited,
  handleActive,
  handleClick,
  eachItemTime = 3000,
  isFromValidated,
  sizeCarousel,
  id,
  ...rest
}) => {
  const [currentActive, setCurrentActive] = useState(0);
  alreadyVisited = localStorage.getItem('@Popeyes.already_visited');
  const params = new URL(window.location as any).searchParams;
  isFromValidated = params.get('validated');
  const screenWidth = window.screen.width;

  const handleAutoChange = useCallback(() => {
    return setInterval(() => {
      setCurrentActive(prev => {
        const newState = prev >= Components.length - 1 ? 0 : prev + 1;
        onChangeCarrousselPage && onChangeCarrousselPage(newState);
        return newState;
      });
    }, eachItemTime);
  }, [setCurrentActive, onChangeCarrousselPage]);

  const timeOut = useMemo(() => {
    if (
      screenWidth >= parseInt(sizes.notebook, 10) &&
      !alreadyVisited &&
      autoPlay
    ) {
      return handleAutoChange();
    }
  }, []);

  handleActive = (increment: boolean) => {
    clearInterval(timeOut);

    setCurrentActive(prev => {
      if (screenWidth >= parseInt(sizes.notebook, 10)) {
        return increment && prev >= Components.length - 1
          ? 0
          : !increment && prev === 0
          ? Components.length - 1
          : !increment
          ? prev - 1
          : prev + 1;
      }
      return increment && prev >= Components.length - 1
        ? prev
        : !increment && prev === 0
        ? 0
        : !increment
        ? prev - 1
        : prev + 1;
    });
  };

  handleClick = (index: number) => {
    clearInterval(timeOut);
    if (screenWidth <= parseInt(sizes.notebook, 10)) {
      return;
    }
    onChangeCarrousselPage && onChangeCarrousselPage(index);
    setCurrentActive(index);
  };

  if (Components.length > 1) {
    const container = document.getElementsByClassName('carouselContainer')[0];
    let touchstartX = 0;
    let touchendX = 0;

    const handleGesture = () => {
      if (touchendX < touchstartX) handleActive(true);
      if (touchendX > touchstartX) handleActive(false);
      document.scrollingElement.scrollLeft = 0;
    };

    container?.addEventListener('touchstart', (e: any) => {
      e.stopImmediatePropagation();

      touchstartX = e.changedTouches[0].screenX;
    });

    container?.addEventListener('touchend', (e: any) => {
      e.stopImmediatePropagation();

      touchendX = e.changedTouches[0].screenX;
      handleGesture();
    });

    document.addEventListener('scroll', e => {
      e.preventDefault();
      e.stopImmediatePropagation();
      document.scrollingElement.scrollLeft = 0;
    });
  }

  return (
    <Carousel
      {...rest}
      Components={Components}
      Buttons={Buttons}
      alreadyVisited={alreadyVisited}
      autoPlay={autoPlay}
      currentActive={currentActive}
      handleActive={handleActive}
      handleClick={handleClick}
      handleHomeButtons={handleHomeButtons}
      onChangeCarrousselPage={onChangeCarrousselPage}
      showArrows={showArrows}
      showCarrouselButtons={showCarrouselButtons}
      isFromValidated={isFromValidated}
      sizeCarousel={sizeCarousel}
      id={`carousel@${id}`}
    >
      {children}
    </Carousel>
  );
};
