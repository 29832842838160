import React from 'react';

import popSuccess from 'assets/images/Pop/popSuccess.svg';
import Button from 'components/Button';
import PropTypes from 'prop-types';

import { ModalContainer } from '../ModalContainer';
import * as S from '../styles';

const Finish = ({ onClose }) => (
  <ModalContainer onClose={onClose}>
    <S.Spacing size="small" />

    <img src={popSuccess} alt="Mascote Pop falando" className="finish" />
    <S.Spacing size="small" />
    <h3>Agradecemos sua contribuição</h3>
    <S.Spacing />
    <S.ButtonContainer>
      <Button variant="primary" type="button" onClick={onClose}>
        Voltar para home
      </Button>
    </S.ButtonContainer>
  </ModalContainer>
);

Finish.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export { Finish };
