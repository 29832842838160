import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Button from 'components/Button';
import ModalResponsive from 'components/ModalResponsive';

// Hooks, Services and Utils
import { useAuth } from 'hooks/Auth';
import PropTypes from 'prop-types';
import firebase from 'services/firebase';
import emailSecretMask from 'utils/emailSecretMask';
import numberSecretMask from 'utils/numberSecretMask';

// Styles
import { ModalContent } from '../../styles';

const ModalErrorCode = ({
  open,
  onClose,
  isRegister,
  isEmail,
  urlBack,
  sendNewSms,
}) => {
  const { user } = useAuth();
  const history = useHistory();

  const subTitle = useMemo(() => {
    if (isEmail) {
      return (
        <>
          Volte e verifique novamente o código de verificação que foi enviado
          para o e-mail
          <strong> {user?.email && emailSecretMask(user.email)} </strong>
          {isRegister ? ' ou edite seu e-mail tocando no botão abaixo' : '. '}
        </>
      );
    }
    return (
      <>
        Volte e verifique novamente o código de verificação que foi enviado por
        SMS para o número
        <strong> 55 {numberSecretMask(user?.cellNumber)} </strong>
      </>
    );
  }, [isEmail, isRegister, user]);

  return (
    <>
      <ModalResponsive
        open={open}
        onClose={() => onClose(false)}
        maxModalHeight={380}
        sizeModal="middle"
        scrollable={false}
      >
        <ModalContent>
          <h3 className="title">Código de verificação inválido</h3>
          <p>{subTitle}</p>

          {isEmail ? (
            <>
              <Button
                type="button"
                onClick={() =>
                  isRegister
                    ? history.push(urlBack)
                    : sendNewSms({ sendEmail: true })
                }
              >
                {isRegister ? 'Editar e-mail' : 'Reenviar código por email'}
              </Button>
              <Button
                type="button"
                variant="secondary"
                onClick={() => {
                  if (isRegister) {
                    sendNewSms({ sendEmail: true });
                  } else {
                    firebase.analytics().logEvent('ajudarecuperacao');
                    history.push({
                      pathname: '/enviar-email',
                      search: `?${new URLSearchParams({
                        href: `/verificar?openIsInvalid=true&isEmail=${isEmail}&href=${urlBack}`,
                        title: 'Fale conosco',
                        isHelp: true,
                        subject: 'Preciso de ajuda',
                      }).toString()}`,
                    });
                  }
                }}
              >
                {isRegister ? 'Reenviar código por email' : 'Preciso de ajuda'}
              </Button>
            </>
          ) : (
            <>
              <Button type="button" onClick={() => history.push('/login')}>
                Conferir número
              </Button>
              <Button
                type="button"
                variant="secondary"
                onClick={() => sendNewSms({ sendEmail: false })}
              >
                Reenviar SMS
              </Button>
            </>
          )}
        </ModalContent>
      </ModalResponsive>
    </>
  );
};

ModalErrorCode.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isRegister: PropTypes.bool.isRequired,
  isEmail: PropTypes.bool.isRequired,
  urlBack: PropTypes.string.isRequired,
  sendNewSms: PropTypes.func.isRequired,
};

export default ModalErrorCode;
