import React from 'react';

import OnboardingBackground from 'assets/images/onboarding_background.svg';

import { Container, Content } from './styles';

function AuthLayout({ children }) {
  return (
    <Container background={OnboardingBackground}>
      <Content>{children}</Content>
    </Container>
  );
}

export default AuthLayout;
