import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';

// Components
import Button from 'components/Button';
import InputNumber from 'components/InputNumber';
import ModalResponsive from 'components/ModalResponsive';
import TitleSection from 'components/TitleSection';

// Services, Utils and Hooks
import { useAuth } from 'hooks/Auth';
import { api } from 'services';
import firebase from 'services/firebase';

import { Container, Form, ModalContent } from './styles';
import schema from './validation';

// Styles

export default function SetNumber() {
  const { user, updateUser } = useAuth();
  const history = useHistory();

  // Utilizar estes states para cada modal
  const [isInvalid, setIsInvalid] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const fieldDDD = user?.cellNumber ? user.cellNumber.substr(0, 2) : '';
  const fieldNumber = user?.cellNumber ? user.cellNumber.substr(2, 9) : '';

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ddd: fieldDDD === '[o' ? '' : fieldDDD,
      celNumber: fieldNumber === 'bject Obj' ? '' : fieldNumber,
    },
  });
  const { formState } = methods;

  const onSubmit = async dataForm => {
    setLoadingButton(true);

    const number = String(dataForm.ddd.concat(dataForm.celNumber));

    firebase.analytics().logEvent('cadastro_continuar', {
      etapa_cadastro: 'recuperar_cel_pwa',
    });

    const tempUser = { ...user };
    tempUser.number = number;

    if (user.cpf) {
      try {
        // setar o novo numero!!!!
        await api.post('/customer/v0.1/cpfcontactnumber/accountrecovery', {
          cpf: user.cpf,
          contact_number: number,
        });

        await updateUser(tempUser);
        history.push('/token-recuperacao');
      } catch (err) {
        if (err.response) {
          if (err.response.data?.code === '400005') {
            setIsRegistered(true);
            firebase.analytics().logEvent('modal_erro', {
              tipo_erro: 'num_cadastrado',
            });
          } else {
            setIsInvalid(true);
          }
          setLoadingButton(false);
        }
      }
    } else {
      history.push('/onboarding');
    }

    setLoadingButton(false);
    methods.reset();
  };

  useEffect(() => {
    const params = new URL(window.location).searchParams;

    const openIsRegistered = params.get('openIsRegistered');
    if (openIsRegistered) setIsRegistered(true);
  }, []);

  return (
    <>
      <Container>
        <TitleSection title="Informe o número de seu celular" />
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <InputNumber
              defaultValues={fieldDDD}
              nameDDD="ddd"
              textDDD="DDD"
              namePhone="celNumber"
              textPhone="Número"
            />
            <div className="safetyContainer">
              <p>
                Este é um ambiente seguro e todas as suas informações estão
                protegidas.
              </p>
              <Button type="submit" disabled={!formState.isValid}>
                {loadingButton ? 'Carregando...' : 'Continuar'}
              </Button>
              <Button
                type="button"
                variant="secondary"
                onClick={() => {
                  firebase.analytics().logEvent('ajudarecuperacao');
                  history.push({
                    pathname: '/enviar-email',
                    search: `?${new URLSearchParams({
                      href: `/definir-numero-recuperacao`,
                      title: 'Fale conosco',
                      isHelp: true,
                      isRecover: true,
                      subject: 'Preciso de ajuda',
                    }).toString()}`,
                  });
                }}
              >
                Preciso de ajuda
              </Button>
            </div>
          </Form>
        </FormProvider>
      </Container>

      {/* Esta página possui dois modais:
        - DDD/Telefone inválidos
        - Numero já cadastrado */}
      <ModalResponsive
        open={isRegistered || isInvalid}
        maxModalHeight={350}
        sizeModal="middle"
        onClose={() => {
          setIsRegistered(false);
          setIsInvalid(false);
        }}
      >
        <ModalContent>
          <p>
            {isRegistered && 'Número já cadastrado'}
            {isInvalid && 'Número inválido'}
          </p>
          <p className="text">
            {isRegistered &&
              'Este telefone já tem cadastro em outra conta. Volte e insira um novo número de telefone.'}
            {isInvalid &&
              'Não conseguimos identificar esse número. Confira se o DDD e todos os números estão preenchidos corretamente.'}
          </p>
          <Button
            onClick={() => {
              setIsRegistered(false);
              setIsInvalid(false);
            }}
          >
            {isRegistered && 'Inserir novo número'}
            {isInvalid && 'Conferir número'}
          </Button>

          {isRegistered && (
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                firebase.analytics().logEvent('ajudarecuperacao');
                history.push({
                  pathname: '/enviar-email',
                  search: `?${new URLSearchParams({
                    href: `/definir-numero-recuperacao?openIsRegistered=true`,
                    title: 'Fale conosco',
                    isHelp: true,
                    isRecover: true,
                    subject: 'Preciso de ajuda',
                  }).toString()}`,
                });
              }}
            >
              Preciso de ajuda
            </Button>
          )}
        </ModalContent>
      </ModalResponsive>
    </>
  );
}
