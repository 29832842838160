import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';

import sizes from 'utils/screenSizes';

import * as T from './interfaces';
import { ArrowsLeft, ArrowsRight, Container } from './styles';

export function ScrollDrag({
  id,
  ref,
  pressed,
  isScrolling,
  onMouseDown,
  onMouseUp,
  onMouseMove,
  handleScroll,
  showArrows,
  children,
  ...rest
}: T.IScrollDrag) {
  if (window.screen.width >= parseInt(sizes.notebook, 10)) {
    return (
      <Container
        {...rest}
        id="scroll-drag"
        ref={ref}
        pressed={pressed}
        isScrolling={isScrolling}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseUp}
        onMouseMove={onMouseMove}
        onScroll={handleScroll}
      >
        <ArrowsLeft showArrows={showArrows} data-testid="arrow-left">
          <button
            type="button"
            onClick={(e: any) => {
              const aux = ref.current.scrollLeft - 550;
              ref.current.scrollTo({ left: aux, behavior: 'smooth' });
              setTimeout(() => onMouseUp(e), 500);
            }}
          >
            <RiArrowLeftSLine size={50} color="white" />
          </button>
        </ArrowsLeft>
        <ArrowsRight showArrows={showArrows} data-testid="arrow-right">
          <button
            type="button"
            onClick={(e: any) => {
              const aux = ref.current.scrollLeft + 550;
              ref.current.scrollTo({ left: aux, behavior: 'smooth' });
              setTimeout(() => onMouseUp(e), 500);
            }}
          >
            <RiArrowRightSLine size={50} color="white" />
          </button>
        </ArrowsRight>
        {children}
      </Container>
    );
  }
}
