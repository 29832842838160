import React from 'react';
import { useHistory } from 'react-router-dom';

// Components

import Button from 'components/Button';

// Styles
import { ContainerScreens, Footer } from '../styles';

function WhatDataPopeyesUse() {
  const history = useHistory();

  return (
    <>
      <ContainerScreens>
        <h1>Quais dos meus dados o Popeyes utiliza?</h1>

        <div>
          <p style={{ marginTop: '22px' }}>
            Utilizamos todos os dados preenchidos no seu cadastro.
          </p>
          <p style={{ marginTop: '22px' }}>
            Confira exatamente quais são os dados que utilizamos para melhorar
            sua experiência no App:
          </p>
          <p style={{ marginTop: '22px' }}>
            <ul style={{ marginLeft: '30px' }}>
              <li>E-mail;</li>
              <li>CPF;</li>
              <li>Nome;</li>
              <li>Número de telefone;</li>
              <li>Ano de nascimento;</li>
              <li>Gênero;</li>
            </ul>
          </p>
        </div>

        <Footer>
          <hr style={{ position: 'absolute', width: '100%' }} />
          <p>Para visualizar ou alterar seus dados</p>

          <Button
            variant="secondary"
            onClick={() =>
              history.push({
                pathname: '/enviar-email',
                search: `?${new URLSearchParams({
                  href: `/direitos-e-solicitacoes/o-que-popeyes-utiliza`,
                  isEdit: true,
                  subject: 'Editar informações de cadastro',
                }).toString()}`,
              })
            }
          >
            Editar informações de cadastro
          </Button>
        </Footer>
      </ContainerScreens>
    </>
  );
}

export default WhatDataPopeyesUse;
