// eslint-disable-next-line no-restricted-globals

import { IoIosArrowBack } from 'react-icons/io';

import sizes from 'utils/screenSizes';

import { ButtonLinkCampaign } from '../ButtonLinkCampaign';
import * as T from './interfaces';
import {
  ArrowsContainer,
  ButtonAnimationContainer,
  ButtonContainer,
  ComponentContainer,
  Container,
  List,
  Wrap,
} from './styles';

export function Carousel({
  children,
  Components,
  Buttons,
  handleHomeButtons,
  showArrows,
  showCarrouselButtons,
  alreadyVisited,
  currentActive,
  isFromValidated,
  handleClick,
  handleActive,
  sizeCarousel,
  carouselMobilePositionTop,
  ...rest
}: T.ICarousel) {
  return (
    <Wrap sizeCarousel={sizeCarousel}>
      <Container
        className="carouselContainer"
        sizeCarousel={sizeCarousel}
        data-testid={rest.id}
        carouselMobilePositionTop={carouselMobilePositionTop}
      >
        {children}
        <List
          currentActive={currentActive}
          sizeCarousel={sizeCarousel}
          data-testid="list-components"
        >
          {Components &&
            Components.map(Component => {
              const obj = Object.values(Component);
              const childValues = obj[4].children.key;

              if (childValues === '200') {
                return (
                  <li key={Components.indexOf(Component)}>
                    <ComponentContainer>
                      {Component}
                      <ButtonLinkCampaign link="galinhafritadinha" />
                    </ComponentContainer>
                  </li>
                );
              }
              return (
                <li key={Components.indexOf(Component)}>
                  <ComponentContainer>{Component}</ComponentContainer>
                </li>
              );
            })}
        </List>
        {Components.length > 1 &&
          window.screen.width >= parseInt(sizes.notebook, 10) && (
            <ButtonContainer
              showCarrouselButtons={showCarrouselButtons}
              handleHomeButtons={handleHomeButtons}
              currentActive={currentActive}
              data-testid="button-container"
            >
              {Components &&
                Components.map((object, i) => {
                  return (
                    <button
                      type="button"
                      key={i}
                      onClick={() => {
                        handleClick(i);
                      }}
                      data-testid={`buttonCarousel@${i}`}
                    />
                  );
                })}
            </ButtonContainer>
          )}
      </Container>
      <ArrowsContainer
        sizeCarousel={sizeCarousel}
        showArrows={showArrows}
        data-testid="arrow-container"
      >
        <button
          type="button"
          onClick={() => {
            handleActive(false);
          }}
          data-testid="arrow-left"
        >
          <IoIosArrowBack size={48} color="#D7D7D7" />
        </button>
        <button
          type="button"
          onClick={() => {
            handleActive(true);
          }}
          data-testid="arrow-right"
        >
          <IoIosArrowBack size={48} color="#D7D7D7" />
        </button>
      </ArrowsContainer>
      {Components.length > 1 &&
        window.screen.width <= parseInt(sizes.notebook, 10) && (
          <ButtonContainer
            showCarrouselButtons={showCarrouselButtons}
            currentActive={currentActive}
            data-testid="button-container"
            sizeCarousel={sizeCarousel}
          >
            {Components &&
              Components.map((object, i) => {
                return (
                  <button
                    data-testid={`buttonCarousel${i}`}
                    type="button"
                    key={i}
                    onClick={() => {
                      handleClick(i);
                    }}
                  />
                );
              })}
          </ButtonContainer>
        )}

      {(currentActive === 2 &&
        window.screen.width < parseInt(sizes.notebook, 10)) ||
      (window.screen.width < parseInt(sizes.notebook, 10) && alreadyVisited) ||
      (window.screen.width < parseInt(sizes.notebook, 10) && isFromValidated) ||
      window.screen.width >= parseInt(sizes.notebook, 10) ? (
        <ButtonAnimationContainer
          className="ButtonAnimationContainer"
          sizeCarousel={sizeCarousel}
        >
          {Buttons && <Buttons />}
        </ButtonAnimationContainer>
      ) : (
        ''
      )}
    </Wrap>
  );
}
