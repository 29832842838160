import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${sizes.notebook}) {
    height: 100%;
  }
  width: 100%;
`;

export const Form = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  height: 100%;
  width: 100%;

  .countdown-code {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .container-buttons {
    margin-top: 30px;
  }

  button {
    margin-top: 15px;
  }
  @media (min-width: ${sizes.notebook}) {
    width: 60%;
    justify-content: flex-start;
  }
`;

export const ResendSMS = styled.div`
  text-align: center;
  line-height: 18px;
  font-weight: 400;
  font-size: 18px;
  color: var(--color-burnt-orange);
  cursor: pointer;
  font-family: 'ChickenSansRegular';
  margin: 20px 0 8px 0;
`;

export const ModalContent = styled.div`
  padding: 24px 24px 0px 24px;
  font-family: 'ChickenSansRegular';
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 24px 0px;
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
  }

  h3 {
    margin: 0px 24px;
  }

  a {
    text-decoration: underline;
    color: var(--color-burnt-orange);
    margin-left: 5px;
  }
`;
