import React, { useEffect, useRef, useState, useMemo, RefObject } from 'react';
import { BottomSheetRef } from 'react-spring-bottom-sheet';

import { useModalMobileContext, useStore } from 'hooks';
import { isMobile } from 'utils/isMobile';

import * as T from './interfaces';
import { ModalMobile } from './ModalMobile';
import * as S from './styles';

const snapPointsProps = {
  full: (max: number) => max - max / 10,
  high: (max: number) => max * 0.6,
  middle: (max: number) => max / 2.1,
  halflow: (max: number) => max / 2.44,
  low: (max: number) => max / 4,
};

export const Container: React.FC<T.IModalMobile> = ({
  openHeight,
  maxModalHeight,
  id,
  blocking = true,
  border = 'ease',
  variationModal = 'normal',
  ...rest
}) => {
  const sheetRef = useRef<BottomSheetRef>();

  const focusRef = useRef();
  const { openModal, handleModal } = useStore();
  const [mustShow, setMustShow] = useState(isMobile());
  const { scrollAllTop, handleSetScrollAllTop, handleSetIsDragging } =
    useModalMobileContext();

  useEffect(() => {
    function handleResize() {
      setMustShow(isMobile());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [mustShow]);

  return (
    <ModalMobile
      {...rest}
      open={openModal}
      mustShow={mustShow}
      border={border}
      id={`modalMobile@${id}`}
      data-testid={`modalMobile@${id}`}
      blocking={blocking}
      ref={sheetRef}
      expandOnContentDrag
      onSpringStart={() => {
        handleSetIsDragging(true);
      }}
      onSpringEnd={() => {
        handleSetIsDragging(false);
      }}
      maxHeight={maxModalHeight}
      defaultSnap={({ maxHeight }) => snapPointsProps[openHeight](maxHeight)}
      snapPoints={
        variationModal === 'scrollAlone'
          ? scrollAllTop
            ? ({ maxHeight }) => [maxHeight - 50, maxHeight / 1, maxHeight * 2]
            : ({ minHeight }) => [
                minHeight + 50,
                minHeight / 0.8,
                minHeight * 1.2,
              ]
          : ({ maxHeight }) => [
              maxHeight + 50,
              maxHeight / 2.1,
              maxHeight * 0.6,
            ]
      }
      onClose={() => {
        handleSetScrollAllTop(false);
        handleModal(false);
      }}
    />
  );
};
