import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  /* overflow: auto; */
  background-color: #e58934;
  @media all and (max-width: ${sizes.notebook}) {
    header {
      display: none;
    }
  }
  @media all and (min-width: ${sizes.notebook}) {
    > div {
      > div {
        padding: 0px 0px;
      }
    }
  }
`;
