import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Button from 'components/Button';

import { Container, ContentPolicies } from './styles';

function RegulamentoPromo() {
  const [urlBack, setUrlBack] = useState('');
  const { goBack, push } = useHistory();

  useEffect(() => {
    const params = new URL(window.location).searchParams;

    const href = params.get('href');
    if (href) setUrlBack(href);
  }, []);

  return (
    <Container>
      <ContentPolicies>
        <h2>Regulamento da Promoção: Mini Titãs 24 E 25/05</h2>

        <p>
          <strong>Ação:</strong> Esta ação é realizada por{' '}
          <strong>Popeyes Louisiana Kitchen, Inc. 2024.</strong> Promoção válida
          apenas nos dias 24 e 25 de Maio. Enquanto durarem os estoques.
          Participação: A ação é aberta a residentes do Brasil, maiores de 18
          anos, ou acompanhados por um responsável legal. Período da Ação: A
          ação estará em vigor de durante os dias 24 e 25/05 enquanto durarem os
          estoques. Limitado a 1 (uma) retirada por CPF. Como Participar: Para
          participar, os clientes devem se dirigir a uma das lojas participantes
          listadas abaixo durante o período da ação. Mecânica: Qualquer
          participante que tiver 1,55 de altura ou menos irá ganhar 4 mini filés
          de graça. Limitado a 1 troca por CPF.
          <br />
          <br />
          Lojas Participantes:
          <br />
          <br />
          <b>TODAS AS LOJAS POPEYES DO BRASIL, EXCETO:</b>
        </p>

        <table>
          <tbody>
            <tr>
              <td>
                <strong>Loja</strong>
              </td>
            </tr>
            <tr>
              <td>Popeyes Shopping Avenida</td>
            </tr>
            <tr>
              <td>Popeyes Shopping Limeira</td>
            </tr>
            <tr>
              <td>GK São Paulo - R. Augusta, 1315 - Consolação</td>
            </tr>
            <tr>
              <td>GK Curitiba - Rua Valentin Nichele, 215</td>
            </tr>
            <tr>
              <td>GK Salvador - Avenida cardeal silva, 747 - Federação</td>
            </tr>
            <tr>
              <td>GK Rio de Janeiro - Rua Visconde e Silva, 09</td>
            </tr>
          </tbody>
        </table>
        <h3 style={{ textAlign: 'left' }}>Disposições Gerais</h3>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li>
            A participação nesta promoção constitui a aceitação de todos os
            termos e condições.
          </li>
          <li>
            A Popeyes Louisiana Kitchen, Inc. 2024. reserva-se o direito de
            modificar, suspender ou cancelar a promoção a seu critério.
          </li>
          <li>
            Ao participar desta promoção, os participantes concordam em cumprir
            todas as regras e regulamentos estabelecidos acima.
          </li>
        </ol>

        <div className="button">
          <Button
            variant="primary"
            onClick={() => (urlBack ? push(urlBack) : goBack())}
          >
            Voltar
          </Button>
        </div>
      </ContentPolicies>
    </Container>
  );
}

export default RegulamentoPromo;
