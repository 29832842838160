import React from 'react';
import { useHistory } from 'react-router-dom';

import ErrorPop from 'assets/images/error/burning-pop.svg';
import Button from 'components/Button';

import { Container, Content } from './styles';

const GenericError = () => {
  const { push } = useHistory();

  return (
    <>
      <Container>
        <h2>Parece que algo deu errado :(</h2>

        <Content>
          <img src={ErrorPop} alt="Pop Burning" />
        </Content>

        <p>Mas não esquenta a cabeça, tente novamente.</p>

        <Button onClick={() => push('/')} type="button">
          <p>Tentar novamente</p>
        </Button>
      </Container>
    </>
  );
};

export default GenericError;
