import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import PopeyesName from 'assets/images/carousel/logo-name.png';
import BackWhite from 'assets/images/icons/back-white.svg';
import BackIcon from 'assets/images/icons/back.svg';
import SignOutIcon from 'assets/images/icons/logout.svg';
import Profile from 'containers/Profile';
import { useAuth } from 'hooks/Auth';
import { useHeader } from 'hooks/HeaderContext';
import PropTypes from 'prop-types';
import headerList from 'utils/headerControl';
import sizes from 'utils/screenSizes';

import { useTagManager, ButtonEvents } from 'hooks/Analytics';
import {
  ArrowUp,
  BackButton,
  ButtonContainer,
  ContainerGrid,
  ContainerHeader,
  FakeElementHeader,
  HeaderButton,
  MoreContainer,
  MoreContent,
  Overlay,
  SignOut,
} from './styles';

function HeaderComponent({
  title,
  href,
  onBackClick,
  isSuccess,
  noShadow,
  hideHeaderMobile,
}) {
  const { goBack, push } = useHistory();
  const [moreActive, setMoreActive] = useState(false);
  const [activeLink, setActiveLink] = useState(true);

  const { sendButtonClickEvent } = useTagManager();

  const { user, signOut } = useAuth();
  const { showHeader } = useHeader();
  const backPage = () => {
    if (onBackClick) {
      onBackClick();
    } else if (href) {
      push(href);
    } else {
      goBack();
    }
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    return {
      width,
      height,
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  useEffect(() => {
    function handleResize() {
      const dimensions = getWindowDimensions();
      setWindowDimensions(dimensions);
    }
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  useEffect(() => {
    const params = new URL(window.location).searchParams;

    const delivery = params.get('openDelivery');
    if (delivery) {
      document
        .getElementById('DeliveryComponent')
        .scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const handleMouse = () => {
    if (moreActive) {
      setMoreActive(false);
      setActiveLink(true);
    } else {
      setMoreActive(true);
      setActiveLink(false);
    }
  };

  const checkDelivery = name => {
    const location = window.location.pathname;
    const element = document.querySelector('section#delivery');
    const elementTop = document.getElementById('toScrollTop');

    if (name === 'Delivery' && location === '/' && element) {
      element.scrollIntoView({ behavior: 'smooth' });
      // window.location = 'https://delivery.popeyes.com.br/?utm_source=site-institucional&utm_medium=aba-delivery&utm_campaign=aba-delivery-site-plk';
    }

    if (name === 'Início' && location === '/' && elementTop) {
      elementTop.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return windowDimensions.width >= parseInt(sizes.notebook, 10) ? (
    <>
      <ContainerHeader
        visible={showHeader}
        isSuccess={isSuccess}
        noShadow={noShadow}
      >
        <ContainerGrid>
          <button
            type="button"
            onClick={() => {
              push('/');
            }}
            onKeyDown={() => {
              push('/');
            }}
          >
            <img id="logo" src={PopeyesName} alt="Popeyes" />
          </button>
          <ButtonContainer isLogged={user && user.signed}>
            {headerList.map(header =>
              // header.url.includes(window.location.pathname)
              window.location.pathname.match(header.url) ? (
                <HeaderButton
                  key={header.title}
                  onClick={() => {
                    checkDelivery(header.title);
                    sendButtonClickEvent(
                      ButtonEvents.HEADER_ACTION,
                      header.title.toLowerCase(),
                    );
                    push(header.url[0]);
                  }}
                  isActive={activeLink}
                >
                  {header.title}
                </HeaderButton>
              ) : header.title === 'Mais' ? (
                <HeaderButton
                  key={header.title}
                  onClick={() => {
                    sendButtonClickEvent(
                      ButtonEvents.HEADER_ACTION,
                      header.title.toLowerCase(),
                    );
                    handleMouse();
                  }}
                  isActive={moreActive}
                  isLogged={user && user.signed}
                >
                  {header.title}
                  <MoreContainer moreActive={moreActive}>
                    <ArrowUp />
                    <MoreContent>
                      <Profile />
                    </MoreContent>
                  </MoreContainer>
                </HeaderButton>
              ) : (
                <HeaderButton
                  key={header.title}
                  onClick={() => {
                    checkDelivery(header.title);
                    sendButtonClickEvent(ButtonEvents.HEADER_ACTION, {
                      header: header.title.toLowerCase(),
                      url: header.url[0],
                    });
                    push(header.url[0]);
                  }}
                >
                  {header.title}
                </HeaderButton>
              ),
            )}
            <SignOut
              isLogged={user && user.signed}
              onClick={() => {
                sendButtonClickEvent(ButtonEvents.LOGOUT_ACTION);
                signOut();
              }}
            >
              <img src={SignOutIcon} alt="Sign Out Symbol" />
              <span>Sair </span>
            </SignOut>
          </ButtonContainer>
        </ContainerGrid>
      </ContainerHeader>
      <FakeElementHeader />
      {moreActive && (
        <Overlay
          onClick={() => {
            handleMouse();
          }}
        />
      )}
    </>
  ) : (
    <ContainerHeader isSuccess={isSuccess} hideHeaderMobile={hideHeaderMobile}>
      <BackButton onClick={backPage}>
        <img src={isSuccess ? BackWhite : BackIcon} alt="Voltar" />
      </BackButton>
      <h3>{title}</h3>
    </ContainerHeader>
  );
}

HeaderComponent.propTypes = {
  title: PropTypes.string,
  onBackClick: PropTypes.func,
  href: PropTypes.string,
  isSuccess: PropTypes.bool,
  noShadow: PropTypes.bool,
  hideHeaderMobile: PropTypes.bool,
};

HeaderComponent.defaultProps = {
  href: '/',
  onBackClick: null,
  isSuccess: false,
  noShadow: false,
  hideHeaderMobile: false,
  title: '',
};

export default HeaderComponent;
