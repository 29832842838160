import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  button {
    height: 100%;
    width: 100%;
    border: none;
  }
  img {
    /* height: 100%; */
    width: 100vw;
  }
  button {
    height: 100%;
    width: 100%;
    border: none;
  }
  .banner-main {
    object-fit: contain;
  }
`;
