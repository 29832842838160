import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: auto;
  min-height: 100%;
  width: 100%;
  max-width: var(--size-max-width-container);

  margin: 0 auto;
  padding-top: var(--size-header-height);
  padding-bottom: 40px;

  background-color: white;
  position: relative;

  font-family: 'ChickenSansRegular';

  > .first-group {
    display: flex;
    flex-direction: column;
    margin-top: 7.75px;

    row-gap: 1.25px;
  }

  h1 {
    margin-top: 40px;
    padding: 0px 24px;

    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
  }

  h4 {
    margin-top: 48px;
    padding: 0px 24px;

    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
  }

  button {
    margin-top: 45px;
  }

  > span {
    margin-top: auto;
    padding: 0px 24px;

    color: var(--color-text-switch);
    text-align: center;

    font-family: 'ChickenSansRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 36px;
  padding: 0px 24px;

  font-family: 'ChickenSansRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  div > span {
    margin-top: 8px;

    font-size: 14px;
    line-height: 17px;
    color: var(--color-text-switch);
  }
`;

export const Attention = styled.div`
  display: flex;
  align-items: center;

  margin-top: 40px;
  margin-bottom: 20px;
  padding: 12px 24px;

  background-color: var(--color-background-switch);
  color: var(--color-text-switch);

  font-family: 'ChickenSansRegular';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  img {
    height: 21px;
    width: 21px;
  }

  div {
    margin-left: 12px;
  }
`;

export const SpanText = styled.span`
  cursor: pointer;
  margin: 0px 5px;

  text-decoration: underline !important;
`;
