import React, { useState } from 'react';

import Star from 'assets/images/rating-star.svg';

import { Container } from './styles';

function Rate({ onChange }) {
  const [selected, setSelected] = useState(0);
  const stars = Array(5).fill('');

  return (
    <Container>
      {stars.map((item, index) => (
        <button
          key={index}
          onClick={() => {
            setSelected(index + 1);
            onChange(index + 1);
          }}
        >
          <img
            className={index < selected ? 'selected' : ''}
            src={Star}
            alt="Estrela"
          />
        </button>
      ))}
    </Container>
  );
}

export default Rate;
