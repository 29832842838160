// eslint-disable-next-line import/no-cycle
import { ProductDetails } from './ProductDetails';
import { IProductDetails } from './interfaces';

export const Container: React.FC<IProductDetails> = ({
  containerRef,
  hideBanner,
  ...rest
}) => {
  return (
    <ProductDetails
      containerRef={containerRef}
      hideBanner={hideBanner}
      {...rest}
      data-testid="ProductDetails"
    />
  );
};
