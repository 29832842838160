import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  button {
    padding: 0 8px;
    border: none;
    background: none;
    :focus,
    :focus-visible {
      outline: none;
    }
    img {
      width: auto;
      opacity: 0.3;
      @media (min-width: ${sizes.ipadPro}) {
        height: 65px;
      }
      @media (max-width: ${sizes.ipadPro}) {
        height: 45px;
      }
    }
    img.selected {
      opacity: 1;
    }
  }
`;
