import React, { useEffect, useState } from 'react';

import { menuApi } from 'services';

import { useAuth } from 'hooks';
import { useLocation } from 'context/LocationContext';
import { FuncionamentoRestaurante, IRestaurantModel } from 'types/restaurant';
import { useHistory } from 'react-router-dom';
import {
  ButtonChooseRestaurant,
  FavoriteButton,
  RestaurantDescription,
  RestaurantListItemMainInfo,
  RestaurantListItemWrapper,
  RestaurantStatusTag,
  RestaurantOpenHoursInfo,
  ShowMoreDetailesButton,
  RestaurantOpenHourListItem,
  RestaurantOpenHoursListWrapper,
  TraceRouteButton,
} from './styles';

import heartFilled from '../../assets/images/icons/heart-filled-icon.svg';
import heartNotFilled from '../../assets/images/icons/heart-icon.svg';

import expandArrow from '../../assets/images/icons/expand-more.svg';
import openNewWindowIcon from '../../assets/images/icons/open-new-window.svg';

interface RestaurantListItemProps {
  restaurant: IRestaurantModel;
  onToogleModalLogin: (value: boolean) => void;
}

const RestaurantListItem: React.FC<RestaurantListItemProps> = ({
  restaurant,
  onToogleModalLogin,
  ...props
}) => {
  const { customerId, user } = useAuth();

  const history = useHistory();

  const [isFavorito, setFavorito] = useState(restaurant.favorito);

  const isOpen = isRestaurantOpen(restaurant.funcionamento_restaurante);

  const closingTime = getClosingTime(restaurant.funcionamento_restaurante);

  const { calculateDistance, userLocation } = useLocation();

  const [isExpanded, setIsExpanded] = useState(false);

  const daysOfWeek = [
    'segunda',
    'terca',
    'quarta',
    'quinta',
    'sexta',
    'sabado',
    'domingo',
  ];

  function formatTime(time: any) {
    return time.replace(/^(\d{2}):(\d{2}):(\d{2})$/, '$1:$2');
  }

  // Função para obter o horário de fechamento para o dia atual
  function getClosingTime(
    funcionamento_restaurante: FuncionamentoRestaurante,
  ): string | null {
    const daysOfWeek = [
      'segunda',
      'terca',
      'quarta',
      'quinta',
      'sexta',
      'sabado',
      'domingo',
    ];

    const currentDayOfWeek = new Date().getDay(); // 0 = Domingo, 1 = Segunda-feira, ..., 6 = Sábado
    const currentDay = daysOfWeek[currentDayOfWeek];
    const endKey = `end_${currentDay}`;

    if (funcionamento_restaurante && endKey in funcionamento_restaurante) {
      const closingTime = funcionamento_restaurante[endKey];
      if (closingTime) {
        // Separando a hora dos minutos
        const [hour, minute] = closingTime.split(':');
        return `${hour}:${minute}`;
      }
    }

    return null; // Retorna null se o horário de fechamento não estiver definido para o dia atual
  }

  function isRestaurantOpen(
    funcionamento_restaurante: FuncionamentoRestaurante,
  ): boolean {
    // Get the current day and time
    const currentDate = new Date();
    const currentDay = currentDate.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
    const currentTime = currentDate.getHours() * 60 + currentDate.getMinutes(); // Time in minutes since midnight

    // Get the opening and closing hours for the current day
    const dayOfWeek = [
      'domingo',
      'segunda',
      'terca',
      'quarta',
      'quinta',
      'sexta',
      'sabado',
    ][currentDay];
    const startKey = `start_${dayOfWeek}`;
    const endKey = `end_${dayOfWeek}`;

    if (
      funcionamento_restaurante &&
      startKey in funcionamento_restaurante &&
      endKey in funcionamento_restaurante
    ) {
      const startTime = funcionamento_restaurante[startKey];
      const endTime = funcionamento_restaurante[endKey];

      if (startTime && endTime) {
        const [startHour, startMinute] = startTime.split(':').map(Number);
        const [endHour, endMinute] = endTime.split(':').map(Number);

        const openingTime = startHour * 60 + startMinute;
        const closingTime = endHour * 60 + endMinute;

        return currentTime >= openingTime && currentTime <= closingTime;
      }
    }

    // If opening and closing hours are not defined for the current day, consider the restaurant as closed
    return false;
  }

  function getWeekday(): string {
    const daysOfWeek = [
      'Domingo',
      'Segunda-feira',
      'Terça-feira',
      'Quarta-feira',
      'Quinta-feira',
      'Sexta-feira',
      'Sábado',
    ];
    const currentDayOfWeek = new Date().getDay(); // 0 = Domingo, 1 = Segunda-feira, ..., 6 = Sábado
    return daysOfWeek[currentDayOfWeek];
  }

  async function onFavorite() {
    if (!customerId || user?.fakeUser) {
      onToogleModalLogin(true);
      return;
    }

    try {
      const restaurantsResponse = await menuApi.post(
        `/restaurante/favorito?customer_id=${customerId}&restaurante_id=${restaurant.id}`,
      );

      if (restaurantsResponse.data) {
        restaurant.favorito = !restaurant.favorito;
        setFavorito(restaurant.favorito);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function redirectToGoogleMaps() {
    const userLat = userLocation?.latitude;
    const userLng = userLocation?.longitude;
    const restaurantLat = restaurant?.latitude;
    const restaurantLng = restaurant?.longitude;

    if (restaurantLat && restaurantLng) {
      let url = 'https://www.google.com/maps/dir/?api=1';
      if (userLat && userLng) {
        url += `&origin=${userLat},${userLng}`;
      }
      url += `&destination=${restaurantLat},${restaurantLng}`;
      window.open(url, '_blank');
    }
  }

  return (
    <RestaurantListItemWrapper {...props}>
      <RestaurantListItemMainInfo>
        <FavoriteButton type="button" onClick={onFavorite}>
          <img
            src={isFavorito ? heartFilled : heartNotFilled}
            alt="Favorite Icon"
          />
        </FavoriteButton>
        <img
          width={124}
          height={80}
          src={
            restaurant.imagem[0]?.url ||
            'https://storage.googleapis.com/plk-cms-prd/popeyes_1_04dfaa8266/popeyes_1_04dfaa8266.jpg'
          }
          alt="Foto do restaurante Popeyes"
        />
        <RestaurantDescription>
          <h4>
            {restaurant.name}{' '}
            {userLocation ? (
              <span>
                {calculateDistance(
                  userLocation.latitude,
                  userLocation.longitude,
                  parseFloat(restaurant.latitude),
                  parseFloat(restaurant.longitude),
                )
                  .toFixed(1)
                  .replace('.', ',')
                  .concat(' ')}
                km
              </span>
            ) : (
              ''
            )}
          </h4>
          <p>
            {restaurant.logradouro},{restaurant.numero} - {restaurant.cidade}
          </p>
          <RestaurantStatusTag open={isOpen}>
            {isOpen ? 'ABERTO' : 'FECHADO'}
          </RestaurantStatusTag>
          <span className="opened-day">
            {getWeekday()} -{' '}
            <b>
              Aberto até as{' '}
              {closingTime == null ? '00:00' : closingTime.replace('.', ',')}
            </b>
          </span>
          <ShowMoreDetailesButton
            open={isExpanded}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            Mais detalhes
            <img src={expandArrow} alt="Expand arrow button" />
          </ShowMoreDetailesButton>
        </RestaurantDescription>
        <ButtonChooseRestaurant onClick={() => history.push(`/cupom`)}>
          Ver Cupons
        </ButtonChooseRestaurant>
      </RestaurantListItemMainInfo>
      <RestaurantOpenHoursInfo open={isExpanded}>
        <RestaurantOpenHoursListWrapper>
          <h6>Horários:</h6>
          <ul>
            {daysOfWeek.map(day => (
              <RestaurantOpenHourListItem
                open={
                  [
                    'domingo',
                    'segunda',
                    'terca',
                    'quarta',
                    'quinta',
                    'sexta',
                    'sabado',
                  ][new Date().getDay()] === day
                }
                key={day}
              >
                {day.charAt(0).toUpperCase() + day.slice(1)} -{' '}
                {restaurant.funcionamento_restaurante &&
                restaurant.funcionamento_restaurante[`start_${day}`] &&
                restaurant.funcionamento_restaurante[`end_${day}`] ? (
                  <>
                    {formatTime(
                      restaurant.funcionamento_restaurante[`start_${day}`],
                    )}{' '}
                    -{' '}
                    {formatTime(
                      restaurant.funcionamento_restaurante[`end_${day}`],
                    )}
                  </>
                ) : (
                  <>Fechado</>
                )}
              </RestaurantOpenHourListItem>
            ))}
          </ul>
        </RestaurantOpenHoursListWrapper>
        <TraceRouteButton onClick={redirectToGoogleMaps}>
          Traçar rota para esta loja{' '}
          <img src={openNewWindowIcon} alt="New Window Icon" />
        </TraceRouteButton>
      </RestaurantOpenHoursInfo>
    </RestaurantListItemWrapper>
  );
};

export default RestaurantListItem;
