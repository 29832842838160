import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${sizes.ipadPro}) {
    width: 100%;
    height: 100%;
  }
`;

export const Form = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  .bg-overlay {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #000000;
    z-index: 1;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
  }

  .bg-overlay.show {
    opacity: 20%;
    width: 100%;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
  }

  .registered {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 32px;
    margin-bottom: 44px;

    a {
      text-decoration: none;
      cursor: pointer;

      h3 {
        font-size: 14px;
        color: var(--color-burnt-orange);
      }
    }
  }

  button {
    margin-top: 20px;
  }

  .text-lgpd {
    margin-top: 20px;
    margin-bottom: 40px;
    > p {
      font-size: 14px;
      color: #333333;

      margin-top: 20px;
    }
  }
`;

export const SpanText = styled.span`
  color: #333333;
  cursor: pointer;
  margin: 0px 5px;

  text-decoration: underline !important;
`;
