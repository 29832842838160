import React, { useRef, useEffect, useState } from 'react';

import BackButton from 'components/BackButton';
import FooterComponent from 'components/FooterComponent';
import HeaderComponent from 'components/HeaderComponent';
import { GenericErrorContainer } from 'containers';
import { useHeader } from 'hooks/HeaderContext';

import { Container } from './styles';

const Sucess = () => {
  const containerRef = useRef();
  const { handleRef } = useHeader();

  const [urlBack, setUrlBack] = useState('/');
  const [title, setTitle] = useState('Erro');

  useEffect(() => {
    handleRef(containerRef);

    const params = new URL(window.location).searchParams;
    const href = params.get('href');
    if (href) setUrlBack(href);

    const titleParams = params.get('title');
    if (titleParams) setTitle(titleParams);
  }, []);

  return (
    <Container ref={containerRef}>
      <HeaderComponent title={title} href={urlBack} isSuccess />
      <BackButton isSuccess />
      <GenericErrorContainer />
      <FooterComponent />
    </Container>
  );
};

export default Sucess;
