import styled from 'styled-components';
import { breakpoints } from 'styles';

import { IWrapperStyles } from './interfaces';

export const Wrapper = styled.div<IWrapperStyles>`
  position: relative;
  display: flex;
  flex-direction: row;
  background: #f2f2f2;
  width: 100%;
  align-items: center;
  transition: 1s;

  ${props =>
    props.searchIsActive &&
    `
  width: calc(100% + 68px);
  @media (min-width: ${breakpoints.medium}) {
  width: calc(100% + 75px);
  }
  `}
  button {
    border: none;
    height: 100%;
    width: 0px;
    ${props => props.searchIsActive && `width: 60px;`}
    transition: 1s;
    overflow: hidden;
    background: none;

    &:focus {
      outline: none;
    }
  }
`;

export const PoweredBy = styled.img`
  position: absolute;
  width: 70px;
  right: 15px;
  transform: translateY(5px);
`;
