import React, { useRef, useEffect } from 'react';

import BackButton from 'components/BackButton';
import FooterComponent from 'components/FooterComponent';
import HeaderComponent from 'components/HeaderComponent';
import { MyProfileContainer } from 'containers';
import { useHeader } from 'hooks/HeaderContext';

import { Container } from './styles';

export default function MyProfile() {
  const containerRef = useRef();
  const { handleRef } = useHeader();

  useEffect(() => handleRef(containerRef), []);

  return (
    <Container ref={containerRef}>
      <HeaderComponent title="Minhas Informações" href="/perfil" />
      <BackButton />
      <MyProfileContainer />
      <FooterComponent />
    </Container>
  );
}
