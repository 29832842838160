import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  input {
    width: 40px;
    height: 48px;
    margin: 10px;
    text-align: center;
    outline: none;
    border: none;
    border-radius: 4px;
    background-color: #f2f2f2;
    transition: background-color 0.3s ease;
  }

  input:focus {
    background-color: #e5f7f6;
    transition: background-color 0.2s ease;
  }
`;
