import styled, { css } from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  min-width: 100%;
  max-width: 100%;
  position: relative;
  flex-direction: column;
  margin-top: 6px;
  margin-bottom: 6px;

  input {
    width: 100%;

    border: none;
    border-radius: 4px;
    outline: none;
    padding-left: 24px;
    padding-right: 54px;

    ${({ hasText }) =>
      hasText
        ? css`
            padding-top: 24px;
            padding-bottom: 12px;
          `
        : css`
            padding-top: 18px;
            padding-bottom: 18px;
          `};

    font-size: 16px;
    background-color: ${({ inputIsValid }) =>
      inputIsValid ? 'var(--color-input)' : 'var(--color-input-error)'};
    transition: background-color 0.3s ease;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px
      ${({ inputIsValid }) =>
        inputIsValid ? 'var(--color-input-ok)' : 'var(--color-input-error)'}
      inset !important;
  }

  label {
    position: absolute;
    bottom: 14px;
    left: 24px;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .content-name {
    position: absolute;
    bottom: 5px;
    left: 0px;
    font-size: 16px;
    color: #919191;
    transition: all 0.2s ease;
  }

  input:focus {
    /* background-color: #e5f7f6; */
    background-color: ${({ inputIsValid }) =>
      inputIsValid ? 'var(--color-input-ok)' : 'var(--color-input-error)'};
    transition: background-color 0.2s ease;
  }

  input:focus + .label-name .content-name,
  input:valid + .label-name .content-name {
    font-size: 12px;
    transform: translateY(-100%);
  }

  .icon-test-container {
    position: absolute;
    bottom: 23%;
    right: 20px;
    opacity: 0;
    transition: opacity 0.3s ease;

    img {
      width: 20px;
    }

    .loader {
      position: relative;
      height: 20px;
      width: 20px;
      display: inline-block;
      animation: around 5.4s infinite;
    }

    @keyframes around {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .loader::after,
    .loader::before {
      content: '';
      background: transparent;
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 100%;
      border-width: 2px;
      border-color: var(--color-burnt-orange) var(--color-burnt-orange)
        transparent transparent;
      border-style: solid;
      border-radius: 20px;
      box-sizing: border-box;
      top: 0;
      left: 0;
      animation: around 0.7s ease-in-out infinite;
    }

    .loader::after {
      animation: around 0.7s ease-in-out 0.1s infinite;
      background: transparent;
    }
  }

  .show-icon {
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  input:disabled {
    opacity: 0.3;
    color: black;
  }

  input:disabled + .label-name .content-name {
    opacity: 0.3;
    font-size: 12px;
    transform: translateY(-100%);
  }

  input[type='date'] {
    -webkit-appearance: none;
  }

  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 20px;
    opacity: 0;
    z-index: 999;
  }
`;

export const ErrorMessage = styled.p`
  margin-top: 4px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  font-size: 12px !important;
  color: #ff6666 !important;
  text-align: center !important;
`;
