import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: 1px;

  textarea {
    width: 100%;

    font-family: 'ChickenSansRegular';

    border: 1px solid var(--color-text-switch);
    box-sizing: border-box;
    border-radius: 4px;

    padding: 17px;

    resize: none;
  }
`;
