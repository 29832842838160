import * as yup from 'yup';

const ddds = [
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '21',
  '22',
  '24',
  '27',
  '28',
  '31',
  '32',
  '33',
  '34',
  '35',
  '37',
  '38',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '51',
  '53',
  '54',
  '55',
  '61',
  '62',
  '63',
  '64',
  '65',
  '66',
  '67',
  '68',
  '69',
  '71',
  '73',
  '74',
  '75',
  '77',
  '79',
  '81',
  '82',
  '83',
  '84',
  '85',
  '86',
  '87',
  '88',
  '89',
  '91',
  '92',
  '93',
  '94',
  '95',
  '96',
  '97',
  '98',
  '99',
];

const schema = yup.object().shape({
  ddd: yup
    .string()
    .matches(/\d{2}/g, {
      message: 'Insira um DDD válido',
      excludeEmptyString: true,
    })
    .test('test-ddd', function (value) {
      if (ddds.find(item => item === value)) return value;
      return this.createError({
        message: 'invalid',
        path: 'ddd',
        excludeEmptyString: true,
      });
    }),
  cellNumber: yup
    .string()
    .matches(/(^[9]{1})([0-9]{4})([0-9]{4}$)/g, {
      message: 'Insira um número válido',
      excludeEmptyString: true,
    })
    .matches('((9)|([1-9][1-9]))', {
      message: 'Insira um número válido',
      excludeEmptyString: true,
    }),
  email: yup
    .string()
    .email({ message: 'Este e-mail não é válido', excludeEmptyString: true }),
  name: yup
    .string()
    .min(2, 'Nome muito curto')
    .max(35, 'Nome muito longo')
    .matches(
      /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/,
      'Nome inválido',
    ),
  lastname: yup
    .string()
    .min(2, 'Sobrenome muito curto')
    .max(35, 'Sobrenome muito longo')
    .matches(
      /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/,
      'Sobrenome inválido',
    ),
  birthday: yup
    .string()
    .matches(/(0[1-9]|[12][0-9]|3[01])(\/)(0[1-9]|1[012])(\/)((19|20)\d\d)/, {
      message: 'Insira uma data válida',
      excludeEmptyString: true,
    }),
});

export default schema;
