import styled from 'styled-components';
import { breakpoints } from 'styles';

interface IContainerProps {
  isSticky: boolean;
}

interface IInnerContainer {
  scrollPosition: any;
  isSticky?: boolean;
  IsMenuIconScrollable: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  background: #ffffff;
  width: 100%;
  height: 95px;
  align-items: center;
  justify-content: center;

  ${({ isSticky }) =>
    isSticky &&
    `
    height: 95px;

    top: 88px;
    position: fixed;
    z-index: 6;

    @media (max-width: ${breakpoints.small}) {
      top: 0px !important;
    }
  `};

  @media (max-width: ${breakpoints.small}) {
    height: 88px !important;
  }
`;

export const Box = styled.div`
  display: flex;
  width: 1288px;
  align-items: center;
  justify-content: center;
  gap: 60px;

  @media (max-width: ${breakpoints.xLarge}) {
    gap: 15px;
  }

  @media (max-width: ${breakpoints.small}) {
    width: 100%;
  }
`;

export const InnerContainer = styled.div<IInnerContainer>`
  display: flex;
  width: 1080px;
  height: 95px;
  background: #ffffff;
  display: flex;
  justify-content: ${({ IsMenuIconScrollable }) =>
    IsMenuIconScrollable ? 'left' : 'center'};
  align-items: center;
  gap: 60px;
  transition: all 0.3s ease-in-out 0s;
  overflow: hidden;
  white-space: nowrap;
  transform: ${({ scrollPosition }) => `translateX(-${scrollPosition}px)`},
  transition: transform 0.3s ease;

  ${({ isSticky }) =>
    isSticky &&
    `
    top: 88px;
    position: fixed;
    z-index: 6;
  `};

  @media (max-width: ${breakpoints.small}) {
    height: 80px !important;
    width: 100%;
    top: 0px !important;
    padding: 0 10%;
    overflow: hidden;
    overflow-x: scroll !important;
    gap: 30px;
    justify-content: left;
  }
`;

export const ContainerButtonMenu = styled.div<{
  isSticky: boolean;
  disabled: boolean;
}>`
  position: relative;
  display: flex;
  justify-content: center;

  &::after {
    content: '';
    position: absolute;
    height: 4px;
    bottom: -16%;
    width: 130%;
    background-color: #ff7d00;
    border-radius: 2px 2px 0px 0px;
    border: 0;
    transform: scaleX(0);
    transition: transform 0.5s ease;
  }

  img {
    width: 48px;
    height: 48px;
  }

  p {
    padding-top: 0;
  }

  @media (max-width: ${breakpoints.small}) {
    p {
      padding-top: 0;
    }
  }

  ${({ isSticky }) =>
    isSticky &&
    `
    img {
      width: 48px;
      height: 48px;
    }

    p {
      padding-top: 0;
    }
  `};

  &.active {
    p {
      color: #ff7d00 !important;
    }

    .icon {
      filter: invert(100%) url(#id-of-your-filter);
      opacity: 1;
    }

    &:hover {
      p {
        color: #ff7d00 !important;
      }
    }
  }

  ${({ disabled }) =>
    !disabled &&
    `&:hover {
      p {
        color: #f25600 !important;
      }

      .icon {
        filter: invert(100%) url(#id-of-your-filter);
        opacity: 1;
      }
    }
    `};

  &.active::after {
    transform: scaleX(1);
  }

  @media (max-width: ${breakpoints.small}) {
    p {
      font-size: 14px;
      margin-bottom: 4px;
    }
    svg {
      width: 40px;
      height: 40px;
    }

    &::after {
      height: 4px;
      bottom: -4%;
    }
  }
`;

export const IconButtonMenu = styled.button<{ disabled: boolean }>`
  border: none;
  background: transparent;

  &:focus {
    outline: none;
  }

  .icon {
    opacity: 0.3;
  }

  .icon:hover {
    filter: invert(100%) url(#id-of-your-filter);
    opacity: 1;
  }

  ${({ disabled }) =>
    disabled &&
    `
    cursor: inherit;

    .icon {
      opacity: 0.1;
    }

    p {
      opacity: 0.2;
    }

    .icon:hover {
      filter: grayscale(1);
      opacity: 0.1;
    }
  `};
`;

export const TitleIconButton = styled.p`
  font-family: 'ChickenSansBold' !important;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #757575;
  /* padding-top: 10px; */
`;

export const ButtonScrollMenu = styled.button`
  height: 40px;
  width: 40px;
  background: #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50px;
  outline: none;

  &:focus {
    outline: none;
  }

  &:hover {
    background: #f25600;

    svg {
      color: #ffffff !important;
    }
  }

  @media (max-width: ${breakpoints.medium}) {
    display: none;
  }
`;
