/* eslint-disable @typescript-eslint/no-explicit-any */
import keyCodes from './keyCodes';

export const handleClickOutside: any = (ref: any, frame: any) => {
  const handleClick = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      frame(false);
    }
  };

  document.addEventListener('mousedown', handleClick);
  return () => {
    document.removeEventListener('mousedown', handleClick);
  };
};

export const handleClickOutsideTwoStates: any = (
  ref: any,
  frame: any,
  secondFrame: any,
) => {
  const handleClick = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      frame(false);
      secondFrame(false);
    }
  };

  document.addEventListener('mousedown', handleClick);
  return () => {
    document.removeEventListener('mousedown', handleClick);
  };
};

export const handleClickOutsideAndEsc: any = (ref: any, frame: any) => {
  const handleClick = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      frame(false);
    }
  };

  const handleEscapeKeyPressed = (event: any) => {
    if (event.keyCode === keyCodes.ESC) {
      frame(false);
    }
  };

  document.addEventListener('mousedown', handleClick);
  document.addEventListener('keydown', handleEscapeKeyPressed);
  return () => {
    document.removeEventListener('mousedown', handleClick);
    document.removeEventListener('keydown', handleEscapeKeyPressed);
  };
};
