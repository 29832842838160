import * as yup from 'yup';

const schema = yup.object().shape({
  cpf: yup
    .string()
    .max(14, 'CPF deve ter no máximo 14 dígitos')
    .matches(/(^\d{3}\.?\d{3}\.?\d{3}-?\d{2}$)/, 'CPF Inválido')
    .required('Este campo é obrigatório!'),
});

export default schema;
