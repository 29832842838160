import * as yup from 'yup';

const schema = yup.object().shape({
  name: yup
    .string()
    .min(2, 'Nome muito curto')
    .max(35, 'Nome muito longo')
    .required('Este campo é obrigatório!')
    .matches(/\w+\w/, 'Nome inválido'),
  lastname: yup
    .string()
    .min(2, 'Sobrenome muito curto')
    .max(35, 'Sobrenome muito longo')
    .required('Este campo é obrigatório!')
    .matches(/\w+\w/, 'Sobrenome inválido'),
  email: yup
    .string()
    .email('Este e-mail não é válido')
    .matches(
      /^([.\]+)(_)?([a-z\d_]+)?@([a-z\d-]{3,10})\.([a-z]{2,8})(\.[a-z]{2,8})?$/,
      'Este e-mail não é válido',
    )
    .required('Este campo é obrigatório!'),
  emailConfirmation: yup
    .string()
    .email('Este e-mail não é válido')
    .matches(
      /^([.\]+)(_)?([a-z\d_]+)?@([a-z\d-]{3,10})\.([a-z]{2,8})(\.[a-z]{2,8})?$/,
      'Este e-mail não é válido',
    )
    .required('Este campo é obrigatório!'),
  cpf: yup
    .string()
    .max(14, 'CPF deve ter no máximo 14 dígitos')
    .matches(/(^\d{3}\.?\d{3}\.?\d{3}-?\d{2}$)/, 'CPF Inválido')
    .required('Este campo é obrigatório!'),
  acceptTerms: yup.boolean(),
  emailNotif: yup.boolean(),
  smsNotif: yup.boolean(),
});

export default schema;
