import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: auto;
  min-height: 100%;
  width: 100%;
  max-width: var(--size-max-width-container);

  margin: 0 auto;
  padding-top: var(--size-header-height);
  padding-bottom: 40px;

  background-color: #e5e5e5;
  position: relative;

  @media (min-width: ${sizes.notebook}) {
    max-width: none;
  }

  .first-group {
    display: flex;
    flex-direction: column;
    margin-top: 7.75px;

    row-gap: 1.25px;
    @media (min-width: ${sizes.notebook}) {
      margin-top: 40px;
    }
  }

  .second-group {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 18px;

    row-gap: 1.25px;
    @media (min-width: ${sizes.notebook}) {
      margin-top: 28px;
      margin-bottom: 0px;
    }
  }

  .third-group {
    display: flex;
    flex-direction: column;
    margin-top: 28px;
  }

  .delete-account {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    @media (min-width: ${sizes.notebook}) {
      margin-top: 28px;
    }
  }

  div {
    button {
      font-family: 'ChickenSansRegular';
      color: var(--color-text-dark);
    }
  }
  @media (min-width: ${sizes.notebook}) {
    padding-top: var(--size-header-height);
    padding-bottom: 40px;
    justify-content: start;
    div {
      align-items: center;
      button {
        max-width: var(--size-max-width-container);
      }
    }
  }
`;

export const ItemButton = styled.button`
  outline: none;
  border: none;
  border-radius: 0px;

  width: 100%;
  min-height: 74px;
  background-color: var(--color-salt-white);

  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 21px;

  padding: 26.25px 41.44px;
  text-align: left;
`;

export const ContainerScreens = styled.div`
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  min-height: 100%;

  padding: 0px 24px;
  padding-top: var(--size-header-height);
  padding-bottom: 40px;

  max-width: var(--size-max-width-container);

  font-family: 'ChickenSansRegular';
  font-style: normal;
  font-weight: normal;

  h1 {
    margin-top: 40px;

    font-size: 24px;
    line-height: 29px;
  }

  p {
    margin-top: 3px;
    font-size: 18px;
    line-height: 22px;

    color: var(--color-text-switch);

    &.bold {
      margin-top: 40px;
      font-weight: bold;
    }

    ul li ::marker {
      font-size: 10px;
    }
  }

  form {
    input,
    button,
    textarea {
      margin-top: 12px;
    }

    textarea {
      margin-bottom: 40px;
    }

    p {
      font-size: 20px;
      margin-top: 50px;
    }
  }

  div & .action-buttons {
    display: flex;

    margin-top: 17px;
    margin-bottom: 68px;

    button:first-child {
      margin-right: 10px;
    }

    button:last-child {
      margin-left: 10px;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;

  flex-direction: column;

  font-family: 'ChickenSansRegular';
  font-style: normal;
  font-weight: normal;

  color: var(--color-text-switch);

  text-align: center;
  margin-top: auto;

  > p {
    margin: 22px 0px;

    font-size: 12px;
    line-height: 14px;
  }

  @media (min-width: ${sizes.notebook}) {
    .to-know {
      margin: 22px 0px 11px;
    }
  }
`;

export const ModalContent = styled.div`
  padding: 24px 24px 0px 24px;

  font-family: 'ChickenSansRegular';
  font-style: normal;
  font-weight: normal;

  p {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }

  .text {
    margin: 30px 0px;

    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: rgba(0, 0, 0, 0.4);
  }
`;
