/* eslint-disable jsx-a11y/alt-text */
import { useCallback, useEffect, useRef, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import { useTagManager, ButtonEvents } from 'hooks/Analytics';

import * as S from './styles';

export function Container({
  categories,
  activeId,
  selectedCategory,
  setSelectedCategory,
  isFilterAllCategoriesActive,
  menuBarRef,
  menuContentRef,
  elementsRef,
}: any) {
  const [isSticky, setIsSticky] = useState(false);
  const sticky = 100;
  const IsMenuIconScrollable = categories.length > 8;

  const [scrollPosition, setScrollPosition] = useState(0);
  const menuContainerRef = useRef<HTMLDivElement>(null);

  const { sendButtonClickEvent } = useTagManager();

  const handleScroll = useCallback(() => {
    const { scrollY } = window;

    if (scrollY > sticky) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }

    window.requestAnimationFrame(handleScroll);
  }, [menuContentRef?.current]);

  useEffect(() => {
    handleScroll();

    window.requestAnimationFrame(handleScroll);
  }, [menuContentRef?.current]);

  function scrollToProductSection(id: string) {
    sendButtonClickEvent(ButtonEvents.FILTER_CATEGORY, { category: id });

    if (selectedCategory === '') {
      const divElement = document.getElementById(id);
      if (divElement) {
        const paddingTop = isSticky ? 160 : 280;
        const scrollPosition =
          divElement.getBoundingClientRect().top + window.scrollY - paddingTop;
        window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
      }

      return;
    }

    setSelectedCategory(id);
  }

  const scrollLeft = () => {
    if (menuContainerRef.current) {
      menuContainerRef.current.scrollLeft -= 100;
    }
  };

  const scrollRight = () => {
    if (menuContainerRef.current) {
      menuContainerRef.current.scrollLeft += 100;
    }
  };

  return (
    <S.Container isSticky={isSticky}>
      <S.Box ref={menuBarRef}>
        {categories.length > 8 && (
          <S.ButtonScrollMenu onClick={scrollLeft}>
            <IoIosArrowBack size={24} color="#BDBDBD" />
          </S.ButtonScrollMenu>
        )}
        <S.InnerContainer
          ref={menuContainerRef}
          scrollPosition={scrollPosition}
          IsMenuIconScrollable={IsMenuIconScrollable}
        >
          {categories.length > 0 &&
            categories.map((category: any) => {
              return (
                <S.ContainerButtonMenu
                  key={category.id}
                  className={
                    category.name === activeId ||
                    selectedCategory === category.name
                      ? 'active'
                      : ''
                  }
                  onClick={() => scrollToProductSection(category.name)}
                  isSticky={isSticky}
                  disabled={
                    // !isFilterAllCategoriesActive &&
                    // selectedCategory !== category.name
                    false
                  }
                >
                  <S.IconButtonMenu
                    type="button"
                    disabled={
                      // !isFilterAllCategoriesActive &&
                      // selectedCategory !== category.name
                      false
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      style={{ display: 'none' }}
                    >
                      <defs>
                        <filter id="id-of-your-filter">
                          <feColorMatrix
                            colorInterpolationFilters="sRGB"
                            type="matrix"
                            values="1.00 0   0   0   0
                                0   0.49  0   0   0
                                0   0   0.00  0   0
                                0   0   0   1   0 "
                          />
                        </filter>
                      </defs>
                    </svg>
                    <img className="icon" alt="my icon" src={category.image} />

                    <S.TitleIconButton>{category.name}</S.TitleIconButton>
                  </S.IconButtonMenu>
                </S.ContainerButtonMenu>
              );
            })}
        </S.InnerContainer>
        {categories.length > 8 && (
          <S.ButtonScrollMenu onClick={scrollRight}>
            <IoIosArrowForward size={24} color="#BDBDBD" />
          </S.ButtonScrollMenu>
        )}
      </S.Box>
    </S.Container>
  );
}
