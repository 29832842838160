import PlkPoppyBag from 'assets/images/icons/plk-poppy-bag.svg';
import * as S from './styles';

export function ButtonLinkHero({ btnLinkTo }: any) {
  const onPressBtn = (link: string | any) => {
    window.location = link;
  };

  return (
    <S.ContainerButtonCampaignMobile>
      <S.ButtonCampaignMobile
        type="button"
        id="button-campaign"
        onClick={() => onPressBtn(btnLinkTo)}
      >
        <img src={PlkPoppyBag} alt="Poppy Icon" />
        <p>Peça pelo delivery</p>
      </S.ButtonCampaignMobile>
    </S.ContainerButtonCampaignMobile>
  );
}
