import styled from 'styled-components';

export const StateTitle = styled.h4`
  font-family: 'ChickenSansBold';
  font-size: 18px;
  font-weight: 700;
  color: #bdbdbd;
  text-transform: uppercase;
  margin-bottom: 8px;
`;

export const RestaurantsFavoriteStateListWrapper = styled.div`
  width: 100%;

  margin-top: 19px;

  font-family: 'ChickenSans';
`;

export const RestaurantFavoritesList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
