import React, { useState, useMemo, useCallback } from 'react';

import Star from 'assets/images/rating-star.svg';
import PropTypes from 'prop-types';

import { Container } from './styles';

function Rating({ count, rating, onChange }) {
  const [hoverRating, setHoverRating] = useState(0);

  const handleColor = useCallback(
    index => {
      if (hoverRating >= index) {
        return 'selected';
      }
      if (!hoverRating && rating >= index) {
        return 'selected';
      }

      return '';
    },
    [hoverRating, rating],
  );

  const stars = useMemo(
    () =>
      Array(count)
        .fill(0)
        .map((_, i) => i + 1)
        .map(item => (
          <button
            key={item}
            type="button"
            onClick={() => onChange(item)}
            onMouseEnter={() => setHoverRating(item)}
            onMouseLeave={() => setHoverRating(0)}
          >
            <img className={handleColor(item)} src={Star} alt="Estrela" />
          </button>
        )),
    [count, rating, hoverRating],
  );

  return <Container>{stars}</Container>;
}

Rating.propTypes = {
  count: PropTypes.number,
  rating: PropTypes.number,

  onChange: PropTypes.func.isRequired,
};

Rating.defaultProps = {
  count: 5,
  rating: 0,
};

export default Rating;
