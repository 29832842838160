import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  #map {
    height: 100%;
    width: 100%;
    z-index: 0;
  }
  .leaflet-control-attribution.leaflet-control {
    display: none;
  }
  .leaflet-top.leaflet-left {
    display: none;
  }
  .leaflet-pane {
    z-index: 0;
  }
`;

export const Header = styled.button`
  @media (max-width: ${sizes.notebook}) {
    display: none;
  }
  margin-bottom: -30px;
  z-index: 1;
  height: 30px;
  width: 100%;
  background: var(--color-burnt-orange);
  border-radius: 10px;
  font-family: 'ChickenSansRegular';
  color: var(--color-salt-white);
  font-size: 0.8rem;
  font-weight: 400;
  border: none;

  > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    img {
      height: 25px;
      margin: 0 5% 0 0;
    }
  }
  @media (min-width: ${sizes.desktop}) {
    height: 50px;
    font-size: 1.5rem;
    > div {
      img {
        height: 35px;
      }
    }
  }
`;
