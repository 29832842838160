import { useCallback, useEffect, useRef, useState } from 'react';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';

// import PropTypes from 'prop-types';
import { getWindowDimensions } from 'utils/windowDimensions';

import { ArrowsLeft, ArrowsRight, Container } from './styles';

import * as T from './interfaces';

function ScrollDrag({ children, showArrows = false }: T.IContainer) {
  const ref = useRef(null);
  const [pressed, setPressed] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [pos, setPos] = useState({ top: 0, left: 0, x: 0, y: 0 });

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      const dimensions = getWindowDimensions();
      setWindowDimensions(dimensions);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  useEffect(() => {
    if (ref.current)
      setPos({
        left: ref?.current?.scrollLeft,
        top: ref?.current?.scrollTop,
        x: 0,
        y: 0,
      });
  }, [ref]);

  const onMouseDown = (e: any) => {
    setPressed(true);
    setPos({
      left: ref?.current?.scrollLeft,
      top: ref?.current?.scrollTop,
      x: e.clientX,
      y: e.clientY,
    });
    e.preventDefault();
    e.stopPropagation();
  };

  const onMouseUp = (e: any) => {
    setPressed(false);
    setIsScrolling(false);
    e.preventDefault();
    e.stopPropagation();
  };

  const onMouseMove = (e: any) => {
    if (pressed) {
      // How far the mouse has been moved
      const dx = e.clientX - pos.x;
      // const dy = e.clientY - pos.y;

      // Scroll the element
      // ref.current.scrollTop = pos.top;
      ref.current.scrollLeft = pos.left - dx;
    }

    e.preventDefault();
    e.stopPropagation();
  };

  const handleScroll = useCallback(
    (e: any) => {
      if (!isScrolling) setIsScrolling(true);
      e.stopPropagation();
    },
    [isScrolling, setIsScrolling],
  );

  return (
    <Container
      id="scroll-drag"
      ref={ref}
      pressed={pressed}
      isScrolling={isScrolling}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseUp}
      onMouseMove={onMouseMove}
      onScroll={handleScroll}
    >
      <ArrowsLeft showArrows={showArrows}>
        <button
          type="button"
          onClick={e => {
            const aux = ref.current.scrollLeft - 550;
            ref.current.scrollTo({ left: aux, behavior: 'smooth' });
            setTimeout(() => onMouseUp(e), 500);
          }}
        >
          <RiArrowLeftSLine size={50} color="white" />
        </button>
      </ArrowsLeft>
      <ArrowsRight showArrows={showArrows}>
        <button
          type="button"
          onClick={e => {
            const aux = ref.current.scrollLeft + 550;
            ref.current.scrollTo({ left: aux, behavior: 'smooth' });
            setTimeout(() => onMouseUp(e), 500);
          }}
        >
          <RiArrowRightSLine size={50} color="white" />
        </button>
      </ArrowsRight>
      {children}
    </Container>
  );
}

export default ScrollDrag;
