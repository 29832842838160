import { useEffect, useState } from 'react';

import RestaurantListItem from 'components/RestaurantListItem';

import type { IRestaurantModel } from 'types/restaurant';

import {
  RestaurantStateHeader,
  RestaurantStateWrapper,
  RestaurantStateName,
  RestaurantListInternalWrapper,
} from './styles';

import arrowUp from '../../assets/images/icons/arrowUp.svg';
import arrowDown from '../../assets/images/icons/arrowDown.svg';

interface IRestaurantStateProps {
  state: {
    id: number;
    state: string;
  };
  restaurants: IRestaurantModel[];
  opened?: boolean;
  onToogleModalLogin: (value: boolean) => void;
}

export default function RestaurantState({
  state,
  restaurants,
  opened,
  onToogleModalLogin,
}: IRestaurantStateProps) {
  const [expanded, setExapnded] = useState(opened);

  function expandAccordion(event: React.MouseEvent<HTMLDivElement>) {
    const target = event.target as HTMLElement;
    console.log(event);
    if (
      target.id === 'restaurant-state-header' ||
      target.id === 'restaurant-state-arrow' ||
      target.id === 'restaurant-state-name' ||
      target.id === 'restaurant-state-size'
    )
      setExapnded(!expanded);
  }

  useEffect(() => {
    if (opened) setExapnded(true);
  }, []);

  return (
    <RestaurantStateWrapper onClick={expandAccordion}>
      <RestaurantStateHeader id="restaurant-state-header">
        <RestaurantStateName>
          <h1 id="restaurant-state-name">
            {state.state}{' '}
            <span id="restaurant-state-size">({restaurants.length})</span>
          </h1>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          {/*
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${restaurant.latitude},${restaurant.longitude}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Ver no mapa
          </a>
          */}
        </RestaurantStateName>
        <img
          id="restaurant-state-arrow"
          src={expanded ? arrowDown : arrowUp}
          alt="Arrow Up"
        />
      </RestaurantStateHeader>
      <RestaurantListInternalWrapper open={expanded}>
        {restaurants.map(restaurant => (
          <RestaurantListItem
            key={restaurant.id}
            onToogleModalLogin={onToogleModalLogin}
            restaurant={restaurant}
          />
        ))}
      </RestaurantListInternalWrapper>
    </RestaurantStateWrapper>
  );
}
