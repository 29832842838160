import LoadingPop from 'pages/Loading';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { menuApi } from 'services';
// eslint-disable-next-line import/no-cycle
import CloseIconGrey from 'assets/images/icons/close-grey.svg';
import HamburgerIconGrey from 'assets/images/icons/hamburguer-icon-grey.svg';
import { breakpoints } from 'styles/themes';
import * as T from './interfaces';

// import SliderCarousel from '../SliderCarousel';
import SliderCarousel from '../SliderCarousel';
import {
  Container,
  Header,
  HeaderContent,
  MenuBars,
  MenuButton,
  MenuPageTitle,
  PageTitle,
} from './styles';

type Banner = {
  id: number;
  url: string;
  banner_image_mobile: {
    data: {
      id: number;
      url: string;
      attributes: {
        name: string;
        alternativeText: string | null;
        caption: string | null;
        width: number;
        height: number;
        formats: {
          thumbnail: {
            name: string;
            hash: string;
            ext: string;
            mime: string;
            path: string | null;
            width: number;
            height: number;
            size: number;
            url: string;
          };
        };
        hash: string;
        ext: string;
        mime: string;
        size: number;
        url: string;
        previewUrl: string | null;
        provider: string;
        provider_metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
    };
  };
  banner_image_desktop: {
    data: {
      id: number;
      url: string;
      attributes: {
        name: string;
        alternativeText: string | null;
        caption: string | null;
        width: number;
        height: number;
        formats: {
          thumbnail: {
            name: string;
            hash: string;
            ext: string;
            mime: string;
            path: string | null;
            width: number;
            height: number;
            size: number;
            url: string;
          };
        };
        hash: string;
        ext: string;
        mime: string;
        size: number;
        url: string;
        previewUrl: string | null;
        provider: string;
        provider_metadata: string | null;
        createdAt: string;
        updatedAt: string;
      };
    };
  };
};

export const HeaderMenu: React.FC<T.IHeaderMenu> = ({
  id,
  numberOfPagesCarousel,
  openCategories,
  hideBanner,
  hideBackButton,
  backgroundColorBackButton,
  hideFilters,
  handleClickSearchButtonMobile,
  handleClickOnFilter,
  isSearchButtonClick,
  onPressAnyCategoryFilter,
  storeSelected,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);
  const [banners, setBanners] = useState([]);
  const [timer, setTimer] = useState(1000);
  const [isMobile, setIsMobile] = useState(false);
  const [dataBanners, setDataBanners] = useState([]);
  const [sliderTimeDuration, setSliderTimeDuration] = useState(6000);

  const { push } = useHistory();

  // useEffect(() => {
  //   const fetchBanners = async () => {
  //     try {
  //       setLoading(true);

  //       const response = await menuApi.get('/slider-home-cardapios', {
  //         params: {
  //           populate:
  //             'home_cardapio_banner.banner_image_desktop,home_cardapio_banner.banner_image_mobile',
  //         },
  //       });

  //       const bannersData = response.data.data;

  //       // If timer is null, default 5 secs
  //       setTimer(
  //         bannersData[0].attributes.timer
  //           ? bannersData[0].attributes.timer * 1000
  //           : 5000,
  //       );

  //       bannersData[0].attributes.home_cardapio_banner.forEach(
  //         (banner: Banner) => {
  //           banners.push(
  //             CarouselContent({
  //               imageUrl: banner.banner_image_desktop.data.attributes.url,
  //               bannerId: banner.id,
  //               link: banner.url,
  //             }),
  //           );
  //         },
  //       );

  //       setLoading(false);
  //     } catch (error) {
  //       console.error('Erro ao obter os banners:', error);
  //       setLoading(false);
  //     }
  //   };

  //   fetchBanners();
  // }, []);

  const checkIfIsMobile = useCallback(() => {
    const { innerWidth: width } = window;
    if (width < parseInt(breakpoints.large, 10)) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    checkIfIsMobile();
  });

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const bannersArr: any = [];
        const response = await menuApi.get(
          '/slider-home-cardapios?populate=home_cardapio_banner.banner_image_desktop',
        );

        const bannersData = response.data;
        const results = bannersData.data;

        const resultsFilterd: any = results.filter(
          (res: any) => res.attributes.device === 'SITE',
        );

        const getAttributes = resultsFilterd[0].attributes.home_cardapio_banner;

        getAttributes.map((bannerEl: any) => {
          const arrEl = bannerEl.banner_image_desktop.data.attributes;
          const dataUrl = arrEl.url;
          return bannersArr.push({ imageUrl: dataUrl });
        });

        const { timer } = resultsFilterd[0].attributes;
        const sliderDuration = timer ? timer * 1000 : 6000;
        setSliderTimeDuration(sliderDuration);

        setDataBanners(bannersArr);
      } catch (error) {
        console.error('Erro ao obter banners:', error);
      }
    };

    fetchBanners();
  }, []);

  const CarouselMenuSettings = {
    dots: true,
    fade: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: sliderTimeDuration,
    infinite: true,
    pauseOnHover: false,
  };

  return (
    <>
      {!isMobile && (
        <Container {...rest} data-testid={`HeaderMenu@${id}`}>
          {loading && <LoadingPop />}
          {!loading && (
            <SliderCarousel
              settings={CarouselMenuSettings}
              dataBanners={dataBanners}
            />
          )}
        </Container>
      )}
      {isMobile && (
        <HeaderContent>
          <Header>
            <MenuBars>
              <MenuButton onClick={() => push('perfil?href=%2Fcardapio')}>
                <img src={HamburgerIconGrey} alt="Botão Hamburguer" />
              </MenuButton>
            </MenuBars>
            <MenuPageTitle>Cardápio</MenuPageTitle>
            <MenuButton onClick={() => push('/')}>
              <img src={CloseIconGrey} alt="Voltar à home" />
            </MenuButton>
          </Header>
          <PageTitle hide={hideBanner}>Cardápio</PageTitle>
        </HeaderContent>
      )}
    </>
  );
};
