import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import axios from 'axios';

import { ICardapioContextData, ICategoryCardapio } from './interfaces';

const CardapioContext = createContext<ICardapioContextData>(
  {} as ICardapioContextData,
);

type CardapioContextProviderProps = {
  children: React.ReactNode;
};

function CardapioContextProvider({ children }: CardapioContextProviderProps) {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<ICategoryCardapio[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>();
  const [selectedStore, setselectedStore] = useState(() => {
    const storeStorage = localStorage.getItem('@Popeyes.storeSelected');
    const storeData = JSON.parse(storeStorage);
    return storeData;
  });

  const loadCardapio = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await axios.get(
        'https://plk-microservice-store-cardapio-ummgmx7irq-ue.a.run.app/category',
      );

      setCategory(data);
      setSelectedCategoryId(data[0].id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, []);

  const loadCardapioFiltered = useCallback(async (idStore: number) => {
    setLoading(true);

    const idStoreString = `00${idStore}`;

    try {
      const { data } = await axios.get(
        `https://plk-microservice-store-cardapio-ummgmx7irq-ue.a.run.app/category/bk_number/${idStoreString}`,
      );

      setCategory(data);
      setSelectedCategoryId(data[0].id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, []);

  const handleSelectCategory = (idSelected: number) => {
    const categoryItem = category.find(category => category.id === idSelected);
    setSelectedCategoryId(categoryItem.id);
  };

  const setPositionCategory = () => {
    // chamar a função no onClick do botão q redireciona para cardapio
    const params = new URL(window.location as any).searchParams;
    const storeId = Number(params.get('loja'));

    let acumulatorPositionScroll = 200;

    if (storeId) acumulatorPositionScroll = 170;

    const newCategories: ICategoryCardapio[] = [];
    category.forEach(categoryItem => {
      categoryItem.positionCategoryContainer = acumulatorPositionScroll;

      newCategories.push(categoryItem);
      acumulatorPositionScroll += document.getElementById(
        categoryItem.name,
      ).clientHeight;
    });

    setCategory(newCategories);
  };

  const setStoreSelected = async (idStore: number, nameStore: string) => {
    setLoading(true);
    const store = {
      id: idStore,
      name: nameStore,
    };

    setselectedStore(store);
    localStorage.setItem('@Popeyes.storeSelected', JSON.stringify(store));

    await loadCardapioFiltered(idStore);

    setLoading(false);
  };

  const findStoreById = async (storeId: number) => {
    setLoading(true);

    try {
      const { data } = await axios.get(
        `https://plk-microservice-store-ummgmx7irq-ue.a.run.app/store/${storeId}`,
      );

      await setStoreSelected(data.id, data.name);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedStore) {
      loadCardapioFiltered(selectedStore.id);
    } else {
      loadCardapio();
    }
  }, []);

  return (
    <CardapioContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        category,
        loading,
        selectedStore,
        handleSelectCategory,
        setStoreSelected,
        setPositionCategory,
        loadCardapio,
        selectedCategoryId,
        findStoreById,
      }}
    >
      {children}
    </CardapioContext.Provider>
  );
}

function useCardapioContext(): ICardapioContextData {
  const context = useContext(CardapioContext);

  if (!context)
    throw new Error(
      'useCardapioContext must be used with CardapioContextProvider',
    );

  return context;
}

export { CardapioContextProvider, useCardapioContext };
