import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Button from 'components/Button';
import ModalResponsive from 'components/ModalResponsive';

// Hooks, Services and Utils
import { useAuth } from 'hooks/Auth';
import PropTypes from 'prop-types';
import firebase from 'services/firebase';
import emailSecretMask from 'utils/emailSecretMask';

// Styles
import { ModalContent } from '../../styles';

const ModalEndCountdown = ({
  open,
  onClose,
  isRegister,
  isEmail,
  urlBack,
  sendNewSms,
}) => {
  const { user } = useAuth();
  const history = useHistory();

  const subTitle = useMemo(() => {
    return (
      <>
        Você pode tentar novamente enviando o código para o e-mail
        <strong> {emailSecretMask(user.email)} </strong>
        {isRegister && isEmail
          ? 'ou edite o seu e-mail tocando no botão abaixo'
          : 'ou reenviando o SMS'}
      </>
    );
  }, [isEmail, isRegister, user]);

  return (
    <>
      <ModalResponsive
        open={open}
        onClose={onClose}
        maxModalHeight={isEmail ? 450 : 430}
        sizeModal="middle"
      >
        <ModalContent>
          <h3 className="title">
            Parece que você não recebeu o código de verificação
          </h3>
          <p>{subTitle}</p>

          <Button
            type="button"
            onClick={() =>
              isRegister && isEmail
                ? history.push(urlBack)
                : sendNewSms({ sendEmail: true })
            }
          >
            {isRegister && isEmail
              ? 'Editar e-mail'
              : 'Enviar código por e-mail'}
          </Button>
          <Button
            type="button"
            size="S"
            variant="secondary"
            onClick={() =>
              isRegister && isEmail
                ? sendNewSms({ sendEmail: true })
                : sendNewSms({ sendEmail: false })
            }
          >
            {isRegister && isEmail
              ? 'Reenviar código por e-mail'
              : 'Reenviar SMS'}
          </Button>

          {isEmail && (
            <Button
              type="button"
              size="S"
              variant="secondary"
              onClick={() => {
                firebase.analytics().logEvent('ajudarecuperacao');
                history.push({
                  pathname: '/enviar-email',
                  search: `?${new URLSearchParams({
                    href: `/verificar?openModalFinish=true&isEmail=${isEmail}&href=${urlBack}`,
                    title: 'Fale conosco',
                    isHelp: true,
                    subject: 'Preciso de ajuda',
                  }).toString()}`,
                });
              }}
            >
              Preciso de ajuda
            </Button>
          )}
        </ModalContent>
      </ModalResponsive>
    </>
  );
};

ModalEndCountdown.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isRegister: PropTypes.bool.isRequired,
  isEmail: PropTypes.bool.isRequired,
  urlBack: PropTypes.string.isRequired,
  sendNewSms: PropTypes.func.isRequired,
};

export default ModalEndCountdown;
