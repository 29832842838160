export default function IconInstagram() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // eslint-disable-next-line max-len
        d="M19.9998 14.0492C16.723 14.0492 14.0489 16.7233 14.0489 20.0001C14.0489 23.2769 16.723 25.951 19.9998 25.951C23.2766 25.951 25.9507 23.2769 25.9507 20.0001C25.9507 16.7233 23.2766 14.0492 19.9998 14.0492ZM37.848 20.0001C37.848 17.5358 37.8703 15.0939 37.7319 12.634C37.5935 9.77689 36.9418 7.24118 34.8525 5.15189C32.7587 3.05814 30.2275 2.41082 27.3703 2.27243C24.906 2.13404 22.4641 2.15636 20.0043 2.15636C17.54 2.15636 15.098 2.13404 12.6382 2.27243C9.78104 2.41082 7.24533 3.06261 5.15604 5.15189C3.06229 7.24564 2.41497 9.77689 2.27658 12.634C2.13819 15.0983 2.16051 17.5403 2.16051 20.0001C2.16051 22.4599 2.13819 24.9064 2.27658 27.3662C2.41497 30.2233 3.06676 32.759 5.15604 34.8483C7.24979 36.9421 9.78104 37.5894 12.6382 37.7278C15.1025 37.8662 17.5444 37.8439 20.0043 37.8439C22.4685 37.8439 24.9105 37.8662 27.3703 37.7278C30.2275 37.5894 32.7632 36.9376 34.8525 34.8483C36.9462 32.7546 37.5935 30.2233 37.7319 27.3662C37.8748 24.9064 37.848 22.4644 37.848 20.0001ZM19.9998 29.1564C14.9328 29.1564 10.8435 25.0671 10.8435 20.0001C10.8435 14.9331 14.9328 10.8439 19.9998 10.8439C25.0668 10.8439 29.156 14.9331 29.156 20.0001C29.156 25.0671 25.0668 29.1564 19.9998 29.1564ZM29.531 12.6073C28.348 12.6073 27.3927 11.6519 27.3927 10.4689C27.3927 9.28582 28.348 8.33046 29.531 8.33046C30.7141 8.33046 31.6694 9.28582 31.6694 10.4689C31.6698 10.7498 31.6147 11.028 31.5074 11.2876C31.4 11.5472 31.2425 11.7831 31.0439 11.9817C30.8453 12.1804 30.6094 12.3379 30.3498 12.4452C30.0902 12.5525 29.812 12.6076 29.531 12.6073Z"
        fill="white"
      />
    </svg>
  );
}
