import { useEffect } from 'react';

import { BrowserRouter, Redirect, Switch } from 'react-router-dom';

import WhatDataPopeyesUse from 'pages/RightsAndRequests/WhatDataPopeyesUse';

import StoreAddreess from 'pages/StoreAddress';
import RegulamentoPromoPage from 'pages/RegulamentoPromoPage';
import { isAfter } from 'date-fns';
import SignInNumber from '../pages/Auth/SignInNumber';
import Coupon from '../pages/Coupon';
import FaqPage from '../pages/Faq';
import FaqQuestionPage from '../pages/Faq/Question';
import Galinhafritadinha from '../pages/Galinhafritadinha';
import GenericError from '../pages/GenericError';
import Home from '../pages/Home';
import Menu from '../pages/Menu';
import MenuRestaurant from '../pages/MenuRestaurant';
import MyProfile from '../pages/MyProfile';
import MyProfileVerify from '../pages/MyProfile/Verify';
import Onboarding from '../pages/Onboarding';
import PermissionsAndPrivacy from '../pages/PermissionsAndPrivacy';
import PrivacyPoliciesPage from '../pages/PrivacyPolicies';
import Product from '../pages/Product';
import Profile from '../pages/Profile';
import SetNumber from '../pages/Recover/SetNumber';
import Validated from '../pages/Recover/Validated';
import Verification from '../pages/Recover/Verification';
import VerifyToken from '../pages/Recover/VerifyToken';
import SignUp from '../pages/Register';
import RightsAndRequests from '../pages/RightsAndRequests';
import MyDataWasUsed from '../pages/RightsAndRequests/MyDataWasUsed';
import WhatDoWithMyData from '../pages/RightsAndRequests/WhatDoWithMyData';
import SendEmail from '../pages/SendEmail';
import SmsVerify from '../pages/SmsVerify';
import SuccessPage from '../pages/Sucess';
import RecoveredNumber from '../pages/Sucess/RecoveredNumber';
import TalkToUs from '../pages/TalkToUs';
import UseTermPage from '../pages/UseTerms';
import ValidateNumber from '../pages/ValidateNumber';
import Route from './Route';

import cardapioA from '../assets/files/Popeyes_Cardapio_Lojista_Tipo_A.pdf';
import cardapioB from '../assets/files/Popeyes_Cardapio_Lojista_Tipo_B.pdf';
import cardapioC from '../assets/files/Popeyes_Cardapio_Lojista_Tipo_C.pdf';

function RedirectToPdf({ pdfUrl }) {
  useEffect(() => {
    window.location.href = pdfUrl;
  }, [pdfUrl]);

  return null;
}

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact title="Home" path="/">
        <Home />
      </Route>
      <Route exact title="Cupom" path="/cupom">
        <Coupon />
      </Route>
      <Route
        exact
        title="Onboarding"
        path="/onboarding"
        component={Onboarding}
      />
      <Route exact title="Perfil" path="/perfil" component={Profile} />
      <Route
        exact
        title="Direitos e Solicitacoes"
        path="/direitos-e-solicitacoes"
        component={RightsAndRequests}
      />
      <Route
        exact
        title="Uso de dados"
        path="/direitos-e-solicitacoes/uso-de-dados"
        component={WhatDoWithMyData}
      />
      <Route
        exact
        title="Uso do CPF"
        path="/direitos-e-solicitacoes/uso-do-cpf"
        component={MyDataWasUsed}
      />
      <Route
        exact
        title="O que Popeyes utiliza"
        path="/direitos-e-solicitacoes/o-que-popeyes-utiliza"
        component={WhatDataPopeyesUse}
      />
      <Route
        exact
        title="Permissoes e Privacidade"
        path="/permissoes-e-privacidade"
        component={PermissionsAndPrivacy}
      />
      <Route exact title="Log in" path="/login" component={SignInNumber} />
      <Route exact title="Registro" path="/registro" component={SignUp} />
      <Route exact title="Verificar" path="/verificar" component={SmsVerify} />
      <Route
        exact
        title="Restaurantes"
        path="/restaurantes"
        component={StoreAddreess}
      />
      <Route
        exact
        title="Validar número"
        path="/validar-numero"
        component={ValidateNumber}
      />
      <Route exact title="Validado" path="/validado" component={Validated} />
      <Route
        exact
        title="Verificar Recuperação"
        path="/verificar-recuperacao"
        component={Verification}
      />
      <Route
        exact
        title="Definir número recuperacão"
        path="/definir-numero-recuperacao"
        component={SetNumber}
      />
      <Route
        exact
        title="Número cadastrado"
        path="/numero-cadastrado"
        component={RecoveredNumber}
      />
      <Route
        exact
        title="Token de recuperação"
        path="/token-recuperacao"
        component={VerifyToken}
      />
      <Route
        exact
        title="Termos de uso"
        path="/termos-de-uso"
        component={UseTermPage}
      />
      <Route
        exact
        title="Politica de Privacidades"
        path="/politica-de-privacidades"
        component={PrivacyPoliciesPage}
      />
      <Route
        exact
        title="Regulamento Promoção - Mini Titãs 24 E 25/05"
        path="/regulamento-acao-mini-titas"
        component={RegulamentoPromoPage}
      />
      {/*
      {isAfter(currentDate, targetDate) ? (

      ) : (
        <Redirect from="/regulamento-acao-mini-titas" to="/" />
      )}
      */}
      <Route exact path="/cardapio-1">
        <RedirectToPdf pdfUrl={cardapioA} />
      </Route>
      <Route exact path="/cardapio-2">
        <RedirectToPdf pdfUrl={cardapioB} />
      </Route>
      <Route exact path="/cardapio-3">
        <RedirectToPdf pdfUrl={cardapioC} />
      </Route>
      <Route
        exact
        title="Enviar e-mail"
        path="/enviar-email"
        component={SendEmail}
      />
      <Route exact title="Sucesso" path="/sucesso" component={SuccessPage} />

      <Route
        exact
        title="Meu perfil"
        path="/meu-perfil"
        component={MyProfile}
        isPrivate
      />
      <Route
        exact
        title="Verificar Perfil"
        path="/meu-perfil/verificacao"
        component={MyProfileVerify}
        isPrivate
      />
      <Route exact path="/faq" component={FaqPage} />
      <Route exact path="/faq/:id" component={FaqQuestionPage} />
      <Route exact path="/fale-conosco" component={TalkToUs} isPrivate />
      <Route exact path="/erro" component={GenericError} />
      <Route exact path="/menu" title="Menu" component={Menu} />
      <Route
        exact
        path="/cardapio"
        title="Cardapio"
        component={MenuRestaurant}
      />
      <Route path="/cardapio/:productId" component={Product} />
      <Route
        exact
        path="/galinhafritadinha"
        title="Galinha Fritadinha"
        component={Galinhafritadinha}
      />
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  </BrowserRouter>
);

export default Routes;
