import styled from 'styled-components';
import sizes from 'utils/screenSizes';

export const Container = styled.div`
  min-height: 100%;
  width: 100%;

  @media (min-width: ${sizes.notebook}) {
    padding: 30px 0px;

    ${props =>
      props.background && `background-image: url(${props.background});`}

    max-width: 100vw;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-size: contain;
  }

  @media (min-width: ${sizes.desktop}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Content = styled.div`
  padding: 0px 24px 24px 24px;

  @media (max-width: ${sizes.notebook}) {
    position: relative;

    display: flex;
    flex-direction: column;

    margin: 0 auto;

    width: 100%;
    height: 95vh;
    max-width: var(--size-max-width-container);

    background-color: var(--color-salt-white);
    padding-top: var(--size-header-height);
  }

  @media (min-width: ${sizes.notebook}) {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0 auto;

    min-height: 600px;
    width: 35%;

    background: var(--color-salt-white);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 12%);
    border-radius: 16px;
  }

  @media (min-width: ${sizes.desktop}) {
    min-height: 800px;
    justify-content: center;
  }

  ::-webkit-scrollbar {
    width: 0.6rem;
  }

  ::-webkit-scrollbar-track {
    background: #f3f3f3;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #bdc3c7;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #95a5a6;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:active {
    background: #7f8c8d;
    border-radius: 10px;
  }
`;
