import React, { useEffect, useRef, useMemo } from 'react';

import BackButton from 'components/BackButton';
import FooterComponent from 'components/FooterComponent';
import HeaderComponent from 'components/HeaderComponent';
import { SignUpContainer } from 'containers';
import { useHeader } from 'hooks/HeaderContext';
import { AuthLayout } from 'layouts';

import { Container } from './styles';

function SignInNumber() {
  const { handleRef, showHeader } = useHeader();
  const containerRef = useRef();

  const isFacebookFlow = useMemo(() => {
    const params = new URL(window.location).searchParams;
    const facebookUser = params.get('facebookUser');
    return !!(facebookUser && JSON.parse(facebookUser));
  }, []);

  useEffect(() => {
    handleRef(containerRef);
  }, []);

  return (
    <Container ref={containerRef}>
      <HeaderComponent
        title="Celular"
        href={isFacebookFlow ? '/onboarding' : '/login'}
        visible={showHeader}
      />
      <BackButton />
      <AuthLayout>
        <SignUpContainer />
      </AuthLayout>
      <FooterComponent />
    </Container>
  );
}

export default SignInNumber;
