import { ApolloClient, InMemoryCache } from '@apollo/client';
import { HttpLink } from 'apollo-link-http';
import axios from 'axios';

export const api = axios.create({
  baseURL:
    process.env.REACT_APP_BASE_API_URL ||
    process.env.STORYBOOK_APP_BASE_API_URL,
  crossDomain: true,
  timeout: 30000,
  headers: { channel: 'PWA_POPEEYES' },
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_BASE_GRAPHQL_URL,
  headers: {
    'x-api-key': process.env.REACT_APP_BASE_GRAPHQL_APIKEY,
  },
});

export const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

export const menuApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_CMS_API_URL,
  crossDomain: true,
  timeout: 30000,
});

// Obtenha o token de autenticação gerado no strapi
// const token = process.env.REACT_APP_BASE_MENU_TOKEN;

// Adicione o cabeçalho de autorização à instância do menuApi
// menuApi.defaults.headers.common.Authorization = `Bearer ${token}`;
