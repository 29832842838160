import React, { useRef, useEffect } from 'react';

import BackButton from 'components/BackButton';
import FooterComponent from 'components/FooterComponent';
import HeaderComponent from 'components/HeaderComponent';
import { RecoveredNumberContainer } from 'containers';
import { useHeader } from 'hooks/HeaderContext';

import { Container } from './styles';

const RecoveredNumber = () => {
  const containerRef = useRef();
  const { handleRef } = useHeader();

  useEffect(() => {
    handleRef(containerRef);
  }, []);

  return (
    <Container ref={containerRef}>
      <HeaderComponent
        title="Verificação"
        href="/definir-numero-recuperacao"
        isSuccess
      />
      <BackButton isSuccess />

      <RecoveredNumberContainer />
      <FooterComponent />
    </Container>
  );
};

export default RecoveredNumber;
