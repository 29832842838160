import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import FramePop from 'assets/images/sucess/FramePop.png';
import Button from 'components/Button';
import TitleSection from 'components/TitleSection';

import { Container, Content } from './styles';

const SucessContainer = () => {
  const [showButton, setShowButton] = useState(false);
  const [buttonTitle, setButtonTitle] = useState('Entrar');
  const [subTitle, setSubTitle] = useState('');
  const [lastSubtitle, setLastSubtitle] = useState('');
  const [textBold, setTextBold] = useState('');
  const [isMessage, setIsMessage] = useState(false);
  const [urlButton, setUrlButton] = useState('/');

  const { push } = useHistory();

  useEffect(() => {
    const params = new URL(window.location).searchParams;

    const showButtonParams = params.get('showButton');
    if (showButtonParams) setShowButton(showButtonParams);

    const buttonTitleParams = params.get('buttonTitle');
    if (buttonTitleParams) setButtonTitle(buttonTitleParams);

    const subTitleParams = params.get('subTitle');
    if (subTitleParams) setSubTitle(subTitleParams);

    const lastSubtitleParams = params.get('lastSubtitle');
    if (lastSubtitleParams) setLastSubtitle(lastSubtitleParams);

    const isMessageParams = params.get('isMessage');
    if (isMessageParams) setIsMessage(isMessageParams);

    const textBoldParams = params.get('textBold');
    if (textBoldParams) setTextBold(textBoldParams);

    const urlButtonParams = params.get('urlButton');
    if (urlButtonParams) setUrlButton(urlButtonParams);
  }, []);

  return (
    <>
      <Container>
        <TitleSection
          title={isMessage ? 'Recebemos sua mensagem' : 'Sucesso!'}
          subtitle={subTitle}
          textBlack={textBold}
          lastSubtitle={lastSubtitle}
        />

        <Content>
          <img src={FramePop} alt="Pop with trombet" />
        </Content>

        {showButton && (
          <Button onClick={() => push(urlButton)} type="button">
            <p>{buttonTitle}</p>
          </Button>
        )}
      </Container>
    </>
  );
};

export default SucessContainer;
